import router from '@/router'
import crypto from 'crypto'
import { connectSocket } from '@/modules/socket'
export const projectURL = 'https://junxpreview.20north.de/'
const sleepSound = 'assets/audio/sandmann.mp3'
const error = new ErrorEvent('doof')
window.projectURL = projectURL
let reportType
let lastActivity
// update Status / activity
export function updateStatus () {
  console.log('TEST')
  return new Promise(function (resolve, reject) {
    if (sessionStorage.getItem('SessionID') != null && sessionStorage.getItem('SessionID') !== 'null' && (lastActivity + 60000) > Date.now()) {
      console.log('a')
      resolve()
    } else if (sessionStorage.getItem('SessionID') != null && sessionStorage.getItem('SessionID') !== 'null') {
      console.log('b')
      lastActivity = Date.now()
      $.ajax({
        url: projectURL + 'dashboard/updateStatus',
        type: 'POST',
        data: { SessionID: sessionStorage.getItem('SessionID') },
        success: function (data, textStatus, xhr) {
          console.log(data, textStatus, xhr)
          if (xhr.status === 250 || xhr.status === 220) {
            resolve(xhr.status)
          } else {
            sessionStorage.removeItem('SessionID')
            cookieLogin().then(function () {
              sessionStorage.setItem('auth', 1)
              resolve()
            }).catch(function () {
              sessionStorage.setItem('auth', 0)
              reject(error)
            })
          }
        },
        error: function (error) {
          reject(error)
        }
      })
    } else {
      console.log('c')
      sessionStorage.removeItem('SessionID')
      cookieLogin().then(function () {
        sessionStorage.setItem('auth', 1)
        resolve()
      }).catch(function () {
        sessionStorage.setItem('auth', 0)
        reject(error)
      })
    }
  })
}

export function cookieLogin () {
  return new Promise(function (resolve, reject) {
    try {
      if (localStorage.getItem('KeepID') && localStorage.getItem('KeepID').length > 5) {
        const loginData = new FormData()
        loginData.append('type', 'cookie')
        loginData.append('KeepID', localStorage.getItem('KeepID'))
        $.ajax({
          type: 'POST',
          url: window.projectURL + 'login/jsLogin',
          data: loginData,
          success: function (data, textStatus, xhr) {
            // console.log(xhr.status)
            if (xhr.status === 250) {
              const pData = JSON.parse(data)
              localStorage.setItem('account_public', pData.account_public)
              localStorage.setItem('account_type', pData.account_type)
              localStorage.setItem('email', pData.email)
              localStorage.setItem('firstname', pData.firstname)
              localStorage.setItem('age', pData.age)
              localStorage.setItem('ghostmode', pData.ghostmode)
              localStorage.setItem('darkmode', pData.darkmode)
              localStorage.setItem('volume', pData.volume)
              localStorage.setItem('lat', pData.lat)
              localStorage.setItem('long', pData.long)
              localStorage.setItem('premium_state', pData.premium_state)
              localStorage.setItem('profile_img', pData.profile_img)
              localStorage.setItem('register_status', pData.register_status)
              localStorage.setItem('role', pData.role)
              localStorage.setItem('subscribed_channels', pData.subscribed_channels)
              localStorage.setItem('user_id', pData.user_id)
              localStorage.setItem('username', pData.username)
              localStorage.setItem('uname', pData.username)
              localStorage.setItem('user', JSON.stringify(pData))
              localStorage.setItem('NodeID', pData.NodeID)
              localStorage.setItem('SessionID', pData.SessionID)
              localStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('NodeID', pData.NodeID)
              sessionStorage.setItem('SessionID', pData.SessionID)

              localStorage.setItem('encrypted', 'false')
              setJunx()
              sessionStorage.setItem('auth', 1)
              connectSocket()
              if (typeof window.wall !== 'undefined' && typeof window.wall.reloadWall !== 'undefined')window.wall.reloadWall()
              if (typeof window.chat !== 'undefined' && typeof window.chat.reloadChat !== 'undefined')window.chat.reloadChat()
              resolve()
            } else {
              localStorage.removeItem('SessionID')
              localStorage.removeItem('KeepID')
              resetApp()
              reject(error)
            }
          },
          error: function (status, xhr) {
            loader.show()
            content.hide()
            localStorage.removeItem('SessionID')
            localStorage.removeItem('KeepID')
            resetApp()
            reject(error)
          },
          cache: false,
          contentType: false,
          processData: false
        })
      } else {
        localStorage.removeItem('KeepID')
        resetApp()
        reject(error)
      }
    } catch (e) {
      reject(error)
    }
  })
}

export function report (type, id, profileID = id, wallID = id) {
  const reportMain = $('#reportMain')
  const reportMainMenu = $('#reportMainMenu')
  const reportSub = $('#reportSub')
  const reportSubMenu = $('#reportSubMenu')

  reportMain.dropdown('restore default text')
  reportSub.dropdown('restore default text')

  reportMainMenu.empty()
  reportSubMenu.empty()

  switch (type) {
    case 'u':
      type = 'profile'
      break

    case 'ch':
      type = 'chat'
      break

    case 'p':
      type = 'wall'
      break

    case 'c':
      type = 'comment'
      break

    case 's':
      type = 'story'
      break
  }

  const reportType = type
  $('#reportedID').val(id)
  $('#reportedType').val(type)
  $('#reportedProfileID').val(profileID)
  $('#reportedSpecialID').val(wallID)
  $('#reportRainbow').show()

  $.post(projectURL + 'report/getCategories', { SessionID: sessionStorage.getItem('SessionID'), postkey: sessionStorage.getItem('postkey') }, function (msg, status, xhr) {
    if (xhr.status === 250) {
      try {
        const data = JSON.parse(msg)
        for (const key in data) {
          if (data[key][reportType] === '1') {
            reportMainMenu.append('<div class="item" data-value="' + data[key].id + '">' + data[key].title + '</div>')
          }
        }
        reportMain.show()
        $('#reportRainbow').hide()
      } catch (e) {}
    } else if (xhr.status === 299) {
      cookieLogin().then(function () { location.reload() }).catch(openLogin)
    } else {
      location.reload()
    }
  })

  reportMain.dropdown('setting', 'onChange', function (val) {
    reportSubMenu.empty()
    reportSub.dropdown('restore default text')
    $.post(projectURL + 'report/getReasons', { SessionID: sessionStorage.getItem('SessionID'), postkey: sessionStorage.getItem('postkey'), reportCat: val }, function (msg, status, xhr) {
      if (xhr.status === 250) {
        $('#reportSend').prop('disabled', true)
        $('#reportDescription').hide()
        $('#reportBlock').hide()
        try {
          const data = JSON.parse(msg)
          for (const key in data) {
            if (data[key][reportType] === '1') {
              reportSubMenu.append('<div class="item" data-value="' + data[key].value + '">' + data[key].title + '</div>')
            }
          }
          reportSub.show()
        } catch (e) {}
      } else if (xhr.status === 299) {
        cookieLogin().then(function () {
          location.reload()
        }).catch(openLogin)
      } else {
        location.reload()
      }
    })
  })

  reportSub.dropdown('setting', 'onChange', function (val) {
    if (val === 'Nervt') {
      $('#reportSend').prop('disabled', true)
      $('#reportBlock').show()
      $('#reportDescription').hide()
    } else if (val !== '') {
      $('#reportSend').prop('disabled', false)
      $('#reportBlock').hide()
      $('#reportDescription').show()
    }
    $('#reportedCategory').val(val)
  })

  $('#reportedReason').val('')
  $('#reportedCategory').val('')
  $('#reportSend').prop('disabled', true)
  $('#reportDescription').hide()
  $('#reportBlock').hide()
  reportMain.hide()
  reportSub.hide()
  $('#ReportModal.ui.modal').modal('show')
}

export function sendReport (e = null) {
  if (e != null)e.preventDefault()
  $.post(projectURL + 'report', {
    postkey: sessionStorage.getItem('postkey'),
    SessionID: sessionStorage.getItem('SessionID'),
    reportedID: $('#reportedID').val(),
    reportedProfileID: $('#reportedProfileID').val(),
    reportedType: $('#reportedType').val(),
    reportedCategory: $('#reportedCategory').val(),
    reportedReason: $('#reportedReason').val(),
    reportedSpecialID: $('#reportedSpecialID').val()
  }, function () {
    $('#reportOk').modal('show')
  })
    .always(function () {
      $('#ReportModal').modal('hide')
      $('#reportForm').trigger('reset')
      $('#reportMain').dropdown('clear')
      $('#reportMainMenu').empty()
      $('#reportSub').dropdown('clear')
      $('#reportSubMenu').empty()
      $('#reportedSpecialID').empty()
    })
}

export function findGetParameter (parameterName) {
  var result = null
  var tmp = []
  location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    })
  return result
}

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export function setCookie (cName, cValue, expDays = 30) {
  const date = new Date()
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + date.toUTCString()
  document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
}

export function urlify (inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3
  if (inputText.includes('junx.app/')) {
    replacePattern1 = /(http[s]?:\/\/)?([^/\s]+\/)(.*)/gim
    replacedText = inputText.replace(replacePattern1, '<a style="font-weight: bold;" onclick="window.junx.$router.push(\'/$3\')">$2$3</a>')
    return replacedText
  }

  // URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  replacedText = inputText.replace(replacePattern1, '<a style="font-weight: bold;" onclick="window.junx.$router.push(\'/redirect?l=$1\')">$1</a>')

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(replacePattern2, '$1<a style="font-weight: bold;" onclick="window.junx.$router.push(\'/redirect?l=$2\')">$2</a>')

  return replacedText
}

export function nl2br (str, replaceMode = false, isXhtml = false) {
  const breakTag = (isXhtml) ? '<br />' : '<br>'
  const replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

export function uniCorn () {
  $.ajax({
    type: 'POST',
    url: projectURL + 'statistiken/unicorn',
    success: function (msg) {
      const data = msg.split('&')
      $('#uniCounter').text(data[0])
      $('#uniLeft').text((87684 - data[0]))
      $('#lastDeath').text(data[1])
      $('#deadUnicorns').modal('show')
    }
  })
  return 'TOTE EINHÖRNER!!!! HALLO?!'
}

export function semantic () {
  (function ($) {
    $.fn.visible = function (partial) {
      var $t = $(this)
      var $w = $(window)
      var viewTop = $w.scrollTop()
      var viewBottom = viewTop + $w.height()
      var _top = $t.offset().top
      var _bottom = _top + $t.height()
      var compareTop = partial === true ? _bottom : _top
      var compareBottom = partial === true ? _top : _bottom

      return ((compareBottom <= viewBottom) && (compareTop >= viewTop))
    }
  })(jQuery)

  /* header height and main container padding top fixed header */
  if ($('.header').hasClass('fixed-top') === true) {
    if ($('.main-container > div:first-child').hasClass('banner-hero') === true) {
      $('.main-container').css('padding-top', '0')
    } else {
      setTimeout(function () {
        $('.main-container').css('padding-top', $('.header').outerHeight())
      }, 500)
    }
  } else {
    if ($('.main-container > div:first-child').hasClass('banner-hero') === true) {
      $('.main-container').css('padding-top', '0')
    } else {
      $('.main-container').css('padding-top', '15px')
    }
  }

  /* header active on scroll more than 50 px */
  if ($('.footer-tabs').length > 0) {
    $('.main-container').css({
      'padding-bottom': $('.footer-tabs').outerHeight()
    })
    $('.footer').css({
      'padding-bottom': $('.footer-tabs').outerHeight() + 25
    })
  } else {
    $('.footer').css({
      'padding-bottom': 15
    })
  }

  /* header active on scroll more than 50 px */
  if ($(this).scrollTop() >= 30) {
    $('.header').addClass('active')
    $('.footer-spaces').addClass('active')
  } else {
    $('.header').removeClass('active')
    $('.footer-spaces').removeClass('active')
  }

  /* sidemenu close */
  $('.main-container').on('click', function () {
    if ($('.header .navbar-collapse.collapse').hasClass('show') === true) {
      $('.header .navbar-collapse.collapse').removeClass('show')
    }
    if ($('.sidebar-right').hasClass('active') === true) {
      $('.sidebar-right').removeClass('active')
      $('.colorsettings').removeClass('active')
    }
    if ($('.search').hasClass('active') === true) {
      $('.search').slideUp().removeClass('active')
    }
    if ($('body').hasClass('sidemenu-open') === true) {
      $('body').removeClass('sidemenu-open')
    }
    if ($('body').hasClass('reveal-sidebar') === true) {
      $('body').removeClass('reveal-sidebar')
    }
  })
  $('.header, .footer').on('click', function () {
    if ($('body').hasClass('sidemenu-open') === true) {
      $('body').removeClass('sidemenu-open')
    }
    if ($('body').hasClass('reveal-sidebar') === true) {
      $('body').removeClass('reveal-sidebar')
    }
  })

  /* .search button click mobile device */
  $('.search-btn').on('click', function () {
    $('.search').slideDown().addClass('active')
  })

  /* Background */
  $('.background').each(function () {
    var imgpath = $(this).find('img')
    $(this).css('background-image', 'url(' + imgpath.attr('src') + ')')
    imgpath.hide()
  })

  /* Iframes components preview resizing for devices. */
  $('.device-selection button.btn').on('click', function () {
    if ($(this).hasClass('active') !== true) {
      var parentcurrent = $(this).parent().find('.btn.active').attr('data-class')
      var parentclass = $(this).attr('data-class')
      $(this).parent().find('.btn').removeClass('active')
      $(this).addClass('active').closest('.demo-view').find('.iframeselements').addClass(parentclass).removeClass(parentcurrent)
    } else {

    }
  })

  /* nav small btn expand collapse and sidemenu open close */
  if ($('.header .navbar').hasClass('navbar-expand-all') === true) {
    $('.main-container').on('click', function () {
      $('.header .navbar .navbar-collapse').removeClass('show')
    })
  } else {}

  /* login row */
  $('.login-row').css('min-height', ($(window).height() - 80))

  /* home page hover text demo */
  $('.hover-text span').on('mouseenter', function () {
    $('.demolive-wraper').slideDown()
    $('.close-demolive-wrapper').fadeIn()

    $('.fullscreen .demolive-wraper').on('mouseleave', function () {
      var thiswrap = $(this)
      if (thiswrap.closest('.fullscreen').hasClass('active') === true) {
        thiswrap.slideUp()
        thiswrap.closest('.fullscreen').find('.close-demolive-wrapper').fadeOut()
      }
    })
  })

  $('.fullscreen.active, .close-demolive-wrapper').on('click', function () {
    $('.demolive-wraper').slideUp()
    $('.close-demolive-wrapper').fadeOut()
  })

  /* Fullscreen btn and area */
  $('.fullscreen-btn').on('click', function () {
    var fullscreenwrap = $(this).closest('.fullscreen')
    fullscreenwrap.toggleClass('active')
    $('body').toggleClass('overflow-hidden')
    if ($('.fullscreen').hasClass('active') !== true) {
      $('.demolive-wraper').slideDown()
    }
    if (!document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }
  })

  /* scroll to top  button  */
  $(window).scroll(function () {
    if ($(this).scrollTop() > 200) {
      $('.scrollup').show()
      setTimeout(function () {
        $('.scrollup').addClass('active')
      }, 500)
    } else {
      $('.scrollup').hide()
      setTimeout(function () {
        $('.scrollup').removeClass('active')
      }, 500)
    }
  })

  $('.scrollup').click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 600)
    return false
  })

  if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
    $('.scrollup').addClass('atbottom')
  } else {
    $('.scrollup').removeClass('atbottom')
  }

  /* sidebar right color scheme */
  $('.colorsettings').on('click', function () {
    $(this).toggleClass('active')
    $('.sidebar-right').toggleClass('active')
  })

  /* loading button load more */
  $('.loading-btn ').on('click', function () {
    var thisbtn = $(this)
    thisbtn.addClass('active')

    setTimeout(function () {
      thisbtn.removeClass('active').blur()
    }, 3000)
  })

  /* smooth scroll */
  $(document).on('click', '.smoothscroll', function (event) {
    event.preventDefault()

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
    }, 750)
  })

  $(window).on('scroll', function () {
    /* header active on scroll more than 50 px */
    if ($(this).scrollTop() >= 30 && $('.header').hasClass('fixed-top') === true) {
      $('.header').addClass('active')
      $('.footer-spaces').addClass('active')
    } else {
      $('.header').removeClass('active')
      $('.footer-spaces').removeClass('active')
    }

    /* scroll to top  button  hide when at bottom of page */
    if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
      $('.scrollup').addClass('atbottom')
    } else {
      $('.scrollup').removeClass('atbottom')
    }
  })

  $(window).on('resize', function () {
    /* login row */
    $('.login-row').css('min-height', ($(window).height() - 80))
  })
  $('.dropdown').dropdown()
}

export function sleepTight () {
  playSound(sleepSound, true, true)
  socket.emit('sleep', { username: localStorage.getItem('username') })
}

export function callingPulse () {
  $('#videoRequestImg').animate({
    width: 150, height: 150
  }, 700, function () {
    $('#videoRequestImg').animate({
      width: 200, height: 200
    }, 700, function () {
      callingPulse()
    })
  })

  $('#profile_img_div_2').animate({
    width: 200,
    height: 200,
    opacity: 0.5
  }, 700, function () {
    $('#profile_img_div_2').animate({
      width: 150,
      height: 150,
      opacity: 1
    }, 700, function () {
    })
  })
}

export function loadError () {
  const urlParams = new URLSearchParams(window.location.search)
  const searchId = urlParams.get('seid')
  if (searchId && searchId > 0) {
    $('#SEModal.ui.modal').modal('show')
  }
}

export function allRead () {
  socket.emit('allNotisRead', { room: sessionStorage.getItem('NodeID'), uname: localStorage.getItem('username') })
  window.junx.notisread = window.junx.notis.length
}

export function decode (obj) {
  try {
    return decodeURIComponent(escape(obj))
  } catch (e) {
    return obj
  }
}

export function makeid (length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength))
  }
  return result
}

export function encrypt (s, cipherKey) {
  const iv = crypto.randomBytes(16)
  const cipher = crypto.createCipheriv('aes-128-cbc', Buffer.from(cipherKey), iv)
  let encrypted = cipher.update(s)

  encrypted = Buffer.concat([encrypted, cipher.final()])

  return iv.toString('hex') + ':' + encrypted.toString('hex')
}

export function decrypt (s, cipherKey) {
  const textParts = s.split(':')
  const iv = Buffer.from(textParts.shift(), 'hex')
  const encryptedText = Buffer.from(textParts.join(':'), 'hex')
  const decipher = crypto.createDecipheriv('aes-128-cbc', Buffer.from(cipherKey), iv)
  let decrypted = decipher.update(encryptedText)

  decrypted = Buffer.concat([decrypted, decipher.final()])

  return decrypted.toString()
}

export function setJunx () {
  /*
  if (process.env.CORDOVA_PLATFORM !== undefined && localStorage.getItem('user_id') != null && localStorage.getItem('encrypted') !== 'false') {
    const secret = sessionStorage.getItem('bioSecret')
    try {
      localStorage.setItem('encrypted', decrypt(localStorage.getItem('encrypted'), secret))
      localStorage.setItem('account_public', decrypt(localStorage.getItem('account_public'), secret))
      localStorage.setItem('account_type', decrypt(localStorage.getItem('account_type'), secret))
      localStorage.setItem('email', decrypt(localStorage.getItem('email'), secret))
      localStorage.setItem('firstname', decrypt(localStorage.getItem('firstname'), secret))
      localStorage.setItem('age', decrypt(localStorage.getItem('age'), secret))
      localStorage.setItem('darkmode', decrypt(localStorage.getItem('darkmode'), secret))
      localStorage.setItem('ghostmode', decrypt(localStorage.getItem('ghostmode'), secret))
      localStorage.setItem('volume', decrypt(localStorage.getItem('volume'), secret))
      localStorage.setItem('lat', decrypt(localStorage.getItem('lat'), secret))
      localStorage.setItem('long', decrypt(localStorage.getItem('long'), secret))
      localStorage.setItem('postkey', decrypt(sessionStorage.getItem('postkey'), secret))
      localStorage.setItem('premium_state', decrypt(localStorage.getItem('premium_state'), secret))
      localStorage.setItem('profile_img', decrypt(localStorage.getItem('profile_img'), secret))
      localStorage.setItem('register_status', decrypt(localStorage.getItem('register_status'), secret))
      localStorage.setItem('role', decrypt(localStorage.getItem('role'), secret))
      localStorage.setItem('subscribed_channels', decrypt(localStorage.getItem('subscribed_channels'), secret))
      localStorage.setItem('user_id', decrypt(localStorage.getItem('user_id'), secret))
      localStorage.setItem('username', decrypt(localStorage.getItem('username'), secret))
      localStorage.setItem('uname', decrypt(localStorage.getItem('uname'), secret))
      sessionStorage.setItem('NodeID', decrypt(sessionStorage.getItem('NodeID'), secret))
      sessionStorage.setItem('postkey', decrypt(sessionStorage.getItem('postkey'), secret))
      sessionStorage.setItem('SessionID', decrypt(sessionStorage.getItem('SessionID'), secret))
    } catch (e) {}
  } */

  window.junx.user.account_public = localStorage.getItem('account_public')
  window.junx.user.account_type = localStorage.getItem('account_type')
  window.junx.user.email = localStorage.getItem('email')
  window.junx.user.firstname = localStorage.getItem('firstname')
  window.junx.user.age = localStorage.getItem('age')
  window.junx.user.darkMode = localStorage.getItem('darkmode') === '1'
  window.junx.user.ghostmode = localStorage.getItem('ghostmode')
  window.junx.user.volume = localStorage.getItem('volume')
  window.junx.user.lat = localStorage.getItem('lat')
  window.junx.user.long = localStorage.getItem('long')
  window.junx.user.postkey = sessionStorage.getItem('postkey')
  window.junx.user.premium_state = localStorage.getItem('premium_state')
  window.junx.user.profile_img = localStorage.getItem('profile_img')
  window.junx.user.register_status = localStorage.getItem('register_status')
  window.junx.user.role = localStorage.getItem('role')
  window.junx.user.subscribed_channels = localStorage.getItem('subscribed_channels')
  window.junx.user.user_id = localStorage.getItem('user_id')
  window.junx.user.username = localStorage.getItem('username')
  window.junx.user.uname = localStorage.getItem('uname')
}

export function encryptStorage () {
  /*
  window.junx.user = {}

  const secret = sessionStorage.getItem('bioSecret')

  localStorage.setItem('account_public', encrypt(localStorage.getItem('account_public'), secret))
  localStorage.setItem('account_type', encrypt(localStorage.getItem('account_type'), secret))
  localStorage.setItem('email', encrypt(localStorage.getItem('email'), secret))
  localStorage.setItem('firstname', encrypt(localStorage.getItem('firstname'), secret))
  localStorage.setItem('age', encrypt(localStorage.getItem('age'), secret))
  localStorage.setItem('darkmode', encrypt(localStorage.getItem('darkmode'), secret))
  localStorage.setItem('ghostmode', encrypt(localStorage.getItem('ghostmode'), secret))
  localStorage.setItem('volume', encrypt(localStorage.getItem('volume'), secret))
  localStorage.setItem('lat', encrypt(localStorage.getItem('lat'), secret))
  localStorage.setItem('long', encrypt(localStorage.getItem('long'), secret))
  localStorage.setItem('postkey', encrypt(sessionStorage.getItem('postkey'), secret))
  localStorage.setItem('premium_state', encrypt(localStorage.getItem('premium_state'), secret))
  localStorage.setItem('profile_img', encrypt(localStorage.getItem('profile_img'), secret))
  localStorage.setItem('register_status', encrypt(localStorage.getItem('register_status'), secret))
  localStorage.setItem('role', encrypt(localStorage.getItem('role'), secret))
  localStorage.setItem('subscribed_channels', encrypt(localStorage.getItem('subscribed_channels'), secret))
  localStorage.setItem('user_id', encrypt(localStorage.getItem('user_id'), secret))
  localStorage.setItem('username', encrypt(localStorage.getItem('username'), secret))
  localStorage.setItem('uname', encrypt(localStorage.getItem('uname'), secret))
  sessionStorage.setItem('NodeID', encrypt(sessionStorage.getItem('NodeID'), secret))
  sessionStorage.setItem('postkey', encrypt(sessionStorage.getItem('postkey'), secret))
  sessionStorage.setItem('SessionID', encrypt(sessionStorage.getItem('SessionID'), secret))
  localStorage.setItem('encrypted', encrypt(localStorage.getItem('encrypted'), secret))

  sessionStorage.removeItem('bioSecret') */
}

export function resetApp () {
  const darkmode = localStorage.getItem('darkmode')
  const vibrate = localStorage.getItem('vibrate')
  localStorage.clear()
  sessionStorage.clear()
  localStorage.setItem('darkmode', darkmode)
  localStorage.setItem('vibrate', vibrate)

  window.junx.chats = []
  window.junx.chatsCounter = 0
  window.junx.chatModal = false
  window.junx.callModal = false
  window.junx.notis = []
  window.junx.friends = []
  window.junx.user = {}
  window.junx.notisread = 0

  const cookies = document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
  if (process.env.CORDOVA_PLATFORM !== undefined) {
    Fingerprint.isAvailable(function () {
      localStorage.setItem('fingerprint', 1)
    }, function () {
      localStorage.setItem('fingerprint', 0)
    },
    {
      allowBackup: true
    })
  }
  if (typeof unlock !== 'undefined') unlock()
}

export function clickElement (elem) {
  // Create our event (with options)
  const evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window
  })
  // If cancelled, don't dispatch our event
  const canceled = !elem.dispatchEvent(evt)
}

export function setIconMenu (messages = 0, notis = 0) {
  if (typeof ThreeDeeTouch !== 'undefined') {
    notis = notis < 0 ? 0 : notis
    messages = messages < 0 ? 0 : messages
    const messageString = messages === 0 ? 'Chat' : 'Chat (' + messages + ')'
    const notiString = notis === 0 ? 'Benachrichtigungen' : 'Benachrichtigungen (' + notis + ')'
    ThreeDeeTouch.configureQuickActions([
      {
        type: 'notification',
        title: notiString,
        subtitle: 'Alle Benachrichtigungen',
        iconTemplate: 'Bell'
      },
      {
        type: 'messenger',
        title: messageString,
        subtitle: 'Alle Nachrichten',
        iconType: 'Message'
      },
      {
        type: 'matchgame',
        title: 'Matchgame',
        subtitle: 'Wo bleibt der Prinz?',
        iconType: 'Love'
      },
      {
        type: 'search',
        title: 'Suche',
        subtitle: 'Bilder, Posts, Nutzer...',
        iconType: 'Search'
      }
    ])
  }
}

window.report = report
window.sendReport = sendReport
window.uniCorn = uniCorn

export function playSound (src, silent = true, vibrate = false) {
  if (typeof cordova !== 'undefined') {
    try {
      window.sound.stop()
      window.sound.release()
    } catch (e) {}
    window.sound = new Media(src)
    window.sound.setVolume(localStorage.getItem('volume') / 100)
    if (silent) {
      window.sound.play({ playAudioWhenScreenIsLocked: false })
    } else {
      window.sound.play({ playAudioWhenScreenIsLocked: true })
    }

    if (vibrate && localStorage.getItem('vibrate') === '1') navigator.vibrate(50)
  } else {
    try {
      window.sound.pause()
    } catch (e) {}
    window.sound = new Audio(src)
    window.sound.volume = localStorage.getItem('volume') / 100
    window.sound.play()
  }
}
