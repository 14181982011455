<template>
  <main id="Settings" class="spaceTop">
    <div class="content-1200">
      <div class="pad-16" id="SettingsHeader">
        <div class="ui segment no-shadow no-border pad-32">
          <div class="grid-16">
            <div class="col-sp-8 row-sp-16">
              <div class="t-left">
                <a @click="back()" class="ui icon circular basic button">
                  <i class="icon left arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-sp-8 row-sp-16">
              <div class="t-right">

              </div>
            </div>
            <div class="col-sp-16 row-sp-16 t-center">
              <img id="settings_profile_img" class="ui image small centered circular ar-1-1" :src="projectURL + 'assets/images/user/' + user.profile_img">
              <h2 class="ui header centered">
                Einstellungen
                <div class="sub header">Für deinen Junx Account</div>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="ui success message" id="emailSuccess">
        <i class="close icon"></i>
        <div class="header">
          Speichern erfolgreich
        </div>
        <p>Deine neue E-Mail-Adresse wurde deinem Account hinterlegt.</p>
      </div>
      <div class="ui error message" id="emailFail" style="display: none;">
        <i class="close icon"></i>
        <div class="header">
          Fehler
        </div>
        <p>Die eingegebene E-Mail-Adresse gehört bereits zu einem Account.</p>
      </div>
      <div class="ui success message" id="passwordSuccess">
        <i class="close icon"></i>
        <div class="header">
          Passwort geändert
        </div>
        <p>Dein Passwort wurde erfolgreich geändert.</p>
      </div>
      <div class="ui error message" id="passwordDiff">
        <i class="close icon"></i>
        <div class="header">
          Bitte überprüfe deine Eingabe
        </div>
        <p>Entweder ist das Passwort zu kurz oder stimmte nicht mit der Wiederholung überein.</p>
      </div>
      <div class="ui error message" id="passwordFail">
        <i class="close icon"></i>
        <div class="header">
          Das Passwort ist falsch!
        </div>
        <p>Bitte überprüfe deine Eingabe.</p>
      </div>
      <div class="pad-8 grid-16">
        <div class="col-sp-16 col-sd-6 row-sp-16 pad-8" id="SettingsMenu">
          <div class="ui segment no-shadow no-radius no-border pad-16">
            <a @click="openSettings2('Account');" class="settings-menu-item">
              <button class="ui circular icon button">
                <i class="user icon"></i>
              </button>
              <span class="menu-title">Account</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
            <a @click="openSettings2('Notifications');" class="settings-menu-item">
              <button class="ui circular icon button">
                <i class="bell icon"></i>
              </button>
              <span class="menu-title">Benachrichtigungen</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
            <a @click="openSettings2('Security');" class="settings-menu-item">
              <button class="ui circular icon button">
                <i class="lock icon"></i>
              </button>
              <span class="menu-title">Passwort & Sicherheit</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
            <a @click="openSettings2('Privacy');" class="settings-menu-item">
              <button class="ui circular icon button">
                <i class="eye icon"></i>
              </button>
              <span class="menu-title">Privatsphäre</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
            <a @click="openSettings2('Design');" class="settings-menu-item">
              <button class="ui circular icon button">
                <i class="paint brush icon"></i>
              </button>
              <span class="menu-title">Designanpassungen</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
            <a @click="openSettings2('Membership');" class="settings-menu-item" v-show="false">
              <button class="ui circular icon button">
                <i class="fire icon"></i>
              </button>
              <span class="menu-title">Mitgliedschaft</span>
              <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
            </a>
          </div>
        </div>
        <div class="col-sp-16 col-sd-10 row-sp-16 pad-8">
          <div id="AccountSettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32 display-Dektop">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="user icon"></i>
                  <div class="content">
                    Account
                    <div class="sub header">Einstellungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>
            <div class="t-center">
              Demnächst verfügbar
            </div>
          </div>
          <div id="NotificationsSettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="bell icon"></i>
                  <div class="content">
                    Benachrichtigung
                    <div class="sub header">Einstellungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>
            <div class="grid-12">
              <form class="col-sp-12 col-tb-6 row-sp-12 ui form" id="pushForm" >
                <hr>
                <div class="pad-4">
                  <div class="ui toggle checkbox push " id="PushMasterUI"  data-check="pushNotification-master">
                    <input type="checkbox" id="PushMaster" name="PushMaster" >
                    <label style="font-weight: bold;">Push-Benachrichtigungen</label>
                  </div>
                  <br />
                  <hr>
                  <div id="push" >
                    <div class="ui toggle checkbox push " data-select="pushNotification-message">
                      <input type="checkbox" name="message">
                      <label>Nachrichten</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push " data-select="pushNotification-matches">
                      <input type="checkbox" name="matches">
                      <label>Matches</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-friendship">
                      <input type="checkbox" name="friends" >
                      <label>Freundschaftsanfragen</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-comment">
                      <input type="checkbox" name="comments">
                      <label>Kommentare</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-chatship">
                      <input type="checkbox" name="chatship" >
                      <label>Nachrichtenanfragen</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-stamp">
                      <input type="checkbox" name="stamp">
                      <label>Stamps</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-reactions">
                      <input type="checkbox" name="reaction">
                      <label>Reaktionen</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-follower">
                      <input type="checkbox" name="follower">
                      <label>Follower</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-calling">
                      <input type="checkbox" name="calling">
                      <label>Anrufe</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-tags">
                      <input type="checkbox" name="tags">
                      <label>Makierungen</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox push" data-select="pushNotification-updates">
                      <input type="checkbox" name="updates">
                      <label>Feed</label>
                    </div>
                  </div>
                </div>
              </form>
              <form id="mailForm" class="col-sp-12 col-tb-6 row-sp-12">
                <hr>
                <div class="pad-4">
                  <div class="ui toggle checkbox mail" id="MailMasterUI" data-check="emailNotification-master">
                    <input type="checkbox" id="MailMaster" name="MailMaster">
                    <label style="font-weight: bold;">E-Mail-Benachrichtigungen</label>
                  </div>
                  <br />
                  <hr>
                  <div id="mail">
                    <div class="ui toggle checkbox mail" data-select="emailNotification-message">
                      <input type="checkbox" name="message">
                      <label>Nachrichten</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox mail" data-select="emailNotification-matches">
                      <input type="checkbox" name="matches">
                      <label>Matches</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox mail" data-select="emailNotification-tags">
                      <input type="checkbox" name="tags">
                      <label>Tags</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox mail" data-select="emailNotification-friendship">
                      <input type="checkbox" name="friends">
                      <label>Freundschaftsanfragen</label>
                    </div>
                    <br />
                    <div class="ui toggle checkbox mail" data-select="emailNotification-stamp">
                      <input type="checkbox" name="stamp">
                      <label>Stamps</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr>
            <div class="pad-4">
              <div class="sub header">Lautstärke</div>
              <label>0%</label>
              <label class="t-right" style="float: right">100%</label>
              <div class="col-sp-16 row-sp-16 field">
                <input style="width: 100%;" type="range" id="volume" name="volume" step="5" min="0" max="100" data-val="settings-volume">
              </div>
            </div>
            <hr v-show="cordova">
            <div class="pad-4" v-show="cordova">
              <div class="ui toggle checkbox" id="vibrateUI">
                <input type="checkbox" id="vibrate" name="vibrate">
                <label style="font-weight: bold;">Vibration</label>
              </div>
            </div>
          </div>
          <div id="SecuritySettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="lock icon"></i>
                  <div class="content">
                    Passwort & Sicherheit
                    <div class="sub header">Einstellungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>
            <div class="ui horizontal divider" v-show="fingerprint">
              Verschlüsselung
            </div>
            <div class="grid-12" v-show="fingerprint">
              <form class="col-sp-12 row-sp-12 ui form" id="biometricForm">
                <div class="pad-4">
                  <div class="ui toggle checkbox" id="biometricUI" @click="saveFingerprint">
                    <input type="checkbox" id="biometric" name="Biometrie" :checked="biometric">
                    <label style="font-weight: bold;">Biometrische Verschlüsselung / Pin</label>
                  </div>
                </div>
              </form>
            </div>
            <form class="ui form" id="emailForm">
              <div class="ui horizontal divider">
                E-Mail Adresse ändern
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neue E-Mail Adresse</label>
                    <input type="email" name="email" placeholder="Neue E-Mail Adresse" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Passwort zur Bestätigung</label>
                    <input type="password" name="password" placeholder="Passwort" required>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button class="ui mini button" type="submit">Speichern</button>
                </div>
              </div>
            </form>
            <div class="ui negative message" id="toShort" style="display: none;">
              <div class="header">
                Dein Passwort ist leider zu kurz.
                &#x1F346;
              </div>
            </div>
            <form class="ui form" id="passwordForm">
              <div class="ui horizontal divider">
                Passwort ändern
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neues Passwort</label>
                    <input style="margin-bottom: 4px;" type="password" name="password" placeholder="Neues Passwort" required @input="toShort($event.target.value);">
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neues Passwort wiederholen</label>
                    <input type="password" name="password2" placeholder="Neues Passwort wiederholen" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Aktuelles Passwort</label>
                    <input type="password" name="current" placeholder="Aktuelles Passwort" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button class="ui mini button" type="submit">Speichern</button>
                </div>
              </div>
            </form>

            <form class="ui form" id="logoutForm">
              <div class="ui horizontal divider">
                Login von allen Geräten löschen
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <p>
                    Damit werden alle aktuell mit deinem Account angemeldeten Geräte abgemeldet.
                  </p>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button class="ui mini button" type="submit">
                    <i class="cancel icon"></i>Abmelden
                  </button>
                </div>
              </div>
            </form>
            <form class="ui form" id="deleteForm">
              <div class="ui horizontal divider">
                Junx Account löschen
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Passwort zur Bestätigung der Löschung</label>
                    <input type="password" name="password" required>
                    <p>Mit der Löschung werden alle Daten unwideruflich gelöscht!</p>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button class="ui mini button" type="submit"><i class="trash icon"></i>  Account löschen</button>
                </div>
              </div>
            </form>
          </div>
          <div id="PrivacySettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="eye icon"></i>
                  <div class="content">
                    Privatsphäre
                    <div class="sub header">Einstellungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>
            <input type="hidden" id="ProfileValue" name="ProfileValue">
            <div class="grid-12" id="channel-selection">
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div class="ui segment t-center pad-16 h-100" id="SelectionPublic" onclick="selectPublicFunc();" data-active="accountPublic-1">
                  <div class="checked-icon"><img src="assets/images/channels/check.png"/></div>
                  <div class="selectContent">
                    <img src="assets/images/public.png"/>
                    <h4 class="pad-16">Öffentliches Profil</h4>
                    <p>Dein Profil und Beiträge sind für alle Junx Benutzer sichtbar.</p>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div class="ui segment t-center pad-16 h-100" id="SelectionPrivate" onclick="selectPrivateFunc();" data-active="accountPublic-0">
                  <div class="checked-icon"><img src="assets/images/channels/check.png"/></div>
                  <div class="selectContent">
                    <img src="assets/images/private.png"/>
                    <h4 class="pad-16">Privates Profil</h4>
                    <p>Dein Profil und Beiträge sind nur für deine Freunde sichtbar.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="DesignSettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="paint brush icon"></i>
                  <div class="content">
                    Design
                    <div class="sub header">Anpassungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>
            <div class="grid-12">
              <form class="col-sp-12 row-sp-12 ui form" id="darkForm">
                <div class="pad-4">
                  <div class="ui toggle checkbox" id="DarthVaderUI" data-select="settings-darkmode">
                    <input type="checkbox" id="DarthVader" name="DarthVader">
                    <label style="font-weight: bold;">Darkmode</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div id="MembershipSettings" class="SettingsPanel ui segment no-shadow no-radius no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a @click="openSettingsMenu();" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="fire icon"></i>
                  <div class="content">
                    Mitgliedschaft
                    <div class="sub header">Einstellungen</div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8"></div>

            <a href="membership">
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <h4 class="ui header">
                    Mitgliedschaft ändern
                    <div class="sub header">Ändere oder kündige deine Mitgliedschaft</div>
                  </h4>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <i class="icon angle right"></i>
                </div>
              </div>
            </a>

          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from '@/router'
import { initSettings, toShort, selectPublicFunc, selectPrivateFunc, openSettings2, openSettingsMenu } from '@/modules/settings'
import { makeid } from '@/modules/main'

export default {
  name: 'settings',
  title: 'Junx | Einstellungen',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL,
      vibrate: parseInt(localStorage.getItem('vibrate'))
    }
  },
  computed: {
    fingerprint () {
      return false // localStorage.getItem('fingerprint') === '1' && localStorage.getItem('KeepID') !== null && localStorage.getItem('KeepID').length > 5
    },
    biometric () {
      return false // localStorage.getItem('biometric') === '1'
    },
    cordova () {
      return typeof cordova !== 'undefined'
    }
  },
  methods: {
    back () {
      router.back()
    },
    toShort (s) {
      toShort(s)
    },
    selectPublicFunc () {
      selectPublicFunc()
    },
    selectPrivateFunc () {
      selectPrivateFunc()
    },
    openSettings2 (s) {
      openSettings2(s)
    },
    openSettingsMenu () {
      openSettingsMenu()
    },
    saveFingerprint () {
      window.ignorePause = true
      const _this = this
      const bioSecret = makeid(16)
      console.log(6)
      Fingerprint.show({
        title: 'Junx Biometrische Verschlüsselung',
        description: 'warte auf Bestätigung....',
        disableBackup: false,
        fallbackButtonTitle: 'Pin verwenden',
        cancelButtonTitle: 'abbrechen'
      }, function () {
        if (localStorage.getItem('biometric') !== '1') {
          Fingerprint.registerBiometricSecret({
            title: 'Junx Biometrische Verschlüsselung',
            description: 'warte auf Bestätigung....',
            fallbackButtonTitle: 'Pin verwenden',
            cancelButtonTitle: 'abbrechen',
            secret: bioSecret,
            invalidateOnEnrollment: true,
            disableBackup: false // always disabled on Android
          }, successCallback, errorCallback)
        } else {
          localStorage.setItem('biometric', '0')
          $('#biometricUI').checkbox('uncheck')
        }
      }, function () {
        console.log('error fingerprint')
      })

      function successCallback () {
        localStorage.setItem('biometric', 1)
        sessionStorage.setItem('bioSecret', bioSecret)
        $('#biometricUI').checkbox('check')
      }

      function errorCallback () {
        localStorage.setItem('biometric', 0)
        $('#biometricUI').checkbox('uncheck')
      }
    }
  },
  mounted () {
    initSettings()
    window.junx.loading = false
  }
}
</script>

<style scoped>

</style>
