<template>
  <div id="page-eltern"  class="spaceTop">
    <section class="bg-white">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-sd-8 row-sp-16 pad-40">
          <div class="pad-16"></div>
          <h1 class="ui header">
            <img src="assets/images/emoticons/fragen.png">
            <div class="content">
              Informationen für Eltern
              <div class="sub header">Was ist Junx?</div>
            </div>
          </h1>
          <p>
            Junx ist die Community, die es sich zur Aufgabe gemacht hat, queere Jungs* zu verbinden und für einen sicheren Austausch zu sorgen. Wir sind ein Ort, an dem jeder* seine Erlebnisse und Erfahrungen mit allen unbesorgt teilen kann, ohne Missbrauch - in welcher Form auch immer - erfahren zu müssen. Egal ob homo-, trans-, bi-, inter-, asexuell - oder irgendetwas dazwischen. Junx ist ein zweites Zuhause.
          </p>
          <div class="pad-8"></div>
          <a href="faq" class="ui large button btn-color-1">Zu unseren FAQs</a>
        </div>
        <div class="col-sp-16 col-sd-8 row-sp-16 pad-40">
          <div class="pad-24"></div>
          <img src="assets/images/eltern-information-fuer-junx.jpg" class="br-16" style="transform: rotate(3deg); max-width: 500px; width: 100%;">
        </div>
      </div>
      <div class="pad-40"></div>
    </section>
    <section class="bg-color-1">
      <div class="content-1400">
        <div class="grid-12 pad-16">
          <div class="col-sp-12 col-tb-4 row-sp-16 pad-16">
            <div class="ui segment fluid no-shadow no-border pad-24 h-100">
              <h3 class="ui header">
                <i class="check icon color-2"></i>
                <div class="content">
                  Jugendschutz
                  <div class="sub header">
                    Der Schutz unserer User steht an erster Stelle.<br>
                    <a href="jugendschutz">Mehr Infos</a>
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-16 pad-16">
            <div class="ui segment fluid no-shadow no-border pad-24 h-100">
              <h3 class="ui header">
                <i class="check icon color-2"></i>
                <div class="content">
                  Datenschutz
                  <div class="sub header">
                    Deine Daten sind sicher bei uns.<br>
                    <router-link to="/datenschutz">Mehr Infos</router-link>
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-16 pad-16">
            <div class="ui segment fluid no-shadow no-border pad-24 h-100">
              <h3 class="ui header">
                <i class="check icon color-2"></i>
                <div class="content">
                  Sicheres Umfeld
                  <div class="sub header">
                    Keine Chance für Ausgrenzungen oder ähnliches.
                  </div>
                </div>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="content-1400">
        <div class="grid-16 pad-16">
          <div class="col-sp-16 col-tb-6 row-sp-16 pad-8 t-center">
            <img src="assets/images/messenger-mockup.png" style="transform: rotate(-3deg); max-width: 300px;" class="responsive-img">
          </div>
          <div class="col-sp-16 col-tb-10 row-sp-16">
            <div class="pad-8">
              <div class="ui segment fluid no-shadow no-border pad-24 h-100">
                <h3 class="ui header">
                  Digital & Regional - Wir kooperieren mit Jugendzentren
                </h3>
                <p>
                  Nicht nur in Großstädten, auch in kleineren Gemeinden und Städten gibt es Jugendzentren und gemeinnützige soziale Projekte die sich speziell um die Bedürfnisse von queeren Kindern- und Jugendlichen kümmern.
                  Innerhalb der Junx App, sind die Jugendzentren mit eigenen Profilen vertreten. Die Nutzer* erhalten auf diesen Informationen zu regelmäßigen Treffen oder Hilfsangeboten und können sich direkt mit den lokalen Anlaufstellen vernetzen.
                </p>
              </div>
            </div>
            <div class="pad-8">
              <div class="ui segment fluid no-shadow no-border pad-24 h-100">
                <h3 class="ui header">
                  Spaß, aber sicher!
                </h3>
                <p>
                  Viele der gängigen App-Angebote die sich an queere Nutzer* richten, werben vor allem mit der Möglichkeit amouröse Kontakte herzustellen.
                  Auch Junx versteht sich als Community Plattform, jedoch fördern wir Freundschaften und keine Flirts.
                  Durch Filterfunktionen gewährleisten wir, dass alle öffentlichen Inhalte nicht sexualisiert und jugendfrei sind. Um zu verhindern dass Nutzer ungewollte Inhalte oder Nachrichten von Ihnen fremden Profilen erhalten, muss eine private Kontaktaufnahme vorab von beiden Seiten bestätigt werden.
                  Unser Support Team ist für die Nutzer* jederzeit erreichbar, sollte es trotzdem zu unangenehmen Situationen oder Belästigungen kommen.                        </p>
              </div>
            </div>
            <div class="pad-8">
              <div class="ui segment fluid no-shadow no-border pad-24 h-100 grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
                  <h3 class="ui header">
                    Noch offene Fragen?
                    <div class="sub header">Dann kontakiere uns gerne</div>
                  </h3>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-8 t-right">
                  <router-link class="ui button btn-color-1" to="/kontakt">
                    <i class="envelope icon"></i> Zum Kontaktformular
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'eltern',
  title: 'Junx | Eltern',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-eltern{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
#page-eltern p{color: rgba(0,0,0,0.6);}
/* Darkmode */
.dark #page-eltern p{color: rgba(255,255,255,0.6);}
</style>
