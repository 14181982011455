<template>
  <div id="matchApp">
    <div id="EmojiRain" v-if="page=='matchgame'"></div>
    <div id="deleteProfile" class="ui mini modal">
      <div class="header t-center">
        Bist du dir sicher?
      </div>
      <div class="actions" style="text-align: center;">
        <div class="ui circular button deny" onclick="$('#deleteProfile').modal('hide');">
          <i class="icon cancel"></i> Abbrechen
        </div>
        <div class="ui circular button approve btn-color-1" @click="deleteProfile()">
          <i class="icon check"></i> Löschen
        </div>
      </div>
    </div>
    <div id="maxVoteModal" class="ui mini modal">
      <div class="content inverted t-center">
        <img src="assets/images/matchgame/menu/sad.png">
        <h3 class="ui inverted header">
          Alle Votes aufgebraucht
          <div class="sub header">Du kannst heute keine weiteren Votes vergeben</div>
        </h3>
        <div class="pad-4">
          <a href="membership" class="ui fluid button btn-inverted-color">Ich will mehr Votes</a>
        </div>
        <div class="pad-4">
          <router-link to="/wall" class="ui fluid button inverted basic">Zurück zur Wall</router-link>
        </div>
      </div>
    </div>
    <div id="maxSuperVoteModal" class="ui mini modal">
      <div class="content inverted t-center">
        <img src="assets/images/matchgame/menu/super-like.png">
        <h3 class="ui inverted header">
          Superlikes aufgebraucht
          <div class="sub header">Du kannst heute keinen weiteren Superlike vergeben</div>
        </h3>
        <div class="pad-4" v-if="premium == 0">
          <a href="membership" class="ui fluid button btn-inverted-color">Ich will mehr Superlikes</a>
        </div>
        <div class="pad-4">
          <button class="ui fluid button inverted basic" onclick="$('#maxSuperVoteModal').modal('hide');">Ohne weiter Matchen</button>
        </div>
      </div>
    </div>
    <div id="goBackModal" class="ui mini modal">
      <div class="content inverted t-center">
        <img src="assets/images/matchgame/menu/sad.png">
        <h3 class="ui inverted header">
          Nur für Unterstützer
          <div class="sub header">Diese Funktion ist nur für Junx Unterstützer freigeschaltet</div>
        </h3>
        <div class="pad-4" v-if="premium == 0">
          <a href="membership" class="ui fluid button btn-inverted-color">Unterstützer werden</a>
        </div>
        <div class="pad-4">
          <button class="ui fluid button inverted basic" onclick="$('#goBackModal').modal('hide');">Ohne weiter Matchen</button>
        </div>
      </div>
    </div>
    <!--start-->
    <section id="page-matches" v-if="page == 'start'">
      <div class="content-600 pad-16 bg-white">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <a class="ui button basic icon labeled" onclick="window.junx.$router.back();">
              <i class="arrow left icon"></i> Zurück
            </a>
          </div>
          <div class="col-sp-12 row-sp-12">
            <div class="pad-8"></div>
            <h2 class="ui centered header">
              <img src="assets/images/matchgame/two-hearts.png">
              <div class="sub header color-1">Junx</div>
              Matches
            </h2>
            <div class="pad-16"></div>
          </div>
          <div class="col-sp-3 row-sp-12">
            <button class="ui button btn-inverted-color">
              <img src="assets/images/smileys/1f468.png"/>
            </button>
          </div>
          <div class="col-sp-9 row-sp-12">
            <h5 class="ui header">
              <div class="content">
                Zeige wer du bist
                <div class="sub header">
                  Erstelle ein eigenes Match Profil mit einer extra Beschreibung. Dieses wird andere Jungs zusammen mit deiner angegebenen Stadt angezeigt.
                </div>
              </div>
            </h5>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8"></div>
          <div class="col-sp-3 row-sp-12">
            <button class="ui button btn-inverted-color">
              <img src="assets/images/smileys/1f446.png"/>
            </button>
          </div>
          <div class="col-sp-9 row-sp-12">
            <div class="pad-4"></div>
            <h5 class="ui header">
              Swipe
              <div class="sub header">
                Swipe dich durch Junx - vielleicht findest du ja den ein oder anderen bekannten
              </div>
            </h5>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8"></div>
          <div class="col-sp-3 row-sp-12">
            <button class="ui button btn-inverted-color">
              <img src="assets/images/smileys/1f468-2764-1f468.png"/>
            </button>
          </div>
          <div class="col-sp-9 row-sp-12">
            <div class="pad-4"></div>
            <h5 class="ui header">
              It’s a Match.
              <div class="sub header">
                Lerne durch die Matchfunktion neue Junx mit gleichen Interessen kennen.
              </div>
            </h5>
          </div>
          <div class="col-sp-12 row-sp-12 pad-24"></div>
        </div>
        <button type="button" @click="page = 'register'" class="ui button btn-color-1 fluid large">
          Los gehts!
        </button>
      </div>
    </section>
    <!--register-->
    <section class="profile" v-if="page == 'register'">
      <div v-if="regStep == 1">
        <div class="content-600 grid-12 pad-8" id="profileimage-selection">
          <div class="col-sp-12 row-sp-12 pad-8">
            <a href="matchgame" class="ui button icon labeled basic"><i class="arrow left icon"></i>Zurück</a>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8 t-center">
            <h2 class="ui header">
              Zeig uns wer du bist!
              <div class="sub header">Lade dein Matchbild hoch</div>
            </h2>
            <div class="pad-16">
              <label for="datei">
                <a>
                  <img style="max-width: 240px;" class="ui image br-8" src="assets/images/matchgame/matchgame-default.jpg"/>
                  <button onclick="document.getElementById('datei').click();" class="ui circular icon massive button btn-color-1" style="position:relative; z-index: 3; margin-top: -120px; margin-right: -120px;">
                    <i class="icon upload"></i>
                  </button>
                </a>
              </label>
            </div>
          </div>
        </div>
        <form id="uploadForm">
          <input type="file" id="datei" @change="uploadFile()" style="display: none;" value="" name="postimage" accept="image/png, image/jpg, image/jpeg" >
        </form>
      </div>
      <div v-if="regStep == 2">
        <div class="content-600 grid-12 pad-8">
          <div class="col-sp-12 row-sp-12 pad-8">
            <a href="matchgame" class="ui button icon labeled basic"><i class="arrow left icon"></i>Zurück</a>
          </div>
          <div class="col-sp-12 row-sp-12 pad-16 t-center">
            <h3 class="ui header">
              Schneide dein Bild zu
              <div class="sub header">
                Achte darauf, dass du gut auf dem Bild zu erkennen bist
              </div>
            </h3>
            <p>Makiere den Bereich auf dem Bild, welches du als Profilbild nutzen möchtest und klicke danach auf Speichern.</p>
          </div>
          <div class="col-sp-12 row-sp-12 pad-16">
            <div>
              <img :src="projectURL + 'assets/images/temp/'+uploadImg" id="target" class="responsive-img" style="display: block;">
            </div>
            <form id="cropForm">
              <input type="hidden" id="x" name="x" />
              <input type="hidden" id="y" name="y" />
              <input type="hidden" id="w" name="w" />
              <input type="hidden" id="h" name="h" />
              <input type="hidden" id="ow" name="ow" />
              <input type="hidden" id="oh" name="oh" />
              <input type="hidden" name="file" :value="uploadImg"/>
              <div class="pad-8 t-center">
                <button id="savecrop" type="button" @click="crop()" class="ui fluid purple disabled button avatar-save">Weiter</button>
              </div>
            </form>
          </div>
          <div class="pad-16"></div>
        </div>
      </div>
      <section class="content-600"  v-if="regStep == 3">
        <div class="pad-16">
          <a href="matchgame" class="ui button icon labeled basic"><i class="arrow left icon"></i>Zurück</a>
        </div>
        <form id="profileForm" class="pad-16">
          <div class="ui fluid card" style="margin: 0 auto; max-width: 500px;">
            <div class="image" style="max-width: 200px; margin: 0 auto;">
              <img :src="projectURL + '/assets/images/matchgame/'+tempImg" >
            </div>
            <div class="content">
              <a class="header t-center">{{firstname}}, {{age}}</a>
              <div class="meta t-center">
                <span class="date"><i class="icon map marker alternate" ></i> {{city}}</span>
              </div>
              <div class="ui form">
                <div class="field">
                  <textarea rows="3" name="description" maxlength="80" placeholder="Schreibe etwas über dich (Maximal 80 Zeichen)" required :value="matchgameDescription"></textarea>
                </div>
              </div>
            </div>
            <div class="field">
              <button @click="saveProfile()" type="button" class="ui button icon fluid btn-color-3">
                <span v-if="register == 0">Los gehts!</span>
                <span v-else>Speichern</span>
              </button>
            </div>
          </div>
        </form>
        <div class="pad-40"></div>
      </section>
    </section>
    <!--matchgame-->
    <section v-if="page == 'matchgame'" style="margin-top: -24px;" id="matchgame">
      <div id="SettingsMatch" class="ui mini modal">
        <i class="close icon"></i>
        <div class="header"><i class="bars icon"></i> Menü</div>
        <div class="content">
          <div class="pad-4">
            <button class="ui basic labeled fluid icon button" onclick="$('#FilterModal').modal('show');">
              <i class="sliders horizontal icon"></i> Filter
            </button>
          </div>
          <div class="pad-4">
            <a @click="editPic()" onclick="$('#SettingsMatch.ui.modal').modal('hide');" class="ui basic labeled icon button fluid">
              <i class="image icon"></i> Bild ändern
            </a>
          </div>
          <div class="pad-4">
            <a @click="openReg()" onclick="$('#SettingsMatch.ui.modal').modal('hide');" class="ui basic labeled icon button fluid">
              <i class="edit icon"></i> Beschreibung bearbeiten
            </a>
          </div>
          <div class="pad-4">
            <a @click="openMatchesVotes()" onclick="$('#SettingsMatch.ui.modal').modal('hide');" class="ui basic labeled icon button fluid">
              <i class="heart icon"></i> Meine Matches & Votes
            </a>
          </div>
          <div class="pad-4">
            <button type="button" class="ui basic labeled icon button fluid" onclick="$('#deleteProfile').modal('show')">
              <i class="trash icon"></i> Matchprofil löschen
            </button>
          </div>
        </div>
        <div class="actions">
          <button type="button" class="ui button"  onclick="$('#SettingsMatch.ui.modal').modal('hide');">
            Abbrechen
          </button>
        </div>
      </div>
      <section>
        <div>
          <div id="MatchTopMenu" class="grid-12 pad-16">
            <div class="col-sp-3 col-sd-6 row-sp-12 t-left">
              <a class="ui basic icon button" onclick="window.junx.$router.back();">
                <i class="arrow left icon"></i>
              </a>
            </div>
            <div class="col-sp-6 row-sp-12 t-center display-Mobile">
              <router-link to="/wall">
                <img class="logo_main" style="max-width: 100%; margin-top: -16px" src=""/>
              </router-link>
            </div>
            <div class="col-sp-3 col-sd-6 row-sp-12 t-right">
              <button class="ui basic icon button" onclick="$('#SettingsMatch.ui.modal').modal('show');">
                <i class="bars icon"></i>
              </button>
            </div>
          </div>
          <div class="ui mini modal" id="FilterModal">
            <i class="close icon"></i>
            <div class="header"><i class="sliders horizontal icon"></i> Matches Filter</div>
            <div class="content">
              <div class="ui form">
                <div class="field">
                  <label>Entfernung: ({{calcDistance}}km)</label>
                  <input class="ui input fluid" type="range" name="distance" v-model="distance" min="1" max="31.7" step="any">
                </div>
                <div class="field">
                  <label>Min. Alter: ({{ageMin}} Jahre)</label>
                  <input class="ui input fluid" type="range" name="ageMin" v-model="ageMin" min="14" max="29">
                </div>
                <div class="field">
                  <label>Max. Alter: ({{ageMax}} Jahre)</label>
                  <input class="ui input fluid" type="range" name="ageMax" v-model="ageMax" min="14" max="29">
                </div>
              </div>
            </div>
            <div class="actions">
              <button class="ui button btn-color-1" onclick="$('#FilterModal').modal('hide');" @click="updateCards">Los gehts!</button>
            </div>
          </div>
          <div v-if="noMoreCards && candidates.length == 0">
            <div class="ui card no-border no-shadow julian">
              <div class="image">
                <img src="assets/images/matchgame/matchgameend.jpg">
              </div>
              <div class="content">
                <div class="header t-center">Ups, keine Junx mehr</div>
                <div class="description t-center">
                  <p>
                    Schaue dir jetzt deine Matches an oder schaue später
                    nochmal vorbei.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!noMoreCards && candidates.length == 0">
            <div class="rainbowloader" id="matchrainbowloader">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div name="stack" class="junx_card-cont" id="stack">
            <img src="assets/images/matchgame/match-cover.png" class="responsive-img"/>
            <candidate v-for="(candidate, key) in candidates" :candidate="candidate" :key="candidate.id" :counter="key" :projecturl="projectURL"></candidate>
          </div>
          <div id="MatchMenu" v-if="candidates.length > 0 && !match">
            <button class="ui circular white mini icon button match" @click="resetVote()" :disabled="oldCandidates.length < 1">
              <img src="assets/images/matchgame/menu/back.png">
            </button>
            <button class="ui circular white tiny icon button match" @click="dislike(0)">
              <img src="assets/images/matchgame/menu/dislike.png">
            </button>
            <button class="ui circular white tiny icon button match" @click="like(1)">
              <img src="assets/images/matchgame/menu/super-like.png">
            </button>
            <button class="ui circular white tiny icon button match" @click="like(0)">
              <img src="assets/images/matchgame/menu/like.png">
            </button>
            <button class="ui circular white mini icon button match display-Dektop-inline-important" @click="openChat(true)">
              <img src="assets/images/matchgame/menu/message.png">
            </button>
            <button class="ui circular white mini icon button match display-Mobile-inline-important" @click="openChat(false)">
              <img src="assets/images/matchgame/menu/message.png">
            </button>
          </div>
        </div>
      </section>
    </section>
    <section v-if="page == 'matches'">
      <div class="content-1000 pad-16">
        <a class="ui button basic" @click="page = 'matchgame'">
          <i class="arrow left icon"></i> Zurück
        </a>
        <div class="pad-8"></div>
        <div class="two ui buttons">
          <a @click="subPage = 'matches'" class="ui button" :class="{'btn-color-1': (subPage == 'matches')}">Meine Matches</a>
          <a @click="subPage = 'votes'" class="ui button" :class="{'btn-color-1': (subPage == 'votes')}">Meine Votes</a>
        </div>
        <div v-if="subPage == 'matches'">
          <div class="ui segment no-border no-shadow no-radius">
            <h3 class="ui header">
              <img src="assets/images/matchgame/menu/match.png">
              <div class="content">
                Deine Matches
                <div class="sub header">die letzten 20 Matches</div>
              </div>
            </h3>
          </div>
          <div class="grid-16">
            <match v-for="match in matches" :match="match" :key="match.id" :projecturl="projectURL"></match>
          </div>
        </div>
        <div v-else>
          <div class="ui segment no-border no-shadow no-radius">
            <h3 class="ui header">
              <img src="assets/images/matchgame/menu/like.png">
              <div class="content">
                Deine Votes
                <div class="sub header">die letzten 20 votes</div>
              </div>
            </h3>
          </div>
          <div class="grid-16">
            <vote v-for="vote in votes" :vote="vote" :key="vote.id" :projecturl="projectURL"></vote>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import candidate from '@/components/matchgame/candidate'
import match from '@/components/matchgame/match'
import vote from '@/components/matchgame/vote'
export default {
  name: 'matchgame',
  title: 'Junx | Match',
  components: {
    candidate,
    match,
    vote
  },
  data () {
    return {
      projectURL: window.projectURL,
      animate: true,
      matchID: null,
      channels: [],
      votes: [],
      matches: [],
      candidates: [],
      oldCandidates: [],
      register: 0,
      regStep: 1,
      uploadImg: false,
      loading: true,
      page: 'matchgame',
      subPage: 'matches',
      username: localStorage.getItem('username'),
      profile_img: localStorage.getItem('profile_img'),
      premium: parseInt(localStorage.getItem('premium_state')),
      city: null,
      firstname: localStorage.getItem('firstname'),
      age: localStorage.getItem('age'),
      matchImg: null,
      tempImg: null,
      matchgameDescription: '',
      match: false,
      noMoreCards: false,
      ageMin: 14,
      ageMax: 29,
      distance: Math.sqrt(1000)
    }
  },
  methods: {
    // open user profile
    openProfile (username) {
      router.push('/p/' + username)
    },
    // upload user match image
    uploadFile () {
      const matchApp = this
      window.junx.loading = true
      const uploadData = new FormData(document.getElementById('uploadForm'))
      uploadData.append('SessionID', sessionStorage.getItem('SessionID'))
      uploadData.append('postkey', sessionStorage.getItem('postkey'))
      $.ajax({
        url: window.projectURL + 'matchgame/uploadImage',
        type: 'POST',
        data: uploadData,
        success: function (msg, textStatus, xhr) {
          if (xhr.status === 250) {
            try {
              const data = JSON.parse(msg)
              matchApp.uploadImg = data.uploadImg
              matchApp.regStep = 2
              setTimeout(function () {
                matchApp.cropper()
              }, 10)
            } catch (e) {
              matchApp.error = true
              window.junx.loading = false
            }
          } else if (xhr.status === 299) {
            matchApp.error = true
            window.junx.loading = false
          }
        },
        error: function (err) {
          console.log(err)
        },
        cache: false,
        contentType: false,
        processData: false
      })
    },

    cropper () {
      const matchApp = this
      const Cropper = window.Cropper
      const minCroppedWidth = 90
      const minCroppedHeight = 180

      const image = document.getElementById('target')
      const cropper = new Cropper(image, {
        aspectRatio: 9 / 16,
        zoomable: false,
        crop (event) {
          if (
            event.detail.width < minCroppedWidth ||
              event.detail.height < minCroppedHeight
          ) {
            cropper.setData({
              width: minCroppedWidth,
              height: minCroppedHeight
            })
          }
          document.getElementById('savecrop').classList.remove('disabled')
          document.getElementById('x').value = event.detail.x
          document.getElementById('y').value = event.detail.y
          document.getElementById('w').value = event.detail.width
          document.getElementById('h').value = event.detail.height
        }
      })
      window.junx.loading = false
    },
    // crop user match image
    crop () {
      const matchApp = this
      window.junx.loading = true
      const uploadData = new FormData(document.getElementById('cropForm'))
      uploadData.append('SessionID', sessionStorage.getItem('SessionID'))
      uploadData.append('postkey', sessionStorage.getItem('postkey'))
      $.ajax({
        url: window.projectURL + 'matchgame/crop',
        type: 'POST',
        data: uploadData,
        success: function (msg, textStatus, xhr) {
          // console.log(msg);return;
          if (xhr.status === 250) {
            try {
              const data = JSON.parse(msg)
              matchApp.regStep = 3
              matchApp.tempImg = data.matchImg
              window.junx.loading = false
            } catch (e) {
              matchApp.error = true
              window.junx.loading = false
            }
          } else if (xhr.status === 251) {
            matchApp.error = 'Zu viel Haut. Bitte wähle ein anderes Bild'
            window.junx.loading = false
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { location.reload() }).catch(openLogin)
          } else {
            openLogin()
          }
        },
        cache: false,
        contentType: false,
        processData: false
      })
    },
    // create or save user match profile
    saveProfile () {
      const matchApp = this
      window.junx.loading = true
      const uploadData = new FormData(document.getElementById('profileForm'))
      uploadData.append('SessionID', sessionStorage.getItem('SessionID'))
      uploadData.append('postkey', sessionStorage.getItem('postkey'))
      $.ajax({
        url: window.projectURL + 'matchgame/saveProfile',
        type: 'POST',
        data: uploadData,
        success: function (msg, textStatus, xhr) {
          if (xhr.status === 250) {
            try {
              const data = JSON.parse(msg)
              localStorage.setItem('match_img', data.matchImg)
              matchApp.register = 1
              matchApp.city = data.city
              matchApp.matchgameDescription = data.description
              matchApp.matchImg = data.matchImg
              matchApp.regStep = 1
              matchApp.page = 'matchgame'
              window.junx.loading = false
            } catch (e) {
              matchApp.error = true
              window.junx.loading = false
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { location.reload() }).catch(openLogin)
          } else {
            openLogin()
          }
        },
        cache: false,
        contentType: false,
        processData: false
      })
    },
    // load data for match and vote history
    openMatchesVotes () {
      const matchApp = this
      window.junx.loading = true
      this.page = 'matches'
      $.post(window.projectURL + 'matchgame/loadMatchesVotes', { SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
        if (xhr.status === 250) {
          try {
            const data = JSON.parse(msg)
            matchApp.votes = data.votes
            matchApp.matches = data.matches
          } catch (e) { matchApp.error = true }
          window.junx.loading = false
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { location.reload() }).catch(openLogin)
        } else {
          matchApp.error = 'Allgemeiner Fehler...'
          window.junx.loading = false
        }
      })
    },
    // open registry tab, keep image
    openReg () {
      this.regStep = 3
      this.page = 'register'
    },
    // open registry tab, change image
    editPic () {
      this.regStep = 1
      this.page = 'register'
    },
    // delete match profile
    deleteProfile () {
      const matchApp = this
      window.junx.loading = true
      $.post(window.projectURL + 'matchgame/deleteProfile', { postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
        if (xhr.status === 250) {
          try {
            window.junx.$router.push('/wall')
          } catch (e) {
            matchApp.error = 'Da ging was schief... bitte wende dich an den Support unter support@junx.app'
            window.junx.loading = false
          }
        } else if (xhr.status === 220) {
          openAdvancedRegister()
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { location.reload() }).catch(openLogin)
        } else {
          matchApp.error = 'Allgemeiner Fehler...'
          window.junx.loading = false
        }
      })
    },
    // load new candidates
    updateCards (reset = false) {
      const matchApp = this
      if ((this.candidates.length < 5 && !this.noMoreCards && !matchApp.updating) || reset) {
        matchApp.updating = true
        const result1 = matchApp.candidates.map(a => a.id)
        const result2 = matchApp.oldCandidates.map(a => a.id)
        let stack
        if (reset) {
          stack = result2
        } else {
          stack = result1.concat(result2)
        }
        $.post(window.projectURL + 'matchgame/getCandidates', {
          postkey: sessionStorage.getItem('postkey'),
          SessionID: sessionStorage.getItem('SessionID'),
          ageMin: matchApp.ageMin,
          ageMax: matchApp.ageMax,
          distance: matchApp.calcDistance,
          stack: stack
        }
        , function (msg, status, xhr) {
          if (xhr.status === 250) {
            try {
              const data = JSON.parse(msg)
              matchApp.noMoreCards = (data.candidates.length <= 7)
              for (const key in data.candidates) {
                data.candidates[key].description = decodeURIComponent(escape(data.candidates[key].description))
              }
              if (reset) {
                matchApp.candidates = data.candidates
              } else {
                matchApp.candidates = matchApp.candidates.concat(data.candidates)
              }
            } catch (e) { matchApp.error = true }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { location.reload() }).catch(openLogin)
          } else if (xhr.status === 200) {
            openLogin()
          }
          matchApp.updating = false
        })
          .always(function () {
            matchApp.updating = false
          })
          .fail(function () {
            matchApp.error = 'Allgemeiner Fehler...'
            window.junx.loading = false
          })
      }
    },
    // like candidate
    like (mega = 0) {
      console.log('like')
      const matchApp = this
      $card = $('.junx_card:not(.inactive):first')
      $cardReject = $('.junx_card_choice.m--reject', $card)
      $cardLike = $('.junx_card_choice.m--like', $card)
      $cardSuper = $('.junx_card_choice.m--super', $card)
      const id = $card.attr('id')

      try {
        const key = matchApp.candidates.findIndex(x => x.id === id)
        if (mega === 0) {
          matchApp.candidates[key].vote = 'like'
          $cardLike.css('opacity', 1)
        } else {
          matchApp.candidates[key].vote = 'super'
          $cardSuper.css('opacity', 1)
        }
        setTimeout(function () {
          $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
            .find('.junx_card_choice').attr('style', '')
          matchApp.oldCandidates.push(matchApp.candidates.splice(key, 1)[0])
          animating = false
        }, 300)
        this.updateCards()
      } catch (e) { console.error(e) }

      // send to db
      $.post(window.projectURL + 'matchgame/voteLike', { postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID'), vuid: id, super: mega }, function (msg, status, xhr) {
        if (xhr.status === 269) {
          try {
            const data = JSON.parse(msg)
            setTimeout(function () {
              matchApp.itsAMatch(data.mid)
            }, 300)
          } catch (e) {}
        } else if (xhr.status === 251) {
          // to many votes
          $('#maxVoteModal').modal('show')
          setTimeout(function () {
            matchApp.failedCard(id)
          }, 300)
        } else if (xhr.status === 252) {
          // to many superlikes
          $('#maxSuperVoteModal').modal('show')
          setTimeout(function () {
            matchApp.failedCard(id)
          }, 300)
        } else if (xhr.status === 253) {
          // premium to revert cards
          $('#goBackModal').modal('show')
          setTimeout(function () {
            matchApp.failedCard(id)
          }, 300)
        } else if (xhr.status === 299) {
          // try relogin
          cookieLogin().then(function () { location.reload() }).catch(openLogin)
        }
      }).fail(function () {
        setTimeout(function () {
          matchApp.failedCard(id)
        }, 300)
      })
    },
    // dislike candidate
    dislike () {
      console.log('dislike')
      const matchApp = this
      $card = $('.junx_card:not(.inactive):first')
      $cardReject = $('.junx_card_choice.m--reject', $card)
      $cardLike = $('.junx_card_choice.m--like', $card)
      $cardSuper = $('.junx_card_choice.m--super', $card)
      $cardReject.css('opacity', 1)
      const id = $card.attr('id')

      try {
        const key = matchApp.candidates.findIndex(x => x.id === id)

        if (key === -1) {
          animating = false
          return
        }
        matchApp.candidates[key].vote = 'dislike'
        if ([33, 1, 11].includes(parseInt(id))) {
          const howDareYou = new Audio('assets/audio/howdareyou.mp3')
          howDareYou.volume = localStorage.getItem('volume') / 100
          howDareYou.play()
          setTimeout(function () {
            matchApp.candidates[0].reset = true
            setTimeout(function () {
              matchApp.candidates[0].vote = ''
              setTimeout(function () {
                $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
                  .find('.junx_card_choice').attr('style', '')
                matchApp.candidates[0].reset = false
                setTimeout(function () {
                  matchApp.candidates[0].vote = 'super'
                  pullDeltaX = 0
                  pullDeltaY = 0
                  setTimeout(function () {
                    $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
                      .find('.junx_card_choice').attr('style', '')
                    matchApp.oldCandidates.push(matchApp.candidates.splice(key, 1)[0])
                    animating = false
                  }, 300)
                }, 1000)
              }, 300)
            }, 50)
          }, 300)
        } else {
          setTimeout(function () {
            $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
              .find('.junx_card_choice').attr('style', '')
            matchApp.oldCandidates.push(matchApp.candidates.splice(key, 1)[0])
            animating = false
          }, 300)
        }
        this.updateCards()
      } catch (e) { console.error(e) }
      // send to db
      $.post(window.projectURL + 'matchgame/voteDislike', { postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID'), vuid: id }, function (msg, status, xhr) {
        if (xhr.status === 251) {
          // max votes decline
          $('#maxVoteModal').modal('show')
          setTimeout(function () {
            matchApp.failedCard(id)
          }, 300)
        } else if (xhr.status === 253) {
          // premium to revert cards
          $('#goBackModal').modal('show')
          setTimeout(function () {
            matchApp.failedCard(id)
          }, 300)
        } else if (xhr.status === 299) {
          // try relogin
          cookieLogin().then(function () { location.reload() }).catch(router.push('/login'))
        }
      }).fail(function (status, xhr) {
        setTimeout(function () {
          matchApp.failedCard(id)
        }, 300)
      })
    },
    // revert vote
    resetVote () {
      const matchApp = this
      if (parseInt(localStorage.getItem('premium_state')) < 1) return
      animating = true
      try {
        matchApp.candidates.unshift(matchApp.oldCandidates.pop())
        matchApp.candidates[0].reset = true
        setTimeout(function () {
          matchApp.candidates[0].vote = ''
          setTimeout(function () {
            $card = $('.junx_card:not(.inactive):first')
            $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
              .find('.junx_card_choice').attr('style', '')
            matchApp.candidates[0].reset = false

            pullDeltaX = 0
            pullDeltaY = 0
            animating = false
          }, 300)
        }, 20)
      } catch (e) {
        animating = false
      }
    },
    // trigger to handle failed votes
    failedCard (id) {
      const matchApp = this
      animating = true
      try {
        const key = matchApp.oldCandidates.findIndex(x => x.id === id)
        if (key === -1) {
          animating = false
          return
        }
        matchApp.candidates.unshift(matchApp.oldCandidates.splice(key, 1)[0])
        matchApp.candidates[0].reset = true
        setTimeout(function () {
          matchApp.candidates[0].vote = ''
          $card = $('.junx_card:not(.inactive):first')
          $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
            .find('.junx_card_choice').attr('style', '')
          setTimeout(function () {
            matchApp.candidates[0].reset = false
            pullDeltaX = 0
            pullDeltaY = 0
            animating = false
          }, 300)
        }, 20)
      } catch (e) {
        animating = false
      }
    },
    // its a match
    itsAMatch (id) {
      const matchApp = this
      animating = true
      try {
        const key = matchApp.oldCandidates.findIndex(x => x.id === id)
        if (key === -1) {
          animating = false
          return
        }
        console.log(0)
        this.itsAMatchAnimation()
        this.match = true
        /* let audios = ['match1.mp3', 'match2.mp3'],
             audio = new Audio('assets/audio/'+audios[Math.round(Math.random())]);
         audio.volume = localStorage.getItem('volume')/100;
         audio.play(); */

        matchApp.candidates.unshift(matchApp.oldCandidates.splice(key, 1)[0])
        matchApp.candidates[0].reset = true
        matchApp.candidates[0].matched = true
        setTimeout(function () {
          matchApp.candidates[0].vote = ''
          $card = $('.junx_card:not(.inactive):first')
          $card.css({ transform: 'translate(0px,0px) rotate(0deg)', opacity: 1 })
            .find('.junx_card_choice').attr('style', '')
          setTimeout(function () {
            matchApp.candidates[0].reset = false
            pullDeltaX = 0
            pullDeltaY = 0
            animating = false
          }, 300)
        }, 20)
      } catch (e) {
        animating = false
      }
    },
    // open chat
    openChat () {
      const matchApp = this
      const id = $('.junx_card:not(.inactive):first').attr('id')
      try {
        const key = matchApp.candidates.findIndex(x => x.id === id)
        if (key === -1) return
        openChat(matchApp.candidates[key].username)
      } catch (e) {}
    },
    // open chat in iframe
    openMessenger () {
      const matchApp = this
      const id = $('.junx_card:not(.inactive):first').attr('id')
      try {
        const key = matchApp.candidates.findIndex(x => x.id === id)
        if (key === -1) return
        openMessenger(matchApp.candidates[key].username)
      } catch (e) {}
    },
    // close this card
    closeMatch () {
      const matchApp = this
      matchApp.animate = false
      this.match = false
      setTimeout(function () {
        matchApp.candidates[0].vote = 'bye'
        matchApp.candidates[0].match = false
        setTimeout(function () {
          matchApp.candidates.splice(0, 1)
        }, 300)
      }, 20)
    },

    release () {
      const matchApp = this
      if (pullDeltaY <= -decisionVal) {
        matchApp.like(1)
      } else if (pullDeltaX >= decisionVal) {
        matchApp.like()
      } else if (pullDeltaX <= -decisionVal) {
        matchApp.dislike()
      }
      if (Math.abs(pullDeltaX) < decisionVal && Math.abs(pullDeltaY) < decisionVal) {
        $card.addClass('reset')
      }

      setTimeout(function () {
        $card.css({
          transform: 'translate(0px,0px) rotate(0deg)',
          opacity: 1
        }).removeClass('reset')
          .find('.junx_card_choice').attr('style', '')

        pullDeltaX = 0
        pullDeltaY = 0
        animating = false
      }, 300)
    },

    pullChange () {
      const matchApp = this
      animating = true
      deg = pullDeltaX / 10

      const opacityX = pullDeltaX / 100
      const opacityY = pullDeltaY / 100
      const rejectOpacity = (opacityX >= 0) ? 0 : Math.abs(opacityX) / 2
      const superOpacity = (opacityY >= 0) ? 0 : Math.abs(opacityY) / 2
      const likeOpacity = (opacityX <= 0) ? 0 : opacityX / 2
      const hide = (1 - Math.abs(opacityX) / 3 - Math.abs(opacityY) / 4 <= 0) ? 0 : 1 - Math.abs(opacityX) / 3 - Math.abs(opacityY) / 4
      $card.css({
        transform: 'translate(' + pullDeltaX + 'px, ' + pullDeltaY + 'px) rotate(' + deg + 'deg)',
        opacity: hide
      })
      $cardReject.css('opacity', rejectOpacity)
      $cardLike.css('opacity', likeOpacity)
      $cardSuper.css('opacity', superOpacity)
    },

    itsAMatchAnimation () {
      const matchApp = this
      const container = document.getElementById('EmojiRain')
      const emoji = ['💖', '🧡', '💛', '💚', '💙', '💜', '😍']
      // let emoji = ['🎁','🎈', '🥳', '✨', '🎂', '🎉'];
      // let emoji = ['🍆','🍑'];
      let circles = []
      let stop = false

      for (let i = 0; i < 7; i++) {
        const width = window.innerWidth
        const widthPart = width / 200
        for (let i = 0; i < widthPart; i++) {
          addCircle(i * 150, [10 + width / widthPart * i, 200], emoji[Math.floor(Math.random() * emoji.length)])
          addCircle(i * 150, [10 + width / widthPart * i, -200], emoji[Math.floor(Math.random() * emoji.length)])
        }
      }

      function addCircle (delay, range, color) {
        setTimeout(function () {
          const c = new Circle(range[0] + Math.random() * range[1], 100 - Math.random() * 4, color, {
            x: -0.15 + Math.random() * 0.3,
            y: 1 + Math.random() * 4
          }, range)
          circles.push(c)
        }, delay)
      }

      function Circle (x, y, c, v, range) {
        const _this = this
        this.x = x
        this.y = y
        this.color = c
        this.v = v
        this.range = range
        let init = true
        this.element = document.createElement('span')
        /* this.element.style.display = 'block'; */
        this.element.style.opacity = 0
        this.element.style.position = 'absolute'
        this.element.style.fontSize = '48px'
        this.element.style.color = 'hsl(' + (Math.random() * 360 | 0) + ',80%,50%)'
        this.element.innerHTML = c
        container.appendChild(this.element)

        this.update = function () {
          if (_this.y < 0 || init) {
            init = false
            _this.y = window.innerHeight - Math.random() * 4
            _this.x = _this.range[0] + Math.random() * _this.range[1]
          }
          _this.y -= _this.v.y
          _this.x += _this.v.x
          this.element.style.opacity = 1
          this.element.style.transform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)'
          this.element.style.webkitTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)'
          this.element.style.mozTransform = 'translate3d(' + _this.x + 'px, ' + _this.y + 'px, 0px)'
        }
      }

      function animate () {
        for (const i in circles) {
          circles[i].update()
        }
        if (matchApp.animate && !stop) {
          requestAnimationFrame(animate)
        } else if (!stop) {
          matchApp.animate = true
          requestAnimationFrame(animate)
          $('#EmojiRain').css('opacity', 0)
          setTimeout(function () {
            $('#EmojiRain').empty()
            circles = []
            $('#EmojiRain').css('opacity', 1)
            stop = true
          }, 600)
        }
      }
      animate()
    }
  },
  computed: {
    calcDistance () {
      const dist = Math.round(Math.pow(parseFloat(this.distance), 2))
      return (dist > 999) ? 1000 : dist
    }
  },
  watch: {
    ageMin () {
      if (this.ageMax < this.ageMin) this.ageMin = this.ageMax
    },
    ageMax () {
      if (this.ageMax < this.ageMin) this.ageMax = this.ageMin
    }
  },
  mounted () {
    window.ptr = false
    const matchApp = this
    $(document).off('mousedown touchstart', '.junx_card:not(.inactive, .matched)')
    $(document).off('mousemove touchmove')
    $(document).off('mouseup touchend')
    $(document).on('mousedown touchstart', '.junx_card:not(.inactive, .matched)', function (e) {
      if (animating) return

      $card = $(this)
      $cardReject = $('.junx_card_choice.m--reject', $card)
      $cardLike = $('.junx_card_choice.m--like', $card)
      $cardSuper = $('.junx_card_choice.m--super', $card)
      const startX = e.pageX || e.originalEvent.touches[0].pageX
      const startY = e.pageY || e.originalEvent.touches[0].pageY

      $(document).on('mousemove touchmove', function (e) {
        const x = e.pageX || e.originalEvent.touches[0].pageX
        const y = e.pageY || e.originalEvent.touches[0].pageY
        pullDeltaX = (x - startX)
        pullDeltaY = (y - startY)
        if (!pullDeltaX && !pullDeltaY) return
        matchApp.pullChange()
      })

      $(document).on('mouseup touchend', function (e) {
        $(document).off('mousemove touchmove mouseup touchend')
        if (!pullDeltaX && !pullDeltaY) return // prevents from rapid click events
        matchApp.release()
      })
    })

    $.post(window.projectURL + 'matchgame/indexjs', { postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
      window.junx.loading = false
      if (xhr.status === 250) {
        try {
          const data = JSON.parse(msg)
          matchApp.channels = data.channels
          matchApp.subChannels = data.userProfile.subscribed_channels
          matchApp.matchID = data.userProfile.id
          matchApp.register = (data.matchGame) ? 1 : 0
          matchApp.city = data.userProfile.name
          matchApp.matchgameDescription = (data.userProfile.description != null && data.userProfile.description !== '') ? decodeURIComponent(escape(data.userProfile.description)) : ''
          matchApp.matchImg = data.userProfile.image
          matchApp.tempImg = data.userProfile.image
          matchApp.noMoreCards = (data.candidates.length <= 7)
          matchApp.candidates = data.candidates
          for (const key in data.candidates) {
            data.candidates[key].description = decodeURIComponent(escape(data.candidates[key].description))
          }
          if (data.userProfile.filter != null) {
            const filter = JSON.parse(data.userProfile.filter)
            matchApp.ageMin = parseInt(filter.ageMin)
            matchApp.ageMax = parseInt(filter.ageMax)
            matchApp.distance = Math.sqrt(parseInt(filter.distance))
          }
          if (matchApp.register === 0)matchApp.page = 'start'
        } catch (e) { matchApp.error = true }
        window.junx.loading = false
      } else if (xhr.status === 220) {
        openAdvancedRegister()
      } else if (xhr.status === 299) {
        cookieLogin().then(function () { location.reload() }).catch(openLogin)
      } else if (xhr.status === 200) {
        openLogin()
      } else {
        matchApp.error = 'Allgemeiner Fehler...'
        window.junx.loading = false
      }
    }).fail(function () {
      matchApp.error = 'Allgemeiner Fehler...'
      window.junx.loading = false
    })
    $(document).keyup(function (e) {
      if (animating || matchApp.match) return
      animating = true
      switch (e.which) {
        case 37: matchApp.dislike(); break
        case 38: matchApp.like(1); break
        case 39: matchApp.like(); break
        case 40: matchApp.resetVote(); break
      }
    })
  }
}
let animating = false
const decisionVal = 80
let pullDeltaX = 0
let pullDeltaY = 0
let deg = 0
let $card, $cardReject, $cardLike, $cardSuper
</script>

<style>
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#EmojiRain{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.6s;
  z-index: -1;
}
@media (min-width: 992px) {
  #matchApp{
    padding-top: 48px;
  }
}
@media (max-width: 991px) {
  #matchApp{
    margin-top: calc(constant(safe-area-inset-top) + 80px) !important;
    margin-top: calc(env(safe-area-inset-top) + 80px) !important;
  }
}
.junx_card-cont {
  width: 80vw;
  max-height: calc(100% - 130px);
  aspect-ratio: 9/16;
  position: absolute;
  left: 50%;
  top: calc(constant(safe-area-inset-top) + 50% + 15px);
  top: calc(env(safe-area-inset-top) + 50% + 15px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (min-width: 992px) {
  .junx_card-cont{
    max-height: calc(100% - 220px);
    max-width: 350px;
  }
}
.junx_card {
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: 50% 100%;
  overflow: hidden;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.junx_card.reset {
  transition: transform 0.3s;
  transform: translateX(0) !important;
}
.junx_card.reset .junx_card_choice {
  transition: opacity 0.3s;
  opacity: 0 !important;
}
.junx_card.matched{
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(1.1);
  margin-top: 48px;
}
.junx_card.inactive {
  transition: transform 0.3s, opacity 0.3s;
}
.junx_card.to-left {
  transform: translateX(-30rem) rotate(-30deg) !important;
  opacity: 0 !important;
}
.junx_card.to-right {
  transform: translateX(30rem) rotate(30deg) !important;
  opacity: 0 !important;
}
.junx_card.to-top {
  transform: translateY(-30rem) !important;
  opacity: 0 !important;
}
.junx_card.bye {
  transform: perspective(500px) translateZ(400px) translateY(30rem);
  opacity: 0 !important;
}
.junx_card__top {
  position: absolute;
  top: 0;
  padding: 8px 4px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 6;
}
.junx_card__top.purple {
  background: #7132b9;
}
.junx_card__img {
  overflow: hidden;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  border: 0.5rem solid #ffffff;
}
.junx_card__name {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
}
.junx_card__btm {
  padding-top: 32px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%);
  color: rgba(255,255,255,1);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 32px;
  text-align: center;
}
.junx_card__btm a{
  font-size: 0.8em;
}
.junx_card__we {
  text-align: center;
  font-size: 0.9em;
  padding: 0 8px;
}
.junx_card_choice {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: center;
  padding-top: 75%;
}
.junx_card_choice.m--reject {
  background: #ff0000;
}
.junx_card_choice.m--super {
  background: #390055;
}
.junx_card_choice.m--like {
  background: #59e303;
}
.junx_card__drag {
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
}
#MatchTopMenu{
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 0px;
  z-index: 99;
  position: relative;
}
#MatchMenu{
  margin: 0 auto;
  text-align: center;
  padding-bottom: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}
#MatchMenu img{
  padding: 4px;
}
#MatchMenu button.ui.mini{
  background-color: rgba(255,255,255,0.8);
  border: 1px solid rgba(0,0,0,0.15);
}
#MatchMenu button.ui.mini img{
  max-width: 32px;
}
#MatchMenu button.ui.tiny{
  background-color: #ffffff;
  border: 1px solid rgba(0,0,0,0.15);
}
#MatchMenu button.ui.tiny img{
  max-width: 44px;
}
#maxVoteModal,
#maxSuperVoteModal,
#goBackModal{
  background-color: #390055!important;
  color: #FFFFFF!important;
}
button.ui.match {
  transition: transform 0.3s;
}
button.ui.match:active {
  -webkit-transform:scale(1.5);
  transform:scale(1.5);
  transition: transform 0.05s !important;
}
.junx_card .interessen div{
  margin-bottom: 4px;
}
.junx_card .interessen img{
  width: 24px;
}
.ui.basic.button.matchInverted{
  background-color: #0000003b !important;
  -webkit-box-shadow: 0px 0px 0px 2px rgb(255 255 255 / 50%) inset !important;
  box-shadow: 0px 0px 0px 2px rgb(255 255 255 / 50%) inset !important
}
.ui.card.julian{
  position: absolute !important;
  margin-top: constant(26vh + env(safe-area-inset-top)) !important;;
  margin-top: calc(26vh + env(safe-area-inset-top)) !important;;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Darkmode */
.dark #MatchMenu button.ui.tiny{background-color: #000000;}
.dark #MatchMenu button.ui.mini{background-color: #000000;}
</style>
