<template>
  <section class="pad-16">
    <div class="rainbowloader forumLoader" v-if="subLoading">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
      </div>
    </div>
    <div class="ui icon message" v-if="!(question.id > 0) && !subLoading">
      <i class="search icon"></i>
      <div class="content">
        <div class="header">
          Leider nichts gefunden!
        </div>
        <p>
          Wir konnten keine Ergebnisse zu deiner Suche finden.<br>
          Stelle doch gerne selber eine Frage.
        </p>
      </div>
    </div>
    <question-item :question="question" :answers="answers" :favAnswer="favAnswer" v-if="question.id > 0"></question-item>
  </section>
</template>

<script>
import questionItem from '@/components/forum/question'
import axios from 'axios'
import { decode } from '@/modules/main'
export default {
  name: 'question',
  props: ['questionid'],
  components: {
    questionItem
  },
  data () {
    return {
      question: {},
      answers: [],
      favAnswer: [],
      projectURL: window.projectURL,
      subLoading: true
    }
  },
  methods: {
    loadQuestion () {
      window.junx.loading = false
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('qid', this.questionid)
      axios.post(this.projectURL + 'forum/question', formData)
        .then(function (response) {
          if (response.status === 250) {
            const data = response.data
            if (typeof data.question !== 'undefined') {
              data.question.title = decode(data.question.title)
              _this.question = data.question
              for (const key in data.answers) {
                if (data.answers[key].id === _this.question.favorite) {
                  _this.favAnswer.push(data.answers[key])
                } else {
                  _this.answers.push(data.answers[key])
                }
              }
            }
            _this.subLoading = false
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.loadQuestion()).catch(() => _this.$router.push('/login'))
          }
        })
    },
    favoriteAnswer (aid) {
      console.log(aid)
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('aid', aid)
      formData.append('qid', this.question.id)
      axios.post(this.projectURL + 'forum/favoriteAnswer', formData)
        .then(function (response) {
          if (response.status === 250) {
            _this.question.favorite = aid
            if (_this.favAnswer.length > 0) _this.answers.push(_this.favAnswer.shift())
            for (const key in _this.answers) {
              if (_this.answers[key].id === aid) {
                _this.favAnswer.push(_this.answers.splice(key, 1)[0])
              }
            }
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.favoriteAnswer(aid)).catch(() => _this.$router.push('/login'))
          }
        })
    },
    deleteAnswer (aid) {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('aid', aid)
      axios.post(this.projectURL + 'forum/deleteAnswer', formData)
        .then(function (response) {
          if (response.status === 250) {
            const index = _this.answers.findIndex(el => el.id === aid)
            if (index !== -1)_this.answers.splice(index, 1)
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.deleteAnswer(aid)).catch(() => _this.$router.push('/login'))
          }
        })
    },
    deleteQuestion () {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('qid', this.question.id)
      axios.post(this.projectURL + 'forum/deleteQuestion', formData)
        .then(function (response) {
          if (response.status === 250) {
            _this.$router.push('/forum')
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.deleteQuestion()).catch(() => _this.$router.push('/login'))
          }
        })
    },
    vote (vote) {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('question', this.question.id)
      formData.append('vote', vote)
      axios.post(this.projectURL + 'forum/voteQuestion', formData)
        .then(function (response) {
          _this.question.downVotes = response.data.downVotes
          _this.question.upVotes = response.data.upVotes
          _this.question.ownVote = vote
        })
    },
    voteAnswer (id, vote) {
      console.log(id + ' - ' + vote)
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('answer', id)
      formData.append('vote', vote)
      axios.post(this.projectURL + 'forum/voteAnswer', formData)
        .then(function (response) {
          for (const key in _this.answers) {
            if (_this.answers[key].id === id) {
              _this.answers[key].downVotes = response.data.downVotes
              _this.answers[key].upVotes = response.data.upVotes
              _this.answers[key].ownVote = vote
            }
          }
          for (const key in _this.favAnswer) {
            if (_this.favAnswer[key].id === id) {
              _this.favAnswer[key].downVotes = response.data.downVotes
              _this.favAnswer[key].upVotes = response.data.upVotes
              _this.favAnswer[key].ownVote = vote
            }
          }
        })
    }
  },
  watch: {
    questionid () {
      this.loadQuestion()
    }
  },
  mounted () {
    this.loadQuestion()
  }
}
</script>

<style scoped>

</style>
