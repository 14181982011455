<template>
  <div id="hoverModal" class="ui segment no-shadow profile-preview">
    <h5 class="ui header active">
      <img id="hoverModalImg" class="ui avatar image">
      <div class="content">
        <span id="hoverModalFirstName">Vorname</span>
        <div id="hoverModalUserName" class="sub header active">
          @username
        </div>
      </div>
    </h5>
  </div>
  <div class="ui mini deletePost modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deletePost.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui mini deleteComment modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deleteComment.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui tiny modal" id="FilterSearchModal">
    <div class="header">Suche nach Jungs</div>
    <div class="content">
      <form class="ui form" id="searchForm">
        <div class="two fields">
          <div class="field">
            <label>Alter</label>
            <select class="ui fluid dropdown clear" name="ageMin">
              <option value="-" selected>von</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
            </select>
          </div>
          <div class="field">
            <label><br></label>
            <select class="ui fluid dropdown" name="ageMax">
              <option value="-" selected>bis</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
            </select>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Ort</label>
            <input type="text" name="city" placeholder="Ort">
          </div>
          <div class="field">
            <label>oder maximale Entfernung</label>
            <input type="number" name="distance" placeholder="50km">
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Lebensweise</label>
            <select class="ui fluid dropdown" name="lifestyle[]" multiple>
              <option selected disabled>Auswahl</option>
              <option value="Omnivore (Alles)">Omnivore (Alles)</option>
              <option value="Vegetarier">Vegetarier</option>
              <option value="Veganer">Veganer</option>
              <option value="Flexitarier">Flexitarier</option>
              <option value="Pescetarier">Pescetarier</option>
              <option value="Frutarier">Frutarier</option>
              <option value="Halal">Halal</option>
              <option value="Koscher">Koscher</option>
              <option value="Diät">Diät</option>
              <option value="Zöliakie">Zöliakie</option>
              <option value="Anderes">Anderes</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
          <div class="field">
            <label>Raucher</label>
            <select class="ui fluid dropdown" name="smoking_state[]" multiple>
              <option value="Raucher">Raucher</option>
              <option value="Nichtraucher">Nichtraucher</option>
              <option value="Partyraucher">Partyraucher</option>
              <option value="Gelegenheitsraucher">Gelegenheitsraucher</option>
              <option value="Shisharaucher">Shisharaucher</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Tätigkeit</label>
            <select class="ui fluid dropdown" name="job[]" multiple>
              <option value="Schüler">Schüler</option>
              <option value="Student">Student</option>
              <option value="FSJ / FÖJ">FSJ / FÖJ</option>
              <option value="Azubi">Azubi</option>
              <option value="Angestellter">Angestellter</option>
              <option value="Selbstständig">Selbstständig</option>
              <option value="Arbeitssuchend">Arbeitssuchend</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
          <div class="field">
            <label>Wohnsituation</label>
            <select class="ui fluid dropdown" name="living[]" multiple>
              <option value="Bei der Familie">Bei der Familie</option>
              <option value="Wohngemeinschaft">Wohngemeinschaft</option>
              <option value="Wohnheim / Internat">Wohnheim / Internat</option>
              <option value="Hotels">Hotels</option>
              <option value="Wohnungslos">Wohnungslos</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Geschlecht</label>
            <select class="ui fluid dropdown" name="gender[]" multiple>
              <option value="Männlich">Männlich</option>
              <option value="Weiblich">Weiblich</option>
              <option value="Männlich (cis)">Männlich (cis)</option>
              <option value="Weiblich (cis)">Weiblich (cis)</option>
              <option value="Männlich (ftm)">Männlich (ftm)</option>
              <option value="Weiblich (mtf)">Weiblich (mtf)</option>
              <option value="Nonbinary">Nonbinary</option>
              <option value="Agender">Agender</option>
              <option value="Demiboy">Demiboy</option>
              <option value="Demigirl">Demigirl</option>
              <option value="Genderqueer">Genderqueer</option>
              <option value="Genderfluid">Genderfluid</option>
              <option value="Genderflux">Genderflux</option>
              <option value="Bigender">Bigender</option>
              <option value="Mensch">Mensch</option>
              <option value="Keine Schublade">Keine Schublade</option>
              <option value="Unsicher">Unsicher</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
          <div class="field">
            <label>Coming-Out</label>
            <select class="ui fluid dropdown" name="outing[]" multiple>
              <option value="Überall geoutet">Überall geoutet</option>
              <option value="bei Familie und Freunden">bei Familie und Freunden</option>
              <option value="bei Familie">bei Familie</option>
              <option value="bei Freunden">bei Freunden</option>
              <option value="Ungeoutet">Ungeoutet</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Orientierung</label>
            <select class="ui fluid dropdown" name="orientation[]" multiple>
              <option value="Heterosexuell">Heterosexuell</option>
              <option value="Homosexuell">Homosexuell</option>
              <option value="Bisexuell">Bisexuell</option>
              <option value="Pansexuell">Pansexuell</option>
              <option value="Androsexuell">Androsexuell</option>
              <option value="Gynosexuell">Gynosexuell</option>
              <option value="Polysexuell">Polysexuell</option>
              <option value="Skoliosexuell">Skoliosexuell</option>
              <option value="Queer">Queer</option>
              <option value="Asexuell">Asexuell</option>
              <option value="Gray-Asexuell">Gray-Asexuell</option>
              <option value="Keine Schublade">Keine Schublade</option>
              <option value="Unsicher">Unsicher</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
          <div class="field">
            <label>Beziehungsstatus</label>
            <select class="ui fluid dropdown" name="relationship[]" multiple>
              <option value="Single">Single</option>
              <option value="Vergeben">Vergeben</option>
              <option value="Polyamorische Beziehung">Polyamorische Beziehung</option>
              <option value="Offene Beziehung">Offene Beziehung</option>
              <option value="Verlobt">Verlobt</option>
              <option value="Verheiratet">Verheiratet</option>
              <option value="Verliebt">Verliebt</option>
              <option value="Es ist kompliziert">Es ist kompliziert</option>
              <option value="Keine Angabe">Keine Angabe</option>
            </select>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" tabindex="0" class="hidden" name="avatar">
              <label>Nur mit Profilbild</label>
            </div>
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" tabindex="0" class="hidden" name="fakecheck">
              <label>Nur mit FakeCheck</label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="actions">
      <button class="ui button" onclick="$('#FilterSearchModal').modal('hide');">Abbrechen</button>
      <button class="ui button"  id="resetButton">Zurücksetzen</button>
      <button class="ui labeled icon button btn-color-1" id="searchButton"><i class="search icon"></i> Suchen</button>
    </div>
  </div>
  <!-- End Modal -->
  <div id="page-search" class="spaceTop">
    <div class="content-1200" id="searchApp">
      <div>
        <div class="ui segment no-shadow no-border pad-8 no-radius h-100">
          <div class="ui form pad-8">
            <div class="field">
              <div class="ui icon input">
                <input type="search" placeholder="Suche..." name="s" class="fluid" id="searchInput" autocomplete="off" v-model="searchString">
                <i class="circular search link icon"></i>
              </div>
            </div>
          </div>
          <div id="SearchMobileMenu">
            <div class="ui secondary pointing menu">
              <a id="SearchMenuMedia" class="searchmenuitem item active" @click="openSearch('Media');">
                <i class="images icon"></i>
              </a>
              <a id="SearchMenuUser" class="searchmenuitem item" @click="openSearch('User');">
                <i class="users icon"></i>
              </a>
              <a id="SearchMenuHashtags" class="searchmenuitem item" @click="openSearch('Hashtags');">
                <i class="hashtag icon"></i>
              </a>
              <a id="SearchMenuForum" class="searchmenuitem item" @click="openSearch('Forum');">
                <i class="comments icon"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-12 spaceBottom">
        <div id="SearchResultsMedia" class="searchresults col-sp-12 row-sp-12">
          <div class="rainbowloader" v-if="loading">
            <div class="loader-inner">
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
            </div>
          </div>
          <div id="MansoryGaleryGrid" class="grid">
            <div class="grid-sizer"></div>
            <image-comp>
            </image-comp>
          </div>
          <div class="col-sp-12 row-sp-12 pad-32 t-center" v-if="empty">
            <img src="assets/images/likes/sad-but-relieved-face.png">
            <h3 class="ui center aligned header">
              <div class="content">
                Keine Ergebnisse gefunden
                <div class="sub header">Bitte passe deine Suchanfrage an</div>
              </div>
            </h3>
          </div>
        </div>
        <div id="SearchResultsUser" class="searchresults col-sp-12 row-sp-12">
          <div class="grid-12">
            <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            </div>
            <div class="col-sp-12 col-sd-6 row-sp-12 pad-8 t-right">
              <button class="ui floating basic circular labeled icon dropdown button">
                <i class="sort amount down icon"></i>
                <span class="text">Zufällig</span>
                <div class="menu">
                  <div class="header">
                    Sortieren nach
                  </div>
                  <div class="item" @click="this.order = 'last'">
                    Letzte Aktivität
                  </div>
                  <div class="item" @click="this.order = 'distance'">
                    Entfernung
                  </div>
                  <div class="item" @click="this.order = 'new'">
                    Neue User zuerst
                  </div>
                  <div class="item" @click="this.order = 'asc'">
                    Alphabetisch
                  </div>
                  <div class="item" @click="this.order = 'random'">
                    Zufällig
                  </div>
                </div>
              </button>
              <button class="ui basic circular labeled icon button" onclick="$('#FilterSearchModal').modal('show');">
                <i class="sliders horizontal icon"></i> Filter
              </button>
            </div>
          </div>
          <div class="grid-12">
            <user>
            </user>
            <div class="col-sp-12 row-sp-12 pad-32 t-center" v-if="empty">
              <img src="assets/images/likes/sad-but-relieved-face.png">
              <h3 class="ui center aligned header">
                <div class="content">
                  Keine Ergebnisse gefunden
                  <div class="sub header">Bitte passe deine Suchanfrage an</div>
                </div>
              </h3>
            </div>
          </div>
        </div>
        <div id="SearchResultsHashtags" class="searchresults col-sp-12 row-sp-12">
          <div class="pad-8">
            <div class="ui segment no-shadow no-border trending pad-24">
              <div class="content grid-12">
                <div class="col-sp-12 col-hp-6 col-tb-4 col-sd-3 row-sp-12">
                  <h3 class="ui header" style="padding: 16px 0">
                    <img src="assets/images/emoticons/flame.png">
                    <div class="content">
                      Trending
                      <div class="sub header">Hashtags</div>
                    </div>
                  </h3>
                </div>
                <div class="col-sp-12 col-hp-6 col-tb-8 col-sd-9 row-sp-12" id="trendingHashtags">
                </div>
              </div>
            </div>
            <div id="posts">
              <div class="rainbowloader" id="Postloader">
                <div class="loader-inner">
                  <div class="loader-line-wrap">
                    <div class="loader-line">
                    </div>
                  </div>
                  <div class="loader-line-wrap">
                    <div class="loader-line">
                    </div>
                  </div>
                  <div class="loader-line-wrap">
                    <div class="loader-line">
                    </div>
                  </div>
                  <div class="loader-line-wrap">
                    <div class="loader-line">
                    </div>
                  </div>
                  <div class="loader-line-wrap">
                    <div class="loader-line">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-32 t-center" v-if="empty">
              <img src="assets/images/likes/sad-but-relieved-face.png">
              <h3 class="ui center aligned header">
                <div class="content">
                  Keine Ergebnisse gefunden
                  <div class="sub header">Bitte passe deine Suchanfrage an</div>
                </div>
              </h3>
            </div>
          </div>
        </div>
        <div id="SearchResultsForum" class="searchresults col-sp-12 row-sp-12">
          <div class="pad-8 no-results">
            <div class="rainbowloader" v-if="loading">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
              </div>
            </div>
            <forum>

            </forum>
            <div class="pad-32 t-center" v-if="empty">
              <img src="assets/images/likes/sad-but-relieved-face.png">
              <h3 class="ui center aligned header">
                <div class="content">
                  Keine Ergebnisse gefunden
                  <div class="sub header">Stelle doch selber eine Frage im Forum</div>
                </div>
              </h3>
              <a href="/forum" class="ui button btn-color-1">Zum Forum</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { re } from '@/modules/search'
import forum from '@/components/search/forum'
import user from '@/components/search/user'
import imageComp from '@/components/search/image'
import { Wall } from '@/modules/wall'
export default {
  name: 'search',
  title: 'Junx | Suche',
  data () {
    return {
      media: [],
      mediaIDs: [],
      user: [],
      userIDs: [],
      questions: [],
      searchString: this.$route.query.s ?? '',
      empty: false,
      more: true,
      moreLoad: false,
      city: null,
      loading: true,
      order: 'random',
      tab: this.$route.query.t ?? 'Media',
      msnry: null
    }
  },
  components: {
    imageComp,
    user,
    forum
  },
  watch: {
    order () {
      console.log('test')
      this.search()
    },
    media () {
      const _this = this
      const grid = document.querySelector('#MansoryGaleryGrid')
      imagesLoaded(grid, function () {
        // init Isotope after all images have loaded
        _this.msnry = new Masonry(grid, {
          itemSelector: '.grid-item',
          columnWidth: '.grid-sizer',
          percentPosition: true
        })
      })
      this.moreLoad = this.more
      if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 >= $('#searchApp').height() && this.moreLoad) {
        this.searchMore()
      }
    },
    user () {
      this.moreLoad = this.more
      if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 >= $('#searchApp').height() && this.moreLoad) {
        this.searchMore()
      }
    },
    questions () {
      this.moreLoad = this.more
      if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 >= $('#searchApp').height() && this.moreLoad) {
        this.searchMore()
      }
    },
    searchString () {
      const _this = this
      const oldQuery = this.searchString
      setTimeout(function () {
        if (oldQuery === _this.searchString)_this.search()
      }, 1500)
    }
  },
  methods: {
    init () {
      this.scroll()
      const searchApp = this
      let start, end

      $('#page-search').on('touchstart', function (e) {
        start = e.originalEvent.touches[0].clientX
      })
      $('#page-search').on('touchmove', function (e) {
        end = e.originalEvent.touches[0].clientX
      })

      $('#page-search').on('touchend', function (e) {
        const width = $('#page-search').width()
        const diff = start - end
        if (diff / width > 0.4) {
          switch (tab) {
            case 'Media':
              searchApp.openSearch('User')
              break

            case 'User':
              searchApp.openSearch('Hashtags')
              break

            case 'Hashtags':
              searchApp.openSearch('Forum')
              break

            case 'Forum':
              searchApp.openSearch('Media')
              break
          }
        } else if (diff / width < -0.4) {
          switch (tab) {
            case 'Media':
              searchApp.openSearch('Forum')
              break

            case 'User':
              searchApp.openSearch('Media')
              break

            case 'Hashtags':
              searchApp.openSearch('User')
              break

            case 'Forum':
              searchApp.openSearch('Hashtags')
              break
          }
        }
      })

      $('#searchForm').form('set values', JSON.parse(localStorage.getItem('searchForm')))

      $('#searchButton').on('click', function () {
        localStorage.setItem('searchForm', JSON.stringify($('#searchForm').form('get values')))
        searchApp.search()
        $('#FilterSearchModal').modal('hide')
      })
      $('#resetButton').on('click', function () {
        $('#searchForm').form('clear')
        localStorage.removeItem('searchForm')
        searchApp.search()
      })

      searchApp.openSearch(this.tab.charAt(0).toUpperCase() + this.tab.slice(1))
      $('.dropdown').dropdown()
      window.junx.loading = false
    },
    search () {
      const searchApp = this
      this.loading = true
      this.more = false
      this.moreLoad = false
      if (searchApp.tab === 'Hashtags') {
        window.wall.hashtag(searchApp.searchString)
      } else if (searchApp.tab === 'User') {
        searchApp.userIDs = []
        searchApp.searchUser(searchApp.order)
      } else if (searchApp.tab === 'Media') {
        searchApp.mediaIDs = []
        searchApp.searchMedia('random')
      } else if (searchApp.tab === 'Forum') {
        searchApp.searchForum('new')
      }
    },
    searchMore () {
      // console.log('more');
      const searchApp = this
      this.loading = true
      searchApp.more = false
      searchApp.moreLoad = false
      if (this.tab === 'User') {
        searchApp.searchUser(this.order, true)
      } else if (this.tab === 'Media') {
        searchApp.searchMedia('random', true)
      } else if (this.tab === 'Forum') {
        searchApp.searchForum('new', true)
      }
    },
    scroll () {
      const searchApp = this
      window.onscroll = () => {
        // console.log(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)  + window.innerHeight + "  -   " + $('#searchApp').height());
        if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 >= $('#searchApp').height() && this.moreLoad && !this.loading) {
          searchApp.searchMore()
        }
      }
    },
    openSearch (tabTemp) {
      const searchApp = this
      searchApp.loading = true
      this.tab = tabTemp
      $('.searchresults').hide()
      $('.searchmenuitem').removeClass('active')
      $('#SearchResults' + this.tab).show()
      $('#SearchMenu' + this.tab).addClass('active')

      switch (searchApp.tab.toLowerCase()) {
        case 'hashtags':
          if (window.wall === undefined) {
            window.wall = new Wall('hashtag', null, searchApp)
          }
          if (searchApp.searchString !== '') {
            window.wall.hashtag(searchApp.searchString)
          }
          break

        case 'media':
        case 'forum':
        case 'user':
        default:
          searchApp.search()
          break
      }
    },
    searchMedia (order = 'random', add = false) {
      const searchApp = this
      const offset = add ? searchApp.media.length : 0
      if (!add) {
        searchApp.mediaIDs = []
        searchApp.media = []
      }
      const loadedTemp = JSON.stringify(searchApp.mediaIDs)
      $.ajax({
        type: 'POST',
        data: {
          offset: offset,
          searchString: searchApp.searchString,
          order: order,
          SessionID: sessionStorage.getItem('SessionID'),
          loaded: loadedTemp
        },
        url: window.projectURL + 'search/searchMedia',
        error: function () {
          // will fire when timeout is reached
        },
        success: function (data, status, xhr) {
          if (xhr.status === 250) {
            try {
              const mediaData = JSON.parse(data)
              const mediaArray = []
              for (const key in mediaData) {
                searchApp.mediaIDs.push(mediaData[key].id)
                mediaArray[key] = mediaData[key]
                mediaArray[key].src = mediaArray[key].src.replace(/\.[^/.]+$/, '_small') + re.exec(mediaArray[key].src)[0]
                mediaArray[key].online = (mediaArray[key].online === 1)
              }
              searchApp.loading = false
              searchApp.empty = !!((mediaArray.length === 0 && !add))
              searchApp.more = mediaArray.length !== 0
              searchApp.media = searchApp.media.concat(mediaArray)
            } catch (e) {
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () {
              searchApp.searchMedia(order)
            }).catch(function () {
              searchApp.openSearch(tab)
            })
          }
        },
        timeout: 3000
      })
    },
    searchForum (order = 'random', add = false) {
      const searchApp = this
      const offset = add ? searchApp.questions.length : 0
      $.ajax({
        type: 'POST',
        data: {
          offset: offset,
          searchString: searchApp.searchString,
          order: order,
          SessionID: sessionStorage.getItem('SessionID')
        },
        url: window.projectURL + 'search/searchForum',
        error: function () {
          // will fire when timeout is reached
        },
        success: function (data, status, xhr) {
          if (xhr.status === 250) {
            try {
              const forumData = JSON.parse(data)
              const forumArray = []
              for (const key in forumData) {
                forumArray[key] = forumData[key]
                forumArray[key].profile_img = forumArray[key].profile_img.replace(/\.[^/.]+$/, '_small') + re.exec(forumArray[key].profile_img)[0]
                forumArray[key].catimg = 'assets/images/forum/' + forumArray[key].catimg
                forumArray[key].link = 'forum?page=question&amp;q=' + forumArray[key].id
                forumArray[key].answers = (forumArray[key].answers === 1) ? '1 Antwort' : forumArray[key].answers + ' Anworten'
              }
              searchApp.loading = false
              searchApp.empty = !!((forumArray.length === 0 && !add))
              searchApp.more = forumArray.length !== 0
              if (add) {
                searchApp.questions = searchApp.questions.concat(forumArray)
              } else {
                searchApp.questions = forumArray
              }
            } catch (e) { }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () {
              searchForum(order)
            }).catch(function () {
              openSearch(tab)
            })
          }
        },
        timeout: 10000
      })
    },
    searchUser (order = 'random', add = false) {
      const searchApp = this

      if (!add) {
        searchApp.userIDs = []
        searchApp.user = []
      }

      const offset = searchApp.user.length
      const loadedTemp = JSON.stringify(searchApp.userIDs)
      const formData = new FormData(document.getElementById('searchForm'))

      formData.append('searchString', searchApp.searchString)
      formData.append('order', order)
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('offset', offset)
      formData.append('loaded', loadedTemp)
      $.ajax({
        type: 'POST',
        data: formData,
        url: window.projectURL + 'search/searchUser',
        error: function () {
          // console.log(err)
        },
        success: function (data, status, xhr) {
          if (xhr.status === 250) {
            try {
              const usersData = JSON.parse(data)
              const userArray = []
              for (const key in usersData) {
                searchApp.userIDs.push(usersData[key].id)
                userArray[key] = usersData[key]
                userArray[key].online = userArray[key].online === '1'
              }
              searchApp.user = searchApp.user.concat(userArray)
              searchApp.empty = !!((userArray.length === 0 && !add))
              searchApp.more = userArray.length !== 0
              searchApp.loading = false
            } catch (e) {
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () {
              searchUser(order)
            }).catch(function () {
              openSearch(tab)
            })
          }
        },
        timeout: 3000,
        cache: false,
        contentType: false,
        processData: false
      })
    }
  },
  updated () {
    const _this = this
    const grid = document.querySelector('#MansoryGaleryGrid')

    /* imagesLoaded(grid, function () {
      // init Isotope after all images have loaded
      _this.msnry = new Masonry(grid, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
      })
      $('.grid-item').css('opacity', '1')
    }) */

    $('#MansoryGaleryGrid').imagesLoaded()
      .always(function () {
        _this.msnry = new Masonry(grid, {
          itemSelector: '.grid-item',
          columnWidth: '.grid-sizer',
          percentPosition: true
        })
        $('.grid-item').css('opacity', '1')
      })

    if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 400 >= $('#searchApp').height() && this.moreLoad && !this.loading) {
      this.searchMore()
    }
  },
  mounted () {
    this.init()
  },
  beforeRouteUpdate (to, from) {
    this.init()
  }
}
</script>

<style lang="scss">
/* Page --> Search */

#page-search {
  #SearchMobileMenu {
    display: block;
    margin: -8px;

    .ui.secondary.pointing.menu .item {
      width: calc(25% - 4px);
      font-size: 1.2em;

      i {
        margin: 0 auto;
      }
    }
  }

  SearchResultsMedia {
    display: block;
  }

  #SearchResultsUser, #SearchResultsHashtags, #SearchResultsForum {
    /* display: none; */
  }
}

#SearchResultsForum .category-img {
  max-width: 24px;
  vertical-align: text-bottom;
}

#page-search #SearchResultsHashtags #posts .ui.fluid.card.post {
  margin-bottom: 8px;
}

#page-search .contact-element {
  vertical-align: middle;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .ui.header {
    margin-bottom: 0 !important;

    i.check.icon {
      display: inline-block;
      font-size: 1em;
      margin-top: -5px;
    }
  }

  .contact-profile-img {
    display: inline-block;
    position: relative;

    .ui.avatar.image {
      height: 3em;
      width: 3em;
      margin-top: -40px;
    }
  }

  .onlinestatus {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .contact-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(255, 255, 255, 0) 100%);
    color: rgba(255, 255, 255, 1);
    width: 100%;
  }

  .location.meta {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.8em;
  }

  &.ar-1-1 {
    padding-top: 100%;
  }
}

#page-search #posts .ui.post.card {
  width: 100%;
  margin: 0 auto;
}

/* clear fix */

#MansoryGaleryGrid {
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  .grid-sizer {
    width: 33.333%;
  }

  .grid-item {
    width: 33.333%;
    float: left;
    padding: 2px;

    img {
      display: block;
      width: 100%;
      cursor: pointer;
    }
  }
}

/* ---- .grid-item ---- */

@media (min-width: 992px) {
  #MansoryGaleryGrid {
    .grid-sizer, .grid-item {
      width: 20%;
    }
  }
}
@media (max-width: 992px) {
  .spaceBottom {
    margin-bottom: 80px;
  }
}
</style>
