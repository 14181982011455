<template>
  <main id="JunxForum" class="spaceTop">
    <div class="content-1200 grid-16">
      <div class="col-sp-16 row-sp-16 pad-4">
        <div>
          <img src="assets/images/banner/forum-desktop.jpg" class="responsive-img pagebanner-desktop"/>
          <img src="assets/images/banner/forum-mobil.jpg" class="responsive-img pagebanner-mobil"/>
        </div>
      </div>
      <div class="col-sp-16 col-ld-5 row-sp-16 pad-4">
        <aside id="forum-sidebar">
          <div class="ui large vertical fluid menu no-border no-shadow">
            <div class="item">
              <div class="ui fluid action input">
                <input type="text" name="search" v-model="searchString" placeholder="Fragen suchen...">
                <button class="ui button"><i class="search icon"></i></button>
              </div>
            </div>
            <router-link class="item" exact-active-class="active" to="/forum">
              Übersicht
            </router-link>
            <div id="category-desktop-menu">
              <div class="item">
                <div class="header">Kategorien</div>
              </div>
              <router-link class="item" active-class="active" :to="'/forum/c/' + categorie.title" v-for="(categorie, index) in categories" :key="index">
                <img :src="'assets/images/forum/'+categorie.img"/>
                <div class="ui small label" :class="{blue: (categorie.id == currentTab)}">
                  {{categorie.count}}
                </div>
                {{categorie.title}}
              </router-link>
            </div>
          </div>
          <div id="category-mobile-menu" class="ui compact menu">
            <div class="ui simple dropdown item">
              Kategorie
              <i class="dropdown icon"></i>
              <div class="menu">
                <router-link class="item" active-class="active" :to="'/forum/c/' + categorie.title" v-for="(categorie, index) in categories" :key="index">
                  <img :src="'assets/images/forum/'+categorie.img"/>
                  <div class="ui small label" :class="{blue: (categorie.id == currentTab)}">
                    {{categorie.count}}
                  </div>
                  {{categorie.title}}
                </router-link>
              </div>
            </div>
          </div>
        </aside>
      </div>
      <div class="col-sp-16 col-ld-11 row-sp-16 pad-4">
        <div class="grid-12 pad-16 m-b-16">
          <div class="col-sp-12 col-tb-6 row-sp-12">
            <h3 class="ui header pad-8">
              <i class="comments icon"></i>
              <div class="content">
                <span v-if="this.$route.params.category == 'Meine Fragen'">Meine </span>Fragen
              </div>
            </h3>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12">
            <router-link to="/forum/c/Meine Fragen" class="ui left labeled button" tabindex="0" v-if="this.$route.params.category != 'Meine Fragen'">
              <span class="ui basic right pointing label">
                  Meine Fragen
              </span>
              <div class="ui button btn-color-3">
                <i class="user icon"></i>
              </div>
            </router-link>
            <router-link to="/forum/ask" id="askModalButton" class="ui left labeled right floated button" tabindex="0">
              <span class="ui basic right pointing label">
                  Frage stellen
              </span>
              <div class="ui button btn-color-3">
                <i class="question icon"></i>
              </div>
            </router-link>
          </div>
        </div>
        <router-view :categories="categories"></router-view>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import { cookieLogin } from '@/modules/main'
export default {
  name: 'forum',
  title: 'Junx | Forum',
  data () {
    return {
      projectURL: window.projectURL,
      questions: [],
      categories: [],
      question: false,
      answers: [],
      more: true,
      currentTab: '',
      currentPage: 'questions',
      search: false,
      searchString: this.$route.params.search, // !== undefined ? this.$route.params.search : '',
      searchBlock: false,
      subLoading: true,
      moreLoad: false,
      lastFunction: 'category',
      lastQuery: ''
    }
  },
  components: {
    /* 'questions-item': questionsComp,
    'question-item': questionComp */
  },
  methods: {
    init () {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(this.projectURL + 'forum/indexJS', formData)
        .then(function (response) {
          if (response.status === 250) {
            const data = response.data
            _this.categories = data.categories
            _this.moreLoad = true
            window.junx.loading = false
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.init()).catch(() => _this.$router.push('/login'))
          }
        })
    }
  },
  watch: {
    searchString () {
      const _this = this
      const tempString = this.searchString
      setTimeout(function () {
        if (tempString === _this.searchString && !_this.searchBlock && _this.searchString !== _this.lastQuery) {
          _this.lastQuery = _this.searchString
          _this.$router.push('/forum/s/' + _this.searchString)
        }
        _this.searchBlock = false
      }, 1000)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style>
/* Forum */
#JunxForum{
  margin-bottom: 80px;
}
#JunxForum .delAnsw{
  float: right;
}
#JunxForum #forum-sidebar .item img{
  float: right;
  width: 24px;
  margin-left: 8px;
}
#JunxForum #forum-sidebar a.item.active{
  color: rgba(255,255,255,1);
  background-color: rgba(57,0,85,1);
}
#JunxForum .questioncard img.category-img{
  width: 24px;
  margin-right: 8px;
}

#JunxForum .askcomment{
  background-color: rgba(255,255,255,1)!important;
  padding: 8px 16px;
  margin-bottom: 16px;
}
#JunxForum .askcomment .like-button-select{
  color: rgba(0,0,0,0.5);
}
#JunxForum  .ui.image.label img {
  vertical-align: middle;
}
#JunxForum #category-mobile-menu{
  margin-top: -16px;
}
#JunxForum #category-desktop-menu{display: block;}
#JunxForum #category-mobile-menu{display: none;}
@media (max-width: 992px){
  #JunxForum #category-desktop-menu{display: none;}
  #JunxForum #category-mobile-menu{display: block;}
}
/* Darkmode */
.dark #forum-sidebar .ui.large.vertical.fluid.menu{background: #1B1C1D!important;}
.dark #forum-sidebar .ui.large.vertical.fluid.menu .item{color: #ffffff;}
.dark #forum-sidebar .ui.vertical.menu .item > .label{color: #FFFFFF;}
.dark #forum-sidebar .ui.fluid.action.input > input{background-color: #141414;color: rgba(255,255,255,1);}
.dark #JunxForum #forum-sidebar a.item.active{color: rgba(255,255,255,1);background-color: rgba(57,0,85,1);}
.dark #JunxForum .askcomment{background-color: #1B1C1D;}
.dark #JunxForum .askcomment .like-button-select{color: rgba(255,255,255,0.5);}

</style>
