<template>
  <router-link class="ui fluid card questioncard no-border no-shadow" :to="'/forum/q/' + question.id">
    <div class="content">
      <span class="right floated">
        <p>{{question.datestamp}}</p>
      </span>
      <router-link :to="'/p/' + question.username" v-if="question.anonym == 0">
        <img class="ui avatar image" :src="projectURL + 'assets/images/user/'+question.profile_img">
        <span><b>{{question.username}}</b>, {{question.age}}</span>
      </router-link>
      <div v-else>
        <img class="ui avatar image" :src="projectURL + 'assets/images/user/'+question.profile_img">
        <span><b>{{question.username}}</b>, {{question.age}}</span>
      </div>
      <div class="description pad-16">
        <h3>
          {{question.title}}
        </h3>
        <a class="ui left labeled right floated tiny button" tabindex="0">
          <span class="ui basic right pointing label">
            Frage ansehen
          </span>
          <div class="ui tiny button btn-color-3">
            <i class="comment icon"></i>
          </div>
        </a>
      </div>
    </div>
    <div class="extra content" style="color:black">
      <span class="left floated like">
        <router-link :to="'/forum/c/' + question.category_title">
          <img class="category-img" :src="'assets/images/forum/'+question.img"/>
          {{question.category_title}}
        </router-link>
      </span>
      <div :class="{active: question.ownVote == 0}">{{question.downVotes}}<i class="fas fa-chevron-down"></i></div>
      <div :class="{active: question.ownVote == 1}"><i class="fas fa-chevron-up"></i>{{question.upVotes}}</div>
      <span class="right floated star">
          <p>
          {{question.answer_count}} {{(question.answer_count == 1)?'Antwort':'Antworten'}}
          </p>
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'questionOverview',
  props: ['question'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>
.questioncard{
  text-decoration: none;
}
.questioncard:link {
  color:inherit;
}
</style>
