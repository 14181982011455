<template>
  <section id="MagazinSuche">
    <div class="content-1400">
      <div class="pad-16">
        <div class="grid-12 ui segment no-border no-shadow">
          <div class="col-sp-12 col-sd-4 row-sp-12">
            <h3 class="ui header">
              <img src="/assets/images/emoticons/flag.png">
              <div class="content">
                Junx Magazin
                <div class="sub header">Dein LGBTIQA+ Newspaper</div>
              </div>
            </h3>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <field>
              <div class="ui icon fluid input">
                <input v-model="searchString" @keyup.enter="searchBlog()" type="text" placeholder="Suche...">
                <i class="circular search link icon" @click="searchBlog()"></i>
              </div>
            </field>
          </div>
        </div>
        <div class="ui two item menu no-shadow">
          <a class="item" :class="{ active: (searchTab == 'magazin')}" @click="searchTab = 'magazin'">Magazinbeiträge</a>
          <a class="item" :class="{ active: (searchTab == 'videos')}" @click="searchTab = 'videos'">Videobeiträge</a>
        </div>
        <div class="grid-12" id="SearchArticles" v-if="searchTab == 'magazin'">
          <article-preview v-for="(article,index) in searchArticles" :key="index" :article="article"></article-preview>
        </div>
        <div class="grid-12" id="SearchVideos" v-if="searchTab == 'videos'">
          <blog-video :video="video" v-for="(video,index)  in searchVideos" :key="index"></blog-video>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { cookieLogin } from '@/modules/main'
import articlePreview from '@/components/blog/articlePreview'
import blogVideo from '@/components/blog/video'
export default {
  name: 'search',
  components: {
    articlePreview,
    blogVideo
  },
  data () {
    return {
      projectURL: window.projectURL,
      searchString: this.$route.params.query,
      searchTab: 'magazin',
      searchArticles: [],
      searchVideos: []
    }
  },
  methods: {
    searchBlog () {
      const _this = this
      $.post(this.projectURL + 'blog/search', { SessionID: sessionStorage.getItem('SessionID'), search: this.searchString }, function (msg, status, xhr) {
        try {
          if (xhr.status === 250) {
            const data = JSON.parse(msg)
            if (data.articles) {
              _this.searchArticles = data.articles
            }
            if (data.videos) {
              _this.searchVideos = data.videos
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.searchBlog).catch(function () { _this.$router.push('/login') })
          }
          window.junx.loading = false
        } catch (e) { console.error(e) }
      })
    }
  },
  mounted () {
    this.searchBlog()
  },
  beforeRouteUpdate (to, from) {
    this.searchString = to.params.query
    window.junx.loading = true
    this.searchBlog()
  }
}
</script>

<style scoped>

</style>
