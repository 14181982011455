<template>
  <div id="hoverModal" class="ui segment no-shadow profile-preview">
    <h5 class="ui header active">
      <img id="hoverModalImg" class="ui avatar image">
      <div class="content">
        <span id="hoverModalFirstName"></span>
        <div id="hoverModalUserName" class="sub header active">
        </div>
      </div>
    </h5>
  </div>
  <div class="ui mini deletePost modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deletePost.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui mini deleteComment modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deleteComment.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div id="page-dashboard" class="spaceTop">
    <div id="ptrest"></div>
    <div class="content-1200 grid-16">
      <div class="col-sp-16 col-sd-10 row-sp-16">
        <div id="ptrPull">
          <div class="pad-8">
            <section id="searchsection">
              <div class="pad-16">
                <div class="ui icon fluid search input">
                  <input class="prompt" name="s" type="search" autocomplete="off" placeholder="Suche..." @keyup.enter="search">
                  <i class="circular search link icon"></i>
                </div>
              </div>
              <!--<section id="Storys" class="StoriesLoading">
                <section id="StorySlider" class="center slider blue-bg">
                  <div class="pad-8" id="StorySliderAfter">
                    <label for="datei">
                      <a>
                        <div class="story-user">
                          <div class="storypreview">
                            <img src="assets/images/storys/preview/story-preview-default.jpg"/>
                          </div>
                          <img class="ui avatar image" src="assets/images/storys/preview/story-add.jpg">
                        </div>
                      </a>
                    </label>
                  </div>
                  <story-preview v-for="( story, index ) in storyPosts" :story="story" :key="index"></story-preview>
                </section>
                <form id="story-form" action="/storycreate" method="post" enctype="multipart/form-data" target="StoryCreateModalIframe">
                  <input type="file" id="datei" style="display: none;" name="datei" accept="image/jpeg,image/jpg,image/png,video/mp4,video/m4v,video/webm,video/mov,video/ogg" onchange="createstory(this);">
                </form>
              </section>-->
            </section>
            <div class="pad-8"></div>
            <div class="ui segment no-shadow no-border trending pad-24">
              <div class="content grid-12">
                <div class="col-sp-12 col-hp-6 col-tb-4 col-sd-3 row-sp-12">
                  <h3 class="ui header" style="padding: 16px 0">
                    <img src="assets/images/emoticons/flame.png">
                    <div class="content">
                      Trending
                      <div class="sub header">Hashtags</div>
                    </div>
                  </h3>
                </div>
                <div class="col-sp-12 col-hp-6 col-tb-8 col-sd-9 row-sp-12" id="trendingHashtags">
                  <div class="rainbowloader" id="hashtagLoader">
                    <div class="loader-inner">
                      <div class="loader-line-wrap">
                        <div class="loader-line">
                        </div>
                      </div>
                      <div class="loader-line-wrap">
                        <div class="loader-line">
                        </div>
                      </div>
                      <div class="loader-line-wrap">
                        <div class="loader-line">
                        </div>
                      </div>
                      <div class="loader-line-wrap">
                        <div class="loader-line">
                        </div>
                      </div>
                      <div class="loader-line-wrap">
                        <div class="loader-line">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-16">
            <div class="col-sp-8 row-sp-16 pad-4">
              <h6 class="page-subtitle" style="margin-left: 18px;">Hey {{user.firstname}}</h6>
            </div>
            <div class="col-sp-8 row-sp-16 pad-4">
              <div class="t-right" style="padding: 12px;">
                <button onclick="$('#WallInfo.ui.modal').modal('show');" class="ui basic button">
                  <i class="info circle icon"></i> Die Wall
                </button>
              </div>
              <div id="WallInfo" class="ui tiny modal">
                <i class="close icon"></i>
                <div class="header">
                  <h3 class="ui header">
                    Die Wall
                    <div class="sub header">kurz erklärt</div>
                  </h3>
                </div>
                <div class="image content">
                  <div class="ui medium image">
                    <img src="assets/images/channels.png" class="responsive-img"/>
                  </div>
                  <div class="description">
                    <p>
                      Wir möchten dir den bestmöglichen Content bieten. Daher kannst du selbst auch bestimmen,
                      was du sehen möchtest und was nicht. Wenn du deinen Filter auf "Meine Wall" stellst, zeigen wir
                      dir Inhalte von deinen Freunden, Personen und <b class="color-1" onclick="$('#AddChannel.ui.modal').modal('show');">Kanälen</b>
                      denen du folgst, sowie Beiträge aus deiner Umgebung, dessen Radius du zusätzlich rechts einstellen kannst.
                      <br><br>
                      Wenn du den Filter auf einen Kanal stellst, siehst du alle Beiträge, welche direkt in den Kanal gepostet wurden,
                      oder dazu passen.
                    </p>
                  </div>
                </div>
                <div class="actions">
                  <div class="ui button btn-color-1" onclick="$('#WallInfo.ui.modal').modal('hide');">
                    Okay
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id="PostFunction" class="pad-16">
            <form class="ui form" id="postIt">
              <input type="hidden" name="galery" value="0">
              <input type="hidden" name="wall" value="1">
              <div class="card">
                <div class="card-body p-0">
                  <div id="preview-col" class="pimg pad-8" style="display: none;">
                    <img id="output_image"/>
                  </div>
                  <textarea style="border-radius: 0;" class="form-control border-0" id="postText" rows="4" name="postcontent" placeholder="Was machst du gerade?" required></textarea>
                </div>
                <div class="card-footer py-2">
                  <div class="grid-16">
                    <div class="col-sp-16 col-tb-8 row-sp-16">
                      <div class="ui tiny selection dropdown" id="channelsDropdown">
                        <input type="hidden" id="channelPostSelect" name="channel">
                        <i class="dropdown icon"></i>
                        <div class="default text">Channel</div>
                        <div class="menu" id="channelAll">
                          <div class="item" data-value="">Meine Wall</div>
                          <channel-wall v-for="(ownChannel, index) in ownChannels" :key="index" :channels="channels" :ownChannel="ownChannel" :verified="verified"></channel-wall>
                        </div>
                      </div>
                    </div>
                    <div class="col-sp-16 col-tb-8 row-sp-16 pad-8 t-right">
                      <div class="ui button basic icon smileyMenue display-Dektop-inline" data-menu="0">
                        <i class="smile outline icon"></i>
                      </div>
                      <div id="post-image-upload">
                        <input type="file" accept="image/png, image/jpeg" name="postimage" id="postimage" style="display: none;">
                        <div class="ui button basic icon" onclick="$('#postimage').click();">
                          <i class="icon camera retro"></i>
                        </div>
                      </div>
                      <button type="submit" class="ui button btn-color-2" id="postButton">
                        Posten
                      </button>
                    </div>
                  </div>

                </div>
                <div class="emojiCon" id="emojiCon_0">
                  <emojimenu></emojimenu>
                </div>
              </div>
            </form>
          </section>
        </div>

        <section id="wall" class="pad-16 ptab active postList">
          <div class="ui mini modal" id="errorModal">
            <div class="header" id="errorMessageMTi"></div>
            <div class="content" id="errorMessageMT"></div>
          </div>
          <div class="grid-16">
            <div class="col-sp-16 col-sd-10 row-sp-16 pad-4">
              <div class="ui selection fluid dropdown" id ="wallChannels">
                <input type="hidden" id="channelSelect">
                <!--<input class="search" autocomplete="off" onKeyup="javascript: if(event.keyCode == 13) {topi = this.value;this.parentElement.getElementsByClassName('search')[0].blur();setTopic(topi);}" tabindex="0">-->
                <i class="dropdown icon"></i>
                <div class="default text" id="wallChannelsText">Channels</div>
                <div class="menu" id="myWall">
                  <div class="item" data-value="">Meine Wall</div>
                  <channel-my-wall v-for="(ownChannel, index) in ownChannels" :key="index" :channels="channels" :ownChannel="ownChannel"></channel-my-wall>
                  <!-- <div class="item" data-value="pic">Fotos</div> -->
                  <div class="item" onclick="$('#AddChannel.ui.modal').modal({onHide: function(){location.reload()}}).modal('show');">
                    <button class="ui mini button btn-color-1 icon">
                      <i class="add icon"></i>
                    </button>
                    Weitere Kanäle abonnieren
                  </div>
                  <div class="item verifiedBye" onclick="$('#AddCountry.ui.modal').modal({onHide: function(){location.reload()}}).modal('show');" v-if="verified == 1">
                    <button class="ui mini button btn-color-1 icon">
                      <i class="add icon"></i>
                    </button>
                    Weitere Länder abonnieren
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sp-16 col-sd-6 row-sp-16 t-right pad-4">
              <div class="ui floating large fluid btn-inverted-color dropdown labeled icon button" id="maxDiB" style="text-align: center!important;">
                <i class="map marker alternate icon"></i>
                <span class="text"></span>
                <input type="hidden"  id="maxDiSel">
                <div class="menu">
                  <div class="item" data-value="10">Mein Standort</div>
                  <div class="item" data-value="20">20km</div>
                  <div class="item" data-value="50">50km</div>
                  <div class="item" data-value="100">100km</div>
                  <div class="item" data-value="200">200km</div>
                  <div class="item" data-value="500">500km</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-8"></div>

          <div id="posts">
            <div class="rainbowloader" id="Postloader">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<a id="loadMorePosts" onClick="getPosts();"><button id="moreButton" class="ui fluid mini button btn-inverted-color">Mehr</button></a>-->

          <div class="pad-32">
            <div id="loader" class="ui centered inline loader"></div>
          </div>
        </section>
        <div id="AddChannel" class="ui tiny modal">
          <i class="close icon"></i>
          <div class="header">
            Kanäle Abonnieren
          </div>
          <div class="content scrolling">
            <div class="grid-12 channel-selection">
              <div class="col-sp-12 row-sp-12 pad-8 t-center" id="allChannels">
                <h2 class="ui header">
                  Wofür interessierst du dich?
                  <div class="sub header">Wähle mindestens 3 Kanäle</div>
                </h2>
              </div>
              <div class="col-sp-12 row-sp-12 pad-16">
                <div class="col-sp-12 row-sp-12" id="interestsChannels">
                  <channel-select v-for="(channel, index) in channels" :key="index" :channel="channel" :ownchannels="ownChannels" type="channel"></channel-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="AddCountry" class="ui tiny modal">
          <i class="close icon"></i>
          <div class="header">
            Länder Abonnieren
          </div>
          <div class="content scrolling">
            <div class="grid-12 channel-selection">
              <div class="col-sp-12 row-sp-12 pad-8 t-center" id="allCountrys">
                <h2 class="ui header">
                  Welche Länder möchtest du abonnieren?
                </h2>
              </div>
              <div class="col-sp-12 row-sp-12 pad-16">
                <div class="col-sp-12 row-sp-12" id="interestsCountrys">
                  <channel-select v-for="(channel, index) in channels" :key="index" :channel="channel" :ownchannels="ownChannels" type="country"></channel-select>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sp-16 col-sd-6 row-sp-16">
        <aside id="Sidemenu">
          <div class="pad-8">
            <div class="ui segment no-shadow no-border trending pad-24">
              <div class="content">
                <div class="grid-12">
                  <div class="col-sp-12 col-tb-6 row-sp-12">
                    <h3 class="ui header">
                      <img src="assets/images/emoticons/chat.png">
                      <div class="content">
                        Junx
                        <div class="sub header">Spotlight</div>
                      </div>
                    </h3>
                  </div>
                  <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
                    <a @click="openSearch" class="circular ui basic icon mini button">
                      <i class="icon search"></i> Mehr sehen
                    </a>
                  </div>
                </div>
              </div>
              <div class="ui secondary pointing menu" style="margin-bottom: 10px;margin-top: 5px;">
                <a @click="NearTab()" id="NearTabMenu" class="item active">
                  Nähe
                </a>
                <a @click="NewTab()" id="NewTabMenu" class="item">
                  Neu
                </a>
                <a @click="LastTab()" id="LastTabMenu" class="item">
                  Online
                </a>
              </div>
              <div class="content grid-12" id="nearTab" style="display: grid;" >
                <tinyuser v-for="(user, index) in locationUser" :key="index" :user="user"></tinyuser>
              </div>
              <div class="content grid-12" id="newTab" style="display: none;" >
                <tinyuser v-for="(user, index) in newUser" :key="index" :user="user"></tinyuser>
              </div>
              <div class="content grid-12" id="lastTab" style="display: none;" >
                <tinyuser v-for="(user, index) in lastOnlineUser" :key="index" :user="user"></tinyuser>

              </div>
            </div>
          </div>
          <div class="pad-4"></div><div id="NewsSlider">
          <div class="slideshow-container">
            <div class="NewsSlides fade">
              <div style="padding: 8px 10px!important;">
                <div class="ui segment no-shadow no-border h-100">
                  <a class="ui ribbon label bg-color-2">Update</a>
                  <div class="grid-12">
                    <div class="col-sp-3 row-sp-16 pad-8 t-center">
                      <div class="pad-40"></div>
                      <img src="assets/images/emoticons/flame.png" style="max-width: 80px;" class="responsive-img"/>
                    </div>
                    <div class="col-sp-9 row-sp-16 pad-8">
                      <h3 class="ui header">
                        Hey {{user.firstname}},
                        <div class="sub header">das neue Junx Matches ist da!</div>
                      </h3>
                      <p>
                        Wir haben die Junx Matches Funktion komplett überarbeitet und nach euren
                        Wünschen erweitert. Wir haben die Seite nicht nur Designtechnisch angepasst,
                        sondern auch die von euch gewünschten Filter eingebaut.
                        <br><br>
                        Erstelle dir doch gleich dein neues Matchprofil! <br><a class="ui basic icon button" onclick="openMatches()">Matches</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsSlides fade">
              <div style="padding: 8px 10px!important;">
                <div class="ui segment no-shadow no-border h-100">
                  <a class="ui ribbon label bg-color-2">Infos</a>
                  <div class="grid-12">
                    <div class="col-sp-3 row-sp-16 pad-8 t-center">
                      <div class="pad-40"></div>
                      <img src="assets/images/emoticons/flag.png" style="max-width: 80px;" class="responsive-img"/>
                    </div>
                    <div class="col-sp-9 row-sp-16 pad-8">
                      <div class="pad-16"></div>
                      <h3 class="ui header">
                        Junx geht weiter! 😍
                        <div class="sub header"></div>
                      </h3>
                      <p>
                        Voller Freude können wir verkünden, dass Junx endlich weitergeht.
                        Mehr Informationen erhaltet ihr auf Instagram:<br>
                        <a href="https://www.instagram.com/p/CP-3rHgKh7j/" target="_blank">Zum Beitrag</a>
                        <br><br>
                        <i>Das Junx Team</i>
                        <br/><br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsSlides fade">
              <div style="padding: 8px 10px!important;">
                <div class="ui segment no-shadow no-border h-100">
                  <a class="ui ribbon label bg-color-2">Infos</a>
                  <div class="grid-12">
                    <div class="col-sp-3 row-sp-16 pad-8 t-center">
                      <div class="pad-40"></div>
                      <img src="assets/images/junx-admin-topbar.png" class="responsive-img"/>
                    </div>
                    <div class="col-sp-9 row-sp-16 pad-8">
                      <div class="pad-16"></div>
                      <h3 class="ui header">
                        Open Beta
                        <div class="sub header">Phase</div>
                      </h3>
                      <p>
                        Hey Junx*, willkommen in der Open Beta von Junx.
                        Wir arbeiten mit Hochtouren daran weitere Features und Wünsche von
                        euch umzusetzen. Bitte habt Verständnis wenn hier und da auch nochmal
                        ein Bug auftaucht. Diese könnt ihr uns aber gerne direkt an
                        bugs@junx.app melden. Viel Spaß!
                        <br>
                        <i>Das Junx Team</i>
                        <br/><br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsSlides fade">
              <div style="padding: 8px 10px!important;">
                <div class="ui segment no-shadow no-border h-100">
                  <a class="ui ribbon label bg-color-1">Neues Feature</a>
                  <div class="grid-12">
                    <div class="col-sp-3 row-sp-16 pad-8 t-center">
                      <div class="pad-40"></div>
                      <img src="assets/images/fake-check.png" style="max-width: 80px;" class="responsive-img"/>
                    </div>
                    <div class="col-sp-9 row-sp-16 pad-8">
                      <div class="pad-16"></div>
                      <h3 class="ui header">
                        Verifikation
                        <div class="sub header">Zeig, dass du kein Fake bist</div>
                      </h3>
                      <p>
                        Verifiziere dein Profil und bekomme einen Verifizierungshaken.
                        So sehen andere Nutzer, dass du auch wirklich die Person auf
                        deinen Bildern bist.
                        <br>
                        <i>Das Junx Team</i>
                        <br/><br>
                        <a data-profile="verify" class="ui button btn-color-1">Zum Profil</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="prev" @click="plusSlides(-1)">&#10094;</a>
            <a class="next" @click="plusSlides(1)">&#10095;</a>
          </div>
          <div style="text-align:center">
            <span class="dot" @click="currentSlide(1)"></span>
            <span class="dot" @click="currentSlide(2)"></span>
            <span class="dot" @click="currentSlide(3)"></span>
            <span class="dot" @click="currentSlide(4)"></span>
          </div>
        </div>
          <div class="pad-4"></div>
          <div class="pridedigital ad" data-bannertype="4" data-uid></div>
          <div class="pad-4"></div>
          <div class="pad-8" v-if="article">
            <wallArticle :article="article"></wallArticle>
          </div>
          <div class="pad-4"></div>
          <div class="pridedigital ad" data-bannertype="4" data-uid></div>
          <div class="pad-4"></div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import emojimenu from '@/components/plugins/emojimenu'
// import storyPreview from '@/components/story/storypreview'
import tinyuser from '@/components/basic/tinyUser'
import { init, plusSlides, currentSlide, NewTab, NearTab, LastTab, Wall, initInterest } from '@/modules/wall'
import { semantic, resetApp, cookieLogin } from '@/modules/main'
import wallArticle from '@/components/wall/article'
import ChannelMyWall from '@/components/wall/channelMyWall'
import ChannelWall from '@/components/wall/channelWall'
import ChannelSelect from '@/components/wall/channelSelect'
import PullToRefresh from 'pulltorefreshjs'

export default {
  name: 'wall',
  title: 'Junx | Wall',
  props: ['user'],
  data () {
    return {
      locationUser: [],
      newUser: [],
      lastOnlineUser: [],
      storyPosts: [],
      article: false,
      verified: 0,
      ownChannels: [],
      channels: []
    }
  },
  components: {
    ChannelSelect,
    ChannelMyWall,
    ChannelWall,
    wallArticle,
    emojimenu,
    // storyPreview,
    tinyuser
  },
  mounted () {
    this.init()
  },
  methods: {
    plusSlides (n) {
      plusSlides(n)
    },
    currentSlide (n) {
      currentSlide(n)
    },
    NearTab () {
      NearTab(true)
    },
    NewTab () {
      NewTab(true)
    },
    LastTab () {
      LastTab(true)
    },
    openSearch () {
      router.push({ name: 'Search', query: { t: 'User' } })
    },
    search (e) {
      router.push({ name: 'Search', query: { s: e.target.value } })
    },
    init () {
      const _this = this
      init().then(function (data) {
        _this.locationUser = data.locationUser
        _this.newUser = data.newUser
        _this.lastOnlineUser = data.lastOnlineUser
        _this.article = data.lastArticle
        _this.verified = data.verify.verified
        _this.channels = data.channels
        _this.ownChannels = localStorage.getItem('subscribed_channels').split(',').sort(function (a, b) { return a - b })
        window.junx.loading = false
        semantic()
        initInterest()
        window.wall = new Wall()
        currentSlide(1)
      }).catch(function () {
        cookieLogin().then(_this.init).catch(function () {
          resetApp()
          _this.$router.push('/login')
        })
      })
      PullToRefresh.init({
        mainElement: document.querySelector('#ptrest'),
        triggerElement: document.querySelector('#ptrPull'),
        instructionsPullToRefresh: 'ziehen...',
        instructionsReleaseToRefresh: 'Loslassen...',
        instructionsRefreshing: 'aktualisiere...',
        iconArrow: '<i class="fas fa-angle-down fa-2x"></i>',
        shouldPullToRefresh () {
          return !window.junx.chatModal
        },
        onRefresh () {
          window.location.reload()
        }
      })
    }
  }
}

</script>

<style scoped>
  #posts{
    position: relative;
    z-index: 1;
  }
  .dark #posts .ui.fluid.card .content p{
    color: rgba(255,255,255,0.9)!important;
  }
  .dark #posts .like_type{color: rgba(255,255,255,0.8);}

</style>
