<template>
  <div id="footerNav">
    <button class="btn btn-sm rounded-0 btn-no-effect" id='toggle-video' title="Hide Video">
      <i class="fa fa-video text-white"></i>
    </button>

    <button class="btn btn-sm rounded-0 btn-no-effect" id='toggle-mute' title="Mute">
      <i class="fa fa-microphone-alt text-white"></i>
    </button>
    <button id="endIt" class="btn btn-sm rounded-0 btn-no-effect text-white"><i class="fa fas fa-phone-slash text-white" title="Leave"></i></button>
  </div>

  <div id="notAllowed"  class="container-fluid" style="display: none;">
    <br>
    <br><div class="row"> <h1 style="text-align: center;width: 100vw;">Hier darfst du nicht hin. Bitte starte einen Anruf direkt per Link</h1></div>

  </div>
  <div id="call_details">
    <img id="profile_img" :src="projectURL + 'assets/images/user/' + profile_img">
    <div id="profile_img_div"></div>
    <p id="username">{{username}}</p>
  </div>

  <div  class="container-fluid room-comm" hidden>
    <div class="row">
      <video class="local-video mirror-mode" id='local' volume='0' autoplay muted playsinline></video>
    </div>

    <div class="row">
      <div class="col-md-12 main" id='main-section'>
        <div class="row mt-2 mb-2" id='videos'></div>
      </div>
    </div>
  </div>
</template>

<script>
import { pulse, initCall } from '@/modules/call'
export default {
  name: 'call',
  title: 'Junx | Videoanruf',
  props: ['username', 'room', 'profile_img', 'test'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  mounted () {
    pulse()
    console.log(this.room)
    console.log(this.username)
    console.log(this.test)
    initCall(this.room, this.username)
  },
  watch: {
    username () {
      pulse()
    }
  }
}
</script>

<style>
.chat-col{
  right: -100vw;
  bottom: 0;
  top: 40.5px;
  z-index: 1000;
  position: fixed;
  color: #fff;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 40px;
  padding-top: 15px;
  min-height: 100vh;
}

.chat-col.chat-opened {
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease !important;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
}

#chat-messages{
  height: 70vh;
  margin-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

#chat-messages::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;
}

.chat-box{
  bottom: 30px;
  right: 0;
  position: absolute;
  border: 0;
  border-top: 1px groove white;
  border-left: 1px groove white;
  font-size: small;
}

.chat-box::placeholder{
  font-size: small;
  font-weight: lighter;
  font-style: italic;
}

.chat-box,
.chat-box:focus{
  resize: none !important;
  box-shadow: none !important;
}

.chat-row{
  height: 100%;
  overflow-x: scroll;
}

.main{
  padding-top: 40px;
}

.remote-video{
  width:100%;
  height:auto;
  max-height: 90vh;
}

.remote-video-controls{
  position:absolute;
  bottom: 0;
  background-color:rgba(0, 0, 0, 0.5);
  z-index:300000;
  padding: 10px;
  width: 100%;
  text-align: center;
  visibility: hidden;
  display: none;
}

.remote-video:hover + .remote-video-controls,
.remote-video-controls:hover{
  visibility: visible;
}

.local-video{
  border-radius: 24px;
  left: 16px;
  bottom: 16px;
  position: fixed;
  width:15vw;
}

.mirror-mode{
  -ms-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.sender-info{
  font-size: smaller;
  margin-top: 5px;
  align-self: flex-end;
}

.msg{
  font-weight: 400;
  font-size: 12px;
  color: black;
  background-color: wheat;
}

.chat-card{
  border-radius: 6px;
}

.btn-no-effect:focus{
  box-shadow: none;
}

.very-small{
  font-size: 6px !important;
}

#close-single-peer-btn {
  position: fixed;
  top: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  border-radius: 0%;
  z-index: 100;
}

.pointer{
  cursor: pointer;
}

.record-option{
  height: 200px;
  border-radius: 10%;
  border: 1px solid #17a2b8;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;
}

.custom-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.custom-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #17a2b8;
  width: 80%;
}

@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

@media only screen and (max-width:767px){
  .chat-col{
    right: -100vw;
    width: 100vw;
    z-index: 99999;
    transition: 0.3s;
    top: 47px;
  }

  .chat-opened::-webkit-scrollbar {
    display: none;
  }

  #chat-messages{
    height: 60vh;
  }

  .chat-box{
    bottom: 90px;
    margin-bottom: 0px;
  }

  .card-sm{
    max-width: 100%;
    min-width: 50%;
  }

  .local-video{
    width:30vw;
  }
}
@media (min-width:768px){
  .card{
    width: 50%;
    z-index: 1000;
  }
}
#footerNav{
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 24px;
  z-index: 9999;
}
#footerNav button{
  font-size: 1.2em;
  border: 4px solid rgba(255,255,255,0.5);
  border-radius: 90%!important;
  padding: 8px;
  width: 64px;
  height: 64px;
  margin: 0 16px;
}
.card.card-sm{
  width: 100vw!important;
  height: calc(100vh - 47px);
  z-index: 1;
  justify-content: center;
}
.local-video{
  z-index: 10;
}
.navbar{
  background-color: transparent!important;
  border: none;
}
.remote-video{
  min-width: 100vw;
  min-height: 100vh;
  /* max-height: calc(100vh - 46px); */
  object-fit: cover;
  position: absolute;
  bottom: 0px;
  top: -50px;
  left: -1px;
}
h1{
  color: azure;
}
#profile_img{
  top: 40%;
  right: 50%;
  position: absolute;
  transform: translate(50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: 10;
}
#profile_img_div{
  top: 40%;
  right: 50%;
  position: absolute;
  transform: translate(50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: 5;
  backdrop-filter: blur(20px);
  background-image: url('/assets/images/videocall/rainbow-call.png');
  background-size: cover;
}
#username{
  top: 65%;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 3em;
}
body{
  height: 100%;
}
html{
  height: 100%;
}
</style>
