<template>
  <div class="ui segment no-shadow no-border trending pad-24">
    <div class="content">
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12">
          <h3 class="ui header">
            <img src="assets/images/emoticons/flag.png">
            <div class="content">
              Junx Magazin
              <div class="sub header">Dein LGBTIQ+ Newspaper</div>
            </div>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <router-link class="ui fluid card" style="margin-top: -8px; box-shadow: none;" :to="'/blog/a/' + article.link_slag">
    <div class="image" style="border-radius: 0!important;">
      <img :src="projectURL + 'assets/images/magazin/' + article.article_img">
    </div>
    <div class="content">
      <div class="header">
        {{article.article_title}}
      </div>
      <div class="description" id="mSub">
        {{article.article_subtitle}}
      </div>
      <div class="extra">
        <router-link class="ui violet label" :to="'/blog/s/' + article.category_name">
          {{article.category_name}}
        </router-link>
        <a class="ui label" id="mDate">
          {{date}}
        </a>
      </div>
    </div>
    <router-link to="/blog" class="ui bottom attached button btn-color-1" id="mSlag">
      <i class="eye icon"></i>
      Zum Magazin
    </router-link>
  </router-link>
</template>

<script>
export default {
  name: 'article',
  props: ['article'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    date () {
      const dateString = this.article.timestamp.split(' ')[0].split('-')
      return dateString[2] + '.' + dateString[1] + '.' + dateString[0]
    }
  }
}
</script>

<style scoped>

</style>
