<template>
  <div class="col-sp-4 col-sd-3 row-sp-12 pad-4" :class="{onlineuser: user.online == 1}">
    <router-link :to="'/p/' + user.username"  class='user_info linkTinyUser' :style="{ backgroundImage: 'url(' + projectURL + 'assets/images/user/' +  image + ')' }">
      <div class="user_info-content contentTinyUser">
        <h6 class="unameDis">{{ user.username }}</h6>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'tinyUser',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    image () {
      const re = /(?:\.([^.]+))?$/
      const img = this.user.profile_img.replace(/\.[^/.]+$/, '_small') + re.exec(this.user.profile_img)[0]
      return img
    }
  }
}
</script>

<style scoped>
.linkTinyUser {
  position: relative;background-position: center center;background-size: cover;display: block;width: 80px;height: 80px; overflow: hidden;border-radius: 8px;
}
.contentTinyUser{
  position: absolute;bottom: 0; padding: 0 4px;color: #FFFFFF;
}
.onlineuser::after {
  content: '';
  width: 15px;
  height: 15px;
  right: 16px;
  top: 20px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #21BA45 !important;
  border-color: #21BA45 !important;
  position: absolute;
}
</style>
