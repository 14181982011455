import axios from 'axios'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
const firebaseConfig = {
  apiKey: 'AIzaSyAOdCtNTkf-3D-vOUdCjysqb8BzTRwQzAA',
  authDomain: 'junx-2011.firebaseapp.com',
  projectId: 'junx-2011',
  storageBucket: 'junx-2011.appspot.com',
  messagingSenderId: '310085250790',
  appId: '1:310085250790:web:d9fb88185e3e066c361527'
}

export function firebaseSetup () {
  const isOniOS = typeof process.env.CORDOVA_PLATFORM !== 'undefined' && !!process.env.CORDOVA_PLATFORM && process.env.CORDOVA_PLATFORM.toUpperCase() === 'IOS'

  FirebasePlugin.setAutoInitEnabled(true)
  const channel = {
    id: 'junx',
    sound: 'chat.mp3',
    vibration: true,
    light: true,
    lightColor: parseInt('6435C9FF', 16).toString(),
    importance: 4,
    badge: true,
    visibility: 1
  }
  FirebasePlugin.createChannel(channel)

  if (isOniOS) {
    // iOS device token
    FirebasePlugin.onApnsTokenReceived(apnsToken => {
      console.log(apnsToken)
      sendToken(apnsToken)
    }, error => {
      console.error(error)
    })
  } else {
    // Android device token
    FirebasePlugin.onTokenRefresh(fcmToken => {
      console.log(fcmToken)
      sendToken(fcmToken)
    }, error => {
      console.error(error)
    })
  }
}

function sendToken (fcmToken) {
  if (localStorage.getItem('pushToken') !== fcmToken) {
    localStorage.setItem('pushToken', fcmToken)
    FirebasePlugin.getId(function (appInstanceId) {
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('name', process.env.CORDOVA_PLATFORM)
      formData.append('data', JSON.stringify({ token: fcmToken, id: appInstanceId }))
      axios.post(window.projectURL + 'einstellungen/setpush', formData)
        .catch(function (error) {
          console.log(error)
        })
    }, function (error) {
      console.error(error)
    })
  }
}

export function pushToken () {
  try {
    if (typeof FirebasePlugin !== 'undefined') {
      FirebasePlugin.setAutoInitEnabled(true)
      const channel = {
        id: 'junx',
        sound: 'node_sound.mp3',
        vibration: true,
        light: true,
        lightColor: parseInt('6435C9FF', 16).toString(),
        importance: 4,
        badge: true,
        visibility: 1
      }
      FirebasePlugin.createChannel(channel)
      FirebasePlugin.onTokenRefresh(function (fcmToken) {
        if (localStorage.getItem('pushToken') !== fcmToken) {
          localStorage.setItem('pushToken', fcmToken)
          FirebasePlugin.getId(function (appInstanceId) {
            const formData = new FormData()
            formData.append('SessionID', sessionStorage.getItem('SessionID'))
            formData.append('postkey', sessionStorage.getItem('postkey'))
            formData.append('name', process.env.CORDOVA_PLATFORM)
            formData.append('data', JSON.stringify({ token: fcmToken, id: appInstanceId }))
            axios.post(window.projectURL + 'einstellungen/setpush', formData)
              .catch(function (error) {
                console.log(error)
              })
          }, function (error) {
            console.error(error)
          })
        }
      }, function (error) {
        console.error(error)
      })
    }
  } catch (e) {}
}

export function pushWebToken () {
  try {
    if (typeof FirebasePlugin === 'undefined') {
      const notiApp = initializeApp(firebaseConfig)
      let fbMessaging = null
      try {
        fbMessaging = getMessaging(notiApp)
      } catch (e) {
        console.log(e)
      }
      getToken(fbMessaging, { vapidKey: 'BEY_o89RUv2ZL1ViUuHFVoJV6oSEBCFbEiJcrFHYp83OHtePFME9Hx-t0jKkOK-iWCYuH19C8vNrMDl4P4Xvqjs' }).then((currentToken) => {
        if (currentToken) {
          const formData = new FormData()
          formData.append('SessionID', sessionStorage.getItem('SessionID'))
          formData.append('postkey', sessionStorage.getItem('postkey'))
          formData.append('name', 'web')
          formData.append('data', JSON.stringify({ token: currentToken }))
          axios.post(window.projectURL + 'einstellungen/setpush', formData)
            .catch(function (error) {
              console.log(error)
            })
          onMessage(fbMessaging, (payload) => {
            console.log('Message received. ', payload)
          })
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })
    }
  } catch (e) {
    console.log(e)
  }
}
