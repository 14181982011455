<template>
  <div id="page-about"  class="spaceTop">
    <section id="AboutBanner">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-tb-10 row-sp-16 pad-40 banner-content">
          <h1 class="ui inverted header">
            Über Junx
            <div class="sub header">Das neue Zuhause für queere Jungs*</div>
          </h1>
          <p>
            Junx ist die Community, die es sich zur Aufgabe gemacht hat, queere Jungs* - also Menschen die sich als männlich identifizieren - zu verbinden und für einen sicheren Austausch zu sorgen. Wir sind ein Ort, an dem jeder* seine Erlebnisse und Erfahrungen mit allen unbesorgt teilen kann, ohne Missbrauch - in welcher Form auch immer - erfahren zu müssen. Egal ob homo-, trans-, bi-, inter-, asexuell - oder irgendetwas dazwischen. Mach Junx zu deinem neuen Zuhause.                </p>
          <a @click="scrollMeTo('team')" class="ui button btn-color-1">Unser Team</a>
        </div>
        <div class="col-sp-16 col-tb-6 row-sp-16">
          <img src="assets/images/blonde-boy-junx-about.png" class="responsive-img" style="margin-bottom: 0px;"/>
        </div>
      </div>
    </section>
    <section>
      <div class="content-1200 grid-16 pad-8">
        <div class="col-sp-16 col-tb-8 row-sp-16 pad-8">
          <div class="ui segment no-border pad-24 h-100">
            <h3 class="ui header">Freunde ja - Flirts nur wenn du möchtest</h3>
            <p>
              Junx erleichtert es dir, neue Leute kennenzulernen. Wir verstehen uns dabei jedoch in erster Linie als Plattform, die Freundschaften und keine Flirts fördern will. Deshalb achten wir bei allen öffentlichen Inhalten darauf, dass diese nicht sexualisiert und jugendfrei sind.
              Mit wem du privat welche Inhalte teilst, ist jedoch allein deine Sache. Auch hier kannst du dich allerdings jederzeit an unseren Support wenden, wenn du dich unwohl oder belästigt fühlst.
            </p>
          </div>
        </div>
        <div class="col-sp-16 col-tb-8 row-sp-16 pad-8">
          <div class="ui segment no-border pad-24 h-100">
            <h3 class="ui header">Gemeinsam Unterstützen und Aufklären</h3>
            <p>
              Wir wissen von den Problemen und Sorgen von queeren Heranwachsenden*. Zusammen mit gemeinnützigen Kinder- und Jugendzentren und sozialen Projekten unterstützen wir deshalb die queere Jugendhilfe online. Auf Junx findest du Veranstaltungen und Informationen von queeren Jugendgruppen in deiner Nähe und kannst dich online mit ihnen vernetzen.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="Team" ref="team">
      <div class="content-1200 grid-12 pad-8">
        <div class="col-sp-12 row-sp-12 pad-40 t-center">
          <h2 class="ui header">
            Das Team
            <div class="sub header">Die Köpfe hinter Junx</div>
          </h2>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/dennis-junx-team.jpg">
            <h4 class="ui header t-center">
              Dennis
              <div class="sub header">dennis@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Geschäftsführer</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/jannik-junx-team.jpg">
            <h4 class="ui header t-center">
              Jannik
              <div class="sub header">jannik@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Projektleiter</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/tommy-junx-team.jpg">
            <h4 class="ui header t-center">
              Tommy
              <div class="sub header">tommy@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Technischer-Leiter</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/tobias.jpg">
            <h4 class="ui header t-center">
              Tobias
              <div class="sub header">tobias@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Entwickler</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/daeni.jpg">
            <h4 class="ui header t-center">
              Däni
              <div class="sub header">daeni@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Supportleiter</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/marco.jpg">
            <h4 class="ui header t-center">
              Marco
              <div class="sub header">marco@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Supportleiter</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/jan.jpg">
            <h4 class="ui header t-center">
              Jan
              <div class="sub header">socialmedia@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Social Media</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/ramon.jpg">
            <h4 class="ui header t-center">
              Ramon
              <div class="sub header">socialmedia@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Social Media</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/gabi.jpg">
            <h4 class="ui header t-center">
              Gabi
              <div class="sub header">accounting@junx.app</div>
            </h4>
            <div class="ui label bg-color-2">Buchhaltung</div>
          </div>
        </div>
        <div class="col-sp-6 col-tb-4 col-sd-3 col-ld-2 pad-4">
          <div class="ui segment bg-color-2 inverted no-border no-shadow pad-16 t-center teambox h-100">
            <img class="ui small circular image" src="https://junx.app/assets/images/team/du-team.jpg">
            <h4 class="ui header inverted t-center">
              Du?!
              <div class="sub header">jobs@pridedigital.de</div>
            </h4>
            <a href="mailto:jobs@pridedigital.de" class="ui label bg-color-1">Jetzt Bewerben</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'about',
  title: 'Junx | Über',
  mounted () {
    window.junx.loading = false
  },
  methods: {
    scrollMeTo (refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop

      window.scrollTo(0, top)
    }
  }
}
</script>

<style scoped>
#page-about{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
#page-about #AboutBanner{
  width: 100%;
  min-height: 300px;
  background-color: #a9cbd5;
}
#page-about #AboutBanner .banner-content {
  padding-top: 120px!important;
}
#page-about #AboutBanner .banner-content h1{
  font-size: 2.3em;
}
#page-about #AboutBanner .banner-content p{
  color: rgba(255,255,255,0.8);
  max-width: 600px;
  font-size: 1.1em;
}
#page-about #Team h2.ui.header{
  font-size: 2.3em;
}
#page-about #Team h2.ui.header .sub.header{
  font-size: 0.6em;
}
#page-about #Team .teambox img{
  margin: 0 auto;
}
#page-about #Team .teambox h4.ui.header{
  font-size: 1.5em;
}
</style>
