<template>
  <div class="junx_card" :class="classObject" :style="styleObject" :id="candidate.id" v-if="!candidate.matched">
    <div class="junx_card__top grid-12">
      <div class="col-sp-6 row-sp-12 t-left">
        <button class="ui basic circular matchInverted icon button" @click="report()">
          <i class="flag icon"></i>
        </button>
      </div>
      <div class="col-sp-6 row-sp-12 t-right interessen">
        <div v-for="(interest, index) in sameInterests" :key="index">
          <div class="ui basic circular matchInverted icon button">
            <img :src="'assets/images/channels/' + interest.image">
          </div>
          <br>
        </div>
      </div>
    </div>
    <div class="junx_card__btm">
      <p class="junx_card__name">{{candidate.username}}, {{candidate.age}}</p>
      <a><i class="map marker alternate icon"></i> {{candidate.city}}, {{candidate.distance}}km</a>
      <p class="junx_card__we">{{candidate.description}}</p>
    </div>
    <div class="junx_card_choice m--reject"><img src="assets/images/matchgame/menu/dislike.png" style="max-width: 80px;"></div>
    <div class="junx_card_choice m--super"><img src="assets/images/matchgame/menu/super-like.png" style="max-width: 80px;"></div>
    <div class="junx_card_choice m--like"><img src="assets/images/matchgame/menu/like.png" style="max-width: 80px;"></div>
    <div class="junx_card__drag"></div>
  </div>
  <div class="junx_card junx_match_card" :class="classObject" :style="styleObject" :id="candidate.id" v-else>
    <div class="junx_card__top">
    </div>
    <div class="junx_card__btm">
      <p class="junx_card__name">It&#712;s a Match!</p>
      <div class="junx_card__we">
        <router-link class="ui button fluid btn-color-1" :to="'/p/' + candidate.username">Besuche das Profil von {{candidate.username}}</router-link>
        <div class="pad-4"></div>
        <button class="ui button fluid btn-color-2 display-Dektop-inline-important" @click="this.$parent.openMessenger()">Sende {{candidate.username}} eine Nachricht</button>
        <button class="ui button fluid btn-color-2 display-Mobile-inline-important" @click="this.$parent.openChat()">Sende {{candidate.username}} eine Nachricht</button>
        <div class="ui inverted horizontal divider">oder</div>
        <button class="ui button fluid inverted basic" @click="this.$parent.closeMatch()">Weiter Matchen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'candidate',
  props: ['candidate', 'counter'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    classObject () {
      return {
        'to-left': (this.candidate.vote === 'dislike'),
        'to-right': (this.candidate.vote === 'like'),
        'to-top': (this.candidate.vote === 'super'),
        bye: (this.candidate.vote === 'bye'),
        matched: (this.candidate.matched),
        inactive: (this.candidate.vote === 'like' || this.candidate.vote === 'dislike' || this.candidate.vote === 'super'),
        reset: this.candidate.reset
      }
    },
    styleObject () {
      return {
        'z-index': 20 - this.counter,
        'background-image': 'url(' + this.projectURL + 'assets/images/matchgame/' + this.candidate.matchImg + ')'
      }
    },
    sameInterests () {
      const intersection = this.candidate.subscribed_channels.split(',').filter(element => localStorage.getItem('subscribed_channels').split(',').includes(element))
      const interests = this.$parent.channels.filter(element => intersection.includes(element.id))

      for (let i = interests.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // random index
        [interests[i], interests[j]] = [interests[j], interests[i]] // swap
      }
      return interests.slice(0, 3)
    }
  },
  methods: {
    report () {
      window.report('matchgame', this.candidate.id)
    }
  }
}
</script>

<style scoped lang="scss">

//original selectors
//*, *:before, *:after
%extend_1 {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  @extend %extend_1;
  &:before {
    @extend %extend_1;
  }
  &:after {
    @extend %extend_1;
  }
}
#EmojiRain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: opacity 0.6s;
  z-index: -1;
}
@media(min-width: 992px) {
  #matchApp {
    padding-top: 48px;
  }
  .junx_card-cont {
    max-height: calc(100% - 220px);
    max-width: 350px;
  }
}
.junx_card-cont {
  width: 80vw;
  max-height: calc(100% - 130px);
  aspect-ratio: 9/16;
  position: absolute;
  left: 50%;
  top: 50%;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: translate(-50%, -50%);
}
.junx_card {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
  transform-origin: 50% 100%;
  overflow: hidden;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.reset {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.3s;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(0) !important;
    .junx_card_choice {
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: opacity 0.3s;
      opacity: 0 !important;
    }
  }
  &.matched {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.3s, opacity 0.3s;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1.1);
    margin-top: 48px;
  }
  &.inactive {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.3s, opacity 0.3s;
  }
  &.to-left {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(-30rem) rotate(-30deg) !important;
    opacity: 0 !important;
  }
  &.to-right {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateX(30rem) rotate(30deg) !important;
    opacity: 0 !important;
  }
  &.to-top {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-30rem) !important;
    opacity: 0 !important;
  }
  &.bye {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: perspective(500px) translateZ(400px) translateY(30rem);
    opacity: 0 !important;
  }
  .interessen {
    div {
      margin-bottom: 4px;
    }
    img {
      width: 24px;
    }
  }
}
.junx_card__top {
  position: absolute;
  top: 0;
  padding: 8px 4px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 6;
  &.purple {
    background: $color_purple_heart_approx;
  }
}
.junx_card__img {
  overflow: hidden;
  width: 10rem;
  height: 10rem;
  margin: 0 auto 1.5rem;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 50%;
  border: 0.5rem solid $white;
}
.junx_card__name {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: $white;
  margin-bottom: 0;
}
.junx_card__btm {
  padding-top: 32px;
  background: $black;
  background: linear-gradient(0deg, $black 10%, rgba(255,255,255,0) 100%);
  color: $white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 32px;
  text-align: center;
  a {
    font-size: 0.8em;
  }
}
.junx_card__we {
  text-align: center;
  font-size: 0.9em;
  padding: 0 8px;
}
.junx_card_choice {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: center;
  padding-top: 75%;
  &.m--reject {
    background: $red;
  }
  &.m--super {
    background: $color_ripe_plum_approx;
  }
  &.m--like {
    background: $color_bright_green_approx;
  }
}
.junx_card__drag {
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: grab;
}
#MatchTopMenu {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 0;
  z-index: 99;
  position: relative;
}
#MatchMenu {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  img {
    padding: 4px;
  }
  button.ui {
    &.mini {
      background-color: $white_80;
      border: 1px solid $black_15;
      img {
        max-width: 32px;
      }
    }
    &.tiny {
      background-color: $white;
      border: 1px solid $black_15;
      img {
        max-width: 44px;
      }
    }
  }
}
#maxVoteModal {
  background-color: $color_ripe_plum_approx!important;
  color: $white!important;
}
#maxSuperVoteModal {
  background-color: $color_ripe_plum_approx!important;
  color: $white!important;
}
#goBackModal {
  background-color: $color_ripe_plum_approx!important;
  color: $white!important;
}
button.ui.match {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: transform 0.3s;
  &:active {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1.5);
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: transform 0.05s !important;
  }
}
.ui.basic.button.matchInverted {
  background-color: #0000003b !important;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 0 2px rgb(255 255 255 / 50%) inset !important;
}
</style>
