<template>
  <div  v-if="size == 'small'">
    <div class="ui dropdown item" id="notis" @click="allSeen()" v-if="openAllNotis">
      <i class="bell icon" ></i>
      <div class="floating ui blue tiny circular label" id="notifications-count" v-if="unread > 0">{{unread}}</div>
      <div class="menu notifications-menu" id="notifications-menu">
        <div class="item" style="border-bottom: 1px solid rgba(0,0,0,0.05); " v-for="noti in notiarray" :key="noti.id">
          <div class="ui comments">
            <div class="ui comment cur-point">
              <a :to="'/p/' + noti.username" class="avatar" style="background-color: transparent; width: 3.5em; margin-right: 8px;">
                <img :src="projectURL + noti.profile_img" style="max-height: 45px;border-radius: 90px;">
              </a>
              <component :is="noti.type" :notidata="noti.data" :timestamp="noti.timestamp" :username="noti.username" :size="size"></component>
            </div>
          </div>
        </div>
        <div class="item t-center">
          <router-link class="ui fluid button btn-inverted-color" to="/notification" >Alle ansehen</router-link>
        </div>
      </div>
    </div>
    <router-link class="item" to="/notification" v-else> <i class="bell icon" ></i></router-link>
  </div>
  <div class="pad-8" v-for="noti in notiarray" :class="{notiSeen: seenDelay(noti.seen)}" :key="noti.id" v-else>
    <div class="ui segment no-border no-shadow grid-12 cur-point" >
      <div class="col-sp-3 col-sd-1 row-sp-12 noti-avatar">
        <router-link :to="'/p/' + noti.username" >
          <img class="ui image tiny circular" :src="projectURL + noti.profile_img">
        </router-link>
      </div>
      <component :is="noti.type" :notidata="noti.data" :timestamp="noti.timestamp" :username="noti.username" :size="size"></component>
    </div>
  </div>
</template>

<script>
import { allRead } from '@/modules/main'

import comment from '@/components/basic/notification/comment'
import commentOther from '@/components/basic/notification/commentOther'
import commentReaction from '@/components/basic/notification/commentReaction'
import friendsAccept from '@/components/basic/notification/friendsAccept'
import friends from '@/components/basic/notification/friends'
import mention from '@/components/basic/notification/mention'
import newFollower from '@/components/basic/notification/newFollower'
import newMatch from '@/components/basic/notification/newMatch'
import postReaction from '@/components/basic/notification/postReaction'
import postUnlocked from '@/components/basic/notification/postUnlocked'
import profileImageDeclined from '@/components/basic/notification/profileImageDeclined'
import stamp from '@/components/basic/notification/stamp'
import story from '@/components/basic/notification/story'
import verified from '@/components/basic/notification/verified'

export default {
  name: 'notificationNav',
  components: {
    comment: comment,
    'comment-other': commentOther,
    stamp: stamp,
    story: story,
    'comment-reaction': commentReaction,
    'post-reaction': postReaction,
    'friend-accept': friendsAccept,
    friends: friends,
    'follow-update': newFollower,
    match: newMatch,
    mention: mention,
    verified: verified,
    'post-unlocked': postUnlocked,
    'profile-image-deleted': profileImageDeclined
  },
  props: ['notiarray', 'notisread', 'size'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    unread () {
      if (typeof FirebasePlugin !== 'undefined') {
        FirebasePlugin.setBadgeNumber(window.junx.notis.length - window.junx.notisread)
      }
      return window.junx.notis.length - window.junx.notisread
    },
    openAllNotis () {
      return (this.notiarray.length > 0)
    }
  },
  methods: {
    allSeen () {
      allRead()
    },
    seenDelay (timestampNoti) {
      if (timestampNoti == null) {
        return false
      }
      const arr = timestampNoti.split(/[- :.T]/)
      const timeStamp = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
      return ((Date.now() - timeStamp) / 1000 > 300)
    },
    calculateTime (posted, now) {
      let remainTime = Math.floor((now - posted) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sekunden'
      if (remainTime <= 59)timeString = 'Jetzt'
      if (remainTime > 59) {
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Minuten'
        if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Stunden'
          if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(posted)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    }
  }
}
</script>

<style scoped>

</style>
