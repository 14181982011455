<template>
  <div id="arApp" class="spaceTop">
    <div v-if="error">
      {{error}}
    </div>
    <div v-if="email">
      <div v-show="step == 1">
        <div class="page-register">
          <div class="content-1200 grid-12 pad-8 channel-selection">
            <div class="col-sp-12 row-sp-12 pad-8 t-center">
              <h2 class="ui header">
                Wofür interessierst du dich?
                <div class="sub header">Wähle mindestens 3</div>
              </h2>
            </div>
            <channel v-for="(channel, index) in channels" :channel="channel" :key="index"></channel>
            <div class="pad-40"></div>
          </div>
          <div class="content-1200 t-center">
            <button type="button" @click="step = 2" class="ui button btn-color-1 large fluid" v-if="selectCount > 2">Weiter</button>
          </div>
        </div>
      </div>
      <div v-show="step == 2">
        <div class="page-register">
          <div class="content-1200 grid-12 pad-8">
            <div class="col-sp-12 row-sp-12 pad-8 t-center">
              <img src="assets/images/register-karte.png"/>
              <h2 class="ui header">
                <div class="content">
                  Verrate uns wo du herkommst
                  <div class="sub header">
                    und lerne Jungs* aus deiner Gegend kennen
                  </div>
                </div>
              </h2>
              <div class="pad-16">
                <form class="ui form" method="post" action="advancedregister/setLocation">
                  <div class="field">
                    <label>Land:</label>
                    <div id="countrys" class="ui fluid search selection dropdown">
                      <input type="hidden" name="country" id="countryValue" required>
                      <i class="dropdown icon"></i>
                      <div class="default text"><i>Keine Angabe</i></div>
                      <!--<div class="menu">
                          <div v-for="country in countrys" class="item" :data-value="country.country">
                              {{country.country}}
                          </div>
                      </div>-->
                    </div>
                  </div>
                  <div class="field">
                    <label>Wohnort:</label>
                    <div id='wohnort' class="ui fluid search selection dropdown">
                      <input type="hidden" name="city" @change="city=$event.target.value;" required>
                      <i class="dropdown icon"></i>
                      <div class="default text"><i>Bitte erst Land auswählen.</i></div>
                      <div class="menu">
                      </div>
                    </div>
                  </div>
                  <div class="pad-16"></div>
                  <button type="button" class="ui button large fluid" @click="step = 1">Zurück</button>
                  <div class="pad-8"></div>
                  <button type="button" class="ui button btn-color-1 large fluid" @click="step = 3" v-if="city">Weiter</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="step == 3">
        <div class="page-register">
          <div class="content-1200 grid-12 pad-8" id="channel-selection">
            <div class="col-sp-12 row-sp-12 pad-8 t-center">
              <h2 class="ui header">
                Deine Privatsphäre
                <div class="sub header">ist uns wichtig</div>
              </h2>
            </div>

            <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
              <div class="ui segment t-center pad-24" id="SelectionPublic"  :class="{select: (publicProfile == 1)}" @click="publicProfile = 1">
                <div class="checked-icon"><img src="assets/images/channels/check.png"/></div>
                <div class="selectContent">
                  <img src="assets/images/public.png"/>
                  <h4 class="pad-16">Öffentliches Profil</h4>
                  <p>Dein Profil und Beiträge sind für alle Junx Benutzer sichtbar.</p>
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
              <div class="ui segment t-center pad-24" id="SelectionPrivate" :class="{select: (publicProfile == 0)}" @click="publicProfile = 0">
                <div class="checked-icon"><img src="assets/images/channels/check.png"/></div>
                <div class="selectContent">
                  <img src="assets/images/private.png"/>
                  <h4 class="pad-16">Privates Profil</h4>
                  <p>Dein Profil und Beiträge sind nur für deine Freunde sichtbar.</p>
                </div>
              </div>
            </div>
            <div class="pad-24"></div>
          </div>
          <div class="content-1200 t-center pad-16">
            <button type="button" class="ui button large fluid" @click="step = 2">Zurück</button>
            <div class="pad-8"></div>
            <button type="button" @click="submit" class="ui button btn-color-1 large fluid" v-if="publicProfile != null">Weiter</button>
          </div>
        </div>
      </div>
      <div v-show="step == 4">
        <div id="page-register" v-if="!uploadImg">
          <div class="content-1200 grid-12 pad-8" id="profileimage-selection">
            <div class="col-sp-12 row-sp-12 pad-8 t-center">
              <h2 class="ui header">
                Zeig uns wer du bist!
                <div class="sub header">Lade ein Profilbild hoch</div>
              </h2>
              <div class="pad-16">
                <label for="datei">
                  <a>
                    <img class="ui medium circular image" src="assets/images/user/avatar_default.png"/>
                    <button class="ui circular icon massive button" style="position:relative; z-index: 3; margin-top: -120px; margin-right: -120px;">
                      <i class="icon upload"></i>
                    </button>
                  </a>
                </label>
                <div id="loader" style="top: 58px!important;height: calc(100% - 111px);z-index: 5; display: none;" class="ui active inverted dimmer">
                  <div class="ui text loader">Bitte warten</div>
                </div>
              </div>
              <div class="pad-16">
                <router-link to="/wall" class="color-2">Überspringen</router-link>
              </div>
            </div>
          </div>
          <div style="position: fixed; bottom: 0; margin: 0 auto; width: 100%; z-index: -1;" class="t-center">
            <img src="assets/images/pride-flag.gif" style="max-width: 200px;margin: 0 auto;"/>
          </div>
          <form id="uploadForm">
            <input type="file" id="datei" @change="uploadFile" style="display: none;" value="" name="postimage" accept="image/png, image/jpg, image/jpeg" >
          </form>
        </div>
        <div class="page-register" v-else>
          <div class="content-1000 grid-12 pad-8">
            <div class="col-sp-12 row-sp-12 pad-16 t-center">
              <h3 class="ui header">
                Schneide dein Bild zu
                <div class="sub header">
                  Quadratisch, Praktisch... ähm Rund? <img style="max-width: 32px;" src="assets/images/emojis/1f914.png"/>
                </div>
              </h3>
              <p>Makiere den Bereich auf dem Bild, welches du als Profilbild nutzen möchtest und klicke danach auf Speichern.</p>
            </div>
            <div class="col-sp-12 row-sp-12 pad-16">
              <div>
                <img :src="projectURL + 'assets/images/temp/'+uploadImg" id="target" class="responsive-img" style="display: block;">
              </div>
              <form id="cropForm">
                <input type="hidden" id="x" name="x" />
                <input type="hidden" id="y" name="y" />
                <input type="hidden" id="w" name="w" />
                <input type="hidden" id="h" name="h" />
                <input type="hidden" name="file" :value="uploadImg"/>
                <div class="pad-8 t-center">
                  <button id="savecrop" type="button" @click="crop()" class="ui fluid purple disabled button avatar-save">Speichern</button>
                </div>
              </form>
              <div class="pad-8 t-center">
                <a onclick="openDashboard('tutorial')" class="color-2">Überspringen</a>
              </div>
            </div>
            <div class="pad-16"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="page-register">
        <div class="content-1200 grid-12 pad-8 profileimage-selection">
          <div class="col-sp-12 row-sp-12 pad-8 t-center">
            <h2 class="ui header">
              {{emailText}}
              <br><br>
              <div class="sub header">Bitte bestätige deine Email-Adresse.<br>Solltest du Probleme mit dem Erhalt der Email haben, zögere nicht unseren Support anzuschreiben: <a href="mailto:support@junx.app">Support@junx.app</a></div>
              <br><br>
              <a @click="resendEmail()" v-if="!resend"> <button type="submit" class="ui button btn-color-1 big">Email erneut senden</button></a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import channel from '@/components/advancedregister/channel'
import { initCountry, getCities } from '@/modules/advancedregister'
import axios from 'axios'

export default {
  name: 'advancedregister',
  title: 'Junx | Registrierung',
  props: ['page'],
  components: {
    channel
  },
  data () {
    return {
      projectURL: window.projectURL,
      step: (this.page === '' || this.page == null) ? 1 : this.page,
      channels: [],
      countrys: [],
      uploadImg: false,
      email: null,
      emailText: 'Dir wurde eine Email versand!',
      resend: false,
      error: false,
      loading: true,
      selectCount: 0,
      selectedChannels: [],
      publicProfile: 1,
      country: false,
      city: false
    }
  },
  watch: {
    step () {
      if (this.step === 2)initCountry(this)
    }
  },
  methods: {
    selectChannel (slag, id) {
      const Selection = document.getElementById('Selection_' + slag)
      if (Selection.className.replace(/[\t]/g, ' ').indexOf('select') > -1) {
        const channelIndex = this.selectedChannels.indexOf(id)
        Selection.classList.remove('select')
        this.selectCount = this.selectCount - 1
        this.selectedChannels.splice(channelIndex, 1)
      } else {
        Selection.classList.add('select')
        this.selectCount = this.selectCount + 1
        this.selectedChannels.push(id)
      }
    },
    cropper () {
      const _this = this
      const Cropper = window.Cropper
      const minCroppedWidth = 100
      const minCroppedHeight = 100

      const image = document.getElementById('target')
      const cropper = new Cropper(image, {
        aspectRatio: 1 / 1,
        zoomable: false,
        crop (event) {
          if (
            event.detail.width < minCroppedWidth ||
              event.detail.height < minCroppedHeight
          ) {
            cropper.setData({
              width: minCroppedWidth,
              height: minCroppedHeight
            })
          }
          document.getElementById('savecrop').classList.remove('disabled')
          document.getElementById('x').value = event.detail.x
          document.getElementById('y').value = event.detail.y
          document.getElementById('w').value = event.detail.width
          document.getElementById('h').value = event.detail.height
        }
      })
      _this.loading = false
    },
    submit () {
      const _this = this
      this.loading = true
      const formData = new FormData()
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('city', this.city)
      formData.append('country', this.country)
      formData.append('ChannelsValue', this.selectedChannels.toString())
      formData.append('profileValue', this.publicProfile)
      axios.post(window.projectURL + 'advancedregister/finish', formData)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            try {
              const data = response.data
              localStorage.setItem('lat', data.lat)
              localStorage.setItem('long', data.long)
              localStorage.setItem('account_public', data.account_public)
              localStorage.setItem('subscribed_channels', data.subscribed_channels)
              localStorage.setItem('register_status', '1')
              _this.step = 4
              _this.loading = false
            } catch (e) {}
          } else {
            _this.error = true
            _this.loading = false
          }
        })
        .catch(function (err) { console.log(err) })
    },
    uploadFile () {
      const _this = this
      _this.loading = true
      const uploadData = new FormData(document.getElementById('uploadForm'))
      uploadData.append('SessionID', sessionStorage.getItem('SessionID'))
      uploadData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'advancedregister/uploadImage', uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          if (response.status === 250) {
            try {
              const data = response.data
              _this.uploadImg = data.uploadImg
              setTimeout(function () {
                _this.cropper()
              }, 100)
            } catch (e) {
              _this.error = true
              _this.loading = false
            }
          } else if (response.status === 299) {
            _this.error = true
            _this.loading = false
          }
        })
    },
    crop () {
      const _this = this
      _this.loading = true
      const uploadData = new FormData(document.getElementById('cropForm'))
      uploadData.append('SessionID', sessionStorage.getItem('SessionID'))
      uploadData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'advancedregister/crop', uploadData)
        .then(function (response) {
          if (response.status === 250) {
            try {
              const data = response.data
              localStorage.setItem('profile_img', data.profile_img)
              openDashboard()
            } catch (e) {
              _this.error = true
              _this.loading = false
            }
          } else if (response.status === 251) {
            _this.error = 'Zu viel Haut. Bitte wähle ein anderes Bild'
            _this.loading = false
          } else if (response.status === 299) {
            _this.error = true
            _this.loading = false
          } else {
            openLogin()
          }
        })
    },
    resendEmail () {
      const _this = this
      $.get(projectURL + 'register/resend', function (data, status, xhr) {
        if (xhr.status === 250) {
          _this.emailText = 'Email erneut versand!'
          _this.resend = true
        }
      })
    }

  },
  mounted () {
    const _this = this
    const formData = new FormData()
    formData.append('postkey', sessionStorage.getItem('postkey'))
    formData.append('SessionID', sessionStorage.getItem('SessionID'))
    axios.post(window.projectURL + 'advancedregister/indexjs', formData)
      .then(function (response) {
        if (response.status === 250) {
          try {
            const data = response.data
            console.log(data)
            _this.email = data.email
            _this.channels = data.channels
            for (const key in data.countrys) {
              _this.countrys.push({ value: data.countrys[key].country, name: data.countrys[key].country })
            }
            $('#countrys').dropdown('setup menu', { values: _this.countrys })
            initCountry(_this)
          } catch (e) { _this.error = true }
          _this.loading = false
        } else if (response.status === 290) {
          openDashboard('tutorial')
        } else if (response.status === 280) {
          openBusinessLogin()
        } else if (response.status === 299) {
          openLogin()
        } else {
          _this.error = true
          _this.loading = false
        }
      })
      .finally(function () {
        window.junx.loading = false
      })
  }
}
</script>

<style scoped>

</style>
