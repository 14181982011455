<template>
  <div class="content scrolling">
    <div class="grid-12" id="channel-selection">
      <div class="col-sp-12 row-sp-12 pad-8 t-center">
        <h2 class="ui header">
          Wofür interessierst du dich?
          <div class="sub header">Wähle mindestens 3 Kanäle</div>
        </h2>
      </div>
      <div class="col-sp-12 row-sp-12 pad-16 t-center">
        <div id="interests">
          <a class="ui basic label" :class="{active: ownInterests.includes(interest.id)}" v-for="(interest, index) in userdata.interests.filter(function (el) {return el.channel_type === 'channel'})" :key="index" @click="changeInterest(interest.id)">
            {{ interest.channel_title }}
            <img class="ui mini image" :src="projectURL + '/assets/images/channels/' +interest.channel_img">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'interests',
  props: ['userdata'],
  data () {
    return {
      projectURL: window.projectURL,
      ownInterests: this.userdata.ownInterests,
      reload: false
    }
  },
  methods: {
    changeInterest (id) {
      this.reload = true
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('interest', id)
      if (this.ownInterests.includes(id)) {
        formData.append('add', 0)
        this.ownInterests.splice(this.ownInterests.indexOf(id), 1)
      } else {
        formData.append('add', 1)
        this.ownInterests.push(id)
      }
      axios.post(window.projectURL + 'profile/setInterest', formData)
    }
  },
  mounted () {
    window.junx.loading = false
  },
  beforeRouteLeave (to, from, next) {
    const _this = this
    if (this.reload) {
      _this.$emit('resetdata', '')
    }
    next()
  }
}
</script>

<style scoped>

</style>
