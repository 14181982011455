<template>
  <div id="Blog" class="spaceTop">
    <router-view></router-view>
    <div class="pad-40"></div>
  </div>
</template>

<script>
export default {
  name: 'blog',
  title: 'Junx | Blog'
}
</script>

<style scoped>

</style>
