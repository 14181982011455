<template>
  <div id="Mobile-Bottom-Menu">
    <div class="footer-tabs border-top text-center">
      <ul class="nav nav-tabs justify-content-center" >
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/wall" aria-controls="home" aria-selected="true" style="position: relative;">
            <i class="material-icons">home</i>
            <small class="sr-only">Home</small>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/v" aria-controls="search" aria-selected="false" style="position: relative;">
            <i class="material-icons">pets</i>
          </router-link>
        </li>
        <li class="nav-item centerlarge">
          <router-link active-class="active" class="nav-link bg-default" to="/messages" data-active="messages" aria-controls="messenger" aria-selected="false" style="position: relative;">
            <i class="material-icons">chat_bubble_outline</i>
            <div class="floating ui blue tiny circular label" v-if="chats > 0">
              {{chats}}
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/search" data-acitve="search" aria-controls="favorite" aria-selected="false" style="position: relative;">
            <i class="material-icons">search</i>
          </router-link>
        </li>
        <!--
        <li class="nav-item">
            <a class="nav-link " onclick="openStorys()" aria-controls="cart" aria-selected="false" style="position: relative;" data-active="storys">
                <i class="material-icons">mobile_screen_share</i>
                <small class="sr-only">mobile_screen_share</small>
            </a>
        </li>
        -->
        <li class="nav-item">
          <router-link active-class="active" class="nav-link "  :to="'/p/' + user.username" aria-controls="profile" aria-selected="false" style="position: relative;">
            <i class="material-icons">person</i>
            <small class="sr-only">person</small>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mobileBottom',
  props: ['chats', 'user']
}
</script>

<style scoped>

</style>
