/* eslint-disable no-prototype-builtins,eqeqeq */
import { urlify, nl2br, cookieLogin, playSound } from '@/modules/main'
import { addWaveSegment, initAudioPlayer, pauseAudioPlayer, resetAudioPlayer } from '@/modules/record'
import axios from 'axios'
import router from '@/router'
// main Chat-Object
export function Messenger () {
  // delcare constans for chat-scope
  const monthsobj = {
    '01': 'Jan.',
    '02': 'Feb.',
    '03': 'Mär.',
    '04': 'Apr.',
    '05': 'Mai',
    '06': 'Jun.',
    '07': 'Jul.',
    '08': 'Aug.',
    '09': 'Sep.',
    10: 'Okt.',
    11: 'Nov.',
    12: 'Dez.'
  }
  const chatPartner = $('#MessengerContactsList')
  const chatPartnerFav = $('#MessengerContactsFav')
  const chatPartnerNew = $('#MessengerContactsListNew')
  const ChatHeaderProfileImage = $('#MessengerProfileImage')
  const ChatHeaderName = $('#MessengerName')
  const ChatHeaderVerify = $('#MessengerVerified')
  const ChatUpload = $('#chatUpload')
  const chatBoxContent = $('#MessengerChatContent')
  const MessageContent = $('#messageContent')
  const MediaSlider = $('#MediaSlider')
  const MediaSliderWrapper = $('#MediaSliderWrapper')
  const SearchChatBoxes = $('#search-chatboxes')
  const SearchContactBox = $('#search-contactboxes')
  const SearchTenor = $('#searchTenor')
  const AllContacts = $('#AllContacts')
  const sendChat = $('#sendChat')
  const sendChatRec = $('#sendChatRec')
  const recordAudio = $('#recordAudio')
  const recordAudioPlayer = $('#rec_audio')
  const timeAudioBox = $('#timeAudioBox')
  const stopRecord = $('#stopAudio')
  const deleteRecord = $('#deleteAudio')
  const playRecord = $('#playRecord')
  const pauseRecord = $('#pauseRecord')
  const MessageChats = $('#MessageChats')
  const MessageRequests = $('#MessageRequests')
  const MessengerChatActions = $('#MessengerChatActions')
  const MessengerChatNew = $('#MessengerChatNew')
  const MessengerChatActionsBottom = $('#MessengerChatActionsBottom')
  const Smileys = $('#smileys')
  const Tenor = $('#Gifs')
  const TenorContainer = $('#GifMenu')
  const EmoticonsMenu = $('#EmoticonsMenu')
  const mediaQuery = window.matchMedia('(max-width: 1239px)')
  const contentLoader = $('#ChatContentloader').clone(true)
  const recordingWave = $('#recordingWave')
  const groupSender = 300 // in seconds

  const nodeidc = sessionStorage.getItem('NodeID')
  const projectURL = window.projectURL
  const audio = 'assets/audio/chat.wav'
  const audioRecordExtension = process.env.CORDOVA_PLATFORM == 'android' ? 'aac' : 'wav'
  const audioRecordPath = 'cdvfile://localhost/temporary/memo.' + audioRecordExtension

  // declare variables for chat-scope
  let active = document.hasFocus()
  let audioInterval
  let audioStopped = false
  let audioplaying
  let chatactive = false
  const chatSmids = []
  const chatBoxes = {}
  const sorting = []
  let contacts = null
  const uploadFiles = {}
  let currentChatBox = null
  const thisChat = this
  let sendallowed = 0
  let imageCounter = 0
  let unreadChatBoxes = 0
  let unreadNewChatBoxes = 0
  let destroyMedia = 0
  let uploadType
  let audioStop = false
  let voiceMemo = null
  let lastScrollPos = 0
  let loadMoreScrollPos = -1
  let scrolled = false
  let loadMoreBlock = false
  let lastChatID = -1
  let mediaRec
  let mediaID
  let audioPlayers = []
  let mediaRecorder
  let streams

  // init
  this.init = function () {
    // start by loading chatboxes
    window.chat.loadData()
    // add listeners to socket
    window.chat.activateSocket()
    // always scroll to bottom if not scrolled
    window.chat.scrollBottom()
  }

  // accept chat
  this.accept = function () {
    if (chatBoxes[currentChatBox].permission == 0) {
      chatBoxes[currentChatBox].permission = 1
      thisChat.countUnreadChats()
      thisChat.sortChatBoxes()
      $('.chatList').show()
      $('.newList').hide()
      MessengerChatNew.hide()
      MessengerChatActions.show()
      window.socket.emit('chatAccept', { room: nodeidc, pid: currentChatBox })
    }
  }

  // update and add listeners for socket
  this.activateSocket = function () {
    window.socket.off('newMessage')
    window.socket.off('newRead')
    window.socket.off('readChatBox')
    window.socket.off('typing')
    window.socket.off('likeMessage')
    window.socket.off('revokeMessage')
    window.socket.off('favorite')
    window.socket.off('offline')
    window.socket.off('clearChat')
    window.socket.off('revokeAll')
    window.socket.off('block')

    // if (lastChatID != -1 && lastChatID > 1000)$.post(projectURL + 'messages/updateChat', { mid: lastChatID, postkey: sessionStorage.getItem('postkey') })
    // console.log('lastChatID: ' + lastChatID)
    // push new Messages to Chat
    window.socket.on('newMessage', (data) => {
      if (!chatSmids.includes(data.smid)) {
        chatSmids.push(data.smid)
        thisChat.newSocketMessage(data.sender, data.recipient, data.message)
      }
    })

    // mark messages as read by partner
    window.socket.on('newRead', (data) => {
      try {
        chatBoxes[data.pid].readMessages()
      } catch (e) {}
    })

    // mark Chatbox as read by self
    window.socket.on('readChatBox', (data) => {
      try {
        chatBoxes[data].readChatBox()
      } catch (e) {}
    })

    // partner is typing
    window.socket.on('typing', (data) => {
      try {
        chatBoxes[data].typing()
      } catch (e) { }
      // console.log("typing "+data);
    })

    // message is liked
    window.socket.on('likeMessage', (data) => {
      try {
        chatBoxes[data.pid].getMessages()[data.mid].like(data.liked)
      } catch (e) {
        console.log(e)
      }
    })

    // message is revoked by sender or recipient
    window.socket.on('revokeMessage', (data) => {
      try {
        chatBoxes[data.pid].getMessages()[data.mid].revoke()
      } catch (e) { }
    })

    // save favorite state
    window.socket.on('favorite', (data) => {
      try {
        const favorite = (data.save == 0) ? 1 : 0
        chatBoxes[data.pid].favorite = favorite
        if (currentChatBox == data.pid) {
          if (favorite == 0) {
            $('#modal_fav').html('<i class="icon outline star"></i>Als Favorit hinzufügen')
          } else {
            $('#modal_fav').html('<i class="icon star"></i>Als Favorit entfernen')
          }
        }
        if (favorite == 1) {
          chatBoxes[data.pid].element.find('.icon.star').show()
        } else {
          chatBoxes[data.pid].element.find('.icon.star').hide()
        }
        thisChat.sortChatBoxes()
      } catch (e) {
        console.log(e)
      }
    })

    // update Online
    window.socket.on('online', (data) => {
      try {
        for (const key in data) {
          if (data[key].online == 1) {
            chatBoxes[data[key].id].element.find('.onlinestatus').show()
          } else {
            chatBoxes[data[key].id].element.find('.onlinestatus').hide()
          }

          chatBoxes[data[key].id].online = data[key].online

          if (data[key].id == currentChatBox) {
            if (data[key].online == 1) {
              $('#Online-User').show()
            } else {
              $('#Online-User').hide()
            }
          }
        }
      } catch (e) {
      }
    })

    // cleared Chat for themselves
    window.socket.on('clearChat', (data) => {
      try {
        $('#MessengerStart').show()
        $('#MessengerRightSide').hide()
        $('#MessengerLeftSide').show()
        const messages = chatBoxes[data.pid].getMessages()
        for (const key in messages) {
          delete messages[key]
        }
        chatBoxes[data.pid].element.remove()
        delete chatBoxes[data.pid]
        thisChat.countUnreadChats()
      } catch (e) { }
    })

    // cleared Chat for all
    window.socket.on('revokeAll', (data) => {
      try {
        const messages = chatBoxes[data.pid].getMessages()
        for (const key in messages) {
          messages[key].revoke()
        }
      } catch (e) { }
    })

    // Partner block
    window.socket.on('block', (data) => {
      console.log(data)
      try {
        if (data.user1 == localStorage.getItem('user_id')) {
          chatBoxes[data.user2].hasBlocked = data.block
          chatBoxes[data.user2].blockStatus()
        } else if (data.user2 == localStorage.getItem('user_id')) {
          chatBoxes[data.user1].isBlocked = data.block
          chatBoxes[data.user1].blockStatus()
        }
      } catch (e) {}
    })
  }

  // open Chat of selected contact
  this.addNewContact = function (pid) {
    try {
      if (!chatBoxes.hasOwnProperty(pid)) {
        chatBoxes[pid] = new ChatBox(contacts[pid].obj, true)
        chatBoxes[pid].addChatBox()
        chatBoxes[pid].toTop()
      }
      chatBoxes[pid].open()
      $('#NewChatModal').modal('hide')
    } catch (e) {
      console.log(e)
    }
  }

  // playback for audios on Cordova
  this.appPlayBack = function (src, id, duration) {
    if (!audioPlayers.includes(id)) {
      audioPlayers.push(id)
      initAudioPlayer(src, id, duration)
    }
    return false
  }

  // block or unblock
  this.block = function () {
    try {
      window.socket.emit('block', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, block: chatBoxes[currentChatBox].hasBlocked })
      $('#ChatMenuModal').modal('hide')
    } catch (e) {}
  }

  // clear chat
  this.clearChat = function (type) {
    try {
      window.socket.emit('clearChat', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, clear: type })
      $('.clearChat.modal').modal('hide')
    } catch (e) {}
  }

  // count unread Chats
  this.countUnreadChats = function () {
    unreadChatBoxes = 0
    unreadNewChatBoxes = 0

    for (const key in chatBoxes) {
      if (chatBoxes[key].unread == 1 && (chatBoxes[key].friends == 1 || chatBoxes[key].permission == 1 || chatBoxes[key].matchGame == 1 || chatBoxes[key].favorite == 1 || chatBoxes[key].direct)) {
        unreadChatBoxes++
      } else if (chatBoxes[key].friends == 0 && chatBoxes[key].permission == 0 && chatBoxes[key].matchGame == 0 && chatBoxes[key].favorite == 0 && !chatBoxes[key].direct) {
        unreadNewChatBoxes++
      }
    }

    switch (true) {
      case (unreadNewChatBoxes == 0):
        MessageRequests.hide()
        $('.chatList').show()
        $('.newList').hide()
        break

      case (unreadNewChatBoxes == 1):
        MessageRequests.show()
        MessageRequests.text('1 Nachrichtenanfrage')
        break

      case (unreadNewChatBoxes > 1):
        MessageRequests.show()
        MessageRequests.text(unreadNewChatBoxes + ' Nachrichtenanfragen')
        break
    }

    switch (true) {
      case (unreadChatBoxes == 0):
        MessageChats.text('Alle Chats')
        break

      case (unreadChatBoxes == 1):
        MessageChats.text('1 ungelesener Chat')
        break

      case (unreadChatBoxes > 1):
        MessageChats.text(unreadChatBoxes + ' ungelesene Chats')
        break
    }
  }

  // delete message
  this.deleteMessage = function (mid) {
    try {
      const sender = (chatBoxes[currentChatBox].getMessages()[mid].sender == chatBoxes[currentChatBox].getMessages()[mid].partnerId) ? 0 : 1

      if (sender == 1 && chatBoxes[currentChatBox].getMessages()[mid].revoked == 0) {
        $('.ui.revoke.modal')
          .modal({
            closable: true,
            onDeny: function () {
              return true
            },
            onApprove: function () {
              window.socket.emit('revokeMessage', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, mid: mid, sender: sender })
            }
          })
          .modal('show')
      }
      /* else {
          window.socket.emit('deleteMessage', {room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, mid: mid, sender: sender});
      } */
    } catch (e) {
      console.error(e)
    }
  }

  // open directchat
  this.directChat = function (username = null) {
    try {
      if (username != null && username != '') {
        let open = -1
        for (const key in chatBoxes) {
          if (chatBoxes[key].username == username)open = key
        }

        if (open == -1) {
          $.post(projectURL + 'messages/directChat',
            {
              username: username,
              SessionID: sessionStorage.getItem('SessionID')
            },
            function (data, status, xhr) {
              if (xhr.status == 250) {
                try {
                  AllContacts.empty()
                  const contactData = JSON.parse(data)
                  chatBoxes[contactData.id] = new ChatBox(contactData, true)
                  chatBoxes[contactData.id].addChatBox(true)
                  chatBoxes[contactData.id].toTop(null, true)
                  chatBoxes[contactData.id].open()
                } catch (e) {
                  console.error(e)
                }
              } else if (xhr.status == 299) {
                cookieLogin().then(() => { window.chat.directChat() }).catch(openLogin)
              }
            })
        } else {
          chatBoxes[open].open()
        }
      }
    } catch (e) { console.log(e) }
  }

  // add or remove as favorite
  this.favorite = function () {
    try {
      window.socket.emit('favorite', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, favorite: chatBoxes[currentChatBox].favorite })
      $('#ChatMenuModal').modal('hide')
    } catch (e) {}
  }

  // return all chatboxes
  this.getChatBoxes = function () {
    return chatBoxes
  }

  // get id of current Chatbox
  this.getCurrentChatBox = function () {
    return currentChatBox
  }

  // like message
  this.likeMessage = function (mid) {
    try {
      const liked = (chatBoxes[currentChatBox].getMessages()[mid].liked == 1) ? 0 : 1
      window.socket.emit('likeMessage', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox, mid: mid, like: liked })
    } catch (e) {
      console.err(e)
    }
  }

  // load all Chatboxes via PHP
  this.loadData = function () {
    $.post(projectURL + 'messages/getchats', { SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
      if (xhr.status == 250) {
        try {
          const chatdata = JSON.parse(data)
          for (const key in chatdata) {
            chatBoxes[key] = new ChatBox(chatdata[key])
            sorting.push({ id: key, mdate: chatdata[key].date })
          }
          sorting.sort(function (a, b) { if (a.mdate < b.mdate) { return 1 } if (a.mdate > b.mdate) { return -1 } return 0 })
          // chatPartner.empty();
          $('#contactListLoader').hide()
          for (const key in sorting) {
            chatBoxes[sorting[key].id].addChatBox()
          }
          chatactive = true
          thisChat.updateStatus()
          thisChat.countUnreadChats()
        } catch (e) {
          console.error(e)
        }
      } else if (xhr.status == 299) {
        cookieLogin().then(() => { window.chat.loadData() }).catch(openLogin)
      }
    })
  }

  // loadMore
  this.loadMore = function () {
    loadMoreBlock = true
    loadMoreScrollPos = chatBoxContent.prop('scrollHeight') - chatBoxContent.height() - chatBoxContent.scrollTop()
    chatBoxes[currentChatBox].loadMessages(chatBoxes[currentChatBox].getOldestMessageID())
  }

  // load gifs from tenor
  this.loadTenor = function (string = '') {
    if (string == '') {
      $.get('https://g.tenor.com/v1/trending', {
        key: 'WZFDYIMHHAVT',
        limit: 20
      },
      function (data, status) {
        try {
          $('#tenor-container').empty()
          const tenor = data.results
          for (const key in tenor) {
            const string =
                '<div class="tenor-gif">\n' +
                '    <img src="' + tenor[key].media[0].nanogif.url + '">' +
                '</div>\n'
            const tenorDiv = $('<div/>').html(string).contents()
            $('#tenor-container').append(tenorDiv)
          }
          $('.tenor-gif').on('click', function () {
            thisChat.sendTenor($(this).find('img').attr('src'))
          })
        } catch (e) {
          console.error(e)
        }
      })
    } else {
      $.get('https://g.tenor.com/v1/search', {
        key: 'WZFDYIMHHAVT',
        limit: 20,
        q: string
      },
      function (data, status) {
        try {
          $('#tenor-container').empty()
          const tenor = data.results
          for (const key in tenor) {
            const string =
                '<div class="tenor-gif">\n' +
                '    <img src="' + tenor[key].media[0].nanogif.url + '">' +
                '</div>\n'
            const tenorDiv = $('<div/>').html(string).contents()
            $('#tenor-container').append(tenorDiv)
          }
          $('.tenor-gif').on('click', function () {
            thisChat.sendTenor($(this).find('img').attr('src'))
          })
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  // go back in mobile
  this.mobileBack = function () {
    $('#MessengerLeftSide').show()
    $('#MessengerMobileMenu').show()
    $('#MessengerRightSide').hide()
    TenorContainer.hide()
    recordAudioPlayer.removeAttr('src')
    $('#Online-User').hide()
    $('#messageAudioSlider').hide()
    chatBoxContent.empty()
    timeAudioBox.hide()
    scrolled = false
    currentChatBox = null
  }

  // search for new contacts
  this.newContact = function () {
    $('#rainbowloader').show()
    AllContacts.hide()
    SearchContactBox.val('')
    $('#NewChatModal').modal('show')
    $.post(projectURL + 'messages/getcontacts', { SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
      if (xhr.status == 250) {
        try {
          AllContacts.empty()
          const contactData = JSON.parse(data)
          contacts = {}
          for (const key in contactData) {
            contacts[contactData[key].id] = new Contact(contactData[key])
            contacts[contactData[key].id].addContact()
          }
          $('#rainbowloader').hide()
          AllContacts.show()
        } catch (e) {

        }
      } else if (xhr.status == 299) {
        cookieLogin().then(() => { window.chat.newContact() }).catch(openLogin)
      }
    })
  }

  // handle incoming messages
  this.newSocketMessage = function (sender, recipient, messageObj) {
    let id, partnerData
    if (messageObj.sender == localStorage.getItem('user_id')) {
      id = messageObj.recipient
      partnerData = recipient
    } else {
      id = messageObj.sender
      partnerData = sender
    }
    partnerData.date = messageObj.date
    partnerData.unread = 1
    if (chatBoxes.hasOwnProperty(id)) {
      chatBoxes[id].newMessage(messageObj)
      if (messageObj.recipient == localStorage.getItem('user_id') && (currentChatBox == null || id != currentChatBox || !active)) {
        chatBoxes[id].unreadChatBox()
        chatBoxes[id].videoStatus(1)
        playSound(audio, true, true)
      } else if (messageObj.recipient == localStorage.getItem('user_id') && id == currentChatBox && active && window.junx.chatModal) {
        window.socket.emit('readChatBox', { room: nodeidc, uname: window.junx.user.username, pid: id })
        chatBoxes[id].videoStatus(1)
      }
    } else if (id == messageObj.sender) {
      chatBoxes[id] = new ChatBox(partnerData)
      chatBoxes[id].addChatBox()
      chatBoxes[id].newMessage(messageObj)
      playSound(audio, true, true)
    }

    chatBoxes[id].toTop(messageObj.date)

    thisChat.countUnreadChats()
  }

  // open file (image) upload
  this.openFileUpload = function (type) {
    EmoticonsMenu.hide()
    TenorContainer.hide()
    destroyMedia = 0
    uploadType = type
    switch (type) {
      case 'image':
        ChatUpload.attr('accept', 'image/jpeg, image/png, image/tiff')
        ChatUpload.attr('multiple', 'true')
        break

      case 'video':
        ChatUpload.attr('accept', 'video/mp4, video/webm, video/flv, video/avi, video/wmv, video/mkv, video/quicktime')
        ChatUpload.attr('multiple', 'true')
        break

      case 'audio':
        ChatUpload.attr('accept', 'audio/mp3, audio/aac, audio/wma, audio/mp4, audio/ogg')
        ChatUpload.attr('multiple', 'true')
        break

      case 'destroy':
        destroyMedia = 1
        ChatUpload.attr('accept', 'image/jpeg, image/png, image/tiff, video/mp4, video/webm, video/flv, video/avi, video/wmv, video/mkv, video/quicktime')
        ChatUpload.removeAttr('multiple')
        break
    }
    ChatUpload.val('')
    ChatUpload.click()
    for (const key in uploadFiles) {
      this.removeUploadedFile(key)
    }
  }

  // open image
  this.openImage = function (file, mid) {
    try {
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('file', file)
      axios.post(projectURL + 'messages/files/', formData).then(function (response) {
        if (response.status === 250) {
          $('.chat-image').attr('src', window.projectURL + response.data.link)
          $('#ChatImageModal').modal('show')
          chatBoxes[currentChatBox].getMessages()[mid].viewed(2)
        }
      })
    } catch (e) {}
  }

  // open profile
  this.openProfile = function () {
    try {
      const username = chatBoxes[currentChatBox].username
      if (username.length > 2) router.push('/p/' + username)
    } catch (e) {}
  }

  // open search
  this.openSearch = function (hash) {
    router.push({ name: 'Search', query: { t: 'Hashtags', s: hash } })
  }

  // open video
  this.openVideoFile = function (file, mid) {
    const formData = new FormData()
    formData.append('SessionID', sessionStorage.getItem('SessionID'))
    formData.append('file', file)
    axios.post(projectURL + 'messages/files/?size=small', formData).then(function (response) {
      if (response.status === 250) {
        try {
          $('#ChatVideoTag').attr('src', window.projectURL + response.data.link)
          $('#ChatVideoModal').modal({
            onHidden: function () {
              document.querySelector('#ChatVideoTag').pause()
              document.querySelector('#ChatVideoTag').currentTime = 0
            }
          }).modal('show')
          chatBoxes[currentChatBox].getMessages()[mid].viewed(2)
        } catch (e) {
          // console.error(e)
        }
      } else if (response.status === 299) {
        cookieLogin().then(() => { window.chat.openVideoFile(file, mid) }).catch(function () { router.push('/login') })
      }
    })
  }

  // handle push Data
  this.pushMessage = function (data) {
    this.newSocketMessage(data.sender, data.recipient, data.message)
  }

  // record audio
  this.recordAudio = function () {
    pauseAudioPlayer()
    const time = Date.now() / 1000 | 0
    let steps = 0
    voiceMemo = null
    recordingWave.empty()
    recordingWave.show()
    $('#messageContentSlider').hide()
    recordAudio.hide()
    deleteRecord.hide()
    playRecord.hide()
    pauseRecord.hide()
    stopRecord.show()
    sendChat.hide()
    sendChatRec.hide()
    Smileys.hide()
    timeAudioBox.show()
    MessengerChatActionsBottom.hide()
    const tempWidth = $('#MessengerChatActionsTop').outerWidth() - 20

    if (process.env.CORDOVA_PLATFORM != undefined) {
      timeAudioBox.animate({ width: tempWidth + 'px' }, 500, function () {
        steps = Math.floor(recordingWave.width() / 3)
        mediaRec = new Media(audioRecordPath)
        setTimeout(function () {
          mediaRec.startRecord()
          audioInterval = setInterval(function () {
            const timer = (Date.now() / 1000 | 0) - time
            let seconds = timer % 60
            const minutes = (timer - seconds) / 60 | 0
            if (seconds.toString().length == 1) {
              seconds = '0' + seconds
            } else {
              seconds = '' + seconds
            }
            $('#timeAudio').text(minutes + ':' + seconds)
            mediaRec.getCurrentAmplitude(function (amplitude) {
              addWaveSegment(recordingWave, amplitude, steps)
            })
          }, 50)
        }, 500)
      })
    } else {
      audioStop = false
      audioStopped = false
      const handleSuccess = function (stream) {
        let sinusCorrection = false
        // const options = { mimeType: 'video/webm;codecs=vp9' }
        const recordedChunks = []
        try {
          mediaRecorder.stop()
        } catch (e) {}
        timeAudioBox.animate({ width: tempWidth + 'px' }, 500, function () {
          steps = Math.floor(recordingWave.width() / 3)
          sinusCorrection = (Date.now() / 500 - time) % Math.PI
          mediaRecorder = new MediaRecorder(stream)

          mediaRecorder.addEventListener('dataavailable', function (e) {
            if (e.data.size > 0 && steps > 0) {
              recordedChunks.push(e.data)
              const timer = (Date.now() / 1000 | 0) - time
              let seconds = timer % 60
              const minutes = (timer - seconds) / 60 | 0
              if (seconds.toString().length == 1) {
                seconds = '0' + seconds
              } else {
                seconds = '' + seconds
              }
              const amplitude = Math.abs(Math.sin(Date.now() / 500 - time - sinusCorrection * Math.PI))
              addWaveSegment(recordingWave, amplitude, steps)
              $('#timeAudio').text(minutes + ':' + seconds)
            }

            if (audioStop == true && audioStopped == false) {
              mediaRecorder.stop()
              audioStopped = true
              stream.getAudioTracks().forEach(function (track) {
                track.stop()
              })
            }
          })

          mediaRecorder.addEventListener('stop', function () {
            voiceMemo = new Blob(recordedChunks)
            const url = URL.createObjectURL(voiceMemo)
            recordAudioPlayer.attr('src', url)
            deleteRecord.show()
            sendChatRec.show()
            playRecord.show()
            stopRecord.hide()
            recordingWave.hide()
            timeAudioBox.css('width', '')
          })

          try {
            mediaRecorder.start(50)
          } catch (e) { console.error(e) }
        })
      }

      streams = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then(handleSuccess)
    }
  }

  // reload chat
  this.reloadChat = function () {
    this.loadData()
    if (this.getCurrentChatBox() > 0) this.getChatBoxes()[this.getCurrentChatBox()].loadMessages(999999999, false)
  }

  // remove uploaded preview
  this.removeUploadedFile = function (id) {
    try {
      uploadFiles[id].remove()
      delete uploadFiles[id]
      let counter = 0
      for (const key in uploadFiles) {
        counter++
      }
      if (counter == 0) {
        MediaSlider.hide()
      }
    } catch (e) { console.log(e) }
  }

  // reset Audio
  this.resetAudio = function () {
    voiceMemo = null
    recordAudioPlayer.empty()
    deleteRecord.hide()
    sendChat.hide()
    Smileys.show()
    MessengerChatActionsBottom.show()
    recordAudio.show()
    $('#messageAudioSlider').hide()
    $('#messageContentSlider').show()
    if (process.env.CORDOVA_PLATFORM !== undefined) {
      try {
        mediaRec.release()
      } catch (e) { }
    }
  }

  // scroll bottom
  this.scrollBottom = function () {
    if (!scrolled) {
      chatBoxContent.scrollTop(chatBoxContent.prop('scrollHeight') - chatBoxContent.height())
      loadMoreScrollPos = -1
    } else if (lastScrollPos == chatBoxContent.scrollTop() && loadMoreScrollPos != -1) {
      chatBoxContent.scrollTop(chatBoxContent.prop('scrollHeight') - loadMoreScrollPos - chatBoxContent.height())
      lastScrollPos = chatBoxContent.scrollTop()
    } else {
      loadMoreScrollPos = -1
    }
    setTimeout(thisChat.scrollBottom, 25)
  }

  // send tenor gifs
  this.sendTenor = function (src) {
    var chatData = new FormData()
    chatData.append('recipient', currentChatBox)
    chatData.append('type', 'tenor')
    chatData.append('content', src)
    chatData.append('postkey', sessionStorage.getItem('postkey'))
    chatData.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: projectURL + 'messages/newMessage',
      type: 'POST',
      data: chatData,
      success: function (data, textStatus, xhr) {
        if (xhr.status == 250) {
          thisChat.resetAudio()
          TenorContainer.hide()
        } else if (xhr.status == 299) {
          cookieLogin().then(() => { $('#chatForm').submit() }).catch(openLogin)
        }
      },
      error: function (data) {
        sendallowed = 0
        $('#sendChat').prop('disabled', false)
      },
      cache: false,
      contentType: false,
      processData: false
    })
  }

  // sort Chatboxes for Date
  this.sortChatBoxes = function () {
    const tempSort = []
    for (const key in chatBoxes) {
      tempSort.push({ id: key, mdate: chatBoxes[key].datestring })
    }
    tempSort.sort(function (a, b) { if (a.mdate < b.mdate) { return 1 } if (a.mdate > b.mdate) { return -1 } return 0 })
    chatPartner.empty()
    chatPartnerFav.empty()
    chatPartnerNew.empty()
    for (const key in tempSort) {
      chatBoxes[tempSort[key].id].addChatBox()
    }
  }

  // change chatlist and new contact list
  this.toggleChat = function () {
    $('.chatList').toggle()
    $('.newList').toggle()
  }

  // updates online status
  this.updateStatus = function () {
    if (window.junx.socketOnline) {
      try {
        window.socket.emit('online', { room: nodeidc, pids: Object.keys(chatBoxes) })
      } catch (e) { }
      setTimeout(thisChat.updateStatus, 15000)
    } else {
      setTimeout(thisChat.updateStatus, 100)
    }
  }

  // handle and save drafts for chatboxes
  this.writeDraft = function (text) {
    if (currentChatBox != null) {
      chatBoxes[currentChatBox].draft = text
    }
  }

  /* -----------------------------------------OBJECTS-------------------------------------------------- */

  // chatBox object
  function ChatBox (partnerData, direct = false) {
    const parentBox = this

    this.loaded = false
    this.remaining = false
    this.partnerId = partnerData.id
    this.favorite = partnerData.favorit
    this.matchGame = partnerData.match
    this.friends = partnerData.friends
    this.firstname = partnerData.firstname
    this.username = partnerData.username
    this.profile_img = projectURL + 'assets/images/user/' + partnerData.profile_img
    this.verified = partnerData.verified
    this.lastDM = partnerData.content
    this.lastDMType = partnerData.mtype
    this.lastDMRevoked = partnerData.revoked
    this.permission = partnerData.permission
    this.video = partnerData.video
    this.hasBlocked = partnerData.hasBlocked
    this.isBlocked = partnerData.isBlocked
    this.draft = ''
    this.unread = partnerData.unread
    this.typing = $('<div>', { class: 'typing' }).html('schreibt...').hide()
    this.element = null
    this.lastTyping = 0
    this.lastTypingPartner = 0
    this.datestring = partnerData.date
    this.online = 0
    this.direct = direct
    if (this.datestring != undefined && this.datestring != null) {
      const arr = this.datestring.split(/[- :]/)
      this.chatdate = arr[2] + '. ' + monthsobj[arr[1]]
    } else {
      const d = new Date()
      let month = d.getMonth() + 1
      if (month.toString().length == 1) {
        month = '0' + month
      } else {
        month = '' + month
      }
      this.chatdate = d.getDate() + '. ' + monthsobj[month]
    }

    if (lastChatID < partnerData.maxID)lastChatID = partnerData.maxID

    let messages = {}
    const string =
        '<div class="message" id="typing-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img" target="_top" onclick="openProfile(\'' + this.username + '\');">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n' +
        '        <div class="message-content typing">\n' +
        '            <div class="chat-content">schreibt...</div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n'
    let typingStop
    this.typingElement = $('<div/>').html(string).contents()

    this.chatPartnerContent =
      '<div class="contact-element" id="chatbox-' + this.partnerId + '"  onclick="window.chat.getChatBoxes()[' + this.partnerId + '].open()">\n' +
      '    <div class="contact-profile-img">\n' +
      '        <img class="ui avatar image" src="' + this.profile_img + '">\n' +
      '            <a class="onlinestatus ui green empty circular label" style="display: none;"></a>\n' +
      '    </div>\n' +
      '    <div class="contact-content">\n' +
      '        <h6 class="ui header">\n' +
      this.firstname +
      '            <div class="sub header last_dm">\n' +
      '            </div>\n' + this.typing.prop('outerHTML') +
      '        </h6>\n' +
      '    </div>\n' +
      '    <div class="contact-extra">\n' +
      '        <p class="time">' + this.chatdate + '</p>\n' +
      '        <p class="favorite">\n' +
      '            <i class="icon star" title="Favorit"></i>\n' +
      '            <i class="icon heart" title="Ihr habt ein Match"></i>\n' +
      '            <i class="icon circle" title="Neue Nachricht"></i>\n' +
      '        </p>\n' +
      '    </div>\n' +
      '</div>\n'

    // get Messages
    this.getMessages = function () {
      return messages
    }

    // add Chatbox
    this.addChatBox = function () {
      if (this.favorite == 1) {
        chatPartnerFav.append($.parseHTML(this.chatPartnerContent))
      } else if (this.matchGame == 0 && this.friends == 0 && this.permission == 0 && !this.direct) {
        chatPartnerNew.append($.parseHTML(this.chatPartnerContent))
      } else {
        chatPartner.append($.parseHTML(this.chatPartnerContent))
      }

      this.element = $('#chatbox-' + this.partnerId)
      this.setLastDM()
      if (this.unread == 1) {
        this.unreadChatBox()
      } else {
        this.readChatBox()
      }
      if (this.favorite == 0) {
        this.element.find('.icon.star').hide()
      }
      if (this.matchGame == 0) {
        this.element.find('.icon.heart').hide()
      }
    }

    // open chatbox
    this.open = function () {
      resetAudioPlayer()
      audioPlayers = []
      TenorContainer.hide()
      recordAudioPlayer.removeAttr('src')
      $('#Online-User').hide()
      $('#messageAudioSlider').hide()
      $('#messageContentSlider').show()
      chatBoxContent.empty()
      timeAudioBox.hide()
      scrolled = false
      imageCounter = 0
      lastScrollPos = 0
      audioPlayers = []
      this.typingElement.remove()
      currentChatBox = this.partnerId
      chatBoxContent.show()

      MediaSlider.hide()
      ChatUpload.val('')
      sendChat.hide()
      recordAudio.show()
      deleteRecord.hide()
      stopRecord.hide()
      Smileys.show()
      MessengerChatActionsBottom.show()

      MessageContent.val(parentBox.draft)

      if (mediaQuery.matches) {
        $('#MessengerMobileMenu').hide()
        $('#MessengerLeftSide').hide()
      }

      if (this.loaded) {
        this.addMessagesToBox()
        this.loadMessages()
      } else {
        this.loadMessages()
      }

      if (this.favorite == 0) {
        $('#modal_fav').html('<i class="icon outline star"></i>Als Favorit hinzufügen')
      } else {
        $('#modal_fav').html('<i class="icon star"></i>Als Favorit entfernen')
      }

      if (this.online == 1) {
        $('#Online-User').show()
      } else {
        $('#Online-User').hide()
      }

      $('#MessengerStart').hide()
      $('#MessengerRightSide').show()

      ChatHeaderName.html(this.firstname + '<div class="sub header" id="MessengerUsername">@' + this.username + '</div>')
      $('.chat-partner-link').on('click', function () { openProfile(window.chat.getChatBoxes()[window.chat.getCurrentChatBox()].username) })
      ChatHeaderProfileImage.attr('src', this.profile_img)

      if (this.verified == 1) { ChatHeaderVerify.show() } else { ChatHeaderVerify.hide() }

      this.readChatBox()

      this.blockStatus()

      this.videoStatus()
      window.socket.emit('online', { room: nodeidc, pid: currentChatBox })

      try {
        clearInterval(window.timer)
      } catch (e) {
      }
    }

    // set last message Preview
    this.setLastDM = function () {
      let tempString
      if (parentBox.lastDMRevoked == 1) {
        tempString = '<div class="chat-message-text-deleted">Nachricht gelöscht</div>'
      } else if (parentBox.lastDM != null && parentBox.lastDM != '') {
        switch (parentBox.lastDMType) {
          case 'text':
            tempString = parentBox.lastDM + ''
            break
          case 'image':
            tempString = "<i class='icon image outline'></i>" + parentBox.lastDM
            break
          case 'video':
            tempString = "<i class='icon video'></i>" + parentBox.lastDM
            break
          case 'audio':
            tempString = "<i class='icon itunes note'></i>" + parentBox.lastDM
            break
          case 'tenor':
            tempString = "<i class='icon image outline'></i> Gif"
            break
        }
      } else {
        switch (parentBox.lastDMType) {
          case 'text':
            tempString = ' '
            break
          case 'image':
            tempString = "<i class='icon image outline'></i> Bild"
            break
          case 'video':
            tempString = "<i class='icon video'></i> Video"
            break
          case 'audio':
            tempString = "<i class='icon itunes note'></i> Audio"
            break
          case 'tenor':
            tempString = "<i class='icon image outline'></i> Gif"
            break
        }
      }
      this.element.find('.last_dm').html(tempString)
    }

    // get oldest Messaged-ID
    this.getOldestMessageID = function () {
      for (const key in messages) {
        return messages[key].mid
      }
    }

    // mark Chatbox as read
    this.readChatBox = function () {
      if (this.unread == 1) {
        this.unread = 0
        window.socket.emit('readChatBox', { room: nodeidc, uname: window.junx.user.username, pid: this.partnerId })
        thisChat.countUnreadChats()
      }
      parentBox.element.find('.icon.circle').hide()
    }

    // mark Chatbox as unread
    this.unreadChatBox = function () {
      parentBox.element.find('.icon.circle').show()
      this.unread = 1
    }

    // mark messages as read
    this.readMessages = function () {
      for (const key in messages) {
        if (messages[key].seen == 0) {
          messages[key].read(1)
        }
      }
    }

    // get messages via PHP
    this.loadMessages = function (mid = 999999999, loader = true) {
      const pid = this.partnerId
      this.loaded = true
      let tempBoxes
      if (mid < 999999999) {
        tempBoxes = messages
        messages = []
      } else if (loader) {
        chatBoxContent.append(contentLoader)
      }
      $.post(projectURL + 'messages/getMessages',
        {
          pid: pid,
          mid: mid,
          SessionID: sessionStorage.getItem('SessionID')
        },
        function (data, status, xhr) {
          if (xhr.status == 250) {
            const jsonObject = JSON.parse(data)
            const messagesObj = jsonObject.messages
            parentBox.remaining = jsonObject.remaining
            for (const key in messagesObj) {
              if (messages.hasOwnProperty(messagesObj[key].id)) continue
              switch (messagesObj[key].mtype) {
                case 'text':
                  messages[messagesObj[key].id] = new Message(messagesObj[key], pid, parentBox)
                  break
                case 'image':
                  messages[messagesObj[key].id] = new MessageImage(messagesObj[key], pid, parentBox)
                  break
                case 'video':
                  messages[messagesObj[key].id] = new MessageVideo(messagesObj[key], pid, parentBox)
                  break
                case 'audio':
                  messages[messagesObj[key].id] = new MessageAudio(messagesObj[key], pid, parentBox)
                  break
                case 'tenor':
                  messages[messagesObj[key].id] = new MessageTenor(messagesObj[key], pid, parentBox)
                  break
                default:
                  continue
              }
            }
            if (mid < 999999999) {
              parentBox.addMoreMessagesToBox(tempBoxes)
            } else {
              contentLoader.remove()
              parentBox.addMessagesToBox()
            }
            loadMoreBlock = false
          } else if (xhr.status == 299) {
            cookieLogin().then(() => { window.chat.loadMessages(mid) }).catch(openLogin)
          }
        })
    }

    // load all stored messages
    this.addMessagesToBox = function () {
      chatBoxContent.empty()
      if (parentBox.remaining) {
        const loadMore = '<button id="loadMoreMessages" class="chat-cta-load" onclick="window.chat.loadMore();">Mehr Nachrichten laden</button>'
        chatBoxContent.append($.parseHTML(loadMore))
      }
      for (const key in messages) {
        chatBoxContent.append($.parseHTML(messages[key].chatContent))
        messages[key].indexElement()
      }
      parentBox.formatMessages()
    }

    // add loaded messages
    this.addMoreMessagesToBox = function (tempBoxes) {
      $('#loadMoreMessages').remove()
      messages = messages.reverse()
      for (const key in messages) {
        chatBoxContent.prepend($.parseHTML(messages[key].chatContent))
        messages[key].indexElement()
      }
      if (parentBox.remaining) {
        const loadMore = '<button id="loadMoreMessages" class="chat-cta-load" onclick="window.chat.loadMore();">Mehr Nachrichten laden</button>'
        chatBoxContent.prepend($.parseHTML(loadMore))
      }
      messages = messages.reverse()
      for (const key in tempBoxes) {
        messages[key] = tempBoxes[key]
      }
      parentBox.formatMessages()
    }

    // dates and group messages
    this.formatMessages = function () {
      chatBoxContent.find('.chat-last-date').remove()
      chatBoxContent.find('.new-chat-actions').remove()

      let lastSender = 0; let lastDate = 0; let lastTime = 0; let lastKey = 0
      for (const key in messages) {
        messages[key].element.find('.message-profile-img').css('opacity', '1')
        const arr = messages[key].datestring.split(/[- :]/)
        const date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
        if (lastSender == messages[key].sender && (lastTime + groupSender) > (date.getTime() / 1000) && lastKey != 0) {
          messages[lastKey].element.find('.message-profile-img').css('opacity', '0')
          messages[lastKey].element.find('.chat-time').hide()
        }

        if (arr[2] + ' ' + monthsobj[arr[1]] + ' ' + arr[0] != lastDate) {
          $('<h5 class="ui horizontal divider header chat-last-date">' + arr[2] + ' ' + monthsobj[arr[1]] + ' ' + arr[0] + '</h5>').insertBefore(messages[key].element)
        }
        lastSender = messages[key].sender
        lastDate = arr[2] + ' ' + monthsobj[arr[1]] + ' ' + arr[0]
        lastTime = date.getTime() / 1000
        lastKey = key
      }
    }

    // store and add message
    this.newMessage = function (messagesObj) {
      try {
        switch (messagesObj.mtype) {
          case 'text':
            messages[messagesObj.id] = new Message(messagesObj, this.partnerId, parentBox)
            break
          case 'image':
            messages[messagesObj.id] = new MessageImage(messagesObj, this.partnerId, parentBox)
            break
          case 'video':
            messages[messagesObj.id] = new MessageVideo(messagesObj, this.partnerId, parentBox)
            break
          case 'audio':
            messages[messagesObj.id] = new MessageAudio(messagesObj, this.partnerId, parentBox)
            break
          case 'tenor':
            messages[messagesObj.id] = new MessageTenor(messagesObj, this.partnerId, parentBox)
            break
          default:
            break
        }

        // set last message in contactlist
        parentBox.lastDM = messagesObj.content
        parentBox.lastDMType = messagesObj.mtype
        parentBox.lastDMRevoked = messagesObj.revoked
        parentBox.setLastDM()

        parentBox.element.find('.typing').hide()
        parentBox.element.find('.last_dm').show()

        if (currentChatBox == this.partnerId) {
          this.typingElement.stop().remove()
          chatBoxContent.append($.parseHTML(messages[messagesObj.id].chatContent))
          messages[messagesObj.id].indexElement()
          /*
          if(!scrolled)
          {
              chatBoxContent.animate({ scrollTop: chatBoxContent.prop("scrollHeight") - chatBoxContent.height()}, "slow");
          } */
          parentBox.formatMessages()
        }
      } catch (e) { }
    }

    // mark Chatbox as partner typing and remove it after 3 seconds no typing
    this.typing = function () {
      if ((Date.now() - parentBox.lastTypingPartner) > 500) {
        parentBox.lastTypingPartner = Date.now()
        parentBox.element.find('.typing').stop().css('opacity', '1').show()
        parentBox.element.find('.last_dm').hide()
        if (parentBox.partnerId == currentChatBox) {
          chatBoxContent.append(parentBox.typingElement.stop().css('opacity', '1').show())
          /*
          if(!scrolled)
          {
              chatBoxContent.animate({ scrollTop: chatBoxContent.prop("scrollHeight") - chatBoxContent.height()}, "slow");
          } */
        }
        typingStop = window.setInterval(function () {
          if ((Date.now() - parentBox.lastTypingPartner) > 3000) {
            parentBox.element.find('.typing').fadeOut('slow', function () {
              parentBox.element.find('.last_dm').show()
            })
            parentBox.typingElement.fadeOut('slow', function () {
              this.remove()
            })
            clearInterval(typingStop)
            typingStop = null
          }
        }, 500)
      }
    }

    // block Status
    this.blockStatus = function () {
      $('#chat-blocked').hide()
      if (parentBox.hasBlocked == 1 && parentBox.isBlocked == 1) {
        $('#modal_block').text('Person freigeben')
        $('#chat-blocked').show()
        MessengerChatActions.hide()
        MessengerChatNew.hide()
        $('#chat-blocked').text('Ihr habt euch gegenseitig blockiert')
      } else if (parentBox.hasBlocked == 0 && parentBox.isBlocked == 1) {
        $('#modal_block').text('Person blockieren')
        $('#chat-blocked').show()
        MessengerChatActions.hide()
        MessengerChatNew.hide()
        $('#chat-blocked').text('Du wurdest geblockt')
      } else if (parentBox.hasBlocked == 1 && parentBox.isBlocked == 0) {
        $('#modal_block').text('Person freigeben')
        $('#chat-blocked').show()
        MessengerChatNew.hide()
        MessengerChatActions.hide()
        $('#chat-blocked').text('Du hast diese Person blockiert')
      } else if (parentBox.permission == 0 && parentBox.matchGame == 0 && parentBox.friends == 0 && parentBox.favorite == 0 && !this.direct) {
        MessengerChatNew.show()
        MessengerChatActions.hide()
      } else {
        MessengerChatNew.hide()
        $('#chat-blocked').hide()
        MessengerChatActions.show()
      }
    }

    // video Status
    this.videoStatus = function (video = this.video) {
      this.video = video
      if (parentBox.partnerId == currentChatBox) {
        $('#videoRequestLink').off('click')

        if (parentBox.friends == 1) {
          $('#videoRequestLink').on('click', function () {
            $('#VideoCallRequest').modal('show')
          })
        } else {
          $('#videoRequestLink').on('click', function () {
            $('#VideoCallInfo').modal('show')
          })
        }
      }
    }

    // move Chatbox to top of list
    this.toTop = function (date = null) {
      try {
        if (this.favorite == 1) {
          $('#chatbox-' + this.partnerId).prependTo(chatPartnerFav)
        } else if (this.matchGame == 0 && this.friends == 0 && this.permission == 0 && !this.direct) {
          $('#chatbox-' + this.partnerId).prependTo(chatPartnerNew)
        } else {
          $('#chatbox-' + this.partnerId).prependTo(chatPartner)
        }
        if (date != null) {
          parentBox.datestring = date
          parentBox.datestringexp = parentBox.datestring.split(' ')
          parentBox.datestringexp = parentBox.datestringexp[0].split('-')
          parentBox.chatdate = monthsobj[parentBox.datestringexp[1]] + ', ' + parentBox.datestringexp[2]
          parentBox.element.find('.chat-date-p').html(parentBox.chatdate)
        }
      } catch (e) {}
    }
  }

  // message object
  function Message (obj, partnerId, parentBox) {
    const thisMessage = this
    let tapped = false

    this.partnerId = partnerId
    this.mid = obj.id
    this.profile_img = parentBox.profile_img
    this.datestring = obj.date
    this.sender = obj.sender
    this.recipient = obj.recipient
    this.sUserName = parentBox.username
    this.type = obj.mtype
    this.content = urlify(nl2br(obj.content).replace(/#(\S*)/g, '<a onclick="window.chat.openSearch(\'$1\');">#$1</a>'))
    // this.content = obj['content'].replace(/#(\S*)/g,'<a href="/search?s=$1&c=hashtags">#$1</a>');
    this.seen = obj.seen
    this.liked = obj.liked
    this.revoked = obj.revoked
    this.destroy = obj.destroy
    this.likedImage = ['chat-like-noheart', 'chat-like-heart']
    this.element = null
    this.chatContent = ''
    this.messageContentLocked = null

    if (lastChatID < obj.id)lastChatID = obj.id

    let datestringexp = this.datestring.split(' ')
    datestringexp = datestringexp[1].split(':')
    this.messagedate = datestringexp[0] + ':' + datestringexp[1]

    if (this.partnerId == this.sender) {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n' +
        '        <div class="message-content">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n'
    } else {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-right">\n' +
        '        <a class="message-profile-img">\n' +
        '            <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + localStorage.getItem('profile_img') + '">\n' +
        '        </a>\n' +
        '        <div class="message-content">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n'
    }

    this.indexElement = function () {
      this.element = chatBoxContent.find('#m-' + this.mid)
      if (this.revoked == 1) {
        this.revoke()
      }
      if (this.destroy == 2) {
        this.viewed()
      }
      this.like()
      this.read()
      this.dblTap()
    }

    this.dblTap = function () {
      const _this = this
      if (this.sender == this.partnerId) {
        this.element.find('.message-content').on('touchstart mousedown', function (e) {
          if (!tapped) {
            tapped = setTimeout(function () {
              tapped = null
            }, 300)
          } else {
            e.preventDefault()
            clearTimeout(tapped)
            tapped = null
            _this.like(_this.liked == 0 ? 1 : 0)
          }
        })
      }
    }

    this.like = function (liked = this.liked) {
      if (liked == 1) {
        this.element.find('.chat-heart ').removeClass('chat-like-noheart')
        this.element.find('.chat-heart ').addClass('chat-like-heart')
      } else {
        this.element.find('.chat-heart ').removeClass('chat-like-heart')
        this.element.find('.chat-heart ').addClass('chat-like-noheart')
      }
      this.liked = liked
    }

    this.read = function (seen = this.seen) {
      this.seen = seen
      if (this.seen == 0) {
        this.element.find('.chat-read').css('opacity', '0')
      } else {
        this.element.find('.chat-read').css('opacity', '1')
      }
    }

    this.revoke = function () {
      this.content = 'Nachricht gelöscht'
      this.revoked = 1
      const messagesTemp = this.element.find('.message-content')
      for (const key in messagesTemp) {
        if (key == 'length') {
          break
        }

        if (parseInt(key) + 1 != messagesTemp.length) {
          messagesTemp[key].remove()
        }
      }
      this.element.find('.chat-content').addClass('chat-message-text-deleted')
      this.element.find('.message-content').removeClass('more-media')
      this.element.find('.chat-content').html(this.content)
      this.element.find('.message-actions').hide()
    }
    // mark as viewed
    this.viewed = function (destroy = this.destroy) {
      if (this.destroy != 0 && this.recipient == localStorage.getItem('user_id')) {
        this.destroy = destroy
        this.element.find('.message-content').first().prop('onclick', null).off('click')
        this.element.find('.message-content').first().addClass('viewed')
        this.element.find('.chat-content').first().html('<i class="stopwatch icon"></i> Angesehen')
      } else if (this.destroy == 2) {
        this.element.find('.message-content').first().prop('onclick', null).off('click')
        this.element.find('.message-content').first().addClass('viewed')
        this.element.find('.chat-content').first().html('<i class="stopwatch icon"></i> Angesehen')
      }
    }
  }

  // message-image object
  function MessageImage (obj, partnerId, parentBox) {
    Message.call(this, obj, partnerId, parentBox)
    const files = JSON.parse(obj.file)
    const fileImages = []
    const fileImagesRight = []
    const destroyString = (this.destroy == 1) ? ' destroy' : ''
    let counter = 0
    let key = null
    const _thisM = this

    for (key in files) {
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('file', files[key])
      formData.append('size', 'small')
      const src = files[key].substr(0, files[key].lastIndexOf('.'))
      axios.post(projectURL + 'messages/files/?size=small', formData).then(function (response) {
        if (response.status === 250) {
          $('#' + src).attr('src', window.projectURL + response.data.link)
          _thisM.chatContent = $('#m-' + _thisM.mid).wrap('<p/>').parent().html()
          $('#m-' + _thisM.mid).unwrap()
        }
      })
      const classes = (key != 0) ? ' more-media' : ''
      fileImages[counter] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[key] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileImagesRight[counter] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[key] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'
      counter++
    }

    const src = files[counter - 1].substr(0, files[counter - 1].lastIndexOf('.'))
    if (this.content == '' || this.content == null) {
      const classes = ((counter - 1) != 0) ? ' more-media' : ''
      fileImages[counter - 1] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileImagesRight[counter - 1] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    } else {
      const classes = ' more-media'
      fileImages[counter - 1] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileImagesRight[counter - 1] =
        '        <div class="message-content message-img' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openImage(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileImages[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileImagesRight[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    }

    if (this.partnerId == this.sender) {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n'

      for (key in fileImages) {
        this.chatContent += fileImages[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    } else {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-right">\n' +
        '        <a class="message-profile-img">\n' +
        '            <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + localStorage.getItem('profile_img') + '">\n' +
        '        </a>\n'

      for (key in fileImagesRight) {
        this.chatContent += fileImagesRight[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    }
  }

  // message-video object
  function MessageVideo (obj, partnerId, parentBox) {
    Message.call(this, obj, partnerId, parentBox)

    const files = JSON.parse(obj.file)
    const fileVideos = []
    const fileVideosRight = []
    let counter = 0
    const destroyString = (this.destroy == 1) ? ' destroy' : ''
    let key = null
    const _thisM = this

    for (key in files) {
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('file', files[key])
      formData.append('size', 'small')
      const src = files[key].substr(0, files[key].lastIndexOf('.'))
      axios.post(projectURL + 'messages/files/?size=small', formData).then(function (response) {
        if (response.status === 250) {
          $('#' + src).attr('src', window.projectURL + response.data.link)
          _thisM.chatContent = $('#m-' + _thisM.mid).wrap('<p/>').parent().html()
          $('#m-' + _thisM.mid).unwrap()
        }
      })
      const classes = (key != 0) ? ' more-media' : ''
      fileVideos[counter] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[key] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileVideosRight[counter] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[key] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'
      counter++
    }

    const src = files[counter - 1].substr(0, files[counter - 1].lastIndexOf('.'))
    if (this.content == '' || this.content == null) {
      const classes = ((counter - 1) != 0) ? ' more-media' : ''
      fileVideos[counter - 1] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileVideosRight[counter - 1] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    } else {
      const classes = ' more-media'

      fileVideos[counter - 1] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileVideosRight[counter - 1] =
        '        <div class="message-content' + classes + destroyString + '">\n' +
        '            <div class="chat-content">\n' +
        '               <img class="chat_message_img" id="' + src + '" onclick="window.chat.openVideoFile(\'' + files[counter - 1] + '\', \'' + this.mid + '\')">\n' +
        '            </div>\n' +
        '        </div>\n'

      fileVideos[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileVideosRight[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    }

    if (this.partnerId == this.sender) {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n'

      for (key in fileVideos) {
        this.chatContent += fileVideos[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    } else {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-right">\n' +
        '        <a class="message-profile-img">\n' +
        '            <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + localStorage.getItem('profile_img') + '">\n' +
        '        </a>\n'

      for (key in fileVideosRight) {
        this.chatContent += fileVideosRight[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    }
  }

  // message-audio object
  function MessageAudio (obj, partnerId, parentBox) {
    Message.call(this, obj, partnerId, parentBox)

    const files = JSON.parse(obj.file)
    const fileAudio = []
    const fileAudioRight = []
    let counter = 0
    let key = null

    for (key in files) {
      const classes = (key != 0) ? ' more-media' : ''
      const filename = typeof files[key] === 'object' ? files[key].name : files[key]
      const duration = typeof files[key] === 'object' ? files[key].duration : 0
      const durationString = typeof files[key] === 'object' ? Math.floor(files[key].duration / 60) + ':' + (files[key].duration % 60 > 9 ? files[key].duration % 60 : '0' + files[key].duration % 60) : ''

      fileAudio[counter] =
        '        <div class="message-content' + classes + '">' +
        '            <div class="chat-content"><i class="play icon play-button" onclick="window.chat.appPlayBack(\'' + filename + '\',\'' + this.mid + '\',\'' + duration + '\')"></i><div class="speed-container">x2</div><div class="time-container">' + durationString + '</div><div class="wave-container"></div>' +
        '            </div>' +
        '        </div>'

      fileAudioRight[counter] = fileAudio[counter]
      counter++
    }

    const filename = typeof files[counter - 1] === 'object' ? files[counter - 1].name : files[counter - 1]
    const duration = typeof files[counter - 1] === 'object' ? files[counter - 1].duration : 0
    const durationString = typeof files[counter - 1] === 'object' ? Math.floor(files[counter - 1].duration / 60) + ':' + (files[counter - 1].duration % 60 > 9 ? files[counter - 1].duration % 60 : '0' + files[counter - 1].duration % 60) : ''
    const audio = '<i class="play icon play-button" onclick="return window.chat.appPlayBack(\'' + filename + '\',\'' + this.mid + '\',\'' + duration + '\')"></i><div class="speed-container">x2</div><div class="time-container">' + durationString + '</div><div class="wave-container"></div>'
    if (this.content == '' || this.content == null) {
      const classes = ((counter - 1) != 0) ? ' more-media' : ''
      fileAudio[counter - 1] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + audio +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileAudioRight[counter - 1] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">\n' + audio +
        '            </div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    } else {
      const classes = ' more-media'

      fileAudio[counter - 1] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + audio +
        '            </div>\n' +
        '        </div>\n'

      fileAudioRight[counter - 1] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + audio +
        '            </div>\n' +
        '        </div>\n'

      fileAudio[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n'

      fileAudioRight[counter] =
        '        <div class="message-content' + classes + '">\n' +
        '            <div class="chat-content">' + this.content + '</div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n'
    }

    if (this.partnerId == this.sender) {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n'

      for (key in fileAudio) {
        this.chatContent += fileAudio[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    } else {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-right">\n' +
        '        <a class="message-profile-img">\n' +
        '            <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + localStorage.getItem('profile_img') + '">\n' +
        '        </a>\n'

      for (key in fileAudioRight) {
        this.chatContent += fileAudioRight[key]
      }

      this.chatContent +=
        '    </div>\n' +
        '</div>\n'
    }
  }

  // message-tenor object
  function MessageTenor (obj, partnerId, parentBox) {
    Message.call(this, obj, partnerId, parentBox)

    this.content = obj.content

    if (this.partnerId == this.sender) {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-left">\n' +
        '        <a class="message-profile-img">\n' +
        '             <img class="ui avatar image" src="' + this.profile_img + '">\n' +
        '        </a>\n' +
        '        <div class="message-content message-img">\n' +
        '            <div class="chat-content"><img class="chat_message_img" src="' + this.content + '"></div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="chat-heart chat-like-heart" onclick="window.chat.likeMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n'
    } else {
      this.chatContent =
        '<div class="message" id="m-' + this.mid + '">\n' +
        '    <div class="message-right">\n' +
        '        <a class="message-profile-img">\n' +
        '            <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + localStorage.getItem('profile_img') + '">\n' +
        '        </a>\n' +
        '        <div class="message-content message-img">\n' +
        '            <div class="chat-content"><img class="chat_message_img" src="' + this.content + '"></div>\n' +
        '            <div class="message-info">\n' +
        '                <div class="message-actions">\n' +
        '                    <i class="icon check chat-read"></i>\n' +
        '                    <i class="chat-heart chat-like-heart chat-noHover"></i>\n' +
        '                    <i class="chat-delete" onclick="window.chat.deleteMessage(' + this.mid + ')"></i>\n' +
        '                </div>\n' +
        '                <div class="chat-time">' + this.messagedate + '</div>\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>\n'
    }
  }

  // contact object
  function Contact (partnerData) {
    this.partnerId = partnerData.id
    this.favorite = partnerData.favorit
    this.matchGame = partnerData.match
    this.username = partnerData.username
    this.firstname = partnerData.firstname
    this.profile_img = window.projectURL + 'assets/images/user/' + partnerData.profile_img
    this.verified = partnerData.verified
    this.obj = partnerData
    this.online = partnerData.online
    this.element = null

    this.content =
      '<div class="contact-element" id="contact-' + this.partnerId + '" onclick="window.chat.addNewContact(' + this.partnerId + ')" >\n' +
      '    <div class="contact-profile-img">\n' +
      '        <img class="ui avatar image" src="' + this.profile_img + '">\n' +
      '            <a class="onlinestatus ui green empty circular label"></a>\n' +
      '    </div>\n' +
      '    <div class="contact-content">\n' +
      '        <h6 class="ui header">\n' + this.firstname +
      '            <div class="sub header last_dm">\n@' + this.username +
      '            </div>\n' +
      '        </h6>\n' +
      '    </div>\n' +
      '    <div class="contact-extra">\n' +
      '        <p class="favorite">\n' +
      '            <i class="icon star outline"></i>\n' +
      '            <i class="icon heart outline"></i>\n' +
      '        </p>\n' +
      '    </div>\n' +
      '</div>\n'

    this.addContact = function () {
      AllContacts.append($.parseHTML(this.content))
      this.element = $('#contact-' + this.partnerId)
      if (this.favorite == 0) {
        this.element.find('.icon.star').hide()
      }
      if (this.matchGame == 0) {
        this.element.find('.icon.heart').hide()
      }
      if (this.online == 0) {
        this.element.find('.onlinestatus').hide()
      }
    }
  }

  // uploaded Image objects
  function UploadPreview (id, file) {
    this.name = file.name
    this.id = id
    this.file = file
    this.element = null

    this.content =
      '    <div id="up-' + this.id + '" class="chat-mediaslider-media w-clearfix">\n' +
      '        <a class="chat-mediaslider-media-delete w-button" onclick="window.chat.removeUploadedFile(\'' + this.id + '\')">X</a>' +
      '    </div>'

    this.addPreview = function (slider = MediaSliderWrapper) {
      slider.append($.parseHTML(this.content))
      this.element = $('#up-' + this.id)
      this.element.css('background-image', 'url(' + URL.createObjectURL(this.file) + ')')
    }

    this.remove = function () {
      this.element.remove()
      $('#chatAddFile').show()
    }
  }

  // uploaded video object
  function UploadVideos (id, file) {
    UploadPreview.call(this, id, file)

    this.content =
      '    <div id="up-' + this.id + '" class="chat-mediaslider-media w-clearfix">\n' +
      '        <img src="assets/images/messenger/video.png" loading="lazy" alt="" class="chat-cta-symbole">' +
      '        <a class="chat-mediaslider-media-delete w-button" onclick="window.chat.removeUploadedFile(\'' + this.id + '\')">X</a>' + this.name +
      '    </div>'

    this.addPreview = function (slider = MediaSliderWrapper) {
      slider.append($.parseHTML(this.content))
      this.element = $('#up-' + this.id)
    }
  }

  // uploaded video object
  function UploadAudios (id, file) {
    UploadPreview.call(this, id, file)

    this.content =
      '    <div id="up-' + this.id + '" class="chat-mediaslider-media w-clearfix">\n' +
      '        <img src="assets/images/messenger/audio.png" loading="lazy" alt="" class="chat-cta-symbole">' +
      '        <a class="chat-mediaslider-media-delete w-button" onclick="window.chat.removeUploadedFile(\'' + this.id + '\')">X</a>' + this.name +
      '    </div>'

    this.addPreview = function (slider = MediaSliderWrapper) {
      slider.append($.parseHTML(this.content))
      this.element = $('#up-' + this.id)
    }
  }

  /* -----------------------------------------EVENT LISTENERS------------------------------------------ */

  // save and send messages and typing on keyup
  MessageContent.keydown(function (event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      event.preventDefault()
      // MessageContent.val(MessageContent.val().substring(0, MessageContent.val().length - 1));
      sendChat.click()
    }
  })

  MessageContent.on('keyup change input keydown', function (event) {
    thisChat.writeDraft(MessageContent.val())

    if (MessageContent.val().length > 0) {
      sendChat.show()
      recordAudio.hide()
    } else {
      sendChat.hide()
      recordAudio.show()
    }

    // typing with break
    if ((Date.now() - chatBoxes[currentChatBox].lastTyping) > 1000 && currentChatBox != '-1') {
      chatBoxes[currentChatBox].lastTyping = Date.now()
      window.socket.emit('typing', { room: nodeidc, uname: window.junx.user.username, pid: currentChatBox })
    }
  })

  MessageContent.on('focus', function () {
    if (process.env.CORDOVA_PLATFORM === 'android') {
      MessengerChatActions.css('padding-bottom', '350px')
      chatBoxContent.css('padding-bottom', '350px')
    }
  })

  MessageContent.on('blur', function () {
    if (process.env.CORDOVA_PLATFORM === 'android') {
      MessengerChatActions.css('padding-bottom', '')
      chatBoxContent.css('padding-bottom', '')
    }
  })

  // search in chatboxes
  SearchChatBoxes.keyup(function (event) {
    const searchString = SearchChatBoxes.val()
    for (const key in chatBoxes) {
      if (chatBoxes[key].username.toLowerCase().includes(searchString.toLowerCase()) || chatBoxes[key].firstname.toLowerCase().includes(searchString.toLowerCase())) {
        chatBoxes[key].element.show()
      } else {
        chatBoxes[key].element.hide()
      }
    }
  })

  // search in contacts
  SearchContactBox.keyup(function (event) {
    const searchString = SearchContactBox.val()
    for (const key in contacts) {
      if (contacts[key].username.toLowerCase().includes(searchString.toLowerCase()) || contacts[key].firstname.toLowerCase().includes(searchString.toLowerCase())) {
        contacts[key].element.show()
      } else {
        contacts[key].element.hide()
      }
    }
  })

  // search in contacts
  SearchTenor.keyup(function () {
    thisChat.loadTenor(SearchTenor.val())
  })

  // function for submitting messages
  $('#chatForm').submit(function (e) {
    e.preventDefault()
    if (window.junx.socketOnline) {
      chatBoxContent.scrollTop(chatBoxContent.prop('scrollHeight') - chatBoxContent.height())
      scrolled = false
      recordAudioPlayer.stop()

      if (sendallowed != 0) {
        return false
      }
      sendallowed = 2

      MessageContent.val(MessageContent.val().replace(/[\n\r|\r\n|\r|\n| ]{5,}/g, '\r\n\r\n\r\n\r\n'))
      var chatData = new FormData(this)

      chatData.append('recipient', currentChatBox)
      chatData.append('postkey', sessionStorage.getItem('postkey'))
      chatData.append('SessionID', sessionStorage.getItem('SessionID'))

      if (voiceMemo != null) {
        chatData.append('type', 'record')
        chatData.append('chatUpload[]', voiceMemo)
        $('#sendChat').prop('disabled', true)
        sendallowed = 1
      } else {
        chatData.append('content', MessageContent.val())
        chatData.append('destroy', destroyMedia)

        let counter = 0
        if (!MediaSlider.is(':hidden')) {
          for (const key in uploadFiles) {
            counter++
            chatData.append('chatUpload[]', uploadFiles[key].file)
          }
        }

        if (counter == 0) {
          chatData.append('type', 'text')
          if (MessageContent.val().replace(/[\n\r|\r\n|\r|\n| ]{3,}/g, '').length != 0) {
            $('#sendChat').prop('disabled', true)
            sendallowed = 1
          }
        } else {
          chatData.append('type', uploadType)
          $('#sendChat').prop('disabled', true)
          sendallowed = 1
        }
      }

      if (sendallowed == 1) {
        $.ajax({
          url: projectURL + 'messages/newMessage',
          type: 'POST',
          data: chatData,
          success: function (data, textStatus, xhr) {
            if (xhr.status == 250) {
              MessageContent.val('')
              ChatUpload.val('')
              $('#chatPrice').val('')
              chatBoxes[currentChatBox].draft = ''
              sendallowed = 0
              MediaSlider.hide()
              TenorContainer.hide()
              timeAudioBox.hide()
              sendChat.show()
              for (const key in uploadFiles) {
                uploadFiles[key].remove()
                delete uploadFiles[key]
              }
              $('#sendChat').prop('disabled', false)
              thisChat.resetAudio()
            } else if (xhr.status == 299) {
              cookieLogin().then(() => { $('#chatForm').submit() }).catch(openLogin)
            }
          },
          error: function (xhr) {
            console.log(xhr)
            sendallowed = 0
            sendChat.show()
            $('#sendChat').prop('disabled', false)
          },
          cache: false,
          contentType: false,
          processData: false
        })
      } else {
        sendallowed = 0
        sendChat.show()
      }
    }
  })

  // detect scroll to stop and reset autoscroll
  chatBoxContent.scroll(function (event) {
    const scrollTemp = chatBoxContent.scrollTop()
    if (scrollTemp >= (chatBoxContent.prop('scrollHeight') - chatBoxContent.innerHeight())) {
      scrolled = false
    } else if (scrollTemp < lastScrollPos) {
      loadMoreScrollPos = -1
      scrolled = true
    } else if (scrollTemp > lastScrollPos) {
      loadMoreScrollPos = -1
    }
    if (scrollTemp == 0 && chatBoxes[currentChatBox].remaining && !loadMoreBlock) {
      thisChat.loadMore()
    }
    lastScrollPos = scrollTemp
  })

  // on file input single
  ChatUpload.on('change', function (e) {
    MediaSliderWrapper.empty()
    let counter = 0
    for (const key in uploadFiles) {
      counter++
    }
    const files = ChatUpload.prop('files')

    for (let i = 0; i < files.length; i++) {
      const id = Date.now() + i * 50000
      if (!uploadFiles.hasOwnProperty(id) && counter < 5) {
        switch (uploadType) {
          case 'image':
            uploadFiles[id] = new UploadPreview(id, files[i])
            break

          case 'video':
            uploadFiles[id] = new UploadVideos(id, files[i])
            break

          case 'audio':
            uploadFiles[id] = new UploadAudios(id, files[i])
            break

          case 'destroy':
            // eslint-disable-next-line no-case-declarations
            const extension = files[i].name.split('.').pop().toLowerCase()
            // eslint-disable-next-line no-case-declarations
            const moviesExtensions = ['mov', 'webm', 'avi', 'm4v', 'mp4']
            if (jQuery.inArray(extension, moviesExtensions) == -1) {
              uploadType = 'image'
              uploadFiles[id] = new UploadPreview(id, files[i])
            } else {
              uploadType = 'video'
              uploadFiles[id] = new UploadVideos(id, files[i])
            }
            break
        }
        counter++
      } else if (!uploadFiles.hasOwnProperty(id) && counter < 6) {
        counter++
      }
    }

    if (counter == 5 || destroyMedia == 1) {
      sendChat.show()
      recordAudio.hide()
      $('#chatAddFile').hide()
      MediaSlider.show()
    } else if (counter > 5) {
      sendChat.show()
      recordAudio.hide()
      alert('max. 5 Files')
      $('#chatAddFile').hide()
      MediaSlider.show()
    } else if (counter > 0) {
      sendChat.show()
      recordAudio.hide()
      $('#chatAddFile').show()
      MediaSlider.show()
    } else {
      destroyMedia = 0
      MediaSlider.hide()
    }

    for (const key in uploadFiles) {
      uploadFiles[key].addPreview()
    }
    ChatUpload.val('')
  })

  // active window handler for read messages
  $(window).on('blur focus', function (e) {
    var prevType = $(this).data('prevType')

    if (prevType != e.type) {
      switch (e.type) {
        case 'blur':
          active = false
          break
        case 'focus':
          active = true
          if (currentChatBox != null) {
            chatBoxes[currentChatBox].readChatBox()
          }
          break
      }
    }

    $(this).data('prevType', e.type)
  })

  // audio listener
  stopRecord.on('click', function (e) {
    e.preventDefault()
    if (process.env.CORDOVA_PLATFORM != undefined) {
      clearInterval(audioInterval)
      mediaRec.stopRecord()
      window.resolveLocalFileSystemURL(audioRecordPath, function (fileEntry) {
        fileEntry.file(function (file) {
          const reader = new FileReader()
          reader.onloadend = function (e) {
            voiceMemo = new Blob([this.result], { type: 'audio/' + audioRecordExtension })
            const url = URL.createObjectURL(voiceMemo)
            recordAudioPlayer.attr('src', url)
            deleteRecord.show()
            sendChatRec.show()
            playRecord.show()
            stopRecord.hide()
            recordingWave.hide()
            timeAudioBox.css('width', '')
          }
          reader.readAsArrayBuffer(file)
        })
      })
    } else {
      audioStop = true
    }
  })
  recordAudio.on('click', function (e) {
    e.preventDefault()
    window.chat.recordAudio()
  })
  deleteRecord.on('click', function (e) {
    e.preventDefault()
    thisChat.resetAudio()
    timeAudioBox.hide()
  })
  playRecord.on('click', function () {
    try {
      audioplaying.pause()
    } catch (e) {}
    audioplaying = new Audio(URL.createObjectURL(voiceMemo))
    audioplaying.play()
    playRecord.hide()
    pauseRecord.show()
  })
  pauseRecord.on('click', function () {
    try {
      audioplaying.pause()
      audioplaying.currentTime = 0
    } catch (e) {}
    playRecord.show()
    pauseRecord.hide()
  })

  // new Chat Listeners
  $('#accept-new-chat').on('click', function () {
    thisChat.accept()
  })
  $('#decline-new-chat').on('click', function () {
    thisChat.clearChat(0)
  })
  $('#block-new-chat').on('click', function () {
    thisChat.clearChat(0)
    thisChat.block()
  })
  $('.chatReport').on('click', function () {
    report('chat', currentChatBox)
  })

  // mobile close
  $('#ChatImageModal').on('click', function (e) {
    e.preventDefault()
    $('#ChatImageModal').modal('hide')
  })
  $('#ChatVideoModal').on('click', function (e) {
    e.preventDefault()
  })

  // actions
  Smileys.on('click', function () {
    TenorContainer.hide()
    MediaSlider.hide()
    EmoticonsMenu.toggle()
  })
  Tenor.on('click', function () {
    EmoticonsMenu.hide()
    MediaSlider.hide()
    thisChat.loadTenor()
    SearchTenor.val('')
    TenorContainer.toggle()
  })

  // emojis
  $('.emojione').on('click', function () {
    MessageContent.val(MessageContent.val() + String.fromCodePoint('0x' + $(this).parent().attr('id')))
    EmoticonsMenu.toggle()
    thisChat.writeDraft(MessageContent.val())

    if (MessageContent.val().length > 0) {
      sendChat.show()
      recordAudio.hide()
    } else {
      sendChat.hide()
      recordAudio.show()
    }
  })

  // more
  $('#startVideoButton').on('click', function () {
    calling = false
    $('#ChatMenuModal').modal('hide')
    $('#VideoCallRequest').modal('hide')
    router.push({ name: 'Call', params: { room: '', username: chatBoxes[currentChatBox].username, profile_img: chatBoxes[currentChatBox].profile_img } })
  })
}
