<template>
  <div class="sidebar">
    <div class="row no-gutters">
      <div class="col-auto align-self-center">
        <figure class="avatar avatar-50" style="background: transparent;">
          <img class="ar-1-1" :src="projectURL + 'assets/images/user/' + user.profile_img">
        </figure>
      </div>
      <div class="col pl-3 align-self-center">
        <p class="my-0" >{{ user.firstname }}</p>
        <p class="text-mute my-0 small">{{ user.username }}</p>
      </div>
      <div class="col-auto align-self-center display-Mobile">
        <a class="btn btn-link text-white p-2 logout" @click="this.$parent.logout">
          <i class="material-icons">power_settings_new</i>
        </a>
      </div>
    </div>
    <div class="list-group main-menu my-4">
      <router-link active-class="active" to="/wall" class="list-group-item list-group-item-action">
        <i class="material-icons">dashboard</i>Wall
      </router-link>
      <router-link active-class="active" to="/matchgame" class="list-group-item list-group-item-action">
        <i class="material-icons">favorite</i>Junx Matches
      </router-link>
      <router-link active-class="active" to="/forum" class="list-group-item list-group-item-action">
        <i class="material-icons">forum</i>Forum
      </router-link>
      <router-link active-class="active" to="/blog" class="list-group-item list-group-item-action">
        <i class="material-icons">chrome_reader_mode</i>Magazin
      </router-link>
      <!--
      <a href="/search?&c=map" class="list-group-item list-group-item-action " data-active="map">
          <i class="material-icons">map</i>Karte
      </a>
      -->
      <router-link active-class="active" to="/settings" class="list-group-item list-group-item-action" >
        <i class="material-icons">settings</i>Einstellungen
      </router-link>
      <router-link active-class="active" to="/home" class="list-group-item list-group-item-action" >
        <i class="material-icons">home</i>Startseite
      </router-link>

      <router-link to="/teamdashboard" class="list-group-item list-group-item-action" v-if="user.account_type == 'admin' || user.account_type == 'supporter'">
        <i class="material-icons">view_day</i>Teambereich
      </router-link>
      <router-link to="/redaktion" class="list-group-item list-group-item-action" v-if="user.account_type == 'admin' || user.account_type == 'redakteur'">
        <i class="material-icons">mode_edit</i>Redaktionsbereich
      </router-link>
      <button class="btn btn-link" type="button" v-if="user.premium_state != 0 && false">
          <span class="ghostmode">
            <i class="eye icon" :src="{'assets/images/ghost-on.png': ghostMode}"></i>
          </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    ghostMode () {
      return localStorage.getItem('ghostmode')
    }
  }
}
</script>

<style>
.sidebar .avatar img{
  border-radius: 90%;
}
/* Darkmode */
.dark .sidebar{
  background-color: #141414;
}
</style>
