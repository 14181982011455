<template>
  <form class="ui form" v-if="userdata" id="infoForm" @submit.prevent="sendForm">
    <div class="content">
      <div class="field">
        <label>Vorname:</label>
        <input name="firstname" required placeholder="Vorname" type="text" :value="userdata.profile.firstname" @blur="submit">
      </div>
      <div class="field">
        <label>Willkommenstext für deine Profilbesucher</label>
        <textarea name="description" maxlength="280" placeholder="Beschreibung max. 280 Zeichen" rows="3" :value="userdata.profile.profile_description" @blur="submit"></textarea>
      </div>
      <div class="field">
        <label>Sprachen die ich spreche:</label>
        <div class="ui multiple selection fluid dropdown languages">
          <input type="hidden" name="languages" value="">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Deutsch"><i class="de flag"></i>Deutsch</div>
            <div class="item" data-value="Tschechisch"><i class="cz flag"></i>Tschechisch</div>
            <div class="item" data-value="Polnisch"><i class="pl flag"></i>Polnisch</div>
            <div class="item" data-value="Französich"><i class="fr flag"></i>Französich</div>
            <div class="item" data-value="Niederländisch"><i class="nl flag"></i>Niederländisch</div>
            <div class="item" data-value="Englisch"><i class="gb flag"></i>Englisch</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Lebensweise:</label>
        <div class="ui selection fluid dropdown lifestyle">
          <input type="hidden" name="lifestyle">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Omnivore (Alles)">Omnivore (Alles)</div>
            <div class="item" data-value="Vegetarier">Vegetarier </div>
            <div class="item" data-value="Veganer">Veganer</div>
            <div class="item" data-value="Flexitarier">Flexitarier</div>
            <div class="item" data-value="Pescetarier">Pescetarier</div>
            <div class="item" data-value="Frutarier">Frutarier</div>
            <div class="item" data-value="Halal">Halal</div>
            <div class="item" data-value="Koscher">Koscher</div>
            <div class="item" data-value="Diät">Diät</div>
            <div class="item" data-value="Zöliakie">Zöliakie</div>
            <div class="item" data-value="Anderes">Anderes</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Raucher Status:</label>
        <div class="ui selection fluid dropdown smoking_state">
          <input type="hidden" name="smokingstate">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Raucher">Raucher</div>
            <div class="item" data-value="Nichtraucher">Nichtraucher</div>
            <div class="item" data-value="Partyraucher">Partyraucher</div>
            <div class="item" data-value="Gelegenheitsraucher">Gelegenheitsraucher</div>
            <div class="item" data-value="Shisharaucher">Shisharaucher</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Tätigkeit:</label>
        <div class="ui selection fluid dropdown working_as" >
          <input type="hidden" name="workingas">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Schüler">Schüler</div>
            <div class="item" data-value="Student">Student</div>
            <div class="item" data-value="FSJ / FÖJ">FSJ / FÖJ</div>
            <div class="item" data-value="Azubi">Azubi</div>
            <div class="item" data-value="Angestellter">Angestellter</div>
            <div class="item" data-value="Selbstständig">Selbstständig</div>
            <div class="item" data-value="Arbeitssuchend">Arbeitssuchend</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Geschlecht:</label>
        <div class="ui fluid selection dropdown gender">
          <input type="hidden" name="gender">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Männlich">Männlich</div>
            <div class="item" data-value="Weiblich">Weiblich</div>
            <div class="item" data-value="Männlich (cis)">Männlich (cis)</div>
            <div class="item" data-value="Weiblich (cis)">Weiblich (cis)</div>
            <div class="item" data-value="Männlich (ftm)">Männlich (ftm)</div>
            <div class="item" data-value="Weiblich (mtf)">Weiblich (mtf)</div>
            <div class="item" data-value="Nonbinary">Nonbinary</div>
            <div class="item" data-value="Agender">Agender</div>
            <div class="item" data-value="Demiboy">Demiboy</div>
            <div class="item" data-value="Demigirl">Demigirl</div>
            <div class="item" data-value="Genderqueer">Genderqueer</div>
            <div class="item" data-value="Genderfluid">Genderfluid</div>
            <div class="item" data-value="Genderflux">Genderflux</div>
            <div class="item" data-value="Bigender">Bigender</div>
            <div class="item" data-value="Mensch">Mensch</div>
            <div class="item" data-value="Keine Schublade">Keine Schublade</div>
            <div class="item" data-value="Unsicher">Unsicher</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Bevorzugte Pronomen:</label>
        <div class="ui fluid selection dropdown pronome">
          <input type="hidden" name="pronome">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="er/ihm">er/ihm</div>
            <div class="item" data-value="sie/ihr">sie/ihr</div>
            <div class="item" data-value="sier">sier</div>
            <div class="item" data-value="dey">dey</div>
            <div class="item" data-value="xier">xier</div>
            <div class="item" data-value="ese">ese</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Körper & Größe:</label>
        <div class="grid-12">
          <div class="col-sp-8 row-sp-16">
            <div class="ui selection fluid dropdown epbody">
              <input type="hidden" name="body">
              <i class="dropdown icon"></i>
              <div class="default text"><i>Keine Angabe</i></div>
              <div class="menu">
                <div class="item" data-value="Durchschnittlich">Durchschnittlich</div>
                <div class="item" data-value="Schlank">Schlank</div>
                <div class="item" data-value="Leichter Bauch">Leichter Bauch</div>
                <div class="item" data-value="Stämmig">Stämmig</div>
                <div class="item" data-value="Athletisch">Athletisch</div>
                <div class="item" data-value="Muskulös">Muskulös</div>
                <div class="item" data-value="Keine Angabe">Keine Angabe</div>
              </div>
            </div>
          </div>
          <div class="col-sp-4 row-sp-16">
            <div class="ui selection fluid dropdown body_height">
              <input type="hidden" name="bodyheight">
              <i class="dropdown icon"></i>
              <div class="default text"><i>Keine Angabe</i></div>
              <div class="menu" id="cm">
                <div class="item" data-value="Keine Angabe">Keine Angabe</div>
                <div class="item" :data-value="index + 70" v-for="index in 150" :key="index">{{ index + 70 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Augenfarbe:</label>
        <div class="ui selection fluid dropdown eye_color">
          <input type="hidden" name="eyecolor">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Blau">Blau</div>
            <div class="item" data-value="Grün">Grün</div>
            <div class="item" data-value="Graublau">Graublau</div>
            <div class="item" data-value="Graugrün">Graugrün</div>
            <div class="item" data-value="Grau">Grau</div>
            <div class="item" data-value="Schwarz">Schwarz</div>
            <div class="item" data-value="Braun">Braun</div>
            <div class="item" data-value="Andere">Andere</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Haarfarbe:</label>
        <div class="ui selection fluid dropdown hair_color">
          <input type="hidden" name="haircolor">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Braun">Braun</div>
            <div class="item" data-value="Blond">Blond</div>
            <div class="item" data-value="Schwarz">Schwarz</div>
            <div class="item" data-value="Rot">Rot</div>
            <div class="item" data-value="Gefärbt">Gefärbt</div>
            <div class="item" data-value="Andere">Andere</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" id="submit" style="display: none;"></button>
  </form>
</template>

<script>
import { clickElement } from '@/modules/main'
import axios from 'axios'
export default {
  name: 'info',
  props: ['userdata'],
  data () {
    return {
      reload: false
    }
  },
  methods: {
    sendForm () {
      const _this = this
      this.reload = true
      const formData = new FormData(document.querySelector('#infoForm'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'profile/editProfileAboutdetails', formData)
    },
    submit () {
      clickElement(document.querySelector('#submit'))
    }
  },
  mounted () {
    const _this = this
    $('.languages').dropdown('set selected', this.userdata.details.languages.split(','))
    $('.lifestyle').dropdown('set selected', this.userdata.details.lifestyle)
    $('.smoking_state').dropdown('set selected', this.userdata.details.smoking_state)
    $('.working_as').dropdown('set selected', this.userdata.details.working_as)
    $('.gender').dropdown('set selected', this.userdata.details.gender)
    $('.pronome').dropdown('set selected', this.userdata.details.pronome)
    $('.epbody').dropdown('set selected', this.userdata.details.body)
    $('.body_height').dropdown('set selected', this.userdata.details.body_height)
    $('.eye_color').dropdown('set selected', this.userdata.details.eye_color)
    $('.hair_color').dropdown('set selected', this.userdata.details.hair_color)
    $('#infoForm').find('.dropdown').dropdown('setting', 'onChange', function () {
      _this.submit()
    })
    window.junx.loading = false
  },
  beforeRouteLeave (to, from, next) {
    const _this = this
    if (this.reload) {
      _this.$emit('resetdata', '')
    }
    next()
  }
}
</script>

<style scoped>

</style>
