<template>
  <div id="page-home" class="spaceTop">
    <section id="HomeBanner">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-tb-6 row-sp-16">
          <img src="assets/images/junx-julian.png" class="responsive-img" style="margin-bottom: 0;"/>
        </div>
        <div class="col-sp-16 col-tb-10 row-sp-16 pad-40 banner-content">
          <h2 class="ui inverted header">
            Die Community Plattform für queere Jungs*
            <div class="sub header">Im Web und als App</div>
          </h2>
          <p>
            Freunde* online treffen, neue Leute kennenlernen und sich mit Gleichgesinnten austauschen - ohne Angst vor Anfeindungen und direkt auf deinem Smartphone.
          </p>
          <div class="t-right" style="max-width: 600px;">
            <router-link to="/wall" class="ui inverted basic large button" v-if="auth">
              Zum Community Bereich
            </router-link>
            <router-link to="/login/register" class="ui inverted basic large button" v-else>
              Jetzt Anmelden
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="content-1200">
        <div class="pad-40"></div>
        <div class="grid-12">
          <div class="col-sp-12 row-sp-16 pad-16 t-center">
            <h3 class="ui header" style="font-size: 2.3em">
              Was Junx bietet
              <div class="sub header">
                Eine Plattform viele Features
              </div>
            </h3>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/couple.png">
              <h3 class="ui header">
                <div class="content">
                  Neue Leute kennenlerenen
                  <div class="sub header">
                    Triff Menschen mit gleichen Interessen und finde mit der Match-Funktion ganz leicht neue Freunde.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/chat.png">
              <h3 class="ui header">
                <div class="content">
                  Mit Freunden Chatten
                  <div class="sub header">
                    Verschicke Bilder, Sprachnachrichten und GIFs an deine Kontakte oder chatte live per Video.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/flag.png">
              <h3 class="ui header">
                <div class="content">
                  Austauschen in Kanälen
                  <div class="sub header">
                    Tausch dich in Kanälen mit Freunden und Gleichgesinnten aus.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/fragen.png">
              <h3 class="ui header">
                <div class="content">
                  Anonym Fragen stellen
                  <div class="sub header">
                    Erhalte ehrliche Antworten auf unbequeme Fragen ohne deine Identität preiszugeben.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/partys.png">
              <h3 class="ui header">
                <div class="content">
                  Partys & Veranstaltungen finden
                  <div class="sub header">
                    Nie wieder Langeweile! Finde Events in deiner Nähe, online wie auch offline.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-16">
            <div class="ui segment no-border no-shadow br-4 pad-24 h-100 t-center">
              <img src="assets/images/emoticons/smartphone.png">
              <h3 class="ui header">
                <div class="content">
                  Inhalte teilen
                  <div class="sub header">
                    Fotos, Videos und Gedanken: Teile alles was dir wichtig ist auf deinem persönlichen Profil.
                  </div>
                </div>
              </h3>
            </div>
          </div>
          <div class="col-sp-12 row-sp-16 pad-16 t-center">
            <router-link to="/features" class="ui button large basic">
              Alle Features ansehen
            </router-link>
            <div class="pad-16"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="video-banner">
      <video src="assets/videos/junge-smartphone-junx.mp4" muted autoplay loop></video>
      <div class="content">
        <img src="assets/images/junx-app-icon.png" class="br-8" style="max-width: 64px; margin-bottom: -16px;"/>
        <h4 class="ui header">
          Die Junx App
          <div class="sub header">für unterwegs</div>
        </h4>
        <p>
          Mit dem integrierten Messenger bleibst du auch unterwegs mit deinen Freunden in Kontakt. Versende Bilder, Sprachnachrichten und GIFs oder chatte live per Video.
          <br><br>
          Über die Match-Funktion werden dir neue Leute mit ähnlichen Interessen angezeigt. Du entscheidest selbst, wen du kennenlernen möchtest und wen nicht.
        </p>
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8 t-center">
            <a href="https://play.google.com/store/apps/details?id=app.junx.app&gl=DE" target="_blank">
              <img src="assets/images/playstore-logo.png" class="responsive-img" style="max-width: 200px;"/>
            </a>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8 t-center">
            <a href="https://testflight.apple.com/join/M56JzRpH" target="_blank">
              <img src="assets/images/appstore-logo.png" class="responsive-img appstorelogo"  style="max-width: 200px;"/>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="content-1400 grid-12 pad-16">
        <div class="ui segment no-border no-shadow no-radius pad-32 col-sp-12 row-sp-12 grid-12">
          <div class="col-sp-12 col-tb-8 row-sp-12">
            <h2 class="ui header">
              <img src="assets/images/emoticons/flag.png">
              <div class="content">
                Neues im Magazin
                <div class="sub header">Dein LGBTIQ+ Newspaper</div>
              </div>
            </h2>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
            <form method="get" action="/magazin?s=">
              <div class="ui action fluid input">
                <input name="s" type="text" placeholder="Suche...">
                <button class="ui icon button btn-color-1">
                  <i class="search icon"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sp-12 row-sp-12 pad-8">
          <div class="grid-12" id="BlogArticle">
            <magazin v-for="(article, index) in articles" :article="article" :key="index"></magazin>
          </div>
          <div class="t-right" style="margin-top: 24px;">
            <router-link to="/magazin" class="ui labeled icon button btn-color-1">
              Zum Magazin <i class="arrow right icon"></i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="content-1000 pad-16">
        <div class="ui segment no-border no-shadow no-radius grid-12">
          <div class="col-sp-12 row-sp-12 t-center">
            <div class="pad-8"></div>
            <h2 class="ui header centered">
              <div class="sub header">Unsere</div>
              Koorperationspartner
            </h2>
          </div>
          <div class="col-sp-6 row-sp-12 t-center pad-16">
            <a href="https://20north.de" target="_blank">
              <img src="assets/images/20-north-logo.png" class="responsive-img" style="max-width: 250px;"/>
            </a>
          </div>
          <div class="col-sp-6 row-sp-12 t-center pad-16">
            <a href="https://www.liebewenduwillst.de/" target="_blank">
              <img src="assets/images/liebe-wen-du-willst-logo.png" class="responsive-img" style="max-width: 250px;"/>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import magazin from '@/components/website/magazin'
import axios from 'axios'
export default {
  name: 'home',
  title: 'Junx',
  props: ['auth'],
  components: {
    magazin
  },
  data () {
    return {
      articles: []
    }
  },
  mounted () {
    const _this = this
    axios.post(window.projectURL + 'home/indexJS')
      .then(function (response) {
        _this.articles = response.data.articles
      })
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-home{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
#page-home #HomeBanner{
  width: 100%;
  min-height: 300px;
  background-color: #a9cbd5;
}
#page-home #HomeBanner .banner-content {
  padding-top: 120px!important;
}
#page-home #HomeBanner .banner-content p{
  color: rgba(255,255,255,0.8);
  max-width: 600px;
  font-size: 1.1em;
}
@media (max-width: 768px) {
  #page-home #HomeBanner .banner-content {
    padding-top: 32px!important;
    background-color: #4B0072;
  }
}
#page-home .video-banner{
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}
@media (max-width: 768px) {
  #page-home .video-banner{
    height: 100vh;
  }
}
#page-home .video-banner video{
  width: 100%;
  margin-bottom: -6px;
  position: absolute;
  z-index: -1;

}
#page-home .video-banner .content{
  position: absolute;
  right: 0;
  top: 20vh;
  padding: 40px;
  background-color: rgba(255,255,255,1);
}
#page-home .video-banner .content h4{
  font-size: 2.5em;
}
#page-home .video-banner .content h4 .sub.header{
  font-size: 0.5em;
}
#page-home .video-banner .content p{
  color: rgba(0,0,0,0.6);
  max-width: 600px;
  font-size: 1em;
}
/* Darkmode */
.dark #page-home .video-banner .content{background-color: rgba(0,0,0,1);}
.dark #page-home .video-banner .content p{color: rgba(255,255,255,0.6);}
.dark #page-home .appstorelogo{filter: invert(1);}
</style>
