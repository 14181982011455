<template>
  <router-link to="/ep/info" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="user icon"></i>
    </button>
    <span class="menu-title">Über dich</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
  <router-link to="/ep/love" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="heart icon"></i>
    </button>
    <span class="menu-title">Liebe</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
  <router-link to="/ep/location" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="location arrow icon"></i>
    </button>
    <span class="menu-title">Aktueller Ort</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
  <router-link to="/ep/interests" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="paint brush icon"></i>
    </button>
    <span class="menu-title">Interessen</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
  <router-link to="/ep/questionnaire" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="question icon"></i>
    </button>
    <span class="menu-title">Fragebogen</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
  <router-link to="/ep/social" active-class="active" class="settings-menu-item">
    <button class="ui circular icon button">
      <i class="users icon"></i>
    </button>
    <span class="menu-title">Soziale Medien</span>
    <span class="rightspan">
                <i class="icon angle right"></i>
              </span>
  </router-link>
</template>

<script>
export default {
  name: 'menu',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>

</style>
