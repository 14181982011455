<template>
   <div v-if="size == 'small'" onclick="$('#friendships').dropdown('show')">
      <div class="ui dropdown item" id="friendships" tabindex="0" v-if="openFriend">
            <i class="users icon"></i>
            <div class="floating ui blue tiny circular label" v-if="friendCounter > 0">{{friendCounter}}</div>
            <div class="menu notifications-menu" id="friends-menu" tabindex="-1">
                  <div class="item cur-point" v-for="(friend, index) in friendsarray" :key="index">
                        <friend-single :friendid="friend.id" :username="friend.username" :timestamp="friend.timestamp" :profile_img="friend.profile_img"></friend-single>
                    </div>
                  <div class="item t-center">
                      <router-link class="ui fluid button btn-inverted-color" to="/n/friends" >Alle ansehen</router-link>
                  </div>
            </div>
      </div>
      <router-link class="item" to="/notification/friends" v-else> <i class="users icon" ></i></router-link>
   </div>
   <div v-else>
      <div class="content pad-16" v-if="friendCounter == 0">
            <p class="t-center">
                  Du hast keine offenen Freundschaftsanfragen
              </p>
            <div class="t-center pad-4">
                  <button class="ui button btn-color-1" onclick="$('#friendrequestModal.ui.modal').modal('hide');">Okay</button>
              </div>
            <div class="menu">
              </div>
        </div>
      <div class="content pad-16" v-else>
            <div class="item cur-point" v-for="(friend, index) in friendsarray" :key="index">
                  <friend-single :friendid="friend.id" :username="friend.username" :timestamp="friend.timestamp" :profile_img="friend.profile_img"></friend-single>
              </div>
        </div>
   </div>
</template>

<script>
import friendSingle from '@/components/basic/notification/friendSingle'
import router from '@/router'

export default {
  name: 'friendsNav',
  props: ['friendsarray', 'size'],
  data () {
    return {
    }
  },
  components: {
    friendSingle
  },
  computed: {
    friendCounter () {
      return this.friendsarray.length
    },
    openFriend () {
      return (this.friendsarray.length > 0)
    }
  },
  methods: {
    calculateTime (posted, now) {
      let remainTime = Math.floor((now - posted) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sekunden'
      if (remainTime <= 59)timeString = 'Jetzt'
      if (remainTime > 59) {
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Minuten'
        if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Stunden'
          if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(posted)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    },
    openProfile (uid) {
      router.push('/p/' + uid)
    }
  }
}
</script>

<style scoped>

</style>
