<template>
  <div class="rainbowloader" id="FullSizeLoader" v-if="loading">
    <div class="loader-inner">
      <div class="loader-line-wrap">
        <div class="loader-line">
        </div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line">
        </div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line">
        </div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line">
        </div>
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line">
        </div>
      </div>
    </div>
  </div>
  <report></report>
  <router-view v-if="!testMode" :notis="notis" :notisread="notisread" :user="user" :chats="chatsCounter" :socketonline="socketOnline" :friends="friends" :chatModal="chatModal" name="navBar" :darkMode="darkMode"></router-view>
    <router-view v-if="!testMode" :auth="auth" :user="user"  :notis="notis" :friends="friends"></router-view>
  <router-view v-if="!testMode" :auth="auth" name="footer"></router-view>
  <test v-if="testMode"></test>
  <div class="blurPage" v-if="blur"></div>
  <transition name="modal">
    <modal v-show="chatModal" @close="chatModal = false">
      <div class="messenger-background" @click="closeChat">
        <messages :user="user"></messages>
      </div>
    </modal>
  </transition>
  <transition name="modal">
    <modal v-if="callModal" @close="callModal = false">
      <div class="messenger-background">
        <call :username="callDetails.username" :profile_img="callDetails.profile_img" :room="callDetails.room"></call>
      </div>
    </modal>
  </transition>
</template>

<script>
import Report from '@/components/basic/report'
import Messages from '@/views/messages'
import Call from '@/views/call'
import Test from '@/views/test'
import { setIconMenu } from '@/modules/main'
export default {
  name: 'Junx',
  components: { Messages, Report, Test, Call },
  title: 'Junx',
  data () {
    return {
      chats: [],
      chatsCounter: 0,
      chatModal: false,
      callModal: false,
      callDetails: {},
      notis: [],
      friends: [],
      user: {},
      notisread: 0,
      loading: false,
      socketOnline: false,
      auth: sessionStorage.getItem('auth') === '1',
      blur: false,
      darkMode: localStorage.getItem('darkmode') === '1',
      testMode: false
    }
  },
  watch: {
    notis () {
      setIconMenu(window.junx.chatsCounter, window.junx.notis.length - window.junx.notisread)
    },
    notisread () {
      setIconMenu(window.junx.chatsCounter, window.junx.notis.length - window.junx.notisread)
    },
    chatsCounter () {
      setIconMenu(window.junx.chatsCounter, window.junx.notis.length - window.junx.notisread)
    },
    chatModal () {
      if (this.chatModal) {
        $('body').addClass('no-scroll')
      } else {
        $('body').removeClass('no-scroll')
      }
    }
  },
  methods: {
    closeChat (event) {
      if (event.target.classList.contains('messenger-background')) {
        this.chatModal = false
        $('body').removeClass('no-scroll')
      }
    },
    closeCall (event) {
      if (event.target.classList.contains('messenger-background')) {
        this.callModal = false
        $('body').removeClass('no-scroll')
      }
    }
  },
  mounted () {
    const _this = this
    /*
    PullToRefresh.init({
      mainElement: document.querySelector('#ptrest'),
      triggerElement: document.querySelector('#pullToRefresh'),
      instructionsPullToRefresh: 'ziehen...',
      instructionsReleaseToRefresh: 'Loslassen...',
      instructionsRefreshing: 'aktualisiere...',
      iconArrow: '<i class="fas fa-angle-down fa-2x"></i>',
      shouldPullToRefresh () { return window.ptr },
      onRefresh () {
        window.location.reload()
      }
    }) */
  }
}
</script>

<style lang="scss">
.ptr--icon,
.ptr--text {
 color: rgb(23 23 23 / 72%) !important;
}
body {
  height: 100vh !important;
}
.spaceTop {
  margin-top: calc(constant(safe-area-inset-top) + 54px) !important;
  margin-top: calc(env(safe-area-inset-top) + 54px) !important;
}
.sidebar {
  top: calc(constant(safe-area-inset-top) + 54px) !important;
  top: calc(env(safe-area-inset-top) + 54px) !important;
  z-index: 1001 !important;
}
.notchFix {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.blurPage{
  /* Any browser which supports CSS3 */
  background: url('assets/images/auth.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000%;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 99999;
  overflow:hidden;
}
.no-scroll{
  overflow: hidden;
}
.modal-mask {
  position: fixed;
  z-index: 1111;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.messenger-background {
  backdrop-filter: brightness(20%) blur(5px);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
}
.messenger-foreground {
  @media (min-width: 992px){
    max-width: 80% !important;
    max-height: 80% !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
    /* border-radius: 5px; */
  }
  overflow: hidden;
}
// @import "assets/scss/main.scss";
</style>
