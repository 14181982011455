<template>
  <div id="page-faq"  class="spaceTop">
    <section class="bg-white">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-sd-6 row-sp-16 pad-40">
          <div class="pad-24"></div>
          <img src="assets/images/junx-faq.jpg" class="br-16" style="transform: rotate(-3deg); max-width: 500px; width: 100%;">
        </div>
        <div class="col-sp-16 col-sd-10 row-sp-16 pad-40">
          <h1 class="ui header">
            <img src="assets/images/emoticons/fragen.png">
            <div class="content">
              FAQ
              <div class="sub header">Frequently Asked Questions</div>
            </div>
          </h1>
          <p>
            Du hast Fragen zu unserer Plattform? Vielleicht findest du ja bereits hier die Antwort auf deine Frage.
            Solltest du diese hier nicht finden, so schreibe uns doch gerne einfach über unser <a href="kontakt">Kontaktformular</a>.
          </p>
          <div class="pad-8"></div>
          <div class="ui accordion">
            <div class="title">
              <i class="dropdown icon"></i>
              Für wen ist Junx?
            </div>
            <div class="content">
              <p class="transition hidden">
                Junx ist eine Community App für queere Jugendliche und junge Erwachsene von 14 bis 29 Jahren. Unsere Plattform ermöglicht einen sicheren und offenen Austausch und erleichtert das Freunde* finden.
                Unser Angebot richtet sich vornehmlich an Jungs*, also junge Menschen die männlich sind oder sich so identifizieren.
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Fallen Gebühren bei der Nutzung von Junx an?
            </div>
            <div class="content">
              <p class="transition hidden">
                Nein, Junx ist eine komplett kostenlose App. Die Plattform finanziert sich komplett durch Werbeeinahmen und Unterstützer.
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Gibt es Regeln, an die sich die Jungs* halten müssen?
            </div>
            <div class="content">
              <p>
                Ja, das Junx’sche Gesetz. Wer gegen diese Etikette verstößt, kriegt Ärger mit unserem Support-Team und wird unter Umständen auch der Plattform verwiesen.
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Wie wird die Sicherheit der Nutzer* gewährleistet?
            </div>
            <div class="content">
              <p>
                Um die Wahrung unserer Etikette (Junx’sche Gesetz) und des Jugendschutzes sicherzustellen, gibt es bei uns zwei Instanzen:
                <br>
                Automatische Filter scannen alle öffentlich geteilten Fotos und Videos nach unangemessenen Inhalten, im zweiten Schritt werden diese außerdem durch unser Support-Team überprüft und freigeschaltet.
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Was macht das Support-Team?
            </div>
            <div class="content">
              <p>
                Unser Support-Team ist jeden Tag im Einsatz. Wir sichten regelwidriges Verhalten und gehen Meldungen und Regelverstößen nach. Außerdem sind wir für alle Nutzer jederzeit und ganz einfach erreichbar, sollte es zu unangenehmen Situationen kommen.
                <br><br>
                Gibt es auf Junx Fake-Accounts?
                Um es Bots und Fake-Accounts auf Junx schwer zu machen, haben Nutzer* die Möglichkeit, sich per Foto-Check zu verifizieren.
                <br><br>
                <b>Der Prozess läuft wie folgt ab:</b><br>
                - Der Nutzer* lädt ein Bild von sich mit einem Blatt Papier hoch. Auf diesem Blatt Papier befindet sich der Username sowie eine individuelle Nummer.<br>
                - Der Support gleicht das Bild mit den bereits hochgeladenen Bildern des Nutzers, sowie seiner individuellen Nummer ab.<br>
                - Wenn beides übereinstimmt, erhält der Nutzer* vom Support-Team ein Verifizierungs-Abzeichen auf seinem Profil.<br>
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              Können Nutzer* einfach so private Nachrichten austauschen?
            </div>
            <div class="content">
              <p>
                Nein, nur Nutzer* die miteinander befreundet sind und sich kennen, können im Messenger privat chatten.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-40"></div>
    </section>
    <section>
      <div class="content-1400">
        <div class="pad-16">
          <div class="pad-8">
            <div class="ui segment fluid no-shadow no-border pad-24 h-100 grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
                <h3 class="ui header">
                  Noch offene Fragen?
                  <div class="sub header">Dann kontakiere uns gerne</div>
                </h3>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-8 t-right">
                <router-link class="ui button btn-color-1" to="/kontakt">
                  <i class="envelope icon"></i> Zum Kontaktformular
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'faq',
  title: 'Junx | FAQ',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-faq{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
/* Darkmode */
.dark #page-faq p{color: rgba(255,255,255,0.6);}
.dark .ui.accordion .title:not(.ui){color: rgba(255,255,255,0.8);}
</style>
