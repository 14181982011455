<template>
  <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8">
    <div class="ui segment t-center pad-8" :id="'Selection_'+channel.channel_slag" @click="this.$parent.selectChannel(channel.channel_slag, channel.id)">
      <div class="checked-icon">
        <img src="assets/images/channels/check.png"/>
      </div>
      <div class="selectContent">
        <img style="max-width: 44px;" :src="'assets/images/channels/'+channel.channel_img"/>
        <h4 style="display: inline-block;" class="pad-16">{{channel.channel_title}}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'channel',
  props: ['channel']
}
</script>

<style scoped>
.checked-icon {
  display: none;
}
.select .checked-icon {
  display: block;
}
</style>
