<template>
  <!-- Modals -->
  <div id="hoverModal" class="ui segment no-shadow profile-preview">
    <h5 class="ui header active">
      <img id="hoverModalImg" class="ui avatar image">
      <div class="content">
        <span id="hoverModalFirstName">Vorname</span>
        <div id="hoverModalUserName" class="sub header active">
          @username
        </div>
      </div>
    </h5>
  </div>

  <div id="FakeCheckModal" class="ui modal">
    <div class="header"><i class="check circle blue icon"></i> Junx Profil verifizieren</div>
    <div class="content">
      <div class="grid-12">
        <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
          <img src="assets/images/julian-verify.jpg" class="responsive-img"/>
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12 pad-8">
          <div class="description" style="flex: unset;">
            <h4 class="ui header">
              So funktionierts:
            </h4>
            <ol class="ui list">
              <li>
                Schreibe Handschriftlich auf einen Blatt Papier deinen Verifizierungscode:
                <b id="fakeID"></b>
              </li>
              <li>Mache ein Foto von dir auf dem du den Zettel sichtbar in der Hand hälst und dein Gesicht klar erkennbar ist.</li>
              <li>Lade das Foto hoch und habe etwas geduld.</li>
            </ol>
            <p>Nach dem wir das Bild geprüft haben, werden wir dein Profil als verifiziert kennzeichnen.</p>
            <div class="pad-8"></div>
            <form class="ui form editProfileForm" method="post" action="profile/verifyProfile">
              <div class="field">
                <label>Bilddatei:</label>
                <input type="file" name="verifyimage" accept="image/jpeg, image/png" required/>
              </div>
              <div class="t-right">
                <button type="submit" class="ui button btn-color-1">
                  Abschicken
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="verifyloader" style="top: 58px!important;height: calc(100% - 111px);z-index: 5; display: none;" class="ui active inverted dimmer">
      <div class="ui text loader">Bitte warten</div>
    </div>
  </div>

  <div class="ui mini deletePost modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deletePost.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>

  <div class="ui mini deleteComment modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deleteComment.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>

  <div class="ui tiny modal" id="cropperModal"  v-if="own">
    <div class="page-profile">
      <div class="content-1000 grid-12 pad-8">
        <div class="col-sp-12 row-sp-12 pad-16 t-center">
          <h3 class="ui header">
            Schneide dein Bild zu
            <div class="sub header">
              Quadratisch, Praktisch... ähm Rund? <img style="max-width: 32px;" src="assets/images/emoticons/like.png"/>
            </div>
          </h3>
          <p>Makiere den Bereich auf dem Bild, welches du als Profilbild nutzen möchtest und klicke danach auf Speichern.</p>
        </div>
        <div class="col-sp-12 row-sp-12 pad-16">
          <div>
            <img id="target" class="responsive-img" style="display: block; width: 100%;" :src="projectURL + 'assets/images/temp/' + tempImg">
          </div>
          <form id="cropForm" @submit.prevent="crop">
            <input type="hidden" id="x" name="x" />
            <input type="hidden" id="y" name="y" />
            <input type="hidden" id="w" name="w" />
            <input type="hidden" id="h" name="h" />
            <input type="hidden" id="ow" name="ow" />
            <input type="hidden" id="oh" name="oh" />
            <input type="hidden" name="file" id="cropperFile" :value="tempImg"/>
            <div class="pad-8 t-center">
              <button id="savecrop" type="submit" class="ui fluid purple disabled button avatar-save">Speichern</button>
            </div>
          </form>
          <div class="pad-8 t-center">
            <a onclick="$('#cropperModal').modal('hide');" class="color-2">Abbrechen</a>
          </div>
        </div>
        <div class="pad-16"></div>
      </div>
    </div>
  </div>
  <div id="UserProfile" class="spaceTop" v-if="status == 'ok'">
    <div id="ptrest"></div>
    <div class="content-1400">
      <div class="grid-16">
        <div class="col-sp-16 row-sp-16">
          <div id="ProfileNav">
            <div class="grid-12">
              <div class="col-sp-4 row-sp-12">
                <a @click="this.$router.back();">
                  <i class="icon left arrow"></i>
                </a>
              </div>
              <div class="col-sp-4 row-sp-12 t-center">

              </div>
              <div class="col-sp-4 row-sp-12 t-right"  v-if="!own">
                <div class="ui dropdown" tabindex="0">
                  <i class="ellipsis vertical icon"></i>
                  <div class="menu transition hidden" tabindex="-1">
                    <div class="item" id="reportProfile"><i class="flag icon"></i> Profil Melden</div>
                    <div class="item">
                      <form method="post" class="editProfileForm" action="/profile/blockUser">
                        <input type="hidden" name="u" :value="profile.id">
                        <button style="border: none; background: transparent;">
                          <i class="ban icon"></i> Benutzer blockieren
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile -->
      <div class="grid-16 display-Mobile pad-16" id="ptrPull">
        <div class="col-sp-16 row-sp-16">
          <section id="ProfileImage">
            <div class="profile-image-edit-cta">
              <button onclick="$('#EditProfileImage.ui.modal').modal('show');" class="circular inverted ui basic mini button icon"  v-if="own">
                <i class="icon edit"></i>
              </button>
            </div>
            <img :src="projectURL + 'assets/images/user/' + profile.profile_img" v-if="profile.profile_img" class="ar-1-1">
          </section>
          <section id="ProfileTitle">
            <h2 class="ui header">
              {{profile.username}}, {{profile.age}}<span v-if="profile.verify == 1" style="font-size: 0.7em; margin-right: -16px;" title="Der User ist von Junx verifiziert"><i class="check circle blue icon"></i></span>
              <div class="sub header">
                <i class="icon map marker alternate"></i> {{profile.cname}}
                <a class="ui basic mini label OnlineStatus" v-if="profile.online == 1">
                  <i class="circle green icon"></i>
                  Online
                </a>
                <a class="ui basic mini label OfflineStatus" v-if="profile.lastActive != 0">
                  <i class="circle grey icon"></i>
                  {{profile.lastActive}}
                </a>
              </div>
            </h2>
            <div class="ProfileBadgets">
              <span class="ui image small label btn-color-1" v-if="profile.account_type == 'admin' || profile.account_type == 'supporter'" ><img src="assets/icons/favicon-32x32.png">Team</span>
              <span class="ui image small label btn-color-1" v-if="profile.account_type == 'creator'" ><i class="star icon"></i>Creator</span>
              <router-link to="/member" class="ui label premiumlabel" v-if="profile.premium_state != 0" ><i class="fire icon"></i>Unterstützer</router-link>
            </div>
            <section id="ProfileCTAs" >
              <div class="grid-12"  v-if="!own">
                <div class="col-sp-3 row-sp-12" onclick="$('#StampModal').modal('show');">
                  <div class="profile-cta-img stampImg">
                    <img src="assets/images/emoticons/like.png" v-if="!ownCrush">
                    <img :src="'assets/images/komplimente/' + ownCrush" v-else>
                  </div>
                  <span>Stamp</span>
                </div>
                <router-link class="col-sp-3 row-sp-12" :to="'/messages/' + profile.username">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/chat.png">
                  </div>
                  <span>Chat</span>
                </router-link>
                <div class="col-sp-3 row-sp-12 videoRequestLink" @click="call()">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/videocall.png">
                  </div>
                  <span>Videocall</span>
                </div>
                <div class="col-sp-3 row-sp-12 followButton" @click="follow()">
                  <div class="profile-cta-img" :class="{'bg-color-1': followCheck == true}">
                    <img src="assets/images/emoticons/flag.png">
                  </div>
                  <span>{{followCheck ? 'Entfolgen' : 'Folgen'}}</span>
                </div>
              </div>
              <div class="action-buttons">
                <div onclick="$('#FollowerModal').modal('show');" class="ui labeled fluid button btn-inverted-color" tabindex="0">
                  <div class="ui button fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15)">
                    <i class="heart icon"></i> Follower
                  </div>
                  <a class="ui basic label">{{follower.counter}}</a>
                </div>
                <div class="pad-4"></div>
                <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-if="!own && friendCheck == false" @click="addFriend">
                  <i class="user add icon" ></i> Als Freund hinzufügen
                </button>
                <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own && friendCheck.accept == 0 && friendCheck.user_1 == user.id" @click="declineFriend(friendCheck.id)">
                  <i class="user cancel icon" ></i> Freundschaftsanfrage zurückziehen
                </button>
                <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own && friendCheck.accept == 0" @click="acceptFriend(friendCheck.id)">
                  <i class="user add icon" ></i> Freundschaftsanfrage annehmen
                </button>
                <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own" @click="declineFriend(friendCheck.id)">
                  <i class="user cancel icon" ></i> Als Freund entfernen
                </button>
                <router-link class="ui button icon fluid labeled btn-color-1" to="/ep" v-if="own">
                  <i class="user edit icon"></i> Profil bearbeiten
                </router-link>
              </div>
            </section>
          </section>
          <div class="pad-8"></div>
          <section id="ProfileDescription">
            <div class="description-content">
              <h6 class="ui header">Hey <span data-self></span> <img src="assets/images/emoticons/hi.png"></h6>
              <p v-if="notNull(profile.profile_description)">{{profile.profile_description}}</p>
            </div>
          </section>
          <div class="pad-16"></div>
          <section id="ProfileSocialMediaMobile" class="ProfileSocialMedia" v-if="notNull(details.socialmedia_instagram) || notNull(details.socialmedia_linkedin) || notNull(details.socialmedia_tiktok) || notNull(details.socialmedia_youtube) || notNull(details.socialmedia_twitch) || notNull(details.socialmedia_twitter)">
            <div class="socialmedia-content" >
              <h6 class="ui header">Meine Social Media Kanäle </h6>
              <div class="grid-12">
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_instagram">
                  <a @click="redirect(details.socialmedia_instagram)"><img class="ui mini image" src="assets/images/social-media/instagram.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_linkedin">
                  <a @click="redirect(details.socialmedia_linkedin)"><img class="ui mini image" src="assets/images/social-media/linkedin.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_tiktok">
                  <a @click="redirect(details.socialmedia_tiktok)"><img class="ui mini image" src="assets/images/social-media/tiktok.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_youtube">
                  <a @click="redirect(details.socialmedia_youtube)"><img class="ui mini image" src="assets/images/social-media/youtube.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_twitch">
                  <a @click="redirect(details.socialmedia_twitch)" ><img class="ui mini image" src="assets/images/social-media/twitch.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_twitter">
                  <a @click="redirect(details.socialmedia_twitter)"><img class="ui mini image" src="assets/images/social-media/twitter.png"></a>
                </div>
              </div>
            </div>
          </section>
          <div class="ui segment no-shadow no-border trending pad-24">
            <div class="content">
              <div class="grid-12">
                <div class="col-sp-7 col-tb-6 row-sp-12">
                  <h3 class="ui header">
                    <div class="content">
                      Freunde von
                      <div class="sub header">{{own?'dir':profile.firstname}}</div>
                    </div>
                  </h3>
                </div>
                <div class="col-sp-5 col-tb-6 row-sp-12 t-right">
                  <button onclick="$('#FriendsModal').modal('show');" class="circular ui basic mini button icon labeled">
                    <i class="icon users"></i> Alle ansehen
                  </button>
                </div>
              </div>
              <div class="pad-4"></div>
              <div class="grid-12">
                <friends v-for="(user,index) in allFriends.slice(0, 18)" :key="index" :user="user"></friends>
              </div>
            </div>
          </div>
        </div>
        <div class="ui segment no-shadow no-border trending pad-24 verifyElement"  v-if="own && profile.verify != 1">
          <div class="content">
            <div class="grid-12">
              <div class="col-sp-7 col-tb-6 row-sp-12">
                <h3 class="ui header">
                  <div class="content">
                    Verifizieren
                    <div class="sub header"></div>
                  </div>
                </h3>
              </div>
              <div class="col-sp-5 col-tb-6 row-sp-12 t-right">
                <button onclick="$('#FakeCheckModal').modal('show');" class="circular ui basic mini button icon labeled verifyStart" v-if="profile.verify != 0">
                  <i class="icon check"></i> Los Geht's!
                </button>
                <div class="circular ui basic mini button icon labeled verifyPending" v-else>
                  <i class="icon clock"></i> wird überprüft.....
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="grid-16 display-Dektop">
        <div class="col-sp-16 row-sp-16 pad-16">
          <section id="ProfileTitleDektop">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-3 row-sp-12">
                <section id="ProfileImageDesktop">
                  <div class="profile-image-edit-cta">
                    <button onclick="$('#EditProfileImage.ui.modal').modal('show');" class="circular inverted ui basic mini button icon"  v-if="own">
                      <i class="icon edit"></i>
                    </button>
                  </div>
                  <img :src="projectURL + 'assets/images/user/' + profile.profile_img" v-if="profile.profile_img" class="ar-1-1">
                </section>
              </div>
              <div class="col-sp-12 col-sd-3 row-sp-12">
                <h2 class="ui header">
                  {{profile.username}}, {{profile.age}} <span v-if="profile.verify == 1" style="font-size: 0.7em; margin-right: -16px;" title="Der User ist von Junx verifiziert"><i class="check circle blue icon"></i></span>
                  <div class="sub header">
                    <i class="icon map marker alternate"></i> {{profile.cname}}
                    <a class="ui basic mini label OnlineStatus"  v-if="profile.online != 0">
                      <i class="circle green icon"></i>
                      Online
                    </a>
                    <a class="ui basic mini label OfflineStatus" data-hide="profile-lastActive" v-if="profile.lastActive != 0">
                      <i class="circle grey icon"></i>
                      <span class="OfflineStatus">{{profile.lastActive}}</span>
                    </a>
                  </div>
                </h2>
                <div class="ProfileBadgets">
                  <span class="ui image small label btn-color-1" v-if="profile.account_type == 'admin' || profile.account_type == 'supporter'" ><img src="assets/icons/favicon-32x32.png">Team</span>
                  <span class="ui image small label btn-color-1" v-if="profile.account_type == 'creator'" ><i class="star icon"></i>Creator</span>
                  <router-link to="/member" class="ui label premiumlabel" v-if="profile.premium_state != 0" ><i class="fire icon"></i>Unterstützer</router-link>
                </div>
              </div>
              <div class="col-sp-12 col-sd-6 row-sp-12">
                <section id="ProfileDekstopCTAs"  v-if="!own">
                  <div class="grid-12">
                    <div class="col-sp-3 row-sp-12" onclick="$('#StampModal').modal('show');">
                      <div class="profile-cta-img stampImg">
                        <img src="assets/images/emoticons/like.png" v-if="!ownCrush">
                        <img :src="'assets/images/komplimente/' + ownCrush" v-else>
                      </div>
                      <span>Stamp</span>
                    </div>
                    <router-link class="col-sp-3 row-sp-12" :to="'/messages/' + profile.username">
                      <div class="profile-cta-img">
                        <img src="assets/images/emoticons/chat.png">
                      </div>
                      <span>Chat</span>
                    </router-link>
                    <div class="col-sp-3 row-sp-12 videoRequestLink" @click="call()">
                      <div class="profile-cta-img">
                        <img src="assets/images/emoticons/videocall.png">
                      </div>
                      <span>Videocall</span>
                    </div>
                    <div class="col-sp-3 row-sp-12 followButton" @click="follow()">
                      <div class="profile-cta-img" :class="{'bg-color-1': followCheck}">
                        <img src="assets/images/emoticons/flag.png">
                      </div>
                      <span>{{followCheck ? 'Entfolgen' : 'Folgen'}}</span>
                    </div>
                  </div>
                </section>
                <div class="action-buttons">
                  <div onclick="$('#FollowerModal').modal('show');" class="ui labeled fluid button btn-inverted-color" tabindex="0">
                    <div class="ui button fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15)">
                      <i class="heart icon"></i> Follower
                    </div>
                    <a class="ui basic label">{{follower.counter}}</a>
                  </div>
                  <div class="pad-4"></div>
                  <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-if="!own && !friendCheck" @click="addFriend">
                    <i class="user add icon" ></i> Als Freund hinzufügen
                  </button>
                  <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own && friendCheck.accept == 0 && friendCheck.user_1 == user.id" @click="declineFriend(friendCheck.id)">
                    <i class="user cancel icon" ></i> Freundschaftsanfrage zurückziehen
                  </button>
                  <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own && friendCheck.accept == 0" @click="acceptFriend(friendCheck.id)">
                    <i class="user add icon" ></i> Freundschaftsanfrage annehmen
                  </button>
                  <button class="ui button icon fluid labeled btn-color-1 friendButton"  v-else-if="!own" @click="declineFriend(friendCheck.id)">
                    <i class="user cancel icon" ></i> Als Freund entfernen
                  </button>
                  <router-link class="ui button icon fluid labeled btn-color-1" to="/ep" v-if="own">
                    <i class="user edit icon"></i> Profil bearbeiten
                  </router-link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- Universal -->
      <div class="grid-16">
        <div class="col-sp-16 col-sd-5 row-sp-16 display-Dektop pad-16">
          <section id="ProfileDescriptionDesktop">
            <div class="description-content">
              <h6 class="ui header">Hey {{user.firstname}} <img src="assets/images/emoticons/hi.png"></h6>
              <p  v-if="notNull(profile.profile_description)">{{profile.profile_description}}</p>
            </div>
          </section>
          <div class="pad-8"></div>
          <section id="ProfileSocialMedia" class="ProfileSocialMedia" v-if="notNull(details.socialmedia_instagram) || notNull(details.socialmedia_linkedin) || notNull(details.socialmedia_tiktok) || notNull(details.socialmedia_youtube) || notNull(details.socialmedia_twitch) || notNull(details.socialmedia_twitter)">
            <div class="socialmedia-content" >
              <h6 class="ui header">Meine Social Media Kanäle </h6>
              <div class="grid-12">
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_instagram">
                  <a @click="redirect(details.socialmedia_instagram)"><img class="ui mini image" src="assets/images/social-media/instagram.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_linkedin">
                  <a @click="redirect(details.socialmedia_linkedin)"><img class="ui mini image" src="assets/images/social-media/linkedin.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_tiktok">
                  <a @click="redirect(details.socialmedia_tiktok)"><img class="ui mini image" src="assets/images/social-media/tiktok.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_youtube">
                  <a @click="redirect(details.socialmedia_youtube)"><img class="ui mini image" src="assets/images/social-media/youtube.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_twitch">
                  <a @click="redirect(details.socialmedia_twitch)" ><img class="ui mini image" src="assets/images/social-media/twitch.png" ></a>
                </div>
                <div class="col-sp-2 row-sp-12" v-if="details.socialmedia_twitter">
                  <a @click="redirect(details.socialmedia_twitter)"><img class="ui mini image" src="assets/images/social-media/twitter.png"></a>
                </div>
              </div>
            </div>
          </section>
          <div class="ui segment no-shadow no-border trending pad-24">
            <div class="content">
              <div class="grid-12">
                <div class="col-sp-7 col-tb-6 row-sp-12">
                  <h3 class="ui header">
                    <div class="content">
                      Freunde von
                      <div class="sub header">{{own?'dir':profile.firstname}}</div>
                    </div>
                  </h3>
                </div>
                <div class="col-sp-5 col-tb-6 row-sp-12 t-right">
                  <button onclick="$('#FriendsModal').modal('show');" class="circular ui basic mini button icon labeled">
                    <i class="icon users"></i> Alle ansehen
                  </button>
                </div>
              </div>
              <div class="pad-4"></div>
              <div class="grid-12">
                <friends v-for="(user,index) in allFriends.slice(0, 18)" :key="index" :user="user"></friends>
              </div>
            </div>
          </div>
          <div class="ui segment no-shadow no-border trending pad-24 verifyElement"  v-if="own && profile.verify != 1">
            <div class="content">
              <div class="grid-12">
                <div class="col-sp-7 col-tb-6 row-sp-12">
                  <h3 class="ui header">
                    <div class="content">
                      Verifizieren
                      <div class="sub header"></div>
                    </div>
                  </h3>
                </div>
                <div class="col-sp-5 col-tb-6 row-sp-12 t-right">
                  <button onclick="$('#FakeCheckModal').modal('show');" class="circular ui basic mini button icon labeled verifyStart" v-if="profile.verify != 0">
                    <i class="icon check"></i> Los Geht's!
                  </button>
                  <div class="circular ui basic mini button icon labeled verifyPending" v-else>
                    <i class="icon clock"></i> wird überprüft.....
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-16 col-sd-11 row-sp-16 pad-16">
          <div class="ui secondary pointing menu" style="margin-bottom: 0px;">
            <a @click="tab = 'info'" id="InfoTabMenu" class="item" :class="{active: tab == 'info'}">
              Infos
            </a>
            <a @click="tab = 'fragebogen'" id="FragebogenTabMenu" class="item" :class="{active: tab == 'fragebogen'}">
              Fragebogen
            </a>
            <a @click="tab = 'galerie'" id="GalerieTabMenu" class="item" :class="{active: tab == 'galerie'}">
              Galerie
            </a>
          </div>
          <div id="InfoTab" v-if="tab == 'info'">
            <section id="ProfileInfo">
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <img class="ui mini image" src="assets/images/emoticons/aussehen.png">
                      <div class="content">
                        Info
                        <div class="ui sub header">Über mich</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Vorname</div>
                        {{profile.firstname}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.pronome">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Pronomen</div>
                        {{details.pronome}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.gender">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Geschlecht</div>
                        {{details.gender}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.languages">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Ich spreche</div>
                        {{details.languages.replace(',',', ')}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.smoking_state">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Raucher</div>
                        {{details.smoking_state}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.working_as">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Tätigkeit</div>
                        {{details.working_as}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.lifestyle">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Lebensweise</div>
                        {{details.lifestyle}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.eye_color">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Augenfarbe</div>
                        {{details.eye_color}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.hair_color">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Haarfarbe</div>
                        {{details.hair_color}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.body_height">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Körper & Größe</div>
                        {{details.body}}, {{details.body_height}}cm
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-12" v-if="details.outing_state || details.sexuality || details.relation_state || partnerDetails.username || own">
                <div class="col-sp-8 row-sp-12">
                  <div class="pad-16"></div>
                  <div class="ui horizontal list">
                    <div class="item">
                      <img class="ui mini image" src="assets/images/emoticons/flag.png">
                      <div class="content">
                        Liebe
                        <div class="ui sub header">Coming out & Partner</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.sexuality">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Orientierung</div>
                        {{details.sexuality}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.outing_state">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Coming-Out</div>
                        {{details.outing_state}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.relation_state">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Beziehungsstatus</div>
                        {{details.relation_state}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="partnerDetails.username">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Partner</div>
                        <router-link :to="'/p/' + partnerDetails.username">
                          <img class="ui avatar image" :src="projectURL + 'assets/images/user/' + partnerDetails.profile_img">
                          {{partnerDetails.username}}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <div class="pad-16"></div>
                  <div class="ui horizontal list">
                    <div class="item">
                      <img class="ui mini image" src="assets/images/emoticons/haus.png">
                      <div class="content">
                        Aktueller Ort
                        <div class="ui sub header">Hier komme ich her</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="profile.cname">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Wohnort</div>
                        {{profile.cname}} <span  v-if="!own">({{profile.description}}km)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12" v-if="details.wohn_situation">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">Wohnsituation</div>
                        {{details.wohn_situation}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="ProfileInterests" class="grid-12">
              <div class="col-sp-8 row-sp-12">
                <h3 class="ui header">Interessen</h3>
              </div>
              <div class="col-sp-12 row-sp-12" id="interestsSame">
                  <a class="ui basic label" :class="{active: ownInterests.includes(interest.id)}" v-for="(interest, index) in interestsFiltered" :key="index">
                      {{ interest.channel_title }}
                      <img class="ui mini image" :src="projectURL + '/assets/images/channels/' + interest.channel_img">
                  </a>
              </div>
            </section>
          </div>
          <div id="FragebogenTab"  v-if="tab == 'fragebogen'">
            <section id="ProfileFragebogen" class="grid-12">
              <div class="col-sp-12 row-sp-12">
                <h3 class="ui header">Fragebogen</h3>
              </div>
              <div class="col-sp-12 row-sp-12" v-if="notNull(fragebogen.about_me)">
                <div class="ui horizontal list">
                  <div class="item">
                    <div class="content">
                      Über mich
                      <div class="ui sub header">{{fragebogen.about_me}}</div>
                    </div>
                  </div>
                </div>
                <div class="pad-16"></div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12" v-if="notNull(fragebogen.i_like)">
                <div class="ui horizontal list">
                  <div class="item">
                    <img class="ui mini image" src="assets/images/emoticons/like.png">
                    <div class="content">
                      <div class="ui sub header">Ich mag</div>
                      {{fragebogen.i_like}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12" v-if="notNull(fragebogen.i_hate)">
                <div class="ui horizontal list">
                  <div class="item">
                    <img class="ui mini image" src="assets/images/emoticons/dislike.png">
                    <div class="content">
                      <div class="ui sub header">Ich mag nicht</div>
                      {{fragebogen.i_hate}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12" v-if="notNull(fragebogen.favorite_food)">
                <div class="ui horizontal list">
                  <div class="item">
                    <img class="ui mini image" src="assets/images/emoticons/teller.png">
                    <div class="content">
                      <div class="ui sub header">Lieblingsessen</div>
                      {{fragebogen.favorite_food}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12" v-if="notNull(fragebogen.favorite_music)">
                <div class="ui horizontal list">
                  <div class="item">
                    <img class="ui mini image" src="assets/images/emoticons/noten.png">
                    <div class="content">
                      <div class="ui sub header">Lieblingsmusik</div>
                      {{fragebogen.favorite_music}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12" v-if="notNull(fragebogen.others_text)">
                <div class="ui horizontal list">
                  <div class="item">
                    <img class="ui mini image" src="assets/images/emoticons/lampe.png">
                    <div class="content">
                      <div class="ui sub header">Was ich sonst noch sagen möchte</div>
                      {{fragebogen.others_text}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12"  v-if="notNull(fragebogen.i_am_1) || notNull(fragebogen.i_am_2) || notNull(fragebogen.i_am_3) || notNull(fragebogen.i_am_4) || notNull(fragebogen.i_am_5)">
                <div class="pad-16"></div>
                <h5 class="ui header">
                  Ich bin
                </h5>
                <div class="grid-12" v-if="notNull(fragebogen.i_am_1)">
                  <div class="col-sp-6 row-sp-4">Schüchtern</div>
                  <div class="col-sp-6 row-sp-4 t-right">Forsch</div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div class="bar bg-color-1" :style="{width: fragebogen.i_am_1 + '%'}"></div>
                    </div>
                  </div>
                </div>
                <div class="grid-12" v-if="notNull(fragebogen.i_am_2)">
                  <div class="col-sp-6 row-sp-4">Häuslich</div>
                  <div class="col-sp-6 row-sp-4 t-right">Partygänger</div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div class="bar bg-color-1" :style="{width: fragebogen.i_am_2 + '%'}"></div>
                    </div>
                  </div>
                </div>
                <div class="grid-12" v-if="notNull(fragebogen.i_am_3)">
                  <div class="col-sp-6 row-sp-4">Unkreativ</div>
                  <div class="col-sp-6 row-sp-4 t-right">Kreativ</div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div class="bar bg-color-1" :style="{width: fragebogen.i_am_3 + '%'}"></div>
                    </div>
                  </div>
                </div>
                <div class="grid-12" v-if="notNull(fragebogen.i_am_4)">
                  <div class="col-sp-6 row-sp-4">Gemütlich</div>
                  <div class="col-sp-6 row-sp-4 t-right">Sportlich</div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div class="bar bg-color-1" :style="{width: fragebogen.i_am_4 + '%'}"></div>
                    </div>
                  </div>
                </div>
                <div class="grid-12" v-if="notNull(fragebogen.i_am_5)">
                  <div class="col-sp-6 row-sp-4">Ordentlich</div>
                  <div class="col-sp-6 row-sp-4 t-right">Chaot</div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div class="bar bg-color-1" :style="{width: fragebogen.i_am_5 + '%'}"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div id="GalerieTab"  v-if="tab == 'galerie'">
            <section id="ProfileGalerie" class="grid-12">
              <div class="col-sp-12 row-sp-12 t-right">
                <button class="ui basic icon button" onclick="$('#UploadImage.ui.modal').modal('show');"  v-if="own">
                  <i class="upload icon"></i> Bild hochladen
                </button>
                <div class="pad-8"></div>
              </div>
              <div class="col-sp-12 row-sp-12" id="GaleryGrid">
                <figure v-for="(img, index) in galery" :key="index">
                  <img :id="'galery_' + img.id" class="galeryPost" :src="projectURL + '/assets/images/posts/' + img.image" @click="window.wall.loadSingle(img.id)">
                </figure>
              </div>
            </section>
          </div>
          <emojimenu></emojimenu>
          <div id="resetWall" class="t-right" style="display:none">
            <button class="circular ui basic button icon labeled" type="button" onclick="window.wall.resetWall('profile');">
              <i class="cancel icon"></i>Wall zurücksetzen
            </button>
            <div class="pad-8"></div>
          </div>
          <div id="posts">
            <div class="rainbowloader" id="Postloader">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="FriendsModal" class="ui mini modal">
    <i class="close icon"></i>
    <div class="header" data-change="Deine Freunde-Alle Freunde">
    </div>
    <div class="content" style="padding: 0;">
      <div id="FriendsModalContacts">
        <user v-for="(user, index) in allFriends" :key="index" :user="user"></user>
      </div>
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('#FriendsModal').modal('hide');">
        Abbrechen
      </div>
    </div>
  </div>

  <div id="FollowerModal" class="ui mini modal">
    <i class="close icon"></i>
    <div class="header" data-change="Deine Follower-Alle Follower">
    </div>
    <div class="content" style="padding: 0;">
      <div id="FollowerModalContacts" >
        <user v-for="(user, index) in allFollows" :key="index" :user="user"></user>
      </div>
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('#FollowerModal').modal('hide');">
        Abbrechen
      </div>
    </div>
  </div>
  <!-- EditProfileImage Modal -->
  <div id="pbloader" style="z-index: 9999; display: none;" class="ui active inverted dimmer"  v-if="own">
    <div class="ui text loader">Bitte warten</div>
  </div>

  <div class="ui tiny modal" id="EditProfileImage"  v-if="own">
    <i class="close icon report"></i>
    <div class="header">
      Profilbild
    </div>
    <div class="scrolling content">
      <div class="grid-16">
        <div class="col-sp-16 col-tb-4 row-sp-16 pad-4">
          <div class="ui small centered image circular">
            <img class="ar-1-1" data-src="profile-profile_img">
          </div>
        </div>
        <div class="col-sp-16 col-tb-12 row-sp-16 pad-4">
          <form class="ui form" id="profileImgForm" @submit.prevent="profileImgUpload">
            <div class="field">
              <label>Profilbild:</label>
              <input type="file" id="datei" value="" name="postimage" accept="image/png, image/jpg, image/jpeg" @change="profileImgUpload">
            </div>
          </form>
          <div class="pad-8"></div>
          <button class="ui button labeled icon mini fluid" @click="deleteProfileImg()"><i class="trash icon"></i> Profilbild löschen</button>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="ui button" onclick="$('.ui.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui btn-color-3 right labeled icon button" onclick="document.getElementById('editProfileDescriptionForm').submit();">
        Speichern
        <i class="save icon"></i>
      </div>
    </div>
  </div>
  <!-- Stamp Menü -->
  <div id="StampModal" class="ui mini modal"  v-if="!own">
    <i class="close icon"></i>
    <div class="header">
      <i class="icon heart"></i> Sende einen Stamp
    </div>
    <div class="content scrolling">
      <div class="grid-12">
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(1)">
            <img src="assets/images/komplimente/hi.png">
          </div>
          <span>Hi!</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(2)">
            <img src="assets/images/komplimente/smile.png">
          </div>
          <span>Smile</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(3)">
            <img src="assets/images/komplimente/sweet.png">
          </div>
          <span>Cute</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(4)">
            <img src="assets/images/komplimente/kiss.png">
          </div>
          <span>Kuss</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(5)">
            <img src="assets/images/komplimente/flame.png">
          </div>
          <span>Hot</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img" @click="setCrush(6)">
            <img src="assets/images/komplimente/love.png">
          </div>
          <span>Love</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img bg-color-1" @click="setCrush(7)">
            <img src="assets/images/komplimente/pride.png">
          </div>
          <span>Pride</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img bg-color-1" @click="setCrush(8)">
            <img src="assets/images/komplimente/sun.png">
          </div>
          <span>Sunny</span>
        </div>
        <div class="col-sp-4 row-sp-12 pad-4">
          <div class="profile-cta-img bg-color-1" @click="setCrush(9)">
            <img src="assets/images/komplimente/date.png">
          </div>
          <span>Date?</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('#StampModal').modal('hide');">
        Abbrechen
      </div>
    </div>
  </div>

  <!--Video Modals -->
  <div class="ui mini modal" id="VideoCallRequest"  v-if="!own">
    <i class="close icon"></i>
    <div class="header">
      Videoanruf
    </div>
    <div class="content">
      <p>Möchtest du den Videoanruf wirklich starten?</p>
      <div id="startVideo"></div>
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('#VideoCallRequest').modal('hide');">
        Abbrechen
      </div>
      <button class="ui tiny button btn-color-1" id="startVideoButton" @click="call">
        <i class="icon video"></i>Videoanruf starten
      </button>
    </div>
  </div>
  <div class="ui mini modal" id="VideoCallInfo"  v-if="!own">
    <i class="close icon"></i>
    <div class="header">
      Videoanruf
    </div>
    <div class="content">
      <p>Du musst mit der Person befreundet sein um einen Videoanruf starten zu können.</p>
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('#VideoCallInfo').modal('hide');">
        Okay
      </div>
    </div>
  </div>
  <!-- Imageupload Modal -->
  <div id="UploadImage" class="ui tiny modal">
    <i class="close icon"></i>
    <div class="header">
      Bild hochladen
    </div>
    <div class="content scrolling">
      <form class="ui form" action="profile/uploadImage" enctype="multipart/form-data" id="postImageForm">
        <div id="post-image-upload" style="display: block;">
          <input type="file" accept="image/png, image/jpeg, image/jpg" name="postimage" id="postimage" required style="display: none;">
          <label for="postimage" class="ui button fluid" type="button">
            <i class="camera icon"></i> Bild auswählen
          </label>
        </div>
        <div id="preview-col" class="pimg pad-8" style="display: none;">
          <img id="output_image" class="responsive-img"/>
        </div>
        <div class="pad-8"></div>
        <div class="field">
          <label>Bildbeschreibung</label>
          <textarea id="postText" rows="4" name="postcontent" placeholder="Bildbeschreibung"></textarea>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input type="checkbox" tabindex="0" class="hidden" name="postintimeline">
            <label>Dieses Bild auch auf meine Timeline posten.</label>
          </div>
        </div>
        <div class="t-right">
          <button class="ui right labeled icon button btn-color-1"  type="submit" id="UploadtoGalerybtn" onclick="UploadtoGalery();">
            Zur Galerie hinzufügen
            <i class="upload icon"></i>
          </button>

        </div>
      </form>
      <div class="pad-16"></div>
    </div>
  </div>

  <div class="page-profile iBlock spaceTop" v-if="status == 'iBlock'">
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">Du hast dieses Profil blockiert.</div>
        </h2>
        <form method="post" class="editProfileForm" action="profile/entblockUser">
          <input type="hidden" name="u" :value="profile.username">
          <input type="hidden" name="uid"  :value="profile.id">
          <button class="ui button btn-color-1">
            <i class="check icon"></i> Benutzer freigeben
          </button>
        </form>
        <div class="pad-8"></div>
        <a @click="this.$router.back()" class="ui button btn-color-1">Zurück</a>
      </div>
    </div>
  </div>

  <div class="page-profile otherBlock spaceTop" v-if="status == 'otherBlock'">
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">Du wurdest von diesem Nutzer blockiert.</div>
        </h2>
        <a @click="this.$router.back()" class="ui button btn-color-1">Zurück</a>
      </div>
    </div>
  </div>

  <div class="page-profile notFound spaceTop" v-if="status == 'notFound'">
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">Das Profil konnte nicht gefunden werden.</div>
        </h2>
        <a @click="this.$router.back()" class="ui button btn-color-1">Zurück</a>
      </div>
    </div>
  </div>
</template>

<script>
import { initProfile } from '@/modules/profile'
import Emojimenu from '@/components/plugins/emojimenu'
import friends from '@/components/profile/friends'
import User from '@/components/profile/user'
import { decode, cookieLogin, resetApp } from '@/modules/main'
import { Wall } from '@/modules/wall'
import axios from 'axios'
import router from '@/router'
import PullToRefresh from 'pulltorefreshjs'

export default {
  name: 'profile',
  components: {
    User,
    friends,
    Emojimenu
  },
  data () {
    return {
      username: '',
      projectURL: window.projectURL,
      status: null,
      profile: [],
      details: [],
      partnerDetails: [],
      allFriends: [],
      allFollows: [],
      visitors: [],
      interests: [],
      follower: [],
      otherInterests: [],
      ownInterests: [],
      fragebogen: [],
      countrys: [],
      storyCategorys: [],
      show: [],
      crushes: [],
      ownCrush: [],
      friendCheck: [],
      relationships: [],
      followCheck: [],
      galery: [],
      story: [],
      tab: 'info',
      tempImg: false
    }
  },
  computed: {
    own () {
      try {
        return this.profile.id === localStorage.getItem('user_id')
      } catch (e) {
        return false
      }
    },
    interestsFiltered () {
      const _this = this
      const filtered = []
      return this.interests.filter(el => el.channel_type === 'channel' && Object.values(_this.otherInterests).some(function (k) { return k.id === el.id }))
    }
  },
  props: ['user'],
  methods: {
    loadProfile () {
      const _this = this
      $('.modal').modal('hide')
      initProfile(this.username)
        .then(function (data) {
          // console.log(data)
          _this.status = data.status
          _this.profile = data.profile
          _this.details = data.details
          _this.partnerDetails = data.partnerDetails
          _this.allFriends = data.allFriends
          _this.allFollows = data.allFollows
          _this.visitors = data.visitors
          _this.interests = data.interests
          _this.follower = data.follower
          _this.otherInterests = data.otherInterests
          _this.ownInterests = data.ownInterests.split(',')
          _this.fragebogen = data.fragebogen
          _this.countrys = data.countrys
          _this.storyCategorys = data.storyCategorys
          _this.show = data.show
          _this.crushes = data.crushes
          _this.ownCrush = data.ownCrush
          _this.friendCheck = data.friendCheck
          _this.relationships = data.relationships
          _this.followCheck = data.followCheck
          _this.galery = data.galery
          _this.story = data.story

          _this.profile.profile_description = decode(_this.profile.profile_description)
          _this.fragebogen.about_me = decode(_this.fragebogen.about_me)
          _this.fragebogen.i_like = decode(_this.fragebogen.i_like)
          _this.fragebogen.i_hate = decode(_this.fragebogen.i_hate)
          _this.fragebogen.others_text = decode(_this.fragebogen.others_text)
          _this.fragebogen.favorite_music = decode(_this.fragebogen.favorite_music)
          _this.fragebogen.favorite_food = decode(_this.fragebogen.favorite_food)

          _this.tab = 'info'

          window.junx.loading = false

          $('#posts').empty()
          window.wall = new Wall('profile', _this.profile.username)
        })
        .catch(function (e) {
          console.log(e)
          cookieLogin().then(_this.loadProfile).catch(function () {
            resetApp()
            _this.$router.push('/login')
          })
        })
      PullToRefresh.init({
        mainElement: document.querySelector('#ptrest'),
        triggerElement: document.querySelector('#ptrPull'),
        instructionsPullToRefresh: 'ziehen...',
        instructionsReleaseToRefresh: 'Loslassen...',
        instructionsRefreshing: 'aktualisiere...',
        iconArrow: '<i class="fas fa-angle-down fa-2x"></i>',
        shouldPullToRefresh () {
          return !window.junx.chatModal
        },
        onRefresh () {
          window.location.reload()
        }
      })
    },
    notNull (obj) {
      return obj !== null && obj !== undefined && obj !== 'null' && obj !== 'undefined'
    },
    redirect (link) {
      this.$router.push('/redirect/?l=' + link)
    },
    setCrush (cid) {
      const _this = this
      const formdata = new FormData()
      formdata.append('SessionID', sessionStorage.getItem('SessionID'))
      formdata.append('cid', cid)
      formdata.append('pid', this.profile.id)
      axios.post(window.projectURL + 'profile/setCrush', formdata)
        .then(function (response) {
          if (response.status === 250) {
            console.log(response.data)
            _this.ownCrush = response.data.crush
            $('#StampModal').modal('hide')
          } else if (response.status === 299) {
            cookieLogin().then(function () { _this.setCrush(cid) }).catch(function () { _this.$router.push('/login') })
          } else {
            $('#StampModal').modal('hide')
          }
        })
    },
    deleteProfileImg () {
      const _this = this
      const formdata = new FormData()
      formdata.append('postkey', sessionStorage.getItem('postkey'))
      formdata.append('SessionID', sessionStorage.getItem('SessionID'))
      axios.post(window.projectURL + 'profile/deleteProfileImage', formdata)
        .then(function (response) {
          if (response.status === 250) {
            try {
              localStorage.setItem('profile_img', 'avatar_default.png')
              window.junx.user.profile_img = 'avatar_default.png'
              _this.profile.profile_img = 'avatar_default.png'
            } catch (e) {}
          } else if (response.status === 299) {
            cookieLogin().then(_this.deleteProfileImg).catch(window.junx.$router.push('/login'))
          }
        })
    },
    profileImgUpload () {
      const _this = this
      window.junx.loading = true
      const formdata = new FormData(document.getElementById('profileImgForm'))
      formdata.append('postkey', sessionStorage.getItem('postkey'))
      formdata.append('SessionID', sessionStorage.getItem('SessionID'))
      $('#EditProfileImage').modal('hide')
      axios.post(window.projectURL + 'p/uploadProfileImage', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            try {
              _this.tempImg = response.data
              setTimeout(function () {
                _this.cropper()
              }, 10)
              window.junx.loading = false
              $('#cropperModal').modal('show')
            } catch (e) {
              console.log(e)
            }
          } else if (response.status === 299) {
            cookieLogin().then(() => { $(this).submit() }).catch(window.junx.$router.push('/login'))
          }
        })
    },
    cropper () {
      const _this = this
      const Cropper = window.Cropper
      const minCroppedWidth = 100
      const minCroppedHeight = 100

      const cropper = new Cropper(document.getElementById('target'), {
        aspectRatio: 1 / 1,
        zoomable: false,
        crop (event) {
          if (
            event.detail.width < minCroppedWidth ||
            event.detail.height < minCroppedHeight
          ) {
            cropper.setData({
              width: minCroppedWidth,
              height: minCroppedHeight
            })
          }
          document.getElementById('savecrop').classList.remove('disabled')
          document.getElementById('x').value = event.detail.x
          document.getElementById('y').value = event.detail.y
          document.getElementById('w').value = event.detail.width
          document.getElementById('h').value = event.detail.height
        }
      })
      _this.loading = false
    },
    crop () {
      const _this = this
      window.junx.loading = true
      const formdata = new FormData(document.getElementById('cropForm'))
      formdata.append('postkey', sessionStorage.getItem('postkey'))
      formdata.append('SessionID', sessionStorage.getItem('SessionID'))
      $('#cropperModal').modal('hide')
      axios.post(window.projectURL + 'p/crop', formdata)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            try {
              window.junx.user.profile_img = response.data
              _this.profile.profile_img = response.data
              window.junx.loading = false
            } catch (e) {
              console.log(e)
            }
          } else if (response.status === 299) {
            cookieLogin().then(() => { $(this).submit() }).catch(window.junx.$router.push('/login'))
          }
        })
    },
    addFriend () {
      const _this = this
      const postData = new FormData()
      postData.append('SessionID', sessionStorage.getItem('SessionID'))
      postData.append('postkey', sessionStorage.getItem('postkey'))
      postData.append('profileuser', this.profile.username)

      axios.post(this.projectURL + 'profile/friendRequest', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = { accept: 0, user_1: _this.user.id }
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.addFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    acceptFriend (id) {
      const _this = this
      const postData = new FormData()
      postData.append('SessionID', sessionStorage.getItem('SessionID'))
      postData.append('postkey', sessionStorage.getItem('postkey'))
      postData.append('id', id)

      axios.post(this.projectURL + 'profile/friendRequestAccept', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = { accept: 1, user_1: _this.profile.id, id: response.data }
            if (_this.allFriends.length < 12) {
              _this.allFriends.push({
                account_public: 1,
                firstname: _this.user.firstname,
                friendshipid: id,
                id: _this.user.id,
                online: 1,
                profile_img: _this.user.profile_img,
                username: _this.user.username
              })
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.acceptFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    declineFriend (id) {
      const _this = this
      const postData = new FormData()
      postData.append('SessionID', sessionStorage.getItem('SessionID'))
      postData.append('postkey', sessionStorage.getItem('postkey'))
      postData.append('id', id)

      axios.post(this.projectURL + 'profile/friendRequestDelete', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = false
            for (const key in _this.allFriends) {
              if (_this.allFriends[key].id === _this.user.id)_this.allFriends.splice(key, 1)
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.declineFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    follow () {
      const _this = this
      const postData = new FormData()
      postData.append('SessionID', sessionStorage.getItem('SessionID'))
      postData.append('postkey', sessionStorage.getItem('postkey'))
      postData.append('id', this.profile.id)
      const url = (this.followCheck) ? 'profile/defollow' : 'profile/follow'

      axios.post(this.projectURL + url, postData)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            _this.followCheck = !_this.followCheck
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.follow).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    call () {
      router.push({ name: 'Call', params: { room: '', username: this.profile.username, profile_img: this.profile.profile_img } })
    }
  },
  mounted () {
    this.username = this.$route.params.username
    document.title = 'Junx | ' + this.username
    this.loadProfile()
  },
  beforeRouteUpdate (to, from) {
    this.username = to.params.username
    document.title = 'Junx | ' + this.username
    this.loadProfile()
  }
}
</script>

<style lang="scss">
//original selectors
//#UserProfile #ProfileDekstopCTAs span, #UserProfile #ProfileCTAs span, #StampModal span
%extend_1 {
  text-align: center;
  color: $black_80;
  display: block;
  font-weight: 500;
  font-size: 0.9em;
  margin-top: 4px;
}

//original selectors
//#UserProfile #ProfileSocialMediaMobile .socialmedia-content, #UserProfile #ProfileSocialMedia .socialmedia-content
%extend_2 {
  background-color: $white;
  text-align: left;
  padding: 32px;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 24px;
  font-size: 0.9em;
}

//original selectors
//#UserProfile #ProfileSocialMediaMobile .socialmedia-content .ui.mini.image, #UserProfile #ProfileSocialMedia .socialmedia-content .ui.mini.image
%extend_3 {
  border: 2px solid $black_20;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 90px;
  padding: 3px;
}

//original selectors
//#UserProfile #ProfileInterests .ui.basic.label, #interests .ui.basic.label
%extend_4 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 24px;
  padding: 6px 12px!important;
  font-weight: 700;
  color: $black_60;
}

//original selectors
//#UserProfile #ProfileInterests .ui.basic.label.active, #interests .ui.basic.label.active
%extend_5 {
  background-color: $color_half_baked_approx;
  border: $color_half_baked_approx;
  color: $white_90;
  font-weight: 500;
}

//original selectors
//#UserProfile #ProfileInterests .ui.basic.label img.ui.mini.image, #interests .ui.basic.label img.ui.mini.image
%extend_6 {
  display: inline;
  max-width: 18px;
  vertical-align: text-top;
  max-height: 18px;
}

//original selectors
//#ProfileVisitorsContacts, #FriendsModalContacts, #FollowerModalContacts
%extend_7 {
  overflow-y: scroll;
  max-height: 300px;
  min-height: 300px;
  padding: 0;
}

#UserProfile {
  padding-bottom: 40px;
  #ProfileNav {
    padding: 16px 16px 0 16px;
    margin-bottom: 16px;
    a {
      cursor: pointer;
    }
  }
  #ProfileTitleDektop {
    text-align: left;
    padding: 32px;
    margin-bottom: -16px;
    margin: 0 auto;
    margin-top: 60px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 24px;
    background-color: $white;
    #OnlineStatus {
      background: transparent;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 16px;
    }
    #ProfileBadgets {
      margin-top: -8px;
    }
  }
  #ProfileTitle {
    text-align: center;
    padding: 32px 16px 16px;
    margin-bottom: -16px;
    margin: 0 auto;
    margin-top: -20px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 24px;
    background-color: $white;
    #OnlineStatus {
      background: transparent;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 16px;
    }
    #ProfileBadgets {
      margin-top: -8px;
    }
  }
  #ProfileImageDesktop {
    position: relative;
    img {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 24px;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      display: block;
      margin-top: -146px;
    }
    .profile-image-edit-cta {
      position: absolute;
      max-width: 250px;
      width: 100%;
      top: 16px;
      right: 0;
      left: 0;
      padding-right: 8px;
      z-index: 9;
      text-align: right;
      margin: 0 auto;
    }
  }
  #ProfileImage {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    img {
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 24px;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      display: block;
    }
    .profile-image-edit-cta {
      position: absolute;
      max-width: 350px;
      width: 100%;
      top: 16px;
      right: 0;
      left: 0;
      padding-right: 24px;
      z-index: 9;
      text-align: right;
      margin: 0 auto;
    }
  }
  #ProfileDekstopCTAs {
    max-width: 350px;
    width: 100%;
    margin-top: -110px;
    float: right;
    span {
      @extend %extend_1;
    }
  }
  .action-buttons {
    max-width: 350px;
    float: right;
    text-align: right;
  }
  #ProfileCTAs {
    padding: 16px;
    background-color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    margin-bottom: 16px;
    margin-top: 16px;
    .action-buttons {
      max-width: 100%;
      float: none;
      text-align: center;
      padding: 16px;
    }
    .grid-12 {
      max-width: 350px;
      width: 100%;
      margin: 0 auto;
    }
    span {
      @extend %extend_1;
    }
  }
  #ProfileInfo {
    background-color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 16px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0;
    .ui {
      &.header {
        margin-bottom: 8px;
      }
      &.horizontal.list .ui.header {
        margin-bottom: 0;
      }
    }
  }
  #ProfileFragebogen {
    background-color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 16px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0;
    .ui {
      &.header {
        margin-bottom: 8px;
      }
      &.horizontal.list .ui.header {
        margin-bottom: 0;
      }
    }
  }
  #ProfileInterests {
    background-color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 16px;
    .ui {
      &.header {
        margin-bottom: 8px;
      }
      &.basic.label {
        @extend %extend_4;
        &.active {
          @extend %extend_5;
        }
        img.ui.mini.image {
          @extend %extend_6;
        }
      }
    }
  }
  #ProfileGalerie {
    background-color: $white;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 16px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0;
    .ui.header {
      margin-bottom: 8px;
    }
  }
  #ProfileDescriptionDesktop .description-content {
    background-color: $color_half_baked_approx;
    color: $white_60;
    text-align: left;
    padding: 32px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    font-size: 0.9em;
    img {
      width: 20px;
      margin-top: -4px;
    }
    .ui.header {
      color: $white;
      text-align: left;
      margin-bottom: 8px;
      font-size: 1.2em;
      font-weight: normal;
    }
  }
  #ProfileDescription .description-content {
    background-color: $color_half_baked_approx;
    color: $white_60;
    text-align: center;
    padding: 32px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 24px;
    font-size: 0.9em;
    margin-bottom: -16px;
    img {
      width: 20px;
      margin-top: -4px;
    }
    .ui.header {
      color: $white;
      text-align: center;
      margin-bottom: 8px;
      font-size: 1.2em;
      font-weight: normal;
    }
  }
  #ProfileSocialMediaMobile .socialmedia-content {
    @extend %extend_2;
    .ui.mini.image {
      @extend %extend_3;
    }
  }
  #ProfileSocialMedia .socialmedia-content {
    @extend %extend_2;
    .ui.mini.image {
      @extend %extend_3;
    }
  }
  .ui.list .item {
    .content {
      font-weight: bold;
    }
    img.ui.mini.image {
      border: 2px solid $black_20;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 90%;
      padding: 3px;
    }
    .ui.sub.header {
      color: $black_40;
      text-transform: none;
    }
  }
}
.profile-cta-img {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 90%;
  border: 2px solid $black_10;
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 50px;
  vertical-align: middle;
  margin: 0 auto;
  cursor: pointer;
  background-color: $white;
  &:hover {
    border: 2px solid $color_fountain_blue_approx;
  }
  img {
    width: 100%;
    max-width: 48px;
  }
}
.contentLoading {
  visibility: hidden;
}
#ProfileVisitorsContacts {
  @extend %extend_7;
}
#FriendsModalContacts {
  @extend %extend_7;
}
#FollowerModalContacts {
  @extend %extend_7;
}
.contact-element {
  padding: 16px 16px 8px 16px;
  border-top: 1px solid $black_5;
  vertical-align: middle;
  cursor: pointer;
  .contact-profile-img {
    display: inline-block;
    position: relative;
    .onlinestatus {
      position: absolute;
      top: 14px;
      left: 32px;
    }
    .ui.avatar.image {
      height: 3em;
      width: 3em;
      margin-top: -32px;
    }
  }
  .contact-content {
    display: inline-block;
  }
  .contact-extra {
    display: inline-block;
    float: right;
    margin-top: -8px;
  }
  .ui.header i.check.icon {
    display: inline-block;
    font-size: 1em;
    margin-top: -5px;
  }
}
#GaleryGrid {
  //Instead of the line below you could use @include column-count($count)
  column-count: 3;
  //Instead of the line below you could use @include column-gap($width)
  column-gap: 8px;
  figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 8px;
    break-inside: avoid;
    > img {
      grid-row: 1 / -1;
      grid-column: 1;
      max-width: 100%;
      display: block;
    }
  }
}
.editProfileForm {
  padding: 32px 16px;
  .actions {
    margin-top: 16px;
    text-align: right;
  }
}
#StampModal span {
  @extend %extend_1;
}
#interests .ui.basic.label {
  @extend %extend_4;
  &.active {
    @extend %extend_5;
  }
  img.ui.mini.image {
    @extend %extend_6;
  }
}
/* Darkmode */
.dark #UserProfile #ProfileTitleDektop #OnlineStatus,.dark #UserProfile #ProfileTitle #OnlineStatus{border: 1px solid #ffffff;color: #ffffff;}
.dark #UserProfile #ProfileTitleDektop{background-color: #1B1C1D;}
.dark #UserProfile #ProfileTitle{background-color: #1B1C1D;}
.dark #UserProfile #ProfileCTAs{background-color: #1B1C1D;}
.dark .profile-cta-img{border: 2px solid rgba(0,0,0,0.1);background-color: #1B1C1D;}
.dark .profile-cta-img:hover{border: 2px solid #4bb7c3;}
.dark #UserProfile #ProfileDekstopCTAs span,.dark #UserProfile #ProfileCTAs span,.dark #StampModal span{color: rgba(255,255,255,0.8);}
.dark #UserProfile #ProfileDescriptionDesktop .description-content{background-color: #000000;color: rgba(255,255,255,0.6);}
.dark #UserProfile #ProfileDescription .description-content{background-color: #000000;color: rgba(255,255,255,0.6);}
.dark #UserProfile #ProfileDescriptionDesktop .description-content .ui.header{color: rgba(255,255,255,1);}
.dark #UserProfile #ProfileDescription .description-content .ui.header{color: rgba(255,255,255,1);}
.dark #UserProfile #ProfileSocialMediaMobile .socialmedia-content, .dark #UserProfile #ProfileSocialMedia .socialmedia-content{background-color: #1B1C1D;}
.dark .socialmedia-content .ui.image.mini{filter: invert(1);border: 2px solid rgba(0, 0, 0, 0.8)!important;}
.dark #UserProfile #ProfileSocialMediaMobile .socialmedia-content .ui.mini.image,
.dark #UserProfile #ProfileSocialMedia .socialmedia-content .ui.mini.image{border: 2px solid rgba(255,255,255,0.2);}
.dark #UserProfile #ProfileInfo{background-color: #1B1C1D;}
.dark #UserProfile .ui.list .item img.ui.mini.image{border: 2px solid rgba(255,255,255,0.2);}
.dark #UserProfile .ui.list .item .ui.sub.header{color: rgba(255,255,255,0.4);}
.dark #UserProfile #ProfileInterests{background-color: #1B1C1D;}
.dark #UserProfile #ProfileInterests .ui.basic.label,.dark #UserProfile #ProfileInterests .ui.basic.label:hover,.dark #interests .ui.basic.label,.dark #interests .ui.basic.label:hover{color: rgba(255,255,255,0.9);background-color: #141414;}
.dark #UserProfile #ProfileInterests .ui.basic.label.active,.dark #interests .ui.basic.label.active{background-color: #000000;border: #000000;color: rgba(255,255,255,0.9);}
.dark #UserProfile #ProfileFragebogen{background-color: #1B1C1D;}
.dark #ProfileFragebogen{color: rgba(255,255,255,1);}
.dark #UserProfile #ProfileGalerie{background-color: #1B1C1D;}
.dark .contact-element{border-top: 1px solid rgba(0,0,0,0.05);}
.dark .item .content{color: rgba(255,255,255,1);}
.dark .item .content .ui.sub.header{color: rgba(255,255,255,0.4);}
.dark .ui.basic.label.OnlineStatus{background-color: #000000; color: #ffffff;}
.dark .ui.basic.label.OfflineStatus{background-color: #000000; color: #ffffff;}
.dark .ui.label.premiumlabel{background-color: #000000; color: rgba(255,255,255,0.8);}
.dark .ui.labeled.fluid.button.btn-inverted-color .ui.basic.label{background-color: #000000; color: #ffffff;}
</style>
