<template>
  <section id="MessengerRightSide" style="display: block !important;"><div id="MessengerChatContent"><button id="loadMoreMessages" class="chat-cta-load" onclick="window.chat.loadMore();">Mehr Nachrichten laden</button>
    <div class="message" id="m-45087">
      <div class="message">
        <a class="message-profile-img" style="opacity: 1;">
          <img class="ui avatar image" src="https://app.junx.app/assets/images/user/d8ed30675e3da3570ffae9dddbc5b16d.jpg">
        </a>
        <div class="message-content">
          <div class="chat-content">
            <i class="play icon" @click="appPlayBack('7c225fb68cd6384eee3993110bd5d3dc8256523f.record', 45087)"></i>            </div>
          <div class="message-info">
            <div class="message-actions">
              <i class="icon check chat-read" style="opacity: 1;"></i>
              <i class="chat-heart chat-noHover chat-like-noheart"></i>
              <i class="chat-delete" onclick="window.chat.deleteMessage(45087)"></i>
            </div>
            <div class="chat-time">15:20</div>
          </div>
        </div>
      </div>
    </div>
    <div class="message" id="m-45497">
      <div class="message-right">
        <a class="message-profile-img" style="opacity: 1;">
          <img class="ui avatar image" src="https://app.junx.app/assets/images/user/d8ed30675e3da3570ffae9dddbc5b16d.jpg">
        </a>
        <div class="message-content">
          <div class="chat-content">
            <i class="play icon play-button" @click="appPlayBack('cfc934a817f9f9a15ea08b0ef61cfd1a44a5ce9d.mp3', '45497')"></i>
            <div class="time-container"></div>
            <div class="wave-container" id="w-45497">
            </div>
          </div>
          <div class="message-info">
            <div class="message-actions">
              <i class="icon check chat-read" style="opacity: 1;"></i>
              <i class="chat-heart chat-noHover chat-like-noheart"></i>
              <i class="chat-delete" @click="appPlayBack(45497)"></i>
            </div>
            <div class="chat-time">16:01</div>
          </div>
        </div>
      </div>
    </div>
  </div><div id="chat-blocked" style="display: none;"></div></section>
</template>

<script>
import { initAudioPlayer } from '@/modules/record'
export default {
  name: 'test',
  data () {
    return {
      status: 'start',
      src: 'cdvfile://localhost/temporary/memo.',
      extension: process.env.CORDOVA_PLATFORM === 'ios' ? 'wav' : 'aac',
      media: null,
      duration: 0,
      interval: null,
      time: null,
      disabled: false
    }
  },
  methods: {
    appPlayBack (src, id) {
      initAudioPlayer(src, id, null)
    }
  },
  mounted () {
  }
}
</script>

<style>
.recordNewSegment {
  fill: #8c8c8c;
}
.recordNewSegment.played {
  fill: #4bb7c3;
}
path {
  fill: rgba(75,183,195,0.5)
}
svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 190px;
  height: 190px;
}
</style>
