<template>
  <section id="MagazinBeitrag">
    <div class="content-1400">
      <blog-article :article="article"></blog-article>
    </div>
  </section>
</template>

<script>
import blogArticle from '@/components/blog/article'
export default {
  name: 'article',
  components: {
    blogArticle
  },
  data () {
    return {
      projectURL: window.projectURL,
      article: false,
      slag: this.$route.params.id
    }
  },
  methods: {
    loadArticle () {
      const _this = this
      console.log(this.$route.params.id)
      $.post(this.projectURL + 'blog/singleArticle', { SessionID: sessionStorage.getItem('SessionID'), mid: this.slag }, function (msg, status, xhr) {
        try {
          if (xhr.status === 250) {
            const data = JSON.parse(msg)
            if (data) {
              _this.article = data
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { _this.openArticle(mid) }).catch(function () { openLogin('blog') })
          }
          window.junx.loading = false
        } catch (e) { console.log(e) }
      })
    }
  },
  mounted () {
    this.loadArticle()
  },
  beforeRouteUpdate (to, from) {
    this.slag = to.params.id
    window.junx.loading = true
    this.loadArticle()
  }
}
</script>

<style scoped>

</style>
