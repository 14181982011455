<template>
  <div id="page-impressum" class="spaceTop">
    <div class="content-1200 grid-16 pad-16">
      <div class="col-sp-16 row-sp-16 pad-8">
        <h1 class="ui header">Impressum</h1>
      </div>
      <div class="col-sp-16 col-tb-6 row-sp-16 pad-8">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>PrideDigital GmbH<br />
          Scholtzstraße 6<br />
          21465 Reinbek</p>
        <p>Handelsregister:  HRB 21562 HL<br />
          Registergericht: Amtsgericht Lübeck
        </p>
        <p><strong>Vertreten durch:</strong><br />
          Dennis Bleise</p>
        <h2>Kontakt</h2>
        <p>Telefon: +49 (0) 40 2263 872 40<br />
          E-Mail: hello@pridedigtial.de</p>
        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
          folgt</p>
        <h2>Redaktionell Verantwortlicher</h2>
        <p>Dennis Bleise<br />
          Scholtzstraße 6<br />
          21465 Reinbek</p>
        <h2>EU-Streitschlichtung</h2>
        <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
          <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br /> Unsere E-Mail-Adresse finden
          Sie oben im Impressum.</p>
        <h2>Verbraucher-streit-beilegung/Universal-schlichtungs-stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.</p>
      </div>
      <div class="col-sp-16 col-tb-10 row-sp-16 pad-8">
        <h3>Haftung für Inhalte</h3> <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
        eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
        bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die
        auf eine rechtswidrige Tätigkeit hinweisen.</p> <p>Verpflichtungen zur Entfernung oder
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
        unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
        konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p> <h3>Haftung für
        Links</h3> <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
        wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
        übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder

        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
        Zeitpunkt der Verlinkung nicht erkennbar.</p> <p>Eine permanente inhaltliche Kontrolle der
        verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
        zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
        umgehend entfernen.</p> <h3>Urheberrecht</h3> <p>Die durch die Seitenbetreiber
        erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
        Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
        bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
        kommerziellen Gebrauch gestattet.</p> <p>Soweit die Inhalte auf dieser Seite nicht vom
        Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
        Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
        umgehend entfernen.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'impressum',
  title: 'Junx | Impressum',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-impressum{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
/* Darkmode */
.dark #page-impressum{
  color: rgba(255,255,255,0.8);
}
</style>
