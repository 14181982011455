<template>
  <form class="ui form editProfileForm" @submit.prevent="sendForm" id="locationForm">
    <div class="content">
      <div class="field">
        <label>Land:</label>
        <div id="countrys" class="ui fluid search selection dropdown country">
          <input type="hidden" name="country">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" :data-value="country.country" v-for="(country, index) in userdata.countrys" :key="index">
              <div class="scimd">
                <img :src="'assets/images/flags/' + country.flag.toLowerCase() + '.png'">
              </div>
              <div class="sct">
                &nbsp;{{country.country}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Wohnort:</label>
        <div id='wohnort' class="ui fluid search selection dropdown city">
          <input type="hidden" name="city">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
          </div>
        </div>
      </div>
      <div class="field">
        <label>Wohnsituation:</label>
        <div class="ui fluid selection dropdown wohn_situation">
          <input type="hidden" name="wohnsituation">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Bei der Familie">Bei der Familie</div>
            <div class="item" data-value="Eigene Wohnung / Haus">Eigene Wohnung / Haus</div>
            <div class="item" data-value="Wohngemeinschaft">Wohngemeinschaft</div>
            <div class="item" data-value="Wohnheim / Internat">Wohnheim / Internat</div>
            <div class="item" data-value="Hotels">Hotels</div>
            <div class="item" data-value="Wohnungslos">Wohnungslos</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" id="submit" style="display: none;"></button>
  </form>
</template>

<script>
import { getCities } from '@/modules/profile'
import { clickElement } from '@/modules/main'
import axios from 'axios'
export default {
  name: 'location',
  props: ['userdata'],
  data () {
    return {
      reload: false,
      send: false
    }
  },
  methods: {
    sendForm () {
      this.reload = true
      this.send = false
      const formData = new FormData(document.querySelector('#locationForm'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'profile/editProfileWohnortdetails', formData)
    },
    submit () {
      clickElement(document.querySelector('#submit'))
    }
  },
  mounted () {
    const _this = this
    $('.wohn_situation').dropdown('set selected', this.userdata.details.wohn_situation)
    $('.country').dropdown('set selected', this.userdata.profile.ccountry)

    $('#countrys').dropdown('setting', 'onChange',
      function (value, text, $selectedItem) {
        $('#wohnort').dropdown({
          apiSettings: {
            // this url parses query server side and returns filtered results
            url: window.projectURL + 'other/getcities/?country=' + value + '&city={query}',
            cache: false
          }
        })
      })

    const country = this.userdata.profile.ccountry
    $('#wohnort').dropdown({
      apiSettings: {
        // this url parses query server side and returns filtered results
        url: window.projectURL + 'other/getcities/?country=' + country + '&city={query}',
        cache: false
      }
    })

    $('.wohn_situation').dropdown('setting', 'onChange', function () {
      _this.submit()
    })

    getCities(country, this.userdata.profile.cname, this.userdata.profile.cname, this)

    setTimeout(function () {
      $('.city').dropdown('setting', 'onChange', function () {
        _this.submit()
      })
    }, 1000)

    window.junx.loading = false
  },
  beforeRouteLeave (to, from, next) {
    const _this = this
    if (this.reload) {
      _this.$emit('resetdata', '')
    }
    next()
  }
}
</script>

<style scoped>

</style>
