<template>
  <div class="col-sp-4 col-tb-3 col-sd-2 row-sp-12 pad-8">
     <video class="responsive-img br-16" :src="projectURL + 'assets/videos/magazin/'+video.video_src" :poster="projectURL + 'assets/videos/magazin/thumbnails/'+video.video_thumbnail" controls></video>
  </div>
</template>

<script>
export default {
  name: 'video',
  props: ['video'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>

</style>
