<template>
  <div id="page-login">
    <div id="page-login-header">
      <div class="header-content">
        <a href="/login">
          <img src="assets/images/dark-junx-beta-pride.png">
        </a>
      </div>
    </div>
    <div id="page-login-video">
      <video id="bgvideo" preload="auto" autoplay playsinline muted loop>
        <source type="video/mp4" id="bgvideosource">
      </video>
    </div>
    <div id="page-login-footer">
      <div class="cta-content">
        <button onclick="$('#LoginModal').modal('show');" class="ui button circular large fluid btn-color-1">
          Einloggen
        </button>
        <div class="ui divider inverted horizontal">oder</div>
        <button onclick="$('#RegisterModal').modal('show');" class="ui button circular large fluid btn-color-2">
          kostenlos anmelden
        </button>
        <ul>
          <li>
            <router-link to="/impressum">Impressum</router-link>
          </li>
          <li>
            <router-link to="/datenschutz">Datenschutz</router-link>
          </li>
          <li>
            <router-link to="/nutzungsbedingungen">Nutzungsbedingungen</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Modals -->
  <div id="LoginModal" class="ui mini modal">
    <div class="content">
      <div class="ui negative message" id="loginFail">
        <i class="close icon"></i>
        <div class="header">
          Ups!
        </div>
        <p>Email oder Passwort falsch</p>
      </div>
      <form class="ui form"  id="loginForm">
        <h2 class="ui header">
          <img src="assets/images/emoticons/hi.png">
          <div class="content">
            Hey
            <div class="sub header">Bitte logge dich ein</div>
          </div>
        </h2>
        <div class="field">
          <label>E-Mail oder Benutzername</label>
          <input type="text" name="email" placeholder="E-Mail oder Benutzername" required autofocus autocomplete="username">
        </div>
        <div class="field">
          <label style="float: left;">Passwort</label>
          <input type="password" name="loginpassword" placeholder="Passwort" required autocomplete="current-password">
        </div>
        <div class="t-right">
          <div class="pad-4"></div>
          <a onclick="$('#PasswortForgetModal').modal('show');" class="password-forget">Passwort vergessen?</a>
        </div>
        <div class="field">
          <div class="ui checkbox" id="keepIn">
            <input type="checkbox" tabindex="0" class="hidden" name="stayIn">
            <label>Angemeldet bleiben</label>
          </div>
        </div>
        <div class="pad-8"></div>
        <button type="submit" class="ui button circular large fluid btn-color-1" >
          Einloggen
        </button>
        <div class="pad-4"></div>
        <a onclick="$('#LoginModal').modal('hide');" class="ui button circular large fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15);">
          Abbrechen
        </a>
      </form>
    </div>
  </div>
  <div id="PasswortForgetModal" class="ui mini modal">
    <div class="content">
      <div class="ui success message" id="passwordForgetSuccess">
        <i class="close icon"></i>
        <div class="header">
          Passwort zurückgesetzt
        </div>
        <p>Bitte überprüfe dein E-Mail Postfach um fortzufahren.</p>
      </div>
      <div class="ui negative message" id="passwordForgetFail">
        <i class="close icon"></i>
        <div class="header">
          Ups!
        </div>
        <p>Diese E-Mail ist uns nicht bekannt.</p>
      </div>
      <form class="ui form" id="passwordResetForm">
        <input type="hidden" name="type" value="password" required>
        <h2 class="ui header t-center">
          <div class="content">
            Passwort zurücksetzen
            <div class="sub header">Bitte trage deine E-Mail Adresse ein</div>
          </div>
        </h2>
        <div class="field">
          <label>E-Mail</label>
          <input type="email" name="email" placeholder="E-Mail" required autofocus>
        </div>
        <div class="pad-8"></div>
        <button type="submit" class="ui button circular large fluid btn-color-1">
          Passwort zurücksetzen
        </button>
        <div class="pad-4"></div>
        <a onclick="$('#PasswortForgetModal').modal('hide');" class="ui button circular large fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15);">
          Abbrechen
        </a>
      </form>
    </div>
  </div>
  <div id="PasswortResetModal" class="ui mini modal">
    <div class="content">
      <div class="ui negative message" id="restorePasswordFail">
        <i class="close icon"></i>
        <div class="header">
          Ups!
        </div>
        <p>Ein Fehler ist aufgetreten.</p>
      </div>
      <div class="ui negative message" id="restoreKeyFail">
        <i class="close icon"></i>
        <div class="header">
          Ups!
        </div>
        <p>Der Code stimmt nicht.</p>
      </div>
      <form class="ui form" method="post" id="restorePasswordForm">
        <h2 class="ui header">
          <div class="content">
            Passwort zurücksetzen
            <div class="sub header">Bitte lege dein Passwort fest</div>
          </div>
        </h2>
        <input type="hidden" name="type" value="login" required>
        <div class="field">
          <label>Passwort</label>
          <input type="password" name="password" id="inputPassword" placeholder="Passwort" required minlength="8" autocomplete="new-password">
        </div>
        <div class="field">
          <label>Passwort wiederholen</label>
          <input type="password" name="passwordwdh" id="inputPassword2" placeholder="Passwort wiederholen" required minlength="8" autocomplete="new-password">
        </div>
        <div class="pad-8"></div>
        <button type="submit" class="ui button circular large fluid btn-color-1">
          Passwort zurücksetzen
        </button>
        <div class="pad-4"></div>
        <a onclick="$('#PasswortResetModal').modal('hide');" class="ui button circular large fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15);">
          Abbrechen
        </a>
      </form>
    </div>
  </div>
  <div id="RegisterModal" class="ui mini modal">
    <div class="content">
      <div class="ui negative message" id="regEmail">
        <i class="close icon"></i>
        <div class="header">
          Diese E-Mail oder Username sind vergeben oder ungültig.
        </div>
        <p>Diese Email-Adresse ist ungültig oder es besteht bereits ein Account mit dieser E-Mail-Adresse oder Usernamen.</p>
      </div>
      <div class="ui negative message" id="regBot">
        <i class="close icon"></i>
        <div class="header">
          Ups! Bist du ein Bot? &#129302;
        </div>
        <p style="overflow-wrap: break-word;">Falls ja: 01000100011001010100100101001110011001010010000001001101011101010100010001100100011000010010000001001001010100110111010000100000011001010110100101001110001000000101010001101111011000010101001101110100010001010101001000100001</p>
        <p> Falls nein: versuche es später noch einmal oder <a href="mailto:Support@junx.app">kontaktiere den Support.</a> &#128535;</p>
      </div>
      <div class="ui negative message" id="regFail">
        <i class="close icon"></i>
        <div class="header">
          Ups! Da ging was schief!
        </div>
        <p>Überprüfe deine eingaben und versuche es erneut. Falls du weiterhin Probleme haben solltest, zögere nicht den <a href="mailto:Support@junx.app">Support zu kontaktieren.</a></p>
      </div>
      <div class="ui negative message" id="regAge">
        <i class="close icon"></i>
        <div class="header">
          Leider ist die Anmeldung nicht möglich
        </div>
        <p>Du bist leider außerhalb des Zielgruppen alters (14-29 Jahre).</p>
      </div>
      <div class="ui negative message" id="toShort" style="display: none;">
        <div class="header">
          Dein Passwort ist leider zu kurz.
          &#x1F346;
        </div>
      </div>
      <div id="noMatch" class="ui negative message">
        <i class="close icon"></i>
        <div class="header">
          Ups! Bitte überprüfe deine Eingaben
        </div>
        <p>Deine Passwörter sind nicht identisch oder zu kurz.</p>
      </div>
      <form class="ui form" action="register/doRegister" method="post" id="regform">
        <h2 class="ui header">
          <div class="content">
            Registration
            <div class="sub header">Bitte fülle die folgenden Felder aus</div>
          </div>
        </h2>
        <div class="field">
          <label>Vorname</label>
          <input type="text" name="firstname" placeholder="Vorname" pattern="[a-zA-Z0-9\szÄäÖöÜüß\-]+" required >
        </div>
        <div class="field">
          <label>E-Mail</label>
          <input type="email" name="email" placeholder="E-Mail" required >
        </div>
        <div class="field">
          <label>Benutzername</label>
          <input type="text" name="username" placeholder="Username" pattern="[\w]+" required >
        </div>
        <div class="field">
          <label>Passwort (mindestens 8 Zeichen)</label>
          <input type="password" minlength="8" id="pw1" placeholder="Passwort" name="registerpassword" required>
        </div>
        <div class="field">
          <label>Passwort wiederholen</label>
          <input type="password" minlength="8" id="pw2" placeholder="Passwort" name="registerpasswordwdh" required>
        </div>
        <div class="field">
          <div class="ui right labeled left icon input">
            <i class="birthday icon"></i>
            <input type="date" placeholder="Geburtstag" name="dob" required>
            <a class="ui tag label">
              Geburtstag
            </a>
          </div>
          <div class="ui mini label">Junx ist exklusiv für Jungs* zwischen 14 und 29 Jahre</div>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input type="checkbox" tabindex="0" class="hidden" required>
            <label>Ich habe die <a href="datenschutz" target="_blank">Datenschutzerklärung</a>, sowie die <a href="nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> gelesen und akzeptiere diese.</label>
          </div>
        </div>
        <div class="pad-8"></div>
        <button class="ui button circular large fluid btn-color-1" type="submit">
          Registrieren
        </button>
        <div class="pad-4"></div>
        <button onclick="$('#RegisterModal').modal('hide');" class="ui button circular large fluid btn-inverted-color" style="border: 1px solid rgba(34, 36, 38, 0.15);">
          Abbrechen
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { findGetParameter, cookieLogin, setJunx } from '@/modules/main'
import { pushToken, pushWebToken, firebaseSetup } from '@/modules/notification'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'login',
  title: 'Junx | Login',
  props: ['subpage'],
  date () {
    return {
      open: false
    }
  },
  mounted () {
    const videoItems = ['assets/videos/login-background-video-1.mp4', 'assets/videos/login-background-video-5.mp4']
    $('#bgvideosource').attr('src', videoItems[Math.floor(Math.random() * videoItems.length)])

    const url = window.projectURL + 'login/jsLogin'
    const loader = $('.rainbowloader')
    const content = $('.content')
    const passwordReset = $('.passwordReset')
    const passwordResetSuccess = $('#passwordForgetSuccess')
    const passwordResetFail = $('#passwordForgetFail')
    const passwordResetForm = $('#passwordResetForm')
    const passwordRestore = $('.passwordRestore')
    const restorePasswordFail = $('#restorePasswordFail')
    const restoreKeyFail = $('#restoreKeyFail')
    const restorePasswordForm = $('#restorePasswordForm')
    const mainLogin = $('.mainLogin')
    const loginFail = $('#loginFail')
    const loginForm = $('#loginForm')
    const noMatch = $('#noMatch')
    const toShort = $('#toShort')
    const regFail = $('#regFail')
    const regBot = $('#regBot')
    const regEmail = $('#regEmail')
    const regAge = $('#regAge')
    const regform = $('#regform')
    const pw1 = $('#pw1')
    const pw2 = $('#pw2')
    const fsubmit = $('#fsubmit')
    const error = new Error('doof')
    const _this = this

    function init () {
      passwordResetSuccess.hide()
      passwordResetFail.hide()
      restorePasswordFail.hide()
      restoreKeyFail.hide()
      loginFail.hide()
      noMatch.hide()
      toShort.hide()
      regFail.hide()
      regBot.hide()
      regAge.hide()
      regEmail.hide()
      loader.hide()

      if (_this.$route.query.page === 'passwordforget') {
        $('#PasswortForgetModal').modal('show')
      } else if (_this.$route.query.page === 'resetpassword') {
        $('#PasswortResetModal').modal('show')
      } else if (_this.$route.query.page === 'register') {
        $('#RegisterModal').modal('show')
      }

      $('.dropdown').dropdown({ transition: 'drop' })
      $('.message .close').on('click', function () { $(this).closest('.message').transition('fade') })
      $('.ui.checkbox').checkbox()

      var elements = document.getElementsByClassName('openpostmodal')
      var openPostModal = function () { $('#NewPostModal').modal('show') }
      for (var i = 0; i < elements.length; i++) { elements[i].addEventListener('click', openPostModal, false) }

      try {
        document.getElementById('closepostmodal').addEventListener('click', function (e) {
          $('#NewPostModal').modal('hide')
        })
      } catch (e) { };

      loginForm.submit(function (e) {
        e.preventDefault()
        loader.show()
        // content.hide()

        const loginData = new FormData(this)
        loginData.append('type', 'login')

        axios.post(url, loginData)
          .then(function (response) {
            console.log(response)
            if (response.status === 250) {
              $('.modal').modal('hide')
              const pData = response.data
              window.junx.user = pData

              localStorage.setItem('account_public', pData.account_public)
              localStorage.setItem('account_type', pData.account_type)
              localStorage.setItem('email', pData.email)
              localStorage.setItem('firstname', pData.firstname)
              localStorage.setItem('age', pData.age)
              localStorage.setItem('darkmode', pData.darkmode)
              localStorage.setItem('ghostmode', pData.ghostmode)
              localStorage.setItem('volume', pData.volume)
              localStorage.setItem('lat', pData.lat)
              localStorage.setItem('long', pData.long)
              localStorage.setItem('premium_state', pData.premium_state)
              localStorage.setItem('profile_img', pData.profile_img)
              localStorage.setItem('register_status', pData.register_status)
              localStorage.setItem('role', pData.role)
              localStorage.setItem('subscribed_channels', pData.subscribed_channels)
              localStorage.setItem('user_id', pData.user_id)
              localStorage.setItem('username', pData.username)
              localStorage.setItem('uname', pData.username)
              localStorage.setItem('NodeID', pData.NodeID)
              localStorage.setItem('SessionID', pData.SessionID)
              localStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('NodeID', pData.NodeID)
              sessionStorage.setItem('SessionID', pData.SessionID)
              if (process.env.CORDOVA_PLATFORM !== undefined && $('#keepIn').checkbox('is checked')) {
                if (process.env.CORDOVA_PLATFORM === 'ios') {
                  try {
                    FirebasePlugin.grantPermission(hasPermission => {
                      console.log('Permission was ' + (hasPermission ? 'granted' : 'denied'))
                    })

                    firebaseSetup()
                  } catch (e) {
                    console.error('FirebasePlugin grantPermission error', e)
                  }
                } else {
                  firebaseSetup()
                }
              } else if ($('#keepIn').checkbox('is checked')) {
                pushWebToken()
              }

              localStorage.setItem('encrypted', 'false')
              sessionStorage.setItem('auth', 1)
              if ($('#keepIn').checkbox('is checked')) {
                localStorage.setItem('KeepID', pData.KeepID)
              }
              setJunx()
              if (localStorage.getItem('darkmode') === '1') {
                document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
                if (process.env.CORDOVA_PLATFORM !== undefined) StatusBar.styleLightContent()
                window.junx.darkMode = true
              } else {
                document.getElementsByTagName('html')[0].removeAttribute('class')
                if (process.env.CORDOVA_PLATFORM !== undefined) StatusBar.styleDefault()
                window.junx.darkMode = false
              }

              $('#LoginModal').modal('hide')

              if (pData.account_type === 'business') {
                location.href = 'businessdashboard'
              } else if (pData.register_status === '0') {
                router.push('advancedregister')
              } else if (_this.$route.query.redirect === undefined || _this.$route.query.redirect == null || _this.$route.query.redirect === '') {
                router.push('wall')
              } else {
                router.push(_this.$route.query.redirect)
              }
            } else if (response.status === 251) {
              loginFail.show()
              loader.hide()
              content.show()
            } else {
              loader.hide()
              content.show()
            }
          })
          .catch(function () {
            loader.hide()
            content.show()
          })
      })

      passwordResetForm.submit(function (e) {
        e.preventDefault()
        loader.show()
        // content.hide()

        const loginData = new FormData(this)
        loginData.append('type', 'passwordReset')

        $.ajax({
          type: 'POST',
          url: url,
          data: loginData,
          success: function (data, textStatus, xhr) {
            if (xhr.status === 250) {
              passwordResetSuccess.show()
              passwordResetFail.hide()
            } else if (xhr.status === 251) {
              passwordResetFail.show()
              passwordResetSuccess.hide()
            }
            loader.hide()
            content.show()
          },
          cache: false,
          contentType: false,
          processData: false
        })
      })

      restorePasswordForm.submit(function (e) {
        e.preventDefault()
        loader.show()
        // content.hide()

        const loginData = new FormData(this)
        loginData.append('type', 'passwordRestore')
        loginData.append('resetkey', _this.$route.query.resetkey)

        $.ajax({
          type: 'POST',
          url: url,
          data: loginData,
          success: function (data, textStatus, xhr) {
            if (xhr.status === 250) {
              passwordResetSuccess.hide()
              passwordResetFail.hide()
              restorePasswordFail.hide()
              restoreKeyFail.hide()
              loginFail.hide()
              $('#PasswortResetModal').modal('show')
              $('#PasswortForgetModal').modal('show')
              $('#LoginModal').modal('show')
            } else if (xhr.status === 251) {
              restorePasswordFail.show()
              restoreKeyFail.hide()
            } else if (xhr.status === 252) {
              restoreKeyFail.show()
              restorePasswordFail.hide()
            }
            loader.hide()
            content.show()
          },
          cache: false,
          contentType: false,
          processData: false
        })
      })

      regform.submit(function (e) {
        e.preventDefault()
        loader.show()
        // content.hide()

        const regData = new FormData(this)
        regData.append('type', 'passwordRestore')
        regData.append('resetkey', findGetParameter('resetkey'))
        regData.append('affiliate', findGetParameter('affiliate'))

        $.ajax({
          type: 'POST',
          url: window.projectURL + 'register/doRegister',
          data: regData,
          success: function (data, textStatus, xhr) {
            noMatch.hide()
            toShort.hide()
            regFail.hide()
            regBot.hide()
            regAge.hide()
            regEmail.hide()
            console.log(data, textStatus, xhr)
            const pData = JSON.parse(data)

            if (xhr.status === 250) {

              localStorage.setItem('account_public', pData.account_public)
              localStorage.setItem('account_type', pData.account_type)
              localStorage.setItem('email', pData.email)
              localStorage.setItem('firstname', pData.firstname)
              localStorage.setItem('age', pData.age)
              localStorage.setItem('darkmode', pData.darkmode)
              localStorage.setItem('ghostmode', pData.ghostmode)
              localStorage.setItem('volume', pData.volume)
              localStorage.setItem('lat', pData.lat)
              localStorage.setItem('long', pData.long)
              localStorage.setItem('premium_state', pData.premium_state)
              localStorage.setItem('profile_img', pData.profile_img)
              localStorage.setItem('register_status', pData.register_status)
              localStorage.setItem('role', pData.role)
              localStorage.setItem('subscribed_channels', pData.subscribed_channels)
              localStorage.setItem('user_id', pData.user_id)
              localStorage.setItem('username', pData.username)
              localStorage.setItem('uname', pData.username)
              localStorage.setItem('NodeID', pData.NodeID)
              localStorage.setItem('SessionID', pData.SessionID)
              localStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('postkey', pData.postkey)
              sessionStorage.setItem('NodeID', pData.NodeID)
              sessionStorage.setItem('SessionID', pData.SessionID)

              localStorage.setItem('encrypted', 'false')
              sessionStorage.setItem('auth', '1')

              localStorage.setItem('currentPage', 'step1')
              router.push('/advancedregister/1')
            } else if (xhr.status === 251) {
              regBot.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 252) {
              regEmail.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 253) {
              regAge.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 254) {
              noMatch.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 255) {
              regFail.show()
              loader.hide()
              content.show()
            }
          },
          cache: false,
          contentType: false,
          processData: false
        })
      })

      $('#pw1,#pw2').on('input', function () {
        if (pw1.val() === 'penis' || pw1.val() === 'schwanz' || pw1.val() === 'cock' || pw1.val() === 'pimmel') {
          toShort.show()
        } else {
          toShort.hide()
        }
        if (pw2.val().length < 8) {
          fsubmit.prop('disabled', true)
        } else if (pw1.val() !== pw2.val()) {
          noMatch.show()
          fsubmit.prop('disabled', true)
        } else {
          noMatch.hide()
          fsubmit.prop('disabled', false)
        }
      })
    }

    init()

    window.junx.loading = false
  }
}

</script>

<style >
#page-login{
  background-image: url('/assets/images/new-login-background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
}
#page-login-header{
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 300px;
  background: rgb(57,0,85);
  background: linear-gradient(180deg, rgba(57,0,85,1) 3%, rgba(255,255,255,0) 100%);
}
#page-login-header .header-content{
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
#page-login-header .header-content img{
  max-width: 250px;
  width: 100%;
}
#page-login-video{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#page-login-video #bgvideo{
  display: block;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#page-login-footer{
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 300px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 3%, rgba(255,255,255,0) 100%);
}
#page-login-footer .cta-content{
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
#page-login-footer .cta-content ul{
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 16px;
}
#page-login-footer .cta-content ul li{
  text-align: center;
  display: inline-block;
  margin: 0 4px;
  list-style: none;
}
#page-login-footer .cta-content ul li a{
  color: rgba(255,255,255,0.8);
  text-decoration: none;
  font-size: 0.8em;
}
#LoginModal .password-forget{cursor: pointer;}
#PasswortForgetModal .rainbowloader,
#PasswortResetModal .rainbowloader,
#LoginModal .rainbowloader{
  display: none;
  min-height: 300px;
}
</style>
