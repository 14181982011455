import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import SuiVue from 'semantic-ui-vue'
import { projectURL, updateStatus, setJunx, resetApp, setIconMenu } from '@/modules/main'
import title from '@/mixins/title'
import { connectSocket } from '@/modules/socket'
import { closeSideMenu } from '@/modules/preRoute'
import './registerServiceWorker'
import PullToRefresh from 'pulltorefreshjs'

61925068374

window.projectURL = projectURL
window.ignorePause = false
window.time = Date.now()
window.junx = false
window.prevRoute = []
if (localStorage.getItem('darkmode') === '1') {
  document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
} else {
  document.getElementsByTagName('html')[0].removeAttribute('class')
}

router.beforeEach((to, from, next) => {
  clearInterval(window.timer)
  if (window.junx.chatModal && window.prevRoute[1] === to.fullPath) {
    window.junx.chatModal = false
    next(false)
    return
  }
  // prevent back from chat.
  if (!to.path.startsWith('/messages')) {
    window.prevRoute[1] = window.prevRoute[0]
    window.prevRoute[0] = to.fullPath
    window.junx.chatModal = false
    if (typeof window.wall !== 'undefined' && typeof window.wall.bye === 'function') window.wall.bye()
  }
  window.onscroll = function () {}
  $('.dropdown').dropdown()
  closeSideMenu()
  console.log(1)
  if (to.matched.some(record => record.meta.auth)) {
    console.log(4)
    updateStatus().then(function (resolved) {
      console.log(2)
      sessionStorage.setItem('auth', 1)
      window.junx.loading = true
      if (resolved === 220) {
        next('/advancedregister')
      } else {
        if (!window.junx.socketOnline) {
          connectSocket()
        }
        next()
      }
    }).catch(function (e) {
      console.log(e)
      sessionStorage.setItem('auth', 0)
      window.junx.loading = true
      resetApp()
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    console.log('nope', to)
    updateStatus()
      .catch(e => {})
      .finally(function () {
      if (parseInt(sessionStorage.getItem('auth')) === 1) {
        window.junx.loading = true
        next({
          path: '/wall'
        })
      } else {
        window.junx.loading = true
        next()
      }
    })
  } else {
    updateStatus()
    window.junx.loading = true
    next()
  }
})

router.afterEach((to, from) => {
  if (typeof cordova !== 'undefined' && typeof cordova.plugins !== 'undefined' && typeof cordova.plugins.Keyboard !== 'undefined') cordova.plugins.Keyboard.hide()
  // window.junx.callModal = false
  if (!window.junx.chatModal) PullToRefresh.destroyAll()
})

if (process.env.CORDOVA_PLATFORM === undefined) {
  localStorage.setItem('fingerprint', 0)
  localStorage.setItem('encrypted', 'false')
  unlock()
} else {
  document.addEventListener('deviceready', onDeviceReady, false)
  if (localStorage.getItem('vibrate') !== '0') localStorage.setItem('vibrate', 1)
}

function onDeviceReady () {
  Fingerprint.isAvailable(function () {
    localStorage.setItem('fingerprint', 1)
  }, function () {
    localStorage.setItem('fingerprint', 0)
  }, {
    allowBackup: true
  })

  universalLinks.subscribe(null, function (msg) {
    router.push(msg.path)
  })

  window.open = cordova.InAppBrowser.open

  if (typeof ThreeDeeTouch !== 'undefined') {
    ThreeDeeTouch.onHomeIconPressed = function (payload) {
      console.log('Icon pressed. Type: ' + payload.type + '. Title: ' + payload.title + '.')
      if (payload.type === 'notification') {
        router.push('/n')
      } else if (payload.type === 'messenger') {
        router.push('/messages')
      } else if (payload.type === 'match') {
        router.push('/matchgame')
      } else if (payload.type === 'search') {
        router.push('/search')
      }
    }
  }

  window.addEventListener('beforeunload', function () {
    window.ignorePause = true
    // encryptStorage()
    setIconMenu(window.junx.chatsCounter, window.junx.notis.length - window.junx.notisread)
  })
  document.addEventListener('pause', pause, false)
  document.addEventListener('resume', resume, false)
  // auth()
  unlock()
}

function auth () {
  if (localStorage.getItem('biometric') === '1') {
    Fingerprint.loadBiometricSecret({
      title: 'Junx Login',
      description: 'Bist du es?',
      fallbackButtonTitle: 'Pin verwenden',
      cancelButtonTitle: 'abbrechen',
      disableBackup: false
    }, unlock, function (e) {
      console.log(e)
      resetApp()
      console.log(2)
      unlock()
    })
  } else {
    unlock()
  }
}

function unlock (secret = '3xoZZ2Cj4fdsg5sd') {
  if (process.env.CORDOVA_PLATFORM !== undefined) sessionStorage.setItem('bioSecret', secret)
  const vueProto = Vue.createApp(App).use(router, SuiVue, VueAxios, axios).mixin(title)
  vueProto.config.warnHandler = () => null;
  window.junx = !window.junx ? vueProto.mount('#junxApp') : window.junx
  updateStatus()
    .then(function () {
      window.junx.blur = false
      setJunx()
      if (!window.junx.socketOnline)connectSocket()
      if (typeof window.wall !== 'undefined' && typeof window.wall.reloadWall !== 'undefined')window.wall.reloadWall()
      if (process.env.CORDOVA_PLATFORM !== undefined) {
        FirebasePlugin.onMessageReceived(message => {
          // eslint-disable-next-line no-prototype-builtins
          if (message.hasOwnProperty('info') && typeof window.chat !== 'undefined') window.chat.pushMessage(message.info)
          if (message.tap === 'background') {
            console.log(message.url)
            router.push(message.urlApp)
          } else {
            setIconMenu(message.chats, message.notis)
          }
        }, error => {
          console.error(error)
        })
      }
    })
    .catch(function () {
      window.junx.blur = false
      resetApp()
      if (window.junx.$route.meta.auth === 1) router.push('/login')
    })
    .finally(function () {
      if (process.env.CORDOVA_PLATFORM !== undefined) {
        navigator.splashscreen.hide()
        if (window.junx.darkMode) {
          StatusBar.styleLightContent()
        } else {
          StatusBar.styleDefault()
        }
        FirebasePlugin.clearAllNotifications()
      }
      $(document).on('touch mouseover', function () {
        if (!window.junx.socketOnline)connectSocket()
      })
    })
}

function pause () {
  /*
  window.time = Date.now()
  if (!window.ignorePause && localStorage.getItem('encrypted') === 'false' && localStorage.getItem('user_id') !== 'null' && localStorage.getItem('user_id') != null) {
    window.junx.blur = true
    encryptStorage()
  }
  window.ignorePause = false */
  setIconMenu(window.junx.chatsCounter, window.junx.notis.length - window.junx.notisread)
}

function resume () {
  unlock()
  /* if (localStorage.getItem('encrypted') !== 'false' && (localStorage.getItem('user_id') !== 'null' && localStorage.getItem('user_id') != null)) {
    auth()
  } else {
    unlock()
  } */
}
