<template>
  <div class="col-sp-2 row-sp-12 pad-4" :class="{online: user.online == 1}">
    <router-link :to="'/p/' + user.username">
      <img class="ui image br-8 ar-1-1" style="width: 100%;" :src="projectURL + 'assets/images/user/' +  image" :title="user.firstname">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'tinyUser',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    image () {
      const re = /(?:\.([^.]+))?$/
      const img = this.user.profile_img.replace(/\.[^/.]+$/, '_small') + re.exec(this.user.profile_img)[0]
      return img
    }
  }
}
</script>

<style scoped>
.linkTinyUser {
  position: relative;background-position: center center;background-size: cover;display: block;width: 80px;height: 80px; overflow: hidden;border-radius: 8px;
}
.contentTinyUser{
  position: absolute;bottom: 0; padding: 0 4px;color: #FFFFFF;
}
</style>
