<template>
  <div class="contact-element">
    <div class="contact-profile-img">
      <img class="ui avatar image ar-1-1" :src="projectURL + 'assets/images/user/' + user.profile_img">
      <a class="onlinestatus ui green empty circular label" v-if="user.online == 1"></a>
    </div>
    <div class="contact-content">
      <h6 class="ui header">
        {{ user.firstname }}
        <div class="sub header last_dm">
          @{{ user.username }}
        </div>
      </h6>
    </div>
    <div class="contact-extra">
      <div class="pad-4"></div>
        <router-link :to="'/p/' + user.username" class="ui button btn-color-1 mini">Profil ansehen</router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'user',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>
.contact-element{
  padding: 16px 16px 8px 16px;
  border-top: 1px solid rgba(0,0,0,0.05);
  vertical-align: middle;
  cursor: pointer;
}
.contact-element .ui.header i.check.icon{
  display: inline-block;
  font-size: 1em;
  margin-top: -5px;
}
.contact-element .contact-profile-img{
  display: inline-block;
  position: relative;
}
.contact-element .contact-profile-img .ui.avatar.image{
  height: 3em;
  width: 3em;
  margin-top: -32px;
}
.contact-element .contact-profile-img .onlinestatus{
  position: absolute;
  top: 14px;
  left: 32px;
}
.contact-element .contact-content{
  display: inline-block;
}
.contact-element .contact-extra{
  display: inline-block;
  float: right;
  margin-top: -8px;
}
</style>
