<template>
  <div id="page-visitor" class="spaceTop">
    <div id="ptrest"></div>
    <div class="content-1200">
      <div class="grid-12">
        <div id="SearchResultsUser" class="searchresults col-sp-12 row-sp-12">
          <div class="grid-12 spaceBottom">
            <user v-if="user.length > 0">
            </user>
            <div class="col-sp-12 row-sp-12 pad-32 t-center" v-if="moreLoad && user.length == 0">
              <img src="assets/images/likes/sad-but-relieved-face.png">
              <h3 class="ui center aligned header">
                <div class="content">
                  Derzeit keine Besucher.
                </div>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user from '@/components/search/user'
import axios from 'axios'
import { cookieLogin, resetApp } from '@/modules/main'
import PullToRefresh from 'pulltorefreshjs'

export default {
  name: 'visitors',
  title: 'Junx | Besucher',
  data () {
    return {
      projectURL: window.projectURL,
      user: [],
      empty: false,
      moreLoad: false,
      offset: 0
    }
  },
  components: {
    user
  },
  methods: {
    scroll () {
      const _this = this
      window.onscroll = () => {
        // console.log(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)  + window.innerHeight + "  -   " + $('#searchApp').height());
        if ((Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 200 >= $('#page-visitor').height() || document.querySelector('#SearchResultsUser').offsetHeight < document.querySelector('body').offsetHeight) && this.moreLoad && !this.empty) {
          _this.loadVisitors(true)
        }
      }
    },
    loadVisitors (add = false) {
      const _this = this
      const formData = new FormData()
      this.moreLoad = false
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('offset', this.offset)
      axios.post(projectURL + 'profile/visitors', formData)
        .then(function (response) {
          if (response.status === 250) {
            for (const key in response.data) {
              response.data[key].online = response.data[key].online === '1'
            }
            if (!add && response.data.length > 0) {
              _this.user = response.data
            } else if (response.data.length > 0) {
              _this.user = _this.user.concat(response.data)
            }
            _this.empty = response.data.length !== 20
            _this.offset += response.data.length
            _this.moreLoad = true
            if (document.querySelector('#SearchResultsUser').offsetHeight < document.querySelector('body').offsetHeight && _this.moreLoad && !_this.empty) {
              _this.loadVisitors(true)
            }
          } else {
            cookieLogin().then(_this.loadVisitors).catch(function () {
              resetApp()
              _this.$router.push('/login')
            })
          }
          window.junx.loading = false
        })
    },
    calculateTime (posted, now) {
      const arr = posted.split(/[- :.T]/)
      const timeStamp = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
      let remainTime = Math.floor((now - timeStamp) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sekunden'
      if (remainTime <= 59)timeString = 'Jetzt'
      if (remainTime > 59) {
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Minuten'
        if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Stunden'
          if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(timeStamp)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    }
  },
  mounted () {
    this.scroll()
    this.loadVisitors()
    PullToRefresh.init({
      mainElement: document.querySelector('#ptrest'),
      triggerElement: document.querySelector('#ptrPull'),
      instructionsPullToRefresh: 'ziehen...',
      instructionsReleaseToRefresh: 'Loslassen...',
      instructionsRefreshing: 'aktualisiere...',
      iconArrow: '<i class="fas fa-angle-down fa-2x"></i>',
      shouldPullToRefresh () {
        return !window.junx.chatModal
      },
      onRefresh () {
        window.location.reload()
      }
    })
  }
}
</script>

<style>
.contact-content h6.ui.header{
  margin: 0 auto;
}
</style>
