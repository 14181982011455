<template>
  <section id="BlogMain">
    <div class="content-1400">
      <div class="pad-16">
        <div class="grid-12 ui segment no-border no-shadow">
          <div class="col-sp-8 row-sp-12">
            <h3 class="ui header">
              <img src="/assets/images/emoticons/flag.png">
              <div class="content">
                Junx Magazin
                <div class="sub header">Dein LGBTIQA+ Newspaper</div>
              </div>
            </h3>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <router-link class="ui basic icon button" to="/blog/s/">
              <i class="search icon"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="pad-16">
        <div class="grid-12">
          <blog-video :video="video" v-for="(video, index) in videos" :key="index"></blog-video>
        </div>
      </div>
      <div class="pad-16">
        <h2 class="ui header pad-8">
          Neuste Beiträge
        </h2>
        <div class="grid-12" id="BlogArticle">
          <article-preview v-for="(article, index) in articles" :article="article" :key="index"></article-preview>
        </div>
        <div class="t-center pad-16">
          <button class="ui button basic" @click="loadMore()" v-if="more">Mehr laden</button>
        </div>
      </div>
      <div class="pad-16">
        <h2 class="ui header pad-8">
          Kategorien
        </h2>
        <div class="grid-12">
          <category v-for="(category, index) in categories" :category="category" :key="index"></category>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { cookieLogin } from '@/modules/main'
import articlePreview from '@/components/blog/articlePreview'
import blogVideo from '@/components/blog/video'
import category from '@/components/blog/category'
export default {
  name: 'start',
  components: {
    articlePreview,
    blogVideo,
    category
  },
  data () {
    return {
      projectURL: window.projectURL,
      articles: [],
      video: [],
      categories: []

    }
  },
  methods: {
    loadMore (limit = 9, init = false) {
      const _this = this
      $.post(this.projectURL + 'blog/loadArticle', { SessionID: sessionStorage.getItem('SessionID'), offset: this.articles.length, limit: limit }, function (msg, status, xhr) {
        try {
          if (xhr.status === 250) {
            const data = JSON.parse(msg)
            if (data) {
              _this.articles = _this.articles.concat(data)
              if (data.length < limit)blogApp.more = false
            } else {
              _this.more = false
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { blogApp.loadMore(limit) }).catch(function () { _this.$router.push('/login') })
          }
          window.junx.loading = false
        } catch (e) { console.log(e) }
      })
    },
    loadVideos () {
      const _this = this
      $.post(this.projectURL + 'blog/loadVideos', { SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
        try {
          if (xhr.status === 250) {
            const data = JSON.parse(msg)
            if (data) {
              _this.videos = data
            }
          } else if (xhr.status === 299) {
            cookieLogin().then(function () { _this.loadMore(limit) }).catch(function () { _this.$router.push('/login') })
          }
          window.junx.loading = false
        } catch (e) { console.log(e) }
      })
    },
    loadCategorys () {
      const _this = this
      $.post(this.projectURL + 'blog/loadCategories', { SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
        try {
          if (xhr.status === 250) {
            const data = JSON.parse(msg)
            _this.categories = data
          } else if (xhr.status === 299) {
            cookieLogin().then(_this.loadCategorys).catch(function () { _this.$router.push('/login') })
          }
          window.junx.loading = false
        } catch (e) { console.log(e) }
      })
    }
  },
  mounted () {
    this.loadMore(3)
    this.loadVideos()
    this.loadCategorys()
  }
}
</script>

<style scoped>

</style>
