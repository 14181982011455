<template>
  <router-link class="col-sp-6 col-tb-4 col-sd-3 row-sp-12 pad-4" :to="'/blog/s/' + category.category_name">
    <div class="ui segment no-border no-shadow t-center">
      <img class="ui tiny image centered" :src="projectURL + 'assets/images/magazin/kategorien/'+category.category_img">
      <h3 class="ui center aligned header">
        {{category.category_name}}
      </h3>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'category',
  props: ['category'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>

</style>
