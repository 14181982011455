<template>
  <div  class="spaceTop">
    <div style="height: 200px;margin-top: 200px;text-align: center;font-size: 3em;">
      Seite nicht gefunden.
    </div>
    <div class="t-center">
      <img src="assets/images/404-error-unicorn.png" class="responsive-img" style="max-width: 350px;"/>
      <div class="pad-8"></div>
      <router-link class="ui large button btn-color-1" to="/home">Zurück ins Einhornwunderland</router-link>
      <div class="pad-32"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  title: 'Junx | 404',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>

</style>
