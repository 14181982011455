<template>
  <a :data-interest="channel.id" class="setInterests ui basic label" :class="{active: ownchannels.includes(channel.id)}" v-if="channel.channel_type == type">
    {{channel.channel_title}}
    <img class="ui mini image" :src="'assets/images/channels/'+channel.channel_img">
  </a>
</template>

<script>
export default {
  name: 'channelSelect',
  props: ['channel', 'ownchannels', 'type']
}
</script>

<style scoped>

</style>
