<template>
  <div class="item" :data-value="channels[ownChannel].id"><img :src="'assets/images/channels/' + channels[ownChannel].channel_img">{{channels[ownChannel].channel_title}}</div>
</template>

<script>
export default {
  name: 'channelMyWall',
  props: ['channels', 'ownChannel']
}
</script>

<style scoped>

</style>
