<template>
  <div id="navBar">
    <!-- Mobile Top Menu -->
    <mobile-top :user="user" :socketonline="socketonline" :darkMode="darkMode"></mobile-top>
    <!-- Dekstop Top Menu -->
    <desktop-top :notis="notis" :notisread="notisread" :user="user" :chats="chats" :socketonline="socketonline" :friends="friends" :darkMode="darkMode"></desktop-top>
    <!-- sidebar -->
    <sidebar :user="user"></sidebar>
  </div>

  <!-- Modals -->
  <div class="scrollToTop circular ui icon button btn-color-1">
    <i class="icon angle up"></i>
  </div>
  <div class="ui large modal" id="ChatModal">
    <i class="close icon"></i>
    <div class="content no-pad" style="padding: 0!important;margin-bottom: -5px;">
      <div>
        <iframe id="ChatModalIframe" scroll="no" style="border: 0; width: 100%; height: calc(90vh - 108px);"></iframe>
      </div>
    </div>
  </div>

  <div id="videoRequest" class="ui mini modal">
    <div class="header t-center">
      <img style="width: 32px;" src="assets/images/emoticons/call.png"> Eingehender Videoanruf
    </div>
    <div style="background-color: #9bc3cf; color: rgba(255,255,255,1); min-height: 300px; padding: 32px;">
      <img id="videoRequestImg" src="assets/images/user/avatar_default.png" class="ar-1-1">
      <div id="profile_img_div_2"></div>
      <div class="content t-center"></div>
    </div>
    <div class="actions" style="text-align: center;">
      <div class="ui circular button deny">
        <i class="icon cancel"></i> Ablehnen
      </div>
      <div class="ui circular button approve btn-color-1">
        <i class="icon phone"></i> Annehmen
      </div>
    </div>
  </div>

  <div id="videoCallModal" class="ui modal fullscreen" style="width: 100% !important; margin: 0 !important; overflow: hidden;">
    <iframe id="videoCallIframe" scroll="no" style="border: 0; width: 100%; height: 100%;"></iframe>
  </div>

  <div class="ui large modal" id="StoryModal">
    <i class="close icon" id="closeitSM" style="display: none;"></i>
    <iframe id="StoryModalIframe" src="" scroll="no" allowtransparency="true"></iframe>
  </div>

  <div class="ui small modal basic" id="deadUnicorns">
    <div class="ui segment no-border no-shadow pad-32 t-center" style='margin: 10% auto; max-width: 700px;background-image: url("/assets/images/dead-unicorn-background.jpg");background-size: contain;'>
      <img src="assets/images/dead-unicorn.png" class="responsive-img" style="max-width: 200px;">
      <h2 class="ui header" style="color: #edf3f8;">
        <div class="sub header" style="color: #edf3f8;">Letzter ritueller Mord...ähm..."Unfall": <span id="lastDeath"></span><br></div>
        &#8224; <span id="uniCounter"></span> geopferte Einhörner seit 21. Jul. 2021! <br>
        <div class="sub header" style="color: #edf3f8;"><span id="uniLeft"></span> Einhörner übrig....so who cares.</div>
      </h2>
    </div>
  </div>
  <div class="ui large modal" id="StoryCreateModal">
    <div id="loadit">
      <img src='assets/images/progress.gif'>
    </div>
    <i class="close icon" id="closeitSCM" style="display: none;"></i>
    <iframe id="StoryCreateModalIframe" name="StoryCreateModalIframe" src="" scroll="no" allowtransparency="true">
    </iframe>
  </div>
</template>

<script>
import axios from 'axios'
import router from '@/router'
import { navbarSemantic } from '@/modules/adminnavbar'
import desktopTop from '@/components/basic/navbar/desktopTop'
import mobileBottom from '@/components/basic/navbar/mobileBottom'
import mobileTop from '@/components/basic/navbar/mobileTop'
import Sidebar from '@/components/basic/navbar/sidebar'
export default {
  name: 'matchnavbar',
  props: ['user', 'chats', 'notis', 'socket', 'notisread', 'socketonline', 'friends', 'darkMode'],
  components: {
    Sidebar,
    desktopTop,
    mobileTop
  },
  data () {
    return {
      ghostMode: localStorage.getItem('ghostmode') === 1
    }
  },
  methods: {
    logout () {
      const formdata = new FormData()
      formdata.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'login/logout', formdata)
        .then(function (response) {
          if (response.status === 250) {
            const darkmode = localStorage.getItem('darkmode')
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('darkmode', darkmode)

            const cookies = document.cookie.split(';')

            for (var i = 0; i < cookies.length; i++) {
              const cookie = cookies[i]
              const eqPos = cookie.indexOf('=')
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
              document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
            }
            router.push('/')
          }
        })
    },
    ghost () {
      const comp = this
      $.ajax({
        type: 'POST',
        url: window.projectURL + 'einstellungen/setGhostMode',
        data: { postkey: sessionStorage.getItem('postkey') },
        success: function (data) {
          if (data === 1) {
            comp.ghostMode = 1
          } else {
            comp.ghostMode = 1
          }
          localStorage.setItem('ghostmode', comp.ghostMode)
        }
      })
    }
  },
  computed: {
    countNoFri () {
      return this.notis.length + this.friends.length - this.notisread
    },
    countTitle () {
      return this.countNoFri + this.chats
    }
  },
  mounted () {
    navbarSemantic()
    document.addEventListener('click', function (event) {
      if (document.querySelector('body').classList.contains('sidemenu-open')) {
        var isClickInside = document.querySelector('div.sidebar').contains(event.target)

        if (!isClickInside) {
          document.querySelector('.menu-btn').click()
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
