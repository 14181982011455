<template>
  <div>
    <div class="ui fluid card questioncard no-border no-shadow">
      <div class="content">
        <span class="right floated">
          <p>{{question.datestamp}}</p>
        </span>
        <a style="margin-bottom: 16px;" class="ui basic mini labeled button icon delQuestion" @click="this.$parent.deleteQuestion" v-if="question.own_question == 1">
          <i class="trash icon"></i> Frage löschen
        </a>
        <router-link :to="'/p/' + question.username" v-if="question.anonym !== '1'">
          <img class="ui avatar image" :src="projectURL + 'assets/images/user/' + question.profile_img">
          <span><b>{{question.username}}</b>, {{question.age}}</span>
        </router-link>
        <div v-else>
          <img class="ui avatar image" :src="projectURL + 'assets/images/user/' + question.profile_img">
          <span><b>{{question.username}}</b>, {{question.age}}</span>
        </div>
        <div class="description pad-16">
          <h3>{{question.title}}</h3>
          <p>{{question.content}}</p>
        </div>
      </div>
      <div class="extra content">
        <span class="left floated like" style="color: black">
          <router-link :to="'/forum/c/' + question.category_title">
            <img class="category-img" :src="projectURL + 'assets/images/forum/' + question.img"/>
            {{question.category_title}}
          </router-link>
          <div :class="{active: question.ownVote == 0}" @click="this.$parent.vote(0)">{{question.downVotes}}<i class="fas fa-chevron-down"></i></div>
          <div :class="{active: question.ownVote == 1}" @click="this.$parent.vote(1)"><i class="fas fa-chevron-up"></i>{{question.upVotes}}</div>
        </span>
      </div>
      <form class="ui form pad-16" id="answerForm">
        <div class="field">
          <label>Deine Antwort:</label>
          <textarea rows="2" name="comment" required></textarea>
        </div>
        <div class="ui checkbox">
          <input type="checkbox" name="anonym" :checked="(question.anonym == 1 && question.own_question == 1)">
          <label>Anonym antworten</label>
        </div>
        <div>
          <button type="submit" class="ui right labeled icon right floated tiny button btn-color-3">
            <i class="send icon"></i>
            Antworten
          </button>
        </div>
      </form>
    </div>
    <h6 class="ui horizontal divider header" v-if="answers.length > 0">
      <i class="comments icon"></i>
      {{(answers.length == 1)?'Antwort':'Antworten'}}
    </h6>
    <div class="ui comments" v-if="answers.length > 0 || favAnswer.length > 0">
      <div class="comment askcomment" v-for="(answer, index) in favAnswer" :key="index">
        <router-link class="avatar" :to="'/p/' + answer.username" v-if="answer.anonym !== '1'">
          <img :src="projectURL + 'assets/images/user/' + answer.profile_img">
        </router-link>
        <div class="avatar" v-else>
          <img :src="projectURL + 'assets/images/user/' + answer.profile_img">
        </div>
        <div class="content" style="color: black">
          <router-link class="author" :to="'/p/' + answer.username" v-if="answer.anonym !== '1'">
            {{answer.username}} {{(oc == 1)?'(Fragesteller)':''}}
          </router-link>
          <div v-else>
            {{answer.username}} {{(oc == 1)?'(Fragesteller)':''}}
          </div>
          <div class="metadata">
            <div class="rating">
              <a class="ui blue mini label">Hilfreicheste Antwort</a>
            </div>
            <div class="date">{{answer.datestamp}}</div>
          </div>
          <a class="delAnswer" @click="this.$parent.deleteAnswer(answer.id)" v-if="answer.own_answer == 1"><i class="trash icon"></i></a>
          <div :class="{active: answer.ownVote == 0}" @click="this.$parent.voteAnswer(answer.id, 0)">{{answer.downVotes}}<i class="fas fa-chevron-down"></i></div>
          <div :class="{active: answer.ownVote == 1}" @click="this.$parent.voteAnswer(answer.id, 1)"><i class="fas fa-chevron-up"></i>{{answer.upVotes}}</div>
          <div class="text">
            {{answer.content}}
          </div>
        </div>
      </div>
      <div class="comment askcomment" v-for="(answer, index) in answers" :key="index">
        <a class="avatar">
          <img :src="projectURL + 'assets/images/user/' + answer.profile_img">
        </a>
        <div class="content" style="color: black">
          <router-link class="author" :to="'/p/' + answer.username">
            {{answer.username}} {{(oc == 1)?'(Fragesteller)':''}}
          </router-link>
          <div class="metadata">
            <div class="date">{{answer.datestamp}}</div>
          </div>
          <a class="delAnswer" @click="this.$parent.deleteAnswer(answer.id)" v-if="answer.own_answer == 1"><i class="trash icon"></i></a>
          <a @click="this.$parent.favoriteAnswer(answer.id)" v-if="answer.own_answer == 1 || team"><i class="star icon"></i></a>
          <div :class="{active: answer.ownVote == 0}" @click="this.$parent.voteAnswer(answer.id, 0)">{{answer.downVotes}}<i class="fas fa-chevron-down"></i></div>
          <div :class="{active: answer.ownVote == 1}" @click="this.$parent.voteAnswer(answer.id, 1)"><i class="fas fa-chevron-up"></i>{{answer.upVotes}}</div>
          <div class="text">
            {{answer.content}}
          </div>
        </div>
      </div>-
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'question',
  props: ['question', 'answers', 'favAnswer'],
  data () {
    return {
      projectURL: window.projectURL,
      team: localStorage.getItem('account_type') === 'admin' || localStorage.getItem('account_type') === 'supporter'
    }
  }
}
</script>

<style scoped>

</style>
