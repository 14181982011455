/* eslint-disable no-prototype-builtins */
import router from '@/router'
import { cookieLogin } from '@/modules/main'
import axios from 'axios'

export function profileSemantic () {
  $('.modal').modal('hide')
  $('#startVideoButton').on('click', function () {
    calling = false
    $('#ChatMenuModal').modal('hide')
    $('#VideoCallRequest').modal('hide')
    openCall(null, username, username)
  })

  $('#cropForm').on('submit', function (e) {
    e.preventDefault()
    $('#FullSizeLoader').show()
    $('#cropperModal').modal('hide')

    const formdata = new FormData(this)
    $.ajax({
      url: window.projectURL + 'profile/crop',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          localStorage.setItem('profile_img', data)
          location.reload()
        } else if (xhr.status === 299) {
          cookieLogin().then(() => { $('#cropForm').submit() }).catch(function () { router.push('/login') })
        }
      },
      error: function (data, textStatus, xhr) {
        console.log(xhr.status)
      },
      cache: false,
      contentType: false,
      processData: false
    })
  })

  $('.editProfileForm').on('submit', function (e) {
    e.preventDefault()
    const action = $(this).attr('action')
    const formdata = new FormData(this)
    formdata.append('postkey', sessionStorage.getItem('postkey'))
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))
    axios.post(window.projectURL + action, formdata)
      .then(function (response) {
        if (response.status === 250) {
          try {
            const jsonData = response.data
            if (jsonData.hasOwnProperty('firstname'))localStorage.setItem('firstname', jsonData.firstname)
            if (jsonData.hasOwnProperty('lat'))localStorage.setItem('lat', jsonData.lat)
            if (jsonData.hasOwnProperty('long'))localStorage.setItem('long', jsonData.long)
          } catch (e) {}
        } else if (response.status === 299) {
          cookieLogin().then(() => { $(this).submit() }).catch(window.junx.$router.push('/login'))
        }
      })
  })
}

function UploadtoGalery () {
  $('#UploadtoGalerybtn').addClass('loading disabled')
}

export function initProfile (username) {
  return new Promise(function (resolve, reject) {
    $.post(window.projectURL + 'profile/indexjs', {
      username: username,
      SessionID: sessionStorage.getItem('SessionID')
    }, function (msg, status, xhr) {
      try {
        let data = {}
        if (xhr.status === 250) {
          data = JSON.parse(msg)
          data.status = 'ok'
          resolve(data)
        } else if (xhr.status === 204) {
          data.status = 'notFound'
          resolve(data)
        } else if (xhr.status === 205) {
          data.status = 'otherBlock'
          resolve(data)
        } else if (xhr.status === 206) {
          const data = JSON.parse(msg)
          data.status = 'iBlock'
          resolve(data)
        } else if (xhr.status === 299) {
          reject(new Error(299))
        }
      } catch (e) {
        reject(new Error(e))
      }
    })
  })
}

export function initProfileSettings (username) {
  return new Promise(function (resolve, reject) {
    $.post(window.projectURL + 'profile/editjs', {
      username: username,
      SessionID: sessionStorage.getItem('SessionID')
    }, function (msg, status, xhr) {
      try {
        let data = {}
        if (xhr.status === 250) {
          data = JSON.parse(msg)
          resolve(data)
        } else {
          reject(new Error(xhr.status))
        }
      } catch (e) {
        reject(new Error(e))
      }
    })
  })
}

export function getCities (countryname, cityname, select = null) {
  $.ajax({
    type: 'GET',
    url: window.projectURL + 'other/getCities',
    data: { SessionID: sessionStorage.getItem('SessionID'), country: countryname, city: cityname },
    success: function (response) {
      const arr = $.parseJSON(response)
      $('#wohnort').dropdown('change values', arr.results)
      if (select != null) {
        $('#wohnort').dropdown('set selected', select)
      }
    }
  })
}
