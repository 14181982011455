<template>
  <section class="pad-16" v-if="step == 1">
    <h4 class="ui header centered">
      In welcher Kategorie möchtest du deine Frage stellen?
      <div class="sub header">Wähle eine der untenstehenden Kategorien</div>
    </h4>
    <div class="grid-12" id="categories">
      <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4 t-center item" :class="{active: categoryID == categorie.id}" v-for="(categorie, index) in categories" :key="index" @click="categoryID = categorie.id">
        <div class="ui segment no-border no-shadow">
        <img :src="'assets/images/forum/'+categorie.img"/>
          <h5 class="ui header">{{categorie.title}}</h5>
        </div>
      </div>
    </div>
    <div class="t-right">
      <button class="ui button" @click="step = 1">Zurück</button>
      <button class="ui button btn-color-1" :disabled="!categoryID" @click="step = 2">Weiter</button>
    </div>
  </section>
  <section class="pad-16" v-else-if="step == 2">
    <h4 class="ui header centered">
      Wie lautet der Title deiner Frage
      <div class="sub header">Kurz & Knapp</div>
    </h4>
    <div class="ui form">
      <div class="field">
        <input type="text" v-model="title" placeholder="Titel (mindestens 10 Zeichen)..." minlength="10">
      </div>
    </div>
    <div class="pad-8"></div>
    <div class="t-right">
      <button class="ui button" @click="step = 1">Zurück</button>
      <button class="ui button btn-color-1" :disabled="title.length < 10" @click="step = 3">Weiter</button>
    </div>
  </section>
  <section class="pad-16" v-else-if="step == 3">
    <h4 class="ui header centered">
      Was liegt dir auf dem Herzen?
      <div class="sub header">Erläutere deine Frage genauer</div>
    </h4>
    <div class="ui form">
      <div class="field">
        <textarea v-model="question" placeholder="Beschreibung (mindestens 50 Zeichen)..." minlength="50"></textarea>
      </div>
    </div>
    <div class="pad-8"></div>
    <div class="t-right">
      <button class="ui button" @click="step = 2">Zurück</button>
      <button class="ui button btn-color-1" :disabled="question.length < 50" @click="step = 4">Weiter</button>
    </div>
  </section>
  <section class="pad-16" v-else-if="step == 4">
    Wollen wir das ganz öffentlich posten, oder ziehen wir die Maske uff? :P
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
      <div class="ui segment t-center pad-16 h-100" @click="anonym = 0">
        <div class="checked-icon" v-if="anonym == 0"><img src="assets/images/channels/check.png"/></div>
        <div class="selectContent">
          <img src="assets/images/public.png"/>
          <h4 class="pad-16">Öffentlich Fragen</h4>
          <p>Jeder sieht, dass du der Fragesteller bist.</p>
        </div>
      </div>
    </div>
    <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
      <div class="ui segment t-center pad-16 h-100" @click="anonym = 1">
        <div class="checked-icon" v-if="anonym == 1"><img src="assets/images/channels/check.png"/></div>
        <div class="selectContent">
          <img src="assets/images/private.png"/>
          <h4 class="pad-16">Anonym Fragen</h4>
          <p>Dein Profil wird nicht als Absender gezeigt</p>
        </div>
      </div>
    </div>
    <button @click="step = 3">Zurück</button>
    <button :disabled="anonym == -1" @click="submit">Veröffentlichen</button>
  </section>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ask',
  data () {
    return {
      step: 1,
      projectURL: window.projectURL,
      categories: [],
      categoryID: false,
      title: '',
      question: '',
      anonym: -1
    }
  },
  methods: {
    init () {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(this.projectURL + 'forum/indexJS', formData)
        .then(function (response) {
          if (response.status === 250) {
            _this.categories = response.data.categories
            window.junx.loading = false
          }
        })
    },
    submit () {
      const _this = this
      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('title', this.title)
      formData.append('question', this.question)
      formData.append('categoryID', this.categoryID)
      formData.append('anonym', this.anonym)
      axios.post(this.projectURL + 'forum/ask', formData)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            _this.$router.push('/forum/q/' + response.data.id)
          } else if (response.status === 260) {
            alert('Bitte warte etwas mit der nächsten Frage')
          }
        })
    }
  },
  mounted () {
    this.init()
    window.junx.loading = false
  }
}
</script>

<style scoped>

</style>
