<template>
  <div id="Mobile-Top-Menu">
    <header class="header fixed-top header-fill">
      <nav class="navbar">
        <div>
          <button class="menu-btn btn btn-link btn-44">
            <span class="icon material-icons">menu</span>
          </button>
        </div>
        <div>
          <router-link active-class="active" class="navbar-brand" to="/wall">
            <div class="t-center">
              <img class="logo_main" style="max-height: 45px; padding-top: 4px;" :src="logo" />
            </div>
          </router-link>
        </div>
        <div id="nav-right">
          <router-link active-class="active" class="nav-link " to="/n" onclick="allRead();" data-active="notifications" aria-controls="search" aria-selected="false" style="position: relative;display: inline;color: #999;font-size: 12px;" v-if="socketonline">
            <i class="material-icons" style="vertical-align: middle;display: inline-block;text-align: center;">notifications_none</i>
            <small class="sr-only">notifications_none</small>
            <div class="floating ui blue tiny circular label" v-if="this.$parent.countNoFri > 0">
              {{this.$parent.countNoFri}}
            </div>
          </router-link>
          <router-link active-class="active" :to="'/p/' + user.username">
              <span class="avatar avatar-30" style="background: transparent;" v-if="socketonline">
                <img  class="ar-1-1" :src="projectURL + 'assets/images/user/' + user.profile_img">
              </span>
            <div class="ui segment" style="border: none; box-shadow: none;" v-else>
              <div class="ui active loader"></div>
            </div>
          </router-link>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'mobileTop',
  props: ['user', 'socketonline', 'darkMode'],
  data () {
    return {
      projectURL: window.projectURL
    }
  },
  computed: {
    logo () {
      return this.darkMode ? 'assets/images/dark-junx-beta-pride.png' : 'assets/images/junx-beta-pride.png'
    }
  }
}
</script>

<style>
.header.header-fill .navbar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: 0;
}
#nav-right {
  margin-left: -30px;
}
</style>
