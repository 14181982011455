<template>
  <form class="ui form" id="questionnaireForm" @submit.prevent="sendForm">
    <div class="content">
      <div class="grid-16">
        <div class="col-sp-16 row-sp-16">
          <div class="field">
            <label>Über mich:</label>
            <textarea name="aboutme" placeholder="Über mich" rows="3" :value="userdata.fragebogen.about_me" @blur="submit"></textarea>
          </div>
          <div class="pad-4"></div>
        </div>
        <div class="col-sp-16 row-sp-16 pad-4">
          <h4 class="ui header">Ich bin:</h4>
        </div>
        <div class="col-sp-16 row-sp-16 grid-12 pad-4">
          <div class="col-sp-6 row-sp-12">
            <label>Schüchtern</label>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <label>Forsch</label>
          </div>
          <div class="col-sp-16 row-sp-16 field">
            <input style="width: 100%;" type="range" name="i_am_1" step="5" min="0" max="100" :value="userdata.fragebogen.i_am_1" @change="submit">
          </div>
        </div>
        <div class="col-sp-16 row-sp-16 grid-12 pad-4">
          <div class="col-sp-6 row-sp-12">
            <label>Häuslich</label>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <label>Partygänger</label>
          </div>
          <div class="col-sp-16 row-sp-16 field">
            <input style="width: 100%;" type="range" name="i_am_2" step="5" min="0" max="100" :value="userdata.fragebogen.i_am_2" @change="submit">
          </div>
        </div>
        <div class="col-sp-16 row-sp-16 grid-12 pad-4">
          <div class="col-sp-6 row-sp-12">
            <label>Unkreativ</label>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <label>Kreativ</label>
          </div>
          <div class="col-sp-16 row-sp-16 field">
            <input style="width: 100%;" type="range" name="i_am_3" step="5" min="0" max="100" :value="userdata.fragebogen.i_am_3" @change="submit">
          </div>
        </div>
        <div class="col-sp-16 row-sp-16 grid-12 pad-4">
          <div class="col-sp-6 row-sp-12">
            <label>Gemütlich</label>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <label>Sportlich</label>
          </div>
          <div class="col-sp-16 row-sp-16 field">
            <input style="width: 100%;" type="range" name="i_am_4" step="5" min="0" max="100" :value="userdata.fragebogen.i_am_4" @change="submit">
          </div>
        </div>
        <div class="col-sp-16 row-sp-16 grid-12 pad-4">
          <div class="col-sp-6 row-sp-12">
            <label>Ordentlich</label>
          </div>
          <div class="col-sp-6 row-sp-12 t-right">
            <label>Chaot</label>
          </div>
          <div class="col-sp-16 row-sp-16 field">
            <input style="width: 100%;" type="range" name="i_am_5" step="5" min="0" max="100" :value="userdata.fragebogen.i_am_5" @change="submit">
          </div>
        </div>

        <div class="col-sp-16 row-sp-16">
          <h5 class="ui header active">
            <img class="ui image" src="assets/images/emoticons/like.png">
            <div class="content">
              Ich mag:
            </div>
          </h5>
          <div class="field">
            <textarea name="i_like" placeholder="Ich mag" rows="2" :value="userdata.fragebogen.i_like" @blur="submit"></textarea>
          </div>
          <div class="pad-4"></div>
        </div>
        <div class="col-sp-16 row-sp-16">
          <h5 class="ui header active">
            <img class="ui image" src="assets/images/emoticons/dislike.png">
            <div class="content">
              Ich mag nicht:
            </div>
          </h5>
          <div class="field">
            <textarea name="i_hate" placeholder="Ich mag nicht" rows="2"  :value="userdata.fragebogen.i_hate" @blur="submit"></textarea>
          </div>
          <div class="pad-4"></div>
        </div>
        <div class="col-sp-16 row-sp-16">
          <h5 class="ui header active">
            <img class="ui image" src="assets/images/emoticons/teller.png">
            <div class="content">
              Lieblingsessen:
            </div>
          </h5>
          <div class="field">
            <input type="text" name="favorite_food" placeholder="Lieblingsessen" :value="userdata.fragebogen.favorite_food" @blur="submit">
          </div>
          <div class="pad-4"></div>
        </div>
        <div class="col-sp-16 row-sp-16">
          <h5 class="ui header active">
            <img class="ui image" src="assets/images/emoticons/noten.png">
            <div class="content">
              Lieblingsmusik:
            </div>
          </h5>
          <div class="field">
            <input type="text" name="favorite_music" placeholder="Lieblingsmusik" :value="userdata.fragebogen.favorite_music" @blur="submit">
          </div>
          <div class="pad-4"></div>
        </div>
        <div class="col-sp-16 row-sp-16">
          <h5 class="ui header active">
            <img class="ui image" src="assets/images/emoticons/lampe.png">
            <div class="content">
              Was ich sonst noch sagen möchte:
            </div>
          </h5>
          <div class="field">
            <textarea name="others_text" placeholder="Was ich sonst noch sagen möchte" rows="2" :value="userdata.fragebogen.others_text" @blur="submit"></textarea>
          </div>
          <div class="pad-4"></div>
        </div>
      </div>
    </div>
    <button type="submit" id="submit" style="display: none;"></button>
  </form>
</template>

<script>
import { clickElement } from '@/modules/main'
import axios from 'axios'
export default {
  name: 'questionnaire',
  props: ['userdata'],
  methods: {
    sendForm () {
      const formData = new FormData(document.querySelector('#questionnaireForm'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'profile/editFragebogen', formData)
        .then(function () {
          _this.$emit('resetdata', '')
        })
    },
    submit () {
      clickElement(document.querySelector('#submit'))
    }
  },
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>

</style>
