import axios from 'axios'
let players = []

export function initAudioPlayer (src, id, duration = null) {
  if (!(id in players)) {
    const Player = new AudioPlayer()
    Player.init(src, id, duration)
    players[id] = Player
  } else {
    players[id].play()
  }
}

export function resetAudioPlayer () {
  try {
    for (const key in players) {
      players[key].pause()
      players[key].off()
    }
    players = []
  } catch (e) {}
}

export function pauseAudioPlayer () {
  for (const key in players) {
    players[key].pause()
  }
}

function AudioPlayer () {
  let media
  let amplitudes = []
  let duration
  let steps
  let playing = 0
  let wave
  let element
  let waveSegment
  let speedButton
  let speed = 1
  let playButton
  let time
  let data
  const _this = this

  this.init = function (src, id, durationInit) {
    duration = durationInit
    element = $('#m-' + id).find('.message-content')
    amplitudes = []
    wave = element.find('.wave-container')
    time = element.find('.time-container')
    let ready = false
    playButton = element.find('.play-button')
    speedButton = element.find('.speed-container')
    time.text('0:00')
    element.animate({ width: '500px' }, 500, function () {
      steps = Math.floor(wave.width() / 3)
      if (ready) {
        _this.draw()
      } else {
        ready = true
      }
    })
    // time.animate({ width: '30px' }, 500, function () {})
    speedButton.animate({ width: '20px' }, 500, function () {})
    wave.click(function (event) {
      _this.seekTo($(event.target).attr('id'))
    })
    playButton.on('click', function (e) {
      e.preventDefault()
      if (playing === 1) {
        _this.pause()
      } else {
        _this.play()
      }
    })
    speedButton.on('click', _this.speed)

    const formdata = new FormData()
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))
    formdata.append('file', src)
    axios.post(window.projectURL + '/messages/files/', formdata).then(function (response) {
      if (response.status === 250) {
        data = response.data
        if (ready) {
          _this.draw()
        } else {
          ready = true
        }
      }
    })
  }

  this.off = function () {
    playButton.off('click')
  }

  this.draw = function () {
    try {
      media = new Audio(window.projectURL + data.link)
      media.addEventListener('loadedmetadata', (event) => {
        duration = media.duration
        _this.intervalUpdate()
        _this.play()
        amplitudes = JSON.parse(data.wave)
        wave.empty()
        for (let step = 0; step < steps; step++) {
          const valueTop = parseFloat(amplitudes[Math.round(amplitudes.length / steps * step)].top)
          wave.append('<div class="wave-segment" id="' + (step + 1) + '"><div class="wave" style="height: ' + valueTop + '%"  id="' + (step + 1) + '"></div></div>')
        }
        const delay = 10
        const waves = wave.find('.wave')
        waves.each(function (index) {
          $(this).delay(index * delay).show('drop')
        })
        waveSegment = element.find('.wave-segment')
      })
    } catch (e) {}
  }

  this.intervalUpdate = function () {
    clearInterval(window.timer)
    window.timer = setInterval(function () {
      const playedTime = media.currentTime
      _this.setPosition(playedTime / duration * 100)
      const seconds = playedTime % 60 < 10 ? '0' + Math.round(playedTime % 60) : Math.round(playedTime % 60)
      time.text(Math.floor(playedTime / 60) + ':' + seconds)
    }, 50)
  }

  this.setPosition = function (percent) {
    if (percent > 0) {
      if (percent === 100) {
        _this.pause()
        _this.setPosition(0)
      }
      waveSegment.each(function () {
        if ($(this).attr('id') > percent * steps / 100) {
          $(this).removeClass('played')
        } else {
          $(this).addClass('played')
        }
      })
    }
  }

  this.play = function () {
    for (const key in players) {
      players[key].pause()
    }
    media.play()
    window.media = media
    playing = 1
    playButton.removeClass('play')
    playButton.addClass('pause')
    _this.intervalUpdate()
  }

  this.speed = function () {
    if (speed === 1) {
      speed = 2
      speedButton.addClass('active')
    } else {
      speed = 1
      speedButton.removeClass('active')
    }
    media.playbackRate = speed
  }

  this.pause = function () {
    media.pause()
    clearInterval(window.timer)
    playing = 0
    playButton.removeClass('pause')
    playButton.addClass('play')
  }

  this.seekTo = function (percent) {
    const time = duration * (percent / 100)
    media.currentTime = time
    _this.play()
  }
}

export function addWaveSegment (element, percent, steps) {
  element.find('.wave').first().css('opacity', 1)
  element.prepend('<div class="wave-segment recording"><div class="wave" style="height: ' + Math.round(percent * 100) + '%; display: block; opacity: 0"></div></div>')
  element.find('.wave').slice(steps - 10).css('opacity', 0)
  element.find('.wave-segment').slice(steps).remove()
}
