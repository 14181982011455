/* --------------------SOCKET VERBINDUNG----------------------- */

import { callingPulse, playSound, projectURL, updateStatus } from '@/modules/main'
import router from '@/router'
import { Messenger } from '@/modules/messenger'

const node = ['https://junxnode.20north.de/dev', 'https://junxnode.20north.de/dev']

let connecting
let nodeid = Math.round(Math.random())
window.nodeID = nodeid
window.smids = []
const ringVideo = 'assets/audio/video_ring.mp3'
const calling = false
let change = false
const chatAudio = 'assets/audio/chat.wav'
const notiAudio = 'assets/audio/chat.wav'
// main connection to socket.io
export function connectSocket () {
  const nodeidc = sessionStorage.getItem('NodeID')
  if (window.socketConnecting === true || nodeidc === undefined || nodeidc === '' || nodeidc === null) return
  window.socketConnecting = true

  window.socket = io.connect(node[nodeid], {
    reconnection: false
  })
  window.socket.removeAllListeners()

  // console.log("connecting to " + node[nodeid]);

  window.socket.on('error', () => {
    // console.log("error due to "+err.message);
    change = false
    changeNodeServer()
  })

  window.socket.on('disconnect', () => {
    // console.log("disconnect due to "+err.message);
    change = false
    changeNodeServer()
  })

  window.socket.on('connect_error', () => {
    // console.log("connect_error due to "+err.message);
    change = false
    changeNodeServer()
  })

  window.socket.on('connect_failed', () => {
    // console.log("connect_failed due to " + err.message);
    change = false
    changeNodeServer()
  })

  window.socket.on('connect', function () {
    connecting = false
    window.socket.emit('nodeid', { room: nodeidc, uname: localStorage.getItem('username'), id: localStorage.getItem('user_id'), controller: window.junx.$route.name })
  })

  window.socket.on('registered', (data) => {
    window.junx.socketOnline = true
    try {
      if (typeof initCall === 'function')initCall()
      if (typeof window.chat !== 'undefined') {
        window.chat.activateSocket()
      } else {
        window.chat = new Messenger()
        window.chat.init()
      }
      if (typeof window.wall === 'object' && typeof window.wall.startWall === 'function') {
        window.wall.startWall()
      }
    } catch (e) {
      console.log(e)
    }
  })

  window.socket.on('newNoti', (data) => {
    try {
      if (!window.smids.includes(data.smid)) {
        if (process.env.CORDOVA_PLATFORM !== undefined && localStorage.getItem('vibrate') === '1') {
          navigator.vibrate(50)
        }
        window.smids.push(data.smid)
        window.junx.notis.unshift(data)
        try {
          playSound(notiAudio, true, true)
        } catch (e) {}
      }
    } catch (e) {}
  })

  window.socket.on('notis', (data) => {
    try {
      window.junx.notis = data
      $('#notis').dropdown('show')
    } catch (e) {}
  })

  window.socket.on('newFriend', (data) => {
    try {
      if (!window.smids.includes(data.smid)) {
        window.smids.push(data.smid)
        window.junx.friends.unshift(data)
        playSound(notiAudio, true, true)
      }
    } catch (e) {}
  })

  window.socket.on('friends', (data) => {
    try {
      window.junx.friends = data
    } catch (e) {}
  })

  window.socket.on('newMessage', (data) => {
    if (!window.smids.includes(data.smid)) {
      window.smids.push(data.smid)
      if (data.recipient.id === localStorage.getItem('user_id') && $('#ChatModal').is(':hidden')) {
        playSound(chatAudio, true, true)
      }
    }
  })

  window.socket.on('chatCounter', (data) => {
    try {
      window.junx.chatsCounter = parseInt(data)
    } catch (e) {}
  })

  window.socket.on('ringring', (data) => {
    playSound(ringVideo, true, true)
    if (!$('#videoRequest').hasClass('active') && !calling) {
      callingPulse()
      $('#videoRequest').find('.content').text(data.username + ' möchte mit dich sehen!')
      $('#videoRequestImg').attr('src', projectURL + 'assets/images/user/' + data.profile_img)
      $('#videoRequest')
        .modal({
          closable: true,
          onDeny: function () {
            console.log({ videoRoom: data.videoRoom, pid: localStorage.getItem('user_id') })
            window.socket.emit('denyCall', { videoRoom: data.videoRoom, pid: localStorage.getItem('user_id') })
            $('#videoRequest').modal('hide')
          },
          onApprove: function () {
            $('#videoRequest').modal('hide')
            router.push({ name: 'Call', params: { room: data.videoRoom, username: data.username, profile_img: data.profile_img } })
          }
        })
        .modal('show')
    }
  })
}

// change Nginx-LoadBalancer for socket.io-clusters
function changeNodeServer () {
  if (!change) {
    window.socketConnecting = false
    change = true
    window.junx.socketOnline = false
    // console.log('Wechsle Server');
    nodeid = (nodeid === 1) ? 0 : 1
    setTimeout(function () {
      connectSocket()
    }, 2000)
  }
}

window.ring = function () {
  const data = { username: 'pogo', videoRoom: 5 }
  playSound(ringVideo, true, true)
  if (!$('#videoRequest').hasClass('active') && !calling) {
    callingPulse()
    $('#videoRequest').find('.content').text(data.username + ' möchte mit dich sehen!')
    $('#videoRequestImg').attr('src', projectURL + 'assets/images/user/' + data.profile_img)
    $('#videoRequest')
      .modal({
        closable: true,
        onDeny: function () {
          console.log({ videoRoom: data.videoRoom, pid: localStorage.getItem('user_id') })
          window.socket.emit('denyCall', { videoRoom: data.videoRoom, pid: localStorage.getItem('user_id') })
          $('#videoRequest').modal('hide')
        },
        onApprove: function () {
          $('#videoRequest').modal('hide')
          router.push({ name: 'Call', params: { room: data.videoRoom, username: data.username, call: data.username, profile_img: data.profile_img } })
        }
      })
      .modal('show')
  }
}
