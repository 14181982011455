<template>
  <div id="page-nutzungsbedingungen" class="spaceTop">
    <div class="content-1200 pad-16">
      <div class="ui segment no-border pad-24 t-center">
        <h1 class="ui header">
          Nutzungsbedigungen
          <div class="sub header">Die Junx’schen Verhaltensregeln</div>
        </h1>
        <div>
          <h3 class="ui header">Einleitung</h3>
          <p>
            Junx ist die Community, die es sich zur Aufgabe gemacht hat, queere Jungs zu verbinden und für einen sicheren Austausch zu sorgen. Wir möchten ein Ort sein, an dem jeder seine Erlebnisse und Erfahrungen mit allen unbesorgt teilen kann, ohne Missbrauch - in welcher Form auch immer - erfahren zu müssen.
          </p>
          <p>
            Um das sicherzustellen, haben wir unsere ausführlichen Verhaltensregeln verfasst, die für ein vernünftiges Miteinander sorgen und Junx zu deinem Zuhause machen soll.
          </p>
          <div class="pad-24">
            <div class="ui violet message">
              <h3 class="ui header">Das Junx’sche Gesetz</h3>
              <p>
                Wie Oma früher schon immer sagte…<br>
                <i>„Was du nicht willst, das man dir tut, das füg auch keinem anderen zu.“</i>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-16">
        <h3 class="ui divider horizontal header">
          Bilder & Videos
        </h3>
      </div>
      <!--
      <div class="ui segment no-border pad-24">
          <div>
              <p>
                  Sonderregelung für Profilbilder <br>
                  Um allen Junx-Profilen eine Persönlichkeit zu geben, eignen sich nicht alle Bilder als Profilbild.<br>
              </p>
              <p>
                  Verzichte deshalb auf folgendes…<br>
                  - Bilder von Landschaften<br>
                  - Bilder von Promis, Vorbildern, usw.<br>
                  - Bilder, die nicht dich zeigen<br>
                  - Bilder, mit mehr als drei Personen<br>
                  - Memes, Comics, usw.<br>
                  - Bilder, auf denen du dich unkenntlich gemacht hast <i style="cursor: pointer;" onclick="$('#info1.ui.modal').modal('show');" class="info circle icon"></i><br>
                  <div class="ui tiny modal" id="info1">
                      <div class="header">Info</div>
                      <div class="content">
                          <p>
                              Beispiele hierfür wären schwarze Balken oder Emojis, die dich bzw. dein Gesicht komplett zensieren. Fotos, auf denen man mit einem Motorradhelm oder anderen potentiellen Alltagsgegenständen zu sehen ist, sind wiederum in Ordnung.
                          </p>
                      </div>
                  </div>
              </p>
              <p>
                  Profilbilder, die diese Kriterien nicht erfüllen sollten, können vom Support gegebenenfalls gelöscht werden.
              </p>
          </div>
      </div>
      -->
      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Persönliches
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
              - Videos/Bilder mit persönlichen Angaben <i style="cursor: pointer;" onclick="$('#info2.ui.modal').modal('show');" class="info circle icon"></i><br>
            <div class="ui tiny modal" id="info2">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Uns ist der Schutz unserer User sehr wichtig, weshalb wir Fotos und Videos von Ausweisen, Führerscheinen o.Ä. löschen. Grundsätzlich sollte man sich immer gut überlegen, welche Informationen man von sich preisgibt, da man nie weiß, in welche Hände sowas geraten kann.
              </p>
            </div>
          </div>
            - Dein FakeCheck-Foto <i style="cursor: pointer;" onclick="$('#info3.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info3">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Achte bitte darauf, dass das Foto ausschließlich im offiziellen FakeCheck-Bereich von Junx hochgeladen wird. Damit gehen wir sicher, dass das Foto nicht für einen sogenannten Identitätsdiebstahl und Fakeprofil genutzt werden kann.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Sexuelles
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Videos/Bilder auf denen Genitalien vollständig oder nur teilweise zu sehen sind<br>
            - Videos/Bilder auf denen die Umrisse von Genitalien durch Kleidung zu erkennen sind<br>
            - Videos/Bilder auf denen der Intimbereich mit Gegenständen verdeckt oder anderweitig zensiert wurde <i style="cursor: pointer;" onclick="$('#info4.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info4">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Beispiele: Kissen, Hut, schwarzer Balken, Emoji, usw.
              </p>
            </div>
          </div>
          - Videos/Bilder auf denen der Fokus nur auf Intimbereiche liegt<br>
          - Jedes Video/Bild, das sexuelle Aktivitäten oder übermäßig zweideutige Darstellung enthält<br>
          - Videos/Bilder, die übermäßig erotische Darstellung enthalten<br>
          - Jegliche Form sexualisierter Gewalt<br>
          - Alle Videos/Bilder, die körperliche Ausscheidungen enthalten <i style="cursor: pointer;" onclick="$('#info5.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info5">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Beispiele wären Inhalte mit Sperma, Urin, Kot, usw.
              </p>
            </div>
          </div>
          - Videos/Bilder in Kleidung, die für alltägliche und öffentliche Bereiche unangemessen wären <i style="cursor: pointer;" onclick="$('#info6.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info6">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Uns ist es wichtig, dass auf Junx ein positives Benutzererlebnis herrscht. Aus diesem Grund müssen wir teilweise Fotos löschen, die für alltägliche und öffentliche Bereiche unangemessen erscheinen. Somit wäre beispielsweise ein Foto in Boxershorts unangemessen, da man das in der Öffentlichkeit in Normalfall nicht antreffen würde. Fotos in Badehose in Schwimmbädern sind hingegen absolut in Ordnung.
                <br><br>
                Individualentscheidungen sind jederzeit dem Support überlassen.
              </p>
            </div>
          </div>
          - Fetischbekleidungen <i style="cursor: pointer;" onclick="$('#info7.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info7">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Junx ist eine jugendfreie Plattform, weshalb wir bei bestimmten Themen sensibler reagieren müssen. Uns ist zwar bewusst, dass Fetische oft mehr sind, als das Ausleben von sexuellen Vorlieben, allerdings ist die Gratwanderung oft sehr schwierig zu differenzieren. Aus diesem Grund müssen wir solche Inhalte auf unserer Plattform leider komplett verbieten, da alles andere für den Support nicht moderierbar ist. Dazu gehören dementsprechend auch Inhalte, die Pup-playing oder Furrys darstellen.
              </p>
            </div>
          </div>
          - Bilder/Videos von Sexspielzeug <i style="cursor: pointer;" onclick="$('#info8.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info8">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Junx ist eine jugendfreie Plattform, weshalb uns die Einhaltung des Jugendschutzes ein wichtiges Anliegen ist. Da Sexspielzeug zu sexualisierend ist, müssen wir die leider pauschal verbieten, da alles andere für den Support nicht moderierbar wäre.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Illegales & Sicherheit
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
          - Videos/Bilder mit Darstellung oder Ermutigung zur Selbstverletzung oder Selbsttötung <i style="cursor: pointer;" onclick="$('#info10.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info10">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Inhalte, in denen direkt oder indirekt mitgeteilt wird, dass ein User nicht mehr leben möchte, sind als suizidale Hilferufe zu sehen, weshalb diese keinesfalls überhört werden dürfen. Zögere in so einem Fall bitte nicht und melde unserem Support das entsprechende Video/Bild über unsere integrierte Melde-Funktion und der Rubrik „Suizidaler Hilferuf“. Unser Team wird sich anschließend schnellstmöglich um dieses Anliegen kümmern.
              </p>
            </div>
          </div>
          - Videos/Bilder mit obszönen, beleidigenden, diffamierenden oder diskriminierenden Inhalten<br>
          - Gewaltverherrlichende Inhalte jeglicher Art<br>
          - Verherrlichung von illegalen Substanzen nach deutschem Recht<br>
          - Videos/Bilder die gegen das deutsche Recht verstoßen<br>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Werbung & Spam
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Werben für andere private Social Media Kanäle und/oder Kontaktmöglichkeiten <i style="cursor: pointer;" onclick="$('#info11.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info11">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Angaben zu anderen privaten Social Media Kanälen dürfen lediglich in dem dafür vorgesehenen privaten Bereich auf deinem Profil verlinkt werden.
                <br><br>
                In allen anderen Bereichen dulden wir keine Verweise auf...
                WhatsApp, SnapChat, Telefonie, Instagram, Discord, Skype, Teamspeak, Tellonym, usw.
                <br><br>
                Hingegen erlaubt ist das Erwähnen von…
                YouTube-, TikTok-Videos oder Streamingdiensten wie Twitch o.Ä.
                <br><br>
                Für bestimmte Anlässe ist es möglich, sich eine Erlaubnis beim Support einzuholen. Dieses Angebot gilt beispielsweise für offizielle Jugendgruppen oder Partnerprofile von Junx, um für einen entsprechenden Mehrwert zu sorgen. Schreibe hierfür einfach eine Mail an <a href="mailto:support@junx.app">support@junx.app</a>.
              </p>
            </div>
          </div>
          - Alle grundlosen wiederholenden Inhalte, mit der bloßen Absicht, die Community zu nerven <br>
          - Videos/Bilder, die ausschließlich für Produkte oder Dienstleistungen werben<br>
          - Kommerzielle Werbung jeglicher Art<br>
        </div>
      </div>
      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Urheberrecht
          <div class="sub header">
            Diese Verhaltensregeln beziehen sich auf alle Texte, die öffentlich einsehbar sind.
          </div>
        </h3>
        <div>
          <p>
            - Bilder/Videos an denen du nicht die Rechte hast<br>
            - Bilder/Videos mit Freunden, deren Zustimmung du nicht hast<br>
          </p>
        </div>
      </div>
      <div class="pad-16">
        <h3 class="ui divider horizontal header">
          Öffentliche Texte
        </h3>
        <p class="t-center">
          Diese Verhaltensregeln beziehen sich auf alle Texte, die öffentlich einsehbar sind.
        </p>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Persönliches
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Texte mit persönlichen Angaben <i style="cursor: pointer;" onclick="$('#info12.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info12">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Uns ist der Schutz unserer User sehr wichtig, weshalb wir Inhalte wie das Geburtsdatum, die Wohnadresse oder ähnliche persönliche Angaben löschen. Grundsätzlich sollte man sich immer gut überlegen, welche Informationen man von sich preisgibt, da man nie weiß, in welche Hände sowas geraten kann.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Sexuelles
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Anfragen und Gesuche für sexuelle Aktivitäten <i style="cursor: pointer;" onclick="$('#info13.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info13">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Darunter fällt auch die indirekte Suche nach Sex, Freundschaft+ oder sexuelle Gefälligkeiten, wie dem Bildertausch.
              </p>
            </div>
          </div>
          - Taschengeld-Anfragen und Gesuche jeglicher Art, inkl. Geschenke<br>
          - Den Verkauf und Kauf von Klamotten, wie Boxershorts, Socken o.Ä.<br>
          - Details zu Sexpraktiken, Fetischen, extremen Vorlieben oder diversem "Spielzeug".<br>
          - Links zu pornografischen Seiten<br>

        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Illegales & Sicherheit
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Texte die zur Selbstverletzung oder Selbsttötung motivieren <i style="cursor: pointer;" onclick="$('#info14.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info14">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Inhalte, in denen direkt oder indirekt mitgeteilt wird, dass ein User nicht mehr leben möchte, sind als suizidale Hilferufe zu sehen, weshalb diese keinesfalls überhört werden dürfen. Zögere in so einem Fall bitte nicht und melde unserem Support das entsprechende Video/Bild über unsere integrierte Melde-Funktion und der Rubrik „Suizidaler Hilferuf“. Unser Team wird sich anschließend schnellstmöglich um dieses Anliegen kümmern.
              </p>
            </div>
          </div>
          - Texte mit obszönen, beleidigenden, diffamierenden oder diskriminierenden Inhalten<br>
          - Gewaltverherrlichende Inhalte jeglicher Art<br>
          - Verherrlichung von illegalen Substanzen nach deutschem Recht<br>
          - Texte, die gegen das deutsche Recht verstoßen<br>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Werbung und Spam
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Werben für andere private Social Media Kanäle und/oder Kontaktmöglichkeiten <i style="cursor: pointer;" onclick="$('#info15.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info15">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Angaben zu anderen privaten Social Media Kanälen dürfen lediglich in dem dafür vorgesehenen privaten Bereich auf deinem Profil verlinkt werden.
                <br><br>
                In allen anderen Bereichen dulden wir keine Werbung für...
                WhatsApp, SnapChat, Telefonie, Instagram, Discord, Skype, Teamspeak, Tellonym, usw.
                <br><br>
                Hingegen erlaubt ist das Erwähnen von…
                YouTube-, TikTok-Videos oder Streamingdiensten wie Twitch o.Ä.
                <br><br>
                Für bestimmte Anlässe ist es möglich, sich eine Erlaubnis beim Support einzuholen. Dieses Angebot gilt beispielsweise für offizielle Jugendgruppen oder Partnerprofile von Junx, um für einen entsprechenden Mehrwert zu sorgen. Schreibe hierfür einfach eine Mail an support@junx.app.                            </p>
            </div>
          </div>
          - Alle grundlosen wiederholenden Inhalte, mit der bloßen Absicht, die Community zu nerven<br>
          - Texte, die ausschließlich dafür gedacht sind, Unterhaltungen zu stören und die Community zu schädigen<br>
          - Texte, die ausschließlich für Produkte oder Dienstleistungen werben<br>
          - Kommerzielle Werbung jeglicher Art<br>
        </div>
      </div>

      <div class="pad-16">
        <h3 class="ui divider horizontal header">
          Junx-Messenger
        </h3>
        <p class="t-center">
          Uns ist es wichtig, dass User sich bei uns wohl fühlen. Dazu gehört auch die freie Entfaltung, die wir in privaten Unterhaltungen nicht kontrollieren wollen. Aus diesem Grund sind auch alle deine Chats verschlüsselt, damit das auch gewährleistet wird.
          <br><br>
          Bedenke jedoch, dass der Chat keine regelfreie Zone ist und der Support auch hier durchaus einschreitet, wenn uns ein Verhalten gemeldet wird, das nicht in Ordnung ist.
          <br><br>
          Dementsprechend gilt auch hier unser Grundsatz nach dem Junx’schen Gesetz.
        </p>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Hinweise für ein sicheres Miteinander
        </h3>
        <div>
          <h4 class="ui header">Schütze deine Daten</h4>
          <p>
            Sei bedacht, wenn es um die Weitergabe von persönlichen Daten geht. Denn das Internet ist groß und anonym, weshalb man nie sicher sein kann, wer da wirklich am anderen Ende des Chats sitzt.
          </p>
          <div class="pad-4"></div>
          <h4 class="ui header">Bleibe auf Junx</h4>
          <p>
            Uns ist es ein großes Anliegen, dass man auf Junx sicher kommunizieren kann. Für diese Sicherheit können wir allerdings nur sorgen, solange man sich innerhalb unserer Plattform aufhält. Sobald man also auf externe Dienste (WhatsApp, SnapChat, o.Ä.) wechselt, sind uns oft die Hände gebunden, wenn es da zu Regelverstößen kommen sollte.
          </p>
          <div class="pad-4"></div>
          <h4 class="ui header">Ein User wirkt komisch?</h4>
          <p>
            Sollte dich ein User nerven, möchten wir dir hierfür unsere Blockier-Funktion empfehlen. Sobald du den Nutzer blockiert hast, wirst du keine Inhalte mehr von ihm sehen können. Ebenso bleiben auch deine Inhalte vor ihm versteckt.
            <br><br>
            Solltest du dich allerdings in einem Chat zunehmend unwohl fühlen und dich ein User in irgendeiner Form sogar belästigen, kannst du uns den Chat auch jederzeit melden, damit wir uns das mal näher anschauen.
          </p>
          <div class="pad-4"></div>
        </div>
      </div>
      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Sexuelles
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Anfragen und Gesuche für sexuelle Aktivitäten  <i style="cursor: pointer;" onclick="$('#info16.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info16">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Darunter fällt auch die indirekte Suche nach Sex, Freundschaft+ oder sexuelle Gefälligkeiten, wie dem Bildertausch.
              </p>
            </div>
          </div>
          - Taschengeld-Anfragen und Gesuche jeglicher Art, inkl. Geschenke <i style="cursor: pointer;" onclick="$('#info17.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info17">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Taschengeld-Anfragen stellen eine Straftat dar, wenn diese an Minderjährige gerichtet sind. Sollten uns derartige Meldungen erreichen, behalten wir uns das Recht vor, dies zur Anzeige zu bringen!
              </p>
            </div>
          </div>
          - Den Verkauf und Kauf von Klamotten, wie Boxershorts, Socken o.Ä.<br>
          - Details zu Sexpraktiken, Fetischen, extremen Vorlieben oder diversem "Spielzeug"<br>
          - Belästigende Inhalte<br>
          - Links zu pornografischen Seiten<br>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Illegales & Sicherheit
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Chats die zur Selbstverletzung oder Selbsttötung motivieren<i style="cursor: pointer;" onclick="$('#info18.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info18">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Inhalte, in denen direkt oder indirekt mitgeteilt wird, dass ein User nicht mehr leben möchte, sind als suizidale Hilferufe zu sehen, weshalb diese keinesfalls überhört werden dürfen. Zögere in so einem Fall bitte nicht und melde unserem Support das entsprechende Video/Bild über unsere integrierte Melde-Funktion und der Rubrik „Suizidaler Hilferuf“. Unser Team wird sich anschließend schnellstmöglich um dieses Anliegen kümmern.
              </p>
            </div>
          </div>
          - Chats mit obszönen, beleidigenden, diffamierenden oder diskriminierenden Inhalten<br>
          - Gewaltverherrlichende Inhalte jeglicher Art<br>
          - Verherrlichung von illegalen Substanzen nach deutschem Recht<br>
          - Chats, die gegen das deutsche Recht verstoßen<br>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Werbung & Spam
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
          <p>
            - Kettenbriefe<br>
            - Texte, die ausschließlich für Produkte oder Dienstleistungen werben<br>
            - Kommerzielle Werbung jeglicher Art<br>
          </p>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Profil
          <div class="sub header">
            Verboten sind…
          </div>
        </h3>
        <div>
            - Profile, in denen du falsche Angaben zu deiner Person gemacht hast (sog. Fake-Accounts)<br>
            - Profile, in denen es vorrangig um Sex geht (sog. Sex-Accounts)<br>
            - Taschengeld-Anfragen und Gesuche jeglicher Art, inkl. Geschenke <i style="cursor: pointer;" onclick="$('#info20.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info20">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Davon ausgeschlossen sind offizielle Werbepartner von Junx.
              </p>
            </div>
          </div>
          - Troll-Accounts, die ausschließlich die Absicht haben, für Unruhe und Unfrieden zu sorgen <i style="cursor: pointer;" onclick="$('#info21.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info21">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Bei Trolling behält Junx sich das Recht vor, Nutzer zu überprüfen und gegebenenfalls temporär oder dauerhaft und ohne jede Vorwarnung zu sperren.
              </p>
            </div>
          </div>
          - Usernamen mit obszönen, beleidigenden, diffamierenden oder diskriminierenden Bedeutungen<br>
        </div>
      </div>

      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Zusätzliches für das Forum
          <div class="sub header">
            Zusätzlich zu den Verhaltensregeln für öffentliche Texte, gelten im Forum noch diese Regeln, die auch hier für Sucht ähh… Zucht und Ordnung sorgen sollen.
          </div>
        </h3>
        <div>
            - Beiträge die vom Thema abweichen<br>
            - Unnötige Kurzantworten <i style="cursor: pointer;" onclick="$('#info22.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info22">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Antworten sollen konstruktiv gehalten werden. Daher sind Aussagen wie “Ich stimme dir zu” o.Ä. nicht erwünscht und können vom Support gelöscht werden.
              </p>
            </div>
          </div>
          - die Nennung von Nutzernamen in anonymen Fragen<br><br>
          Grundsätze im Forum...<br>

          - Respektiere die Anonymität von Fragenstellern <i style="cursor: pointer;" onclick="$('#info23.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info23">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Es gibt Fragen und Probleme, die Nutzer anonym stellen wollen. Fordere sie daher nicht auf, dir privat zu schreiben, um zu einer Lösung zu finden. Fragen und Probleme sollen daher ausschließlich im Forum geklärt werden.
              </p>
            </div>
          </div>
          - Wähle eine passende Kategorie, die zu deiner Frage passt <i style="cursor: pointer;" onclick="$('#info24.ui.modal').modal('show');" class="info circle icon"></i><br>
          <div class="ui tiny modal" id="info24">
            <div class="header">Info</div>
            <div class="content">
              <p>
                Mit der passenden Kategorie kann deine Frage besser gefunden und auch schneller beantwortet werden.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="ui segment no-border pad-24">
        <h3 class="ui header">
          Dies und das...
        </h3>
        <h4 class="ui header">
          Meldung von Nutzern
        </h4>
        <p>
          Auch wenn wir bemüht sind, Fakern und Regelverstößen nachzugehen, können wir leider nicht alles sehen - so sehr wir auch möchten. Aus diesem Grund sind wir auf deine persönliche Hilfe angewiesen. Solltest du also Inhalte vorfinden, die nicht unseren Regeln entsprechen, kannst du diese jederzeit über unsere integrierten Funktionen melden.
          <br><br>
          Auch wenn wir für jede Mithilfe dankbar sind, wollen wir trotzdem an dieser Stelle noch erwähnen, dass Nutzerbeschwerden bitte nur getätigt werden sollen, sofern Regelverstöße wirklich vorliegen.
        </p>
        <div class="pad-4"></div>
        <h4 class="ui header">
          Ahndung von Verstößen
        </h4>
        <p>
          Mit der Registrierung auf Junx, akzeptiert jeder Nutzer automatisch unsere Regeln. Bei Verstößen behält Junx sich das Recht vor, Nutzer aus der Community entweder temporär oder sogar dauerhaft und ohne jede Vorwarnung zu sperren. Die Sperre ist einem virtuellen Hausverbot gleichzusetzen, weshalb eine Neuregistrierung untersagt ist.
          <br><br>
          In Fällen von illegaler Aktivität behält Junx sich das Recht vor, mit entsprechenden Behörden zu kooperieren und gegebenenfalls Daten - im Rahmen des Datenschutzes - zu übergeben.
        </p>
        <div class="pad-4"></div>
        <h4 class="ui header">
          Entscheidungen durch unsere Junx vom Support
        </h4>
        <a>
          Wie alles im Leben, sind auch Regelverstöße nicht immer schwarz oder weiß, sondern kunterbunt. Aus diesem Grund entscheidet unser Support bei jedem einzelnen Regelverstoß individuell und macht die Sanktionierung von folgenden Faktoren abhängig:
          <br>
          - Wie hart war der Regelverstoß? (Schweregrad, Kontext, usw.)<br>
          - Wie oft ist der Nutzer schon negativ aufgefallen?<br>
          - Ist der Nutzer schon häufiger mit demselben Verstoß aufgefallen?<br>
          - Wie lange liegen seine ganzen Verstöße schon zurück?<br>
          <br>
          Damit trotz der Individualitäten eine faire Sanktionierung stattfindet, sind alle Supporter geschult und arbeiten nach internen und einheitlichen Konzepten und Systemen.
          <br><br>
          Du wurdest irrtümlich sanktioniert?<br>
          Solltest du den Eindruck haben, dass dem Support bei dir ein Fehler unterlaufen sein muss, kannst du dich jederzeit über <a href="mailto:support@junx.app">support@junx.app</a> an uns wenden. Der Fall wird anschließend erneut überprüft und die Sanktion gegebenenfalls rückgängig gemacht oder angepasst.
          <div class="pad-4"></div>
        </a>
      </div>
      <div class="ui label">Letzte Änderung: 28.06.2021 - Aktualisierungen vorbehalten.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nutzungsbedingungen',
  title: 'Junx | Nutzungsbedingungen',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>

#page-nutzungsbedingungen{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
/* Darkmode */
.dark #page-nutzungsbedingungen .ui.label{
  background-color: #000000;
  color: rgba(255,255,255,0.8);
}

</style>
