<template>
  <form class="ui form" id="socialForm" @submit.prevent="sendForm">
    <div class="content">
      <div class="field">
        <label>Instagram:</label>
        <input name="socialmedia_instagram" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+).*" placeholder="https://www.instagram.com/..." :value="userdata.details.socialmedia_instagram" oninput="$(this).val($(this).val().toLowerCase());" @blur="submit">
      </div>
      <div class="field">
        <label>Twitch:</label>
        <input name="socialmedia_twitch" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.)?twitch\.tv\/([A-Za-z0-9-_\.]+).*" placeholder="https://www.twitch.tv/..." :value="userdata.details.socialmedia_twitch" oninput="$(this).val($(this).val().toLowerCase());">
      </div>
      <div class="field">
        <label>YouTube:</label>
        <input name="socialmedia_youtube" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:channel|c|user)\/([A-Za-z0-9-_\.]+).*" placeholder="https://www.youtube.com/..." :value="userdata.details.socialmedia_youtube" oninput="$(this).val($(this).val().toLowerCase());" @blur="submit">
      </div>
      <div class="field">
        <label>Twitter:</label>
        <input name="socialmedia_twitter" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.|mobile\.)?twitter\.com\/([A-Za-z0-9-_\.]+).*" placeholder="https://www.twitter.com/..." :value="userdata.details.socialmedia_twitter" oninput="$(this).val($(this).val().toLowerCase());" @blur="submit">
      </div>
      <div class="field">
        <label>LinkedIn:</label>
        <input name="socialmedia_linkedin" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.)?linkedin\.com\/in\/([A-Za-z0-9-_\.]+).*" placeholder="https://www.linkedin.com/..." :value="userdata.details.socialmedia_linkedin" oninput="$(this).val($(this).val().toLowerCase());" @blur="submit">
      </div>
      <div class="field">
        <label>TikTok:</label>
        <input name="socialmedia_tiktok" type="text" pattern="(?:(?:http|https):\/\/)?(?:www\.|vm\.)?tiktok\.com\/@([A-Za-z0-9-_\.]+).*" placeholder="https://www.tiktok.com/..." :value="userdata.details.socialmedia_tiktok" oninput="$(this).val($(this).val().toLowerCase());" @blur="submit">
      </div>
      <button type="submit" id="submit" style="display: none;"></button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
import { clickElement } from '@/modules/main'
export default {
  name: 'socialMedia',
  props: ['userdata'],
  data () {
    return {
      reload: false
    }
  },
  methods: {
    sendForm () {
      const _this = this
      this.reload = true
      const formData = new FormData(document.querySelector('#socialForm'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'profile/editProfileSocialMedia', formData)
    },
    submit () {
      clickElement(document.querySelector('#submit'))
    }
  },
  mounted () {
    window.junx.loading = false
  },
  beforeRouteLeave (to, from, next) {
    const _this = this
    if (this.reload) {
      _this.$emit('resetdata', '')
    }
    next()
  }
}
</script>

<style scoped>

</style>
