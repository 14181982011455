function generateRandomString () {
  return Math.random().toString(36).slice(2).substring(0, 15)
}

function closeVideo (elemId) {
  if (document.getElementById(elemId)) {
    document.getElementById(elemId).remove()
    adjustVideoElemSize()
  }
}

function pageHasFocus () {
  return !(document.hidden || document.onfocusout || window.onpagehide || window.onblur)
}

function userMediaAvailable () {
  return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)
}

function getUserFullMedia () {
  if (userMediaAvailable()) {
    return navigator.mediaDevices.getUserMedia({
      video: true,
      audio: {
        echoCancellation: true,
        noiseSuppression: true
      }
    })
  } else {
    throw new Error('User media not available')
  }
}

function replaceTrack (stream, recipientPeer) {
  const sender = recipientPeer.getSenders ? recipientPeer.getSenders().find(s => s.track && s.track.kind === stream.kind) : false
  if (sender) sender.replaceTrack(stream)
}

function toggleVideoBtnDisabled (disabled) {
  document.getElementById('toggle-video').disabled = disabled
}

function singleStreamToggleMute (e) {
  if (e.target.classList.contains('fa-microphone')) {
    e.target.parentElement.previousElementSibling.muted = true
    e.target.classList.add('fa-microphone-slash')
    e.target.classList.remove('fa-microphone')
  } else {
    e.target.parentElement.previousElementSibling.muted = false
    e.target.classList.add('fa-microphone')
    e.target.classList.remove('fa-microphone-slash')
  }
}

function toggleModal (id, show) {
  const el = document.getElementById(id)

  if (show) {
    el.style.display = 'block'
    el.removeAttribute('aria-hidden')
  } else {
    el.style.display = 'none'
    el.setAttribute('aria-hidden', true)
  }
}

function setLocalStream (stream, mirrorMode = true) {
  const localVidElem = document.getElementById('local')

  localVidElem.srcObject = stream
  mirrorMode ? localVidElem.classList.add('mirror-mode') : localVidElem.classList.remove('mirror-mode')
}

function adjustVideoElemSize () {
  const elem = document.getElementsByClassName('card')
  const totalRemoteVideosDesktop = elem.length
  const newWidth = totalRemoteVideosDesktop <= 2 ? '50%' : (
    totalRemoteVideosDesktop === 3 ? '33.33%' : (
      totalRemoteVideosDesktop <= 8 ? '25%' : (
        totalRemoteVideosDesktop <= 15 ? '20%' : (
          totalRemoteVideosDesktop <= 18 ? '16%' : (
            totalRemoteVideosDesktop <= 23 ? '15%' : (
              totalRemoteVideosDesktop <= 32 ? '12%' : '10%'
            )
          )
        )
      )
    )
  )

  for (let i = 0; i < totalRemoteVideosDesktop; i++) {
    elem[i].style.width = newWidth
  }
}

function makeid (length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength))
  }
  return result
}

const audio = new Audio('assets/audio/video_tuuuuuuuut.mp3')

var pc = []
var iceservers
var socketId = ''
var myStream = ''
var screen = ''
const nodeidc = sessionStorage.getItem('NodeID')

export async function initCall (room, username) {
  let call = false

  if (!username && !room) {
    $('#notAllowed').show()
    $('#videoDiv').hide()
  } else {
    $('#notAllowed').hide()
    $('#videoDiv').show()
    if (!room || room == null || room === '' || room === 'null') {
      room = makeid(10)
      call = true
    }
    const commElem = document.getElementsByClassName('room-comm')

    for (let i = 0; i < commElem.length; i++) {
      try {
        commElem[i].attributes.removeNamedItem('hidden')
      } catch (e) { }
    }
    let accept = false

    // Get user video by default
    getUserFullMedia().then((stream) => {
      // save my stream
      myStream = stream

      setLocalStream(stream)
    }).catch((e) => {
      console.error(e)
    })

    // set socketId
    socketId = window.socket.io.engine.id
    setTimeout(function () {
      if (!accept) {
        window.socket.emit('exitCall', { videoRoom: room, pid: username, nodeidc: nodeidc })
      }
    }, 60000)

    window.socket.emit('videoChat', {
      room: room,
      socketId: socketId
    })

    if (call) {
      ring(accept, username, room)
    }

    window.socket.on('turn', (data) => {
      iceservers = data.conf
    })

    window.socket.on('new user', (data) => {
      console.log('new user')
      if (socketId !== data.socketId) {
        accept = true
        if (pc.length === 0) {
          socket.emit('newUserStart', { to: data.socketId, sender: socketId })
          pc.push(data.socketId)
          init(true, data.socketId)
        }
      }
    })

    window.socket.on('newUserStart', (data) => {
      console.log('newUserStart')
      accept = true
      if (pc.length === 0) {
        pc.push(data.sender)
        init(false, data.sender)
      }
    })

    window.socket.on('ice candidates', async (data) => {
      console.log('ice candidates')
      if (data.candidate) await pc[data.sender].addIceCandidate(new RTCIceCandidate(data.candidate))
    })

    window.socket.on('sdp', async (data) => {
      console.log('sdp')
      if (data.description.type === 'offer') {
        if (data.description) await pc[data.sender].setRemoteDescription(data.description)

        getUserFullMedia().then(async (stream) => {
          if (!document.getElementById('local').srcObject) {
            setLocalStream(stream)
          }
          // save my stream
          myStream = stream
          stream.getTracks().forEach((track) => {
            pc[data.sender].addTrack(track, stream)
          })
          const answer = await pc[data.sender].createAnswer()

          await pc[data.sender].setLocalDescription(answer)

          socket.emit('sdp', { description: pc[data.sender].localDescription, to: data.sender, sender: socketId })
        }).catch((e) => {
          console.error(e)
        })
      } else if (data.description.type === 'answer') {
        await pc[data.sender].setRemoteDescription(data.description)
      }
    })

    window.socket.on('exitCall', (data) => {
      window.junx.callModal = false
      window.junx.callDetails = {}
    })

    $('#endIt').on('click', function () {
      window.socket.emit('exitCall', { videoRoom: room, pid: username, nodeidc: nodeidc })
    })

    $(window).on('beforeunload', function () {
      window.socket.emit('exitCall', { videoRoom: room, pid: username, nodeidc: nodeidc })
    })

    const camI = 0
    let devices = await navigator.mediaDevices.enumerateDevices()
    devices = devices.filter(function (el) {
      return el.kind === 'videoinput'
    })

    // When the mute icon is clicked

    document.getElementById('toggle-mute').addEventListener('click', (e) => {
      e.preventDefault()
      const elem = document.getElementById('toggle-mute')
      const targ = e.target.tagName === 'I' ? e.target : e.target.getElementsByTagName('i')[0]

      if (myStream.getAudioTracks()[0].enabled) {
        targ.classList.remove('fa-microphone-alt')
        targ.classList.add('fa-microphone-alt-slash')
        elem.setAttribute('title', 'Unmute')

        myStream.getAudioTracks()[0].enabled = false
      } else {
        targ.classList.remove('fa-microphone-alt-slash')
        targ.classList.add('fa-microphone-alt')
        elem.setAttribute('title', 'Mute')

        myStream.getAudioTracks()[0].enabled = true
      }

      this.broadcastNewTracks(myStream, 'audio')
    })

    document.getElementById('toggle-video').addEventListener('click', (e) => {
      e.preventDefault()

      const elem = document.getElementById('toggle-video')
      const targ = e.target.tagName === 'I' ? e.target : e.target.getElementsByTagName('i')[0]

      window.a = e.target
      if (myStream.getVideoTracks()[0].enabled) {
        targ.classList.remove('fa-video')
        targ.classList.add('fa-video-slash')
        elem.setAttribute('title', 'Show Video')
        elem.setAttribute('title', 'Show Video')

        myStream.getVideoTracks()[0].enabled = false
      } else {
        targ.classList.remove('fa-video-slash')
        targ.classList.add('fa-video')
        elem.setAttribute('title', 'Hide Video')

        myStream.getVideoTracks()[0].enabled = true
      }

      this.broadcastNewTracks(myStream, 'video')
    })
  }
}

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function pulse () {
  $('#profile_img').animate({
    width: 300, height: 300
  }, 700, function () {
    $('#profile_img').animate({
      width: 200, height: 200
    }, 700, function () {
      pulse()
    })
  })

  $('#profile_img_div').animate({
    width: 200,
    height: 200,
    opacity: 0.5
  }, 700, function () {
    $('#profile_img_div').animate({
      width: 300,
      height: 300,
      opacity: 1
    }, 700, function () {
    })
  })
}

function ring (accept, username, room) {
  if (!accept) {
    audio.pause()
    audio.volume = localStorage.getItem('volume') / 100
    audio.play()
    window.socket.emit('startCall', { username: window.junx.username, room: nodeidc, pid: username, videoRoom: room })
  }
  setTimeout(function () {
    ring(accept, username, room)
  }, 7000)
}

function init (createOffer, partnerName) {
  pc[partnerName] = new RTCPeerConnection(iceservers)

  if (screen && screen.getTracks().length) {
    screen.getTracks().forEach((track) => {
      pc[partnerName].addTrack(track, screen)// should trigger negotiationneeded event
    })
  } else if (myStream) {
    myStream.getTracks().forEach((track) => {
      pc[partnerName].addTrack(track, myStream)// should trigger negotiationneeded event
    })
  } else {
    getUserFullMedia().then((stream) => {
      // save my stream
      myStream = stream

      stream.getTracks().forEach((track) => {
        pc[partnerName].addTrack(track, stream)// should trigger negotiationneeded event
      })

      setLocalStream(stream)
    }).catch((e) => {
      console.error(e)
    })
  }

  // create offer
  if (createOffer) {
    pc[partnerName].onnegotiationneeded = async () => {
      const offer = await pc[partnerName].createOffer()

      await pc[partnerName].setLocalDescription(offer)

      window.socket.emit('sdp', { description: pc[partnerName].localDescription, to: partnerName, sender: socketId })
    }
  }

  // send ice candidate to partnerNames
  pc[partnerName].onicecandidate = ({ candidate }) => {
    window.socket.emit('ice candidates', { candidate: candidate, to: partnerName, sender: socketId })
  }

  // add
  pc[partnerName].ontrack = (e) => {
    const str = e.streams[0]
    if (document.getElementById(partnerName + '-video')) {
      document.getElementById(partnerName + '-video').srcObject = str
    } else {
      // video elem
      const newVid = document.createElement('video')
      newVid.id = partnerName + '-video'
      newVid.srcObject = str
      newVid.autoplay = true
      newVid.setAttribute('playsInline', 'true')
      newVid.className = 'remote-video'
      newVid.onpause = function () {
        this.play()
      }

      // video controls elements
      const controlDiv = document.createElement('div')
      controlDiv.className = 'remote-video-controls'
      controlDiv.innerHTML = '<i class="fa fa-clone text-white pr-3 changeFit" style="font-size: 8vh;" title="Aspect Ratio"></i><i class="fa fa-microphone text-white pr-3 mute-remote-mic" style="font-size: 8vh;" title="Mute"></i><i class="fa fa-expand text-white expand-remote-video" style="font-size: 8vh" height="10vh" title="Expand"></i>'
      controlDiv.style.height = '10vh'
      controlDiv.style.textAlignLast = 'right'

      // create a new div for card
      const cardDiv = document.createElement('div')
      cardDiv.className = 'card card-sm'
      cardDiv.id = partnerName
      cardDiv.appendChild(newVid)
      cardDiv.appendChild(controlDiv)
      // document.sel('.remote-video').style.setProperty('--cli', 2 + 'px')

      // put div in main-section elem
      document.getElementById('videos').appendChild(cardDiv)

      adjustVideoElemSize()
    }
    $('#call_details').hide()
  }

  pc[partnerName].onconnectionstatechange = (d) => {
    switch (pc[partnerName].iceConnectionState) {
      case 'disconnected':
      case 'failed':
        window.socket.emit('videoChat', {
          room: room,
          socketId: socketId
        })
        $('#call_details').show()
        closeVideo(partnerName)
        pc = []
        break

      case 'closed':
        closeVideo(partnerName)
        pc = []
        break

      case 'connected':
        this.broadcastNewTracks(myStream, 'audio')
        this.broadcastNewTracks(myStream, 'video', true)
        break
    }
  }

  pc[partnerName].onsignalingstatechange = (d) => {
    switch (pc[partnerName].signalingState) {
      case 'closed':
        console.log("Signalling state is 'closed'")
        closeVideo(partnerName)
        pc = []
        break
    }
  }
}

function broadcastNewTracks (stream, type, mirrorMode = true) {
  setLocalStream(stream, mirrorMode)

  const track = type === 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]

  for (const p in pc) {
    const pName = pc[p]

    if (typeof pc[pName] === 'object') {
      replaceTrack(track, pc[pName])
    }
  }
}
