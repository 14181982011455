<template>
  <main id="Settings" class="spaceTop">
    <div class="content-1200">
      <div class="pad-16" id="SettingsHeader">
        <div class="ui segment no-shadow no-border pad-32">
          <div class="grid-16">
            <div class="col-sp-8 row-sp-16">
              <div class="t-left">
                <a @click="back()" class="ui icon circular basic button">
                  <i class="icon left arrow"></i>
                </a>
              </div>
            </div>
            <div class="col-sp-8 row-sp-16">
              <div class="t-right">

              </div>
            </div>
            <div class="col-sp-16 row-sp-16 t-center">
              <img id="settings_profile_img" class="ui image small centered circular ar-1-1" :src="projectURL + 'assets/images/user/' + user.profile_img">
              <h2 class="ui header centered">
                Dein Profil
                <div class="sub header">Sei kreativ!</div>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="pad-8 grid-16">
        <div class="col-sp-16 col-sd-10 row-sp-16 pad-8">
          <div class="SettingsPanel ui segment no-shadow no-radius no-border pad-32" style="display: block !important">
            <router-view @resetdata="loadProfileSettings" :userdata="userdata" v-if="userdata"/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from '@/router'
import { decode } from '@/modules/main'
import { initProfileSettings } from '@/modules/profile'
export default {
  name: 'editProfile',
  title: 'Junx | Profil bearbeiten',
  props: ['user'],
  data () {
    return {
      projectURL: window.projectURL,
      userdata: false
    }
  },
  computed: {
  },
  methods: {
    loadProfileSettings () {
      const _this = this
      initProfileSettings(this.user.username)
        .then(function (data) {
          data.profile.profile_description = data.fragebogen.profile_description === null ? null : decode(data.profile.profile_description)
          data.fragebogen.about_me = data.fragebogen.about_me === null ? null : decode(data.fragebogen.about_me)
          data.fragebogen.i_like = data.fragebogen.i_like === null ? null : decode(data.fragebogen.i_like)
          data.fragebogen.i_hate = data.fragebogen.i_hate === null ? null : decode(data.fragebogen.i_hate)
          data.fragebogen.others_text = data.fragebogen.others_text === null ? null : decode(data.fragebogen.others_text)
          data.fragebogen.favorite_music = data.fragebogen.favorite_music === null ? null : decode(data.fragebogen.favorite_music)
          data.fragebogen.favorite_food = data.fragebogen.favorite_food === null ? null : decode(data.fragebogen.favorite_food)
          data.ownInterests = data.ownInterests.split(',')
          _this.userdata = data
          localStorage.setItem('subscribed_channels', data.ownInterests)

          window.junx.loading = false
        })
        .catch(function () {
          _this.$router.push('/login')
        })
    },
    back () {
      router.back()
    }
  },
  mounted () {
    this.loadProfileSettings()
    window.junx.loading = false
  }
}
</script>

<style scoped>
</style>
