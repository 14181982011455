export function closeSideMenu () {
  $('body').removeClass('sidemenu-open')
  $('body').removeClass('reveal-sidebar')
  $('.modal').modal('hide')
  $('.dimmed').click()
  window.scrollTo(0, 0)
  // $('body').removeClass('pushable')
  $('body').removeClass('dimmable')
  $('.scrollToTop').hide()
}
