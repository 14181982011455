import { getCities } from '@/modules/profile'
export let stage

// step 2
export function initCountry (app) {
  $('#countrys').dropdown('setup menu', {
    action: 'hide',
    values: app.countrys
  })
  $('#countryValue').on('change', function () {
    app.country = $(this).val()
    getCities($(this).val(), '')
    $('#wohnort').dropdown({
      apiSettings: {
        // this url parses query server side and returns filtered results
        url: window.projectURL + 'other/getcities/?country=' + $(this).val() + '&city={query}',
        cache: false
      }
    })
  })
}
