<template>
  <form class="ui form" id="loveForm" @submit.prevent="sendForm">
    <div class="content">
      <div class="field">
        <label>Orientierung:</label>
        <div class="ui fluid selection dropdown sexuality">
          <input type="hidden" name="sexuality" >
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Heterosexuell">Heterosexuell</div>
            <div class="item" data-value="Homosexuell">Homosexuell</div>
            <div class="item" data-value="Bisexuell">Bisexuell</div>
            <div class="item" data-value="Pansexuell">Pansexuell</div>
            <div class="item" data-value="Androsexuell">Androsexuell</div>
            <div class="item" data-value="Gynosexuell">Gynosexuell</div>
            <div class="item" data-value="Polysexuell">Polysexuell</div>
            <div class="item" data-value="Skoliosexuell">Skoliosexuell</div>
            <div class="item" data-value="Queer">Queer</div>
            <div class="item" data-value="Asexuell">Asexuell</div>
            <div class="item" data-value="Gray-Asexuell">Gray-Asexuell</div>
            <div class="item" data-value="Keine Schublade">Keine Schublade</div>
            <div class="item" data-value="Unsicher">Unsicher</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Coming-Out:</label>
        <div class="ui fluid selection dropdown outing_state">
          <input type="hidden" name="comingoutstate">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Überall geoutet">Überall geoutet</div>
            <div class="item" data-value="bei Familie und Freunden">bei Familie und Freunden</div>
            <div class="item" data-value="bei Familie">bei Familie</div>
            <div class="item" data-value="bei Freunden">bei Freunden</div>
            <div class="item" data-value="Ungeoutet">Ungeoutet</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>Beziehungsstatus:</label>
        <div class="ui fluid selection dropdown relation_state">
          <input type="hidden" name="relationstate">
          <i class="dropdown icon"></i>
          <div class="default text"><i>Keine Angabe</i></div>
          <div class="menu">
            <div class="item" data-value="Single">Single</div>
            <div class="item" data-value="Vergeben">Vergeben</div>
            <div class="item" data-value="Polyamorische Beziehung">Polyamorische Beziehung</div>
            <div class="item" data-value="Offene Beziehung">Offene Beziehung</div>
            <div class="item" data-value="Verlobt">Verlobt</div>
            <div class="item" data-value="Verheiratet">Verheiratet</div>
            <div class="item" data-value="Verliebt">Verliebt</div>
            <div class="item" data-value="Es ist kompliziert">Es ist kompliziert</div>
            <div class="item" data-value="Ich geh mit meiner Laterne...">Ich geh mit meiner Laterne...</div>
            <div class="item" data-value="Keine Angabe">Keine Angabe</div>
          </div>
        </div>
      </div>
      <div class="field">
        <label id="setPartner">Partnerprofil:</label>
        <input name="relationpartner" required placeholder="Partner" type="text" :value="userdata.details.relationpartner" @blur="submit">
      </div>
    </div>
    <button type="submit" id="submit" style="display: none;"></button>
  </form>
</template>

<script>
import axios from 'axios'
import { clickElement } from '@/modules/main'
export default {
  name: 'love',
  props: ['userdata'],
  data () {
    return {
      reload: false
    }
  },
  methods: {
    sendForm () {
      const _this = this
      this.reload = true
      const formData = new FormData(document.querySelector('#loveForm'))
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      axios.post(window.projectURL + 'profile/editProfileLovedetails', formData)
    },
    submit () {
      clickElement(document.querySelector('#submit'))
    }
  },
  mounted () {
    $('.sexuality').dropdown('set selected', this.userdata.details.sexuality)
    $('.outing_state').dropdown('set selected', this.userdata.details.outing_state)
    $('.relation_state').dropdown('set selected', this.userdata.details.relation_state)
    $('#loveForm').find('.dropdown').dropdown('setting', 'onChange', function () {
      _this.submit()
    })
    window.junx.loading = false
  },
  beforeRouteLeave (to, from, next) {
    const _this = this
    if (this.reload) {
      _this.$emit('resetdata', '')
    }
    next()
  }
}
</script>

<style scoped>

</style>
