<template>
  <div id="page-kontakt" class="spaceTop">
    <div class="content-1000">
      <div>
        <img src="assets/images/banner/kontakt-desktop.jpg" class="responsive-img pagebanner-desktop">
        <img src="assets/images/banner/kontakt-mobil.jpg" class="responsive-img pagebanner-mobil">
      </div>
      <div class="pad-24"></div>
      <div class="pad-16">
        <h1 class="ui header">
          Kontaktformular
          <div class="sub header">Schreibe uns eine Nachricht</div>
        </h1>
      </div>
      <div class="pad-16" v-if="send">
        <div class="ui success message">
          <i class="close icon"></i>
          <div class="header">
            Deine Nachricht wurde erfolgreich versendet
          </div>
          <p>Wir werden uns schnellstmöglich um dein Anliegen kümmern</p>
        </div>
      </div>
      <form method="post" action="kontakt/sendMessage" class="ui form">
        <div class="grid-12 pad-8">
          <div class="col-sp-12 col-tb-6 row-sp-16 pad-4">
            <div class="field">
              <label>Name</label>
              <input type="text" name="name" placeholder="Name" required>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-16 pad-4">
            <div class="field">
              <label>E-Mail</label>
              <input type="email" name="email" placeholder="E-Mail" required>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="field">
              <label>Betreff</label>
              <input type="text" name="subject" placeholder="Betreff" required>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="field">
              <label>Nachricht</label>
              <textarea name="message" placeholder="Nachricht" required></textarea>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4 t-right">
            <button class="ui button btn-color-1" type="submit"><i class="envelope icon"></i> Nachricht absenden</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'kontakt',
  title: 'Junx | Kontakt',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-kontakt{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
</style>
