<template>
  <div id="page-features"  class="spaceTop">
    <section>
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-tb-6 row-sp-16 pad-40">
          <img src="assets/images/channels-models.png" style="transform: rotate(-5deg)" class="pad-16">
        </div>
        <div class="col-sp-16 col-tb-10 row-sp-16 pad-40">
          <div class="t-right" style="margin-bottom: -48px; position: relative; z-index: 5;">
            <img src="assets/images/channels.png">
          </div>
          <div class="ui segment no-border no-shadow pad-24">
            <h3 class="ui header">
              Deine Interessen, dein Junx!
              <div class="sub header">Inhalte & Kontakte die zu dir passen</div>
            </h3>
            <p>
              Junx hilft dir dabei neue Freunde* zu finden und dich mit anderen Menschen auszutauschen, die so ticken wie du.
              <br><br>
              Aus diesem Grund fragen wir dich bei der Registrierung, für welche Themen du dich interessierst. Anhand dieser Angaben werden dir auf der App verschiedene ,,Kanäle’’ angezeigt, in denen du dich mit anderen Nutzern zum jeweiligen Thema austauschen kannst.
              <br><br>
              Außerdem nutzen wir diese Information auch, um dir in den ,,Junx-Matches’’ andere Jungs* vorzuschlagen, die sich für Dinge interessieren, die du auch magst.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-tb-8 row-sp-16 pad-40">
          <h3 class="ui header">
            <img src="assets/images/emoticons/snap-dates.png">
            <div class="content">
              Junx Dates <a class="ui label bg-color-1">Coming soon</a>
              <div class="sub header">Lerne Spontan neue Jungs* kennen</div>
            </div>
          </h3>
          <p>
            Du möchtest mit deinem Hund in den Park oder ein Eis in der Stadt essen gehen, dir fehlt aber die passende Begleitung? Mit den Junx Dates bist du nicht mehr allein unterwegs!
            <br><br>
            Schreibe einfach kurz, was du unternehmen möchtest. Jungs* in deiner Nähe werden benachrichtigt und können dich kontaktieren. Wenn ihr euch gut versteht, könnt ihr Details zu Ort und Uhrzeit vereinbaren und euch treffen.
          </p>
          <div class="pad-40">
            <video src="assets/videos/snap-dates.mp4" autoplay loop muted class="br-16" style="transform: rotate(3deg); max-width: 500px; width: 100%;"></video>
          </div>
        </div>
        <div class="col-sp-16 col-tb-8 row-sp-16 pad-16 grid-12">
          <div class="col-sp-12">
            <div class="pad-8"></div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 t-center">
            <div class="pad-16"></div>
            <img src="assets/images/junx-snap-date-mockup.png" class="responsive-img">
          </div>
          <div class="col-sp-12 col-tb-8 row-sp-12">
            <div class="pad-16"></div>
            <h4 class="ui header">
              <img src="assets/images/snapdates/kino.png">
              <div class="content">
                Ins Kino gehen
                <div class="sub header">und gemeinsam einen neuen Film anschauen.</div>
              </div>
            </h4>
            <h4 class="ui header">
              <img src="assets/images/snapdates/bowling.png">
              <div class="content">
                Bowling
                <div class="sub header">in lockerer Atmosphäre bowlen.</div>
              </div>
            </h4>
            <h4 class="ui header">
              <img src="assets/images/snapdates/spazieren.png">
              <div class="content">
                Spazieren
                <div class="sub header">gemeinsam durch den Park schlendern.</div>
              </div>
            </h4>
            <h4 class="ui header">
              <img src="assets/images/snapdates/freizeitpark.png">
              <div class="content">
                Freizeitpark
                <div class="sub header">Achterbahn oder Einhornkarusell?</div>
              </div>
            </h4>
            <h4 class="ui header">
              <img src="assets/images/snapdates/party.png">
              <div class="content">
                Feiern gehen
                <div class="sub header">bei guter Musik und Drinks.</div>
              </div>
            </h4>
            <h4 class="ui header">
              <img src="assets/images/snapdates/sport.png">
              <div class="content">
                Sport & Fitness
                <div class="sub header">Joggen oder doch ins Fitnessstudio?</div>
              </div>
            </h4>
          </div>
        </div>
      </div>
      <div class="pad-40"></div>
    </section>
    <section class="bg-color-1">
      <div class="content-1400 grid-16">
        <div class="col-sp-16 col-tb-9 row-sp-16">
          <div class="pad-40 grid-12">
            <div class="col-sp-12 col-tb-5 row-sp-12 t-center">
              <img src="assets/images/forum-smartphone.png" style="transform: rotate(-5deg); max-width: 250px;">
            </div>
            <div class="col-sp-12 col-tb-7 row-sp-12">
              <div class="pad-16"></div>
              <div class="ui segment no-border no-shadow pad-24">
                <h3 class="ui header">
                  Das neue mobile Forum
                  <div class="sub header">Fragen und Anliegen</div>
                </h3>
                <p>
                  Egal ob es um die neue Kultserie oder dein Coming-Out geht: Im Forum kannst du Fragen stellen und dich mitteilen. Heikle Themen kannst du dabei sogar anonym ansprechen. Ganz unkompliziert von deinem Smartphone aus, immer und überall.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-16 col-tb-7 row-sp-16" style="position: relative;">
          <img src="assets/images/forum-julian-wallpaper.png" style="max-width: 1200px; position: relative; bottom: 0;" class="responsive-img">
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="content-1400 grid-16 pad-40">
        <div class="col-sp-16 col-tb-10 row-sp-16">
          <h3 class="ui header">
            <div class="content">
              Der Junx Messenger
              <div class="sub header">Dein Chat für unterwegs</div>
            </div>
          </h3>
          <p>
            Mit dem Junx Messenger bleibst du mit deinen Freunden* in Kontakt, egal wo du gerade bist. Verschicke Text- und Sprachnachrichten oder chatte live per Video.
            <br><br>
            Wer dir private Nachrichten schicken darf, entscheidest du selbst.
          </p>
          <div class="pad-16"></div>
          <video src="assets/videos/junx-messenger.mp4" autoplay loop muted class="br-16" style="transform: rotate(-3deg); max-width: 500px; width: 100%;"></video>
        </div>
        <div class="col-sp-16 col-tb-6 row-sp-16 t-center pad-16">
          <img src="assets/images/messenger-mockup-complete-website.png" style="max-width: 500px;" class="responsive-img">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'features',
  title: 'Junx | Features',
  mounted () {
    window.junx.loading = false
  }
}
</script>

<style scoped>
#page-features{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
#page-features h3.ui.header{
  font-size: 2em;
  color: rgba(0,0,0,0.8);
}
#page-features h3.ui.header .sub.header{
  font-size: 0.7em;
}
#page-features p{color: rgba(0,0,0,0.6);}
/* Darkmode */
.dark #page-features p{color: rgba(255,255,255,0.6);}
</style>
