<template>
  <div class="item" :data-value="channels[ownChannel].id">
    <div class="scimd"><img :src="'assets/images/channels/' + channels[ownChannel].channel_img"> </div>
    <div class="sct">&nbsp;{{channels[ownChannel].channel_title}}</div>
  </div>
</template>

<script>
export default {
  name: 'channelWall',
  props: ['channels', 'ownChannel', 'verified']
}
</script>

<style>

</style>
