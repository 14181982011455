<template>
  <div class="magazinArticleHeader" :style="{ 'background-image': 'url(' + projectURL + 'assets/images/magazin/' + article.article_img + ')' }">
    <div class="ratioBox169"></div>
    <div class="magazinArticleHeaderContent">
      <h1 class="ui inverted header">
        {{article.article_title}}
        <div class="sub header">
          {{article.article_subtitle}}
        </div>
      </h1>
    </div>
  </div>
  <div class="ui segment no-border no-radius grid-16" style="margin-top: 0;">
    <div class="col-sp-16 col-sd-10 row-sp-16 pad-24">
      <div>
        <div class="ui small breadcrumb">
          <router-link to="/blog" class="section">Magazin</router-link>
          <i class="right chevron icon divider"></i>
          <router-link :to="'/blog/s/' + article.cname" class="section">{{article.cname}}</router-link>
          <i class="right chevron icon divider"></i>
          <div class="active section">{{article.article_title}}</div>
        </div>
      </div>
      <div>
        <div class="pad-4"></div>
        <a class="ui basic image label">
          <img :src="projectURL + 'assets/images/authors/'+article.author_image">
          {{article.author_name}}
        </a>
        <router-link class="ui violet label" :to="'/blog/s/' + article.cname">{{article.cname}}</router-link>
        <a class="ui label">
          {{article.date}}
        </a>
        <div class="pad-16"></div>
      </div>
      <div class="magazin-content" v-html="article.article_content"></div>
    </div>
    <div class="col-sp-16 col-sd-6 row-sp-16 pad-8" v-if="article.qcn == 1">
      <div class="ui fluid card">
        <div class="image">
          <img src="assets/images/deine-digitale-queere-jugendgruppe.png">
        </div>
        <div class="content" >
          <a class="header">Ein Beitrag vom</a>
          <div class="meta">
            <span class="date">Queer Content Network e.V.</span>
          </div>
        </div>
        <div class="extra content">
          <a class="ui button fluid" href="https://www.rainbowwarriors.eu/" target="_blank">
            Zur Website
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'article',
  props: ['article'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>

</style>
