<template>
  <section class="pad-16">
    <div class="ui icon message" v-if="questions.length == 0 && !subLoading">
      <i class="search icon"></i>
      <div class="content">
        <div class="header">
          Leider nichts gefunden!
        </div>
        <p>
          Wir konnten keine Ergebnisse zu deiner Suche finden.<br>
          Stelle doch gerne selber eine Frage.
        </p>
      </div>
    </div>
    <div class="rainbowloader forumLoader" v-if="subLoading && questions.length == 0">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
      </div>
    </div>
    <question-overview v-for="(question, index) in questions" :question="question" :key="index"></question-overview>
  </section>
</template>

<script>
import questionOverview from '@/components/forum/questionOverview'
import { cookieLogin } from '@/modules/main'
import axios from 'axios'
export default {
  name: 'overview',
  props: ['search'],
  data () {
    return {
      projectURL: window.projectURL,
      questions: [],
      moreLoad: false,
      subLoading: true
    }
  },
  components: {
    questionOverview
  },
  methods: {
    loadQuestions (add = false) {
      const _this = this
      window.junx.loading = false

      const formData = new FormData()
      formData.append('SessionID', sessionStorage.getItem('SessionID'))
      formData.append('postkey', sessionStorage.getItem('postkey'))
      formData.append('query', this.search)
      formData.append('offset', this.questions.length)
      axios.post(this.projectURL + 'forum/search', formData)
        .then(function (response) {
          if (response.status === 250) {
            const data = response.data
            for (const key in data.questions) {
              // data.questions[key].title = decodeURIComponent( escape(data.questions[key].title));
            }
            if (add) {
              _this.questions = _this.questions.concat(data.questions)
            } else {
              _this.questions = data.questions
            }
            _this.subLoading = false
            _this.moreLoad = (data.questions.length === 10)
          } else if (response.status === 299) {
            cookieLogin().then(() => _this.loadQuestions()).catch(() => _this.$router.push('/login'))
          }
        })
    }
  },
  watch: {
    search () {
      this.loadQuestions()
    }
  },
  mounted () {
    this.loadQuestions()
  }
}
</script>

<style scoped>

</style>
